<div class="login-wrapper" fxLayout="row" fxLayoutAlign="center center">

    <mat-card style="margin: 0;border: 0;box-shadow: none;background-color: transparent;">
        <img mat-card-image style="width: 200px;" src="../../../assets/images/quizbrew-logo-name-v1.01.png"
            alt="App name">
        <br>
        <asl-google-signin-button style="margin-left: 10px;" type='standard' size='medium'></asl-google-signin-button>
        <br>
        <a href="https://ogenkisoftlab.com/#" target="_blank" style="margin-top: 200px;margin-left: 10px;">
            <img style="width: 190px;" src="../assets/images/footer-logo.png" alt="" srcset=""></a>
    </mat-card>

</div>