import { Component } from '@angular/core';
import { ActivatedRoute, NavigationStart, Router } from '@angular/router';
import { filter, map } from 'rxjs';
import { AuthService } from 'src/app/service/auth.service';
ActivatedRoute
@Component({
  selector: 'app-breadcrumbs',
  templateUrl: './breadcrumbs.component.html',
  styleUrls: ['./breadcrumbs.component.css']
})
export class BreadcrumbsComponent {
  menuHeader: any = [];
  appitemsTravel;
  path;

  childExam = "/masterfile/exam";
  masterfileRoutes = [
    { path: '/masterfile/exam', redirectTo: 'exam', title: 'Exam' },
    { path: '/masterfile/category', title: 'Category' },
    { path: '/masterfile/user-exam', title: 'Users' },
    { path: '/masterfile/account-member', title: 'Account Member' },
    { path: '/masterfile/exam-assignment', title: 'Exam Assignment' },
  ]

  accountRoutes = [
    { path: 'account/subscription', title: 'Subscription' },
    { path: 'account/pay-model', title: 'Pay Model' },
    { path: 'account/group', title: 'Account Group' },
  ]

  user: any;
  isAdmin = false;
  constructor(
    private router: Router,
    private activatedRoute: ActivatedRoute,
    private authService: AuthService

  ) { }

  ngOnInit() {
    // console.log(this.router.config)
    this.initUser();
  }
  async initUser() {
    this.user = this.authService.getUser();
    if (this.user != null) {
      this.isAdmin = this.authService.extractId(this.user.azp) === 1;
      const isGroup = this.user.group !== "Individual - No Group";

      if (!this.isAdmin) {
        this.masterfileRoutes.splice(2, 1);
        this.masterfileRoutes.splice(1, 1);
        if (!isGroup) {
          this.masterfileRoutes.splice(1, 1);
          this.masterfileRoutes.splice(0, 1);
        }
      }
    }

  }
  onModuleChange() {
    this.redirect(this.childExam)
  }
  private sub = this.router.events
    .pipe(
      filter(event => event instanceof NavigationStart),
      map(event => event as NavigationStart)
    )
    .subscribe((event) => {
      const route = this.router.url;
      const paths = event.url.split('/').filter(Boolean);
      // paths.shift();
      const result = paths.reduce((acc, path, index) => {
        const capitalizedPath = path.charAt(0).toUpperCase() + path.slice(1);
        const spacedPath = capitalizedPath.replace(/-/g, ' ');
        const fullPath = index === 0 ? path : `${acc[index - 1].fullPath}/${path}`;
        acc.push({ fullPath, path: spacedPath });
        return acc;
      }, []);

      this.menuHeader = result;
      this.path = this.menuHeader[0].path
      this.childExam = route;
    }
    );

  ngOnDestroy() {
    console.log('>> STOP listening to events for DetailsComponent');
    this.sub.unsubscribe();
  }

  redirect(path) {
    this.router.navigate([path]);
  }

  breadCrumb(menu, index, item) {
    this.menuHeader.splice(index + 1, this.menuHeader.length - 1);
    if (menu[index] && menu[index].items && menu[index].items.length) {
      this.appitemsTravel = menu[index].items;
    }
  }

  menuChange(menuChange) {
    if (menuChange.items) {
      this.appitemsTravel = menuChange.items;
      this.menuHeader.push({ label: menuChange.label, icon: menuChange.icon, class: menuChange.class, items: menuChange.items });
    }
  }
}
