import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { RouterModule, Routes } from '@angular/router';
import { ExamListComponent } from './exam-list/exam-list.component';
import { LoginComponent } from './components/login/login.component';
import { authGuard, authGuardLogin } from './auth/auth.guard';
import { ExamComponent } from './exam/exam.component';
import { ExamFileComponent } from './masterfile/exam-file/exam-file.component';
import { CategoryComponent } from './masterfile/category/category.component';
import { QuestionsComponent } from './masterfile/questions/questions.component';
import { UserExamComponent } from './masterfile/user-exam/user-exam.component';
import { UserExamListComponent } from './masterfile/user-exam-list/user-exam-list.component';
import { UnauthorizedComponent } from './components/unauthorized/unauthorized.component';
import { ConfirmUserComponent } from './components/confirm-user/confirm-user.component';
import { UserRegistrationComponent } from './components/user-registration/user-registration.component';
import { AuditTrailComponent } from './audit-trail/audit-trail.component';
import { ExamCatalogueComponent } from './exam-catalogue/exam-catalogue.component';
import { ExamAssignmentComponent } from './masterfile/exam-assignment/exam-assignment.component';
import { PayModelComponent } from './account/pay-model/pay-model.component';
import { GroupComponent } from './account/group/group.component';
import { SubscriptionComponent } from './account/subscription/subscription.component';
import { SubscriptionPlanComponent } from './subscription-plan/subscription-plan.component';
import { SubscribeComponent } from './subscribe/subscribe.component';
import { AccountMemberComponent } from './masterfile/account-member/account-member.component';
import { FinalizeResultComponent } from './masterfile/finalize-result/finalize-result.component';


const routes: Routes = [
  { 
    path: '', 
    pathMatch: 'full',
    redirectTo: 'login' 
  },
  { 
    path: 'login', 
    component: LoginComponent,
    canActivate: [authGuardLogin],
    title: 'QuizBrew - Login'
  },
  { 
    path: 'exam-list/:type', 
    component: ExamListComponent,
    canActivate: [authGuard],
    title: 'QuizBrew - Exam'
  },
  { 
    path: 'my-exam', 
    component: ExamListComponent,
    canActivate: [authGuard],
    title: 'QuizBrew - My Exam'
  },
  {
    path: 'exam/:id/:mode/:userExamId', 
    component: ExamComponent,
    canActivate: [authGuard],
    title: 'QuizBrew - Exam'
  },
  {
    path: 'unauthorized', 
    component: UnauthorizedComponent,
    title: 'QuizBrew - Unauthorized'

  },
  {
    path: 'confirm-user', 
    component: ConfirmUserComponent,
    canActivate: [authGuardLogin],
    title: 'QuizBrew - Confirm Account'
  },
  {
    path: 'confirm-user/:data', 
    component: ConfirmUserComponent,
    canActivate: [authGuardLogin],
    title: 'QuizBrew - Confirm Account'
  },
  {
    path: 'signup', 
    component: UserRegistrationComponent,
    canActivate: [authGuardLogin],
    title: 'QuizBrew - Register'
  },
  {
    path: 'exam-catalogue', 
    component: ExamCatalogueComponent,
    canActivate: [authGuard],
    title: 'QuizBrew - Exam Catalogue'
  },
  {
    path: 'subscription-plan', 
    component: SubscriptionPlanComponent,
    canActivate: [authGuard],
    title: 'QuizBrew - Subscription Plan'
  },
  {
    path: 'subscription-plan/:id', 
    component: SubscribeComponent,
    canActivate: [authGuard],
    title: 'QuizBrew - Subscription Plan'
  },
  {
    path: 'audit-trail', 
    component: AuditTrailComponent,
    canActivate: [authGuard],
    title: 'QuizBrew - Audit Trail'
  },
  {
    path: 'masterfile', 
    canActivate: [authGuard],
    children: [
      { path: '', redirectTo: 'exam', pathMatch: 'full' },
      { path: 'category', component: CategoryComponent, title: 'QuizBrew - Category Masterfile'},
      { path: 'exam', component: ExamFileComponent, title: 'QuizBrew - Exam Masterfile' },
      { path: 'exam/:id', component: QuestionsComponent, title: 'QuizBrew - Exam Masterfile' },
      { path: 'questions', component: QuestionsComponent, title: 'QuizBrew - Question Masterfile' },
      { path: 'user-exam', component: UserExamComponent, title: 'QuizBrew - Users' },
      { path: 'user-exam/:id', component: UserExamListComponent, title: 'QuizBrew - User Exam' },
      { path: 'exam-assignment', component: ExamAssignmentComponent, title: 'QuizBrew - Exam Assignment' },
      { path: 'account-member', component: AccountMemberComponent, title: 'QuizBrew - Account Member' },
      { path: 'account-member/:id', component: UserExamListComponent, title: 'QuizBrew - Account Member Exam' },
      { path: 'exam-result/:examId/:userId', component: FinalizeResultComponent, title: 'QuizBrew - Exam Result' },

    ]
  },
  {
    path: 'account', 
    canActivate: [authGuard],
    children: [
      { path: '', redirectTo: 'subscription', pathMatch: 'full' },
      { path: 'subscription', component: SubscriptionComponent, title: 'QuizBrew - Subscription'},
      { path: 'pay-model', component: PayModelComponent, title: 'QuizBrew - Pay Model' },
      { path: 'group', component: GroupComponent, title: 'QuizBrew - Account Group' },
    ]
  }

];

@NgModule({
  imports: [RouterModule.forRoot(routes,  { useHash: true }),
    CommonModule],
  exports: [RouterModule]
})
export class AppRoutingModule { }