import { Component, Input, ViewChild } from '@angular/core';
import { ActivatedRoute, Router, RouterModule } from '@angular/router';
import { MatButtonModule } from '@angular/material/button';
import { MatCardModule } from '@angular/material/card';
import { MatDialog, MatDialogModule, MatDialogConfig } from '@angular/material/dialog';
import { CommonModule } from '@angular/common'; // Import CommonModule
import { MatTable, MatTableDataSource, MatTableModule } from '@angular/material/table';
import { MatPaginator, MatPaginatorModule } from '@angular/material/paginator';
import { MatAutocompleteModule } from '@angular/material/autocomplete';
import { MatInputModule } from '@angular/material/input';
import { MatFormFieldModule } from '@angular/material/form-field';
import { MatCheckboxModule } from '@angular/material/checkbox';
import { AuditTrailValuesComponent } from '../audit-trail-values/audit-trail-values.component';
import { AppConstants } from 'src/app/app.constants';
import { AuditTrailService } from 'src/app/service/audit.trail.service';
import { Observable, Subscription, catchError, throwError } from 'rxjs';
import * as moment from 'moment';
import { MatToolbarModule } from '@angular/material/toolbar';
import { MatSelectModule } from '@angular/material/select';
import { MatIconModule } from '@angular/material/icon';

@Component({
  selector: 'app-audit-trail-table',
  templateUrl: './audit-trail-table.component.html',
  styleUrls: ['./audit-trail-table.component.css'],
  standalone: true,
  imports: [MatDialogModule, MatIconModule,MatSelectModule,MatToolbarModule, MatInputModule, MatPaginatorModule, MatTableModule, MatButtonModule, MatCheckboxModule, MatAutocompleteModule, MatFormFieldModule, CommonModule,]
})
export class AuditTrailTableComponent {
  @ViewChild(MatPaginator) paginator: MatPaginator;

  constructor(
    private router: Router, public dialog: MatDialog,
    private constant: AppConstants,
    private auditTrailService: AuditTrailService,

  ) {
    this.subscription = this.auditTrailService.getNotification().subscribe(data => {
      if (data) {
        this.fetchData(this.module);
      }
    });
  }

  displayedColumnsAT: string[] = ['id', 'datetime', 'tablename', 'recordpk', 'user', 'actionname', 'module'];
  dataSource = new MatTableDataSource<any>;

  @Input() dataSourceAT: any;
  @Input() module: any;
  subscription: Subscription
  moduleList: any[];
  currentRoute: any;
  ngAfterViewInit() {
    this.dataSource.paginator = this.paginator;
  }
  ngOnInit() {
    this.moduleList = this.constant.moduleList;
    this.fetchData(this.module);
    this.currentRoute = this.router.url;
  }

  refresh(){
    this.fetchData(this.module);
  }
  ngOnDestroy(): void {
    this.subscription.unsubscribe();
  }
  ngAfterContentInit() {
    this.dataSource.paginator = this.paginator;
  }

  applyFilter(event: Event) {
    const filterValue = (event.target as HTMLInputElement).value;
    this.dataSource.filter = filterValue.trim().toLowerCase();
  }

  onModuleChange(event) {
    this.fetchData(this.module);
  }

  viewDetails(data) {
    const dialogConfig: MatDialogConfig = {
      data: {
        details: data
      }
    };

    this.dialog.open(AuditTrailValuesComponent, dialogConfig);
  }

  async fetchData(module) {
    (await this.auditTrailService.fetchAuditTrail(module))
      .pipe(
        catchError(() => {
          return Observable.call(throwError(() => "Error"));
        })
      )
      .subscribe((response) => {
        const data = JSON.parse(JSON.stringify(response));
        const auditTrailActionList = Object.entries(this.constant.auditTrailAction).map(([name, id]) => ({ id, name }));
        const auditTrailModuleList = Object.entries(this.constant.auditTrailModule).map(([name, id]) => ({ id, name }));
        data.forEach(item => {
          const actionTemp = item.action;
          item.action = auditTrailActionList.find(action => action.id == item.action)?.name || 'Unknown';
          item.module = auditTrailModuleList.find(module => module.id === item.module)?.name || 'Unknown';
          item.datetime = moment.utc(item.datetime).format('MM/DD/YYYY hh:mm:ss a');
          item.isHaveValues = actionTemp != this.constant.auditTrailAction.Insert

        });

        this.dataSource.data = data;
        this.paginator.pageSize = 10;
        this.dataSource.paginator = this.paginator;
      });
  }
}
