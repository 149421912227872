import { Component, ElementRef, HostListener, OnInit, Renderer2, ViewChild } from '@angular/core';
import { AppConstants } from '../app.constants';
import { Router, RouterModule, ActivatedRoute, NavigationEnd } from '@angular/router';
import { ExamService } from '../service/exam.service';
import { CountdownConfig, CountdownEvent } from 'ngx-countdown';
import { MatSnackBar } from '@angular/material/snack-bar';
import { Observable, Subscription, catchError, filter, throwError } from 'rxjs';
import { DialogService } from '../service/dialog.service';
import { AuthService } from '../service/auth.service';
import * as moment from 'moment';
import { calculateTotalSecondsByMinute, cleanInputValue } from '../common-function';
import { SnackbarService } from '../lib/snackbar/snackbar.service';
import { EditorChangeContent, EditorChangeSelection, QuillEditorComponent, QuillModule } from 'ngx-quill';
import Quill from 'quill';
import { PopoverComponent } from '../components/popover/popover.component';
import { TabVisibilityService } from '../lib/tab-visibility/tab-visibility.service';
import { SystemConfigurationService } from '../service/sys.config.service';
const KEY = 'time'
const DEFAULT = 1800

@Component({
  selector: 'app-exam',
  templateUrl: './exam.component.html',
  styleUrls: ['./exam.component.css'],

})
export class ExamComponent implements OnInit {
  constructor(
    private router: Router,
    private constant: AppConstants,
    private examService: ExamService,
    private _snackBar: MatSnackBar,
    private dialogService: DialogService,
    private _Activatedroute: ActivatedRoute,
    private authService: AuthService,
    private snackbarService: SnackbarService,
    private tabVisibilityService: TabVisibilityService,
    private sysConfig: SystemConfigurationService

  ) {

  }

  examQuestions: any[] = [];
  reviewQuestions: any[] = [];
  selectedAnswers: { questionId: number; answerIds: number[]; isForReview: boolean, answer: string, isLocked: boolean, questionType: number; }[] = [];

  durationInSeconds = 5;
  examResult = null;
  examResultEssay = [];
  examId: any;
  examMode: any;
  userExamId: any;
  sequence: any;
  examDetails: any;
  userStrengths: any;
  startTime: any;
  answeredLength = 0;
  answer: string;
  currentQuestionIndex: number = 0;
  userAnswers: number[] = []; // Array to store user answers
  showAllAnswers: boolean = false;
  // config: CountdownConfig = { leftTime: DEFAULT, notify: 0, format: 'mm:ss' }
  config: CountdownConfig = { leftTime: DEFAULT, notify: 0 }
  hasActiveCountdownVal: boolean = false;
  time: { id: any; time: number }[] = [];
  percentageAnswered: number = 0;
  userId: any;
  isExamSubmitted: boolean = false;
  isTimerFinish: boolean = false;
  examTimeUsedUp = this.constant.examTimeUsedUp;
  examSubmissionTimerSec = this.constant.examSubmissionTimerSec;
  maxTime: number = 0;
  questionEditor: any;
  modules = {
    toolbar: null
  };

  popoverVisible = false;
  triggerElement: HTMLElement | null = null; // Initialize as null
  popoverContent: string = 'This is dynamic content for the popover.';

  @ViewChild('trigger', { static: false }) trigger: ElementRef;
  @ViewChild(PopoverComponent) popoverComponent: PopoverComponent;
  textAnswerTooltip = this.constant.textAnswerToolTip;
  wholeNumberTooltip = this.constant.wholeNumberToolTip;
  decimalNumberTooltip = this.constant.decimalNumberToolTip;

  switchTab = false;
  private visibilityChangeSubscription: Subscription;
  private routerEventsSubscription: Subscription;
  examRoute = ['exam'];
  wordCount = 0;
  wordCountList = [];
  answerWordCountList = [];
  answerWordCount = 0;
  essayFinalScore = 0;
  essayQuestions = [];
  metrics = [];
  async ngOnInit(): Promise<void> {
    this.getUser();
    this.examId = this._Activatedroute.snapshot.paramMap.get('id');
    this.userExamId = this._Activatedroute.snapshot.paramMap.get('userExamId');
    this.initCountdown();
    this.examMode = this._Activatedroute.snapshot.paramMap.get('mode');
    await this.initExam();
    this.hasActiveCountdownVal = this.hasActiveCountdown();

    this.tabVisibilityService.visibilityChange$.subscribe(hidden => {
      this.lockQuestion();
    });

    this.routerEventsSubscription = this.router.events
      .pipe(filter(event => event instanceof NavigationEnd))
      .subscribe(() => {
        // console.log('Navigation ended, cleaning up subscriptions');
        this.cleanup();
      });

  }

  lockQuestion() {
    if (this.selectAnswer.length > 0) {
      this.selectedAnswers[this.currentQuestionIndex].isLocked = true;
    }
  }
  ngOnDestroy(): void {
    this.cleanup();
  }

  private cleanup(): void {
    if (this.visibilityChangeSubscription) {
      this.visibilityChangeSubscription.unsubscribe();
    }
  }

  questionEditorInstance(editorInstance: any) {
    this.questionEditor = editorInstance;
    this.questionEditor.disable();
  }

  async initExam() {
    this.fetchSysConfig();
    if (this.examMode == this.constant.examMode.Details) {
      this.fetchExamResults(this.examId, this.userId);
    } else {
      this.fetchQuestions(this.examId, this.userId);
    }
  }

  isWordCountCompliant(id: number | null = null): boolean {
    if (id !== null) {
      // Find the specific item by id
      const requiredWordCount = this.wordCountList.find(item => item.id === id);
      const actualWordCount = this.answerWordCountList.find(item => item.id === id);

      // Check if both items exist and if the actual word count meets or exceeds the required word count
      if (requiredWordCount && actualWordCount) {
        return actualWordCount.value >= requiredWordCount.value;
      }
      return false;
    } else {
      // Check all items
      for (let i = 0; i < this.wordCountList.length; i++) {
        const requiredWordCount = this.wordCountList[i];
        const actualWordCount = this.answerWordCountList.find(item => item.id === requiredWordCount.id);

        // If the actual word count exists and is less than the required, return false
        if (actualWordCount && actualWordCount.value < requiredWordCount.value) {
          return false;
        }
      }
      return true;
    }
  }


  submitExam() {

    this.dialogService.openDialog('Submit Exam', this.constant.finishExam, 'No', true, (confirmed) => {
      if (confirmed) {
        if (!this.isExamSubmitted) {
          this.isExamSubmitted = true;
          this.evaluateAnswer();
        }
      } else {
        console.log('Close');
      }
    });

  }

  hasDecimalPoint(number) {
    return number.Contains(".");
  }

  openSnackBar() {
    this._snackBar.openFromComponent(ExamAlertComponent, {
      duration: this.durationInSeconds * 1000,
    });
  }

  hasAnswers(): boolean {
    return this.examQuestions.some((question, i) => {
      return this.selectedAnswers[i]?.answerIds && this.selectedAnswers[i]?.answerIds.length > 0;
    });
  }

  hasReviewAnswers(): boolean {
    return this.selectedAnswers.some(reviewItem => reviewItem.isForReview);
  }

  getSelectedAnswerText(answerId: number, question: any): string {
    const selectedOption = question.options.find(option => option.id === answerId);
    return selectedOption ? selectedOption.answer : 'No answer selected';
  }

  getUser() {
    const user = this.authService.getUser();
    this.userId = user != null ? this.authService.extractId(user.aud) : 0;
  }

  updateArrayItemById(array: any[], id: number, newValue: any) {
    // Find the index of the object with the given id
    const index = array.findIndex(item => item.id === id);

    // Check if the item with the given id was found
    if (index !== -1) {
      // Update the value property of the found object
      array[index].value = newValue;
    }
  }

  getWordCount(text, id) {
    var matches = text.match(/\S+/g);
    let count = matches ? matches.length : 0;
    this.answerWordCount = count;
    this.updateArrayItemById(this.answerWordCountList, id, count);
    return count;
  }

  async evaluateAnswer() {
    const currentDateTime = moment().format('YYYY-MM-DD HH:mm:ss');

    const payload = {
      examId: this.examId,
      userId: this.userId,
      userAnswers: this.selectedAnswers.map(item => {
        if (item.questionType == 4) {
          item.answer = cleanInputValue(item.answer);
        }
        return item;
      }),
      endTime: currentDateTime,
      sequence: this.sequence
    }
    // console.log(payload);

    // return;
    await (await this.examService.evaluateAnswer(payload)).subscribe((response) => {
      const result = JSON.parse(JSON.stringify(response));

      this.router.navigate(['exam', this.examId, this.constant.examMode.Details, this.userExamId || 0]);
      this.examResult = result;
      this.examResultEssay = result.essay;
      this.examResult.starttime = moment.utc(this.examResult.starttime).format('MM/DD/YYYY hh:mm:ss a');
      this.examResult.endtime = moment.utc(this.examResult.endtime).format('MM/DD/YYYY hh:mm:ss a');
      this.fetchStrengths(this.userId, this.examId, this.examResult.sequence, null, this.userExamId);
      this.deleteObjectById(this.examId);
    },
      (errorMessage) => {
      }
    )
  }

  setExam() {
    this.examQuestions.forEach((question) => {
      this.selectedAnswers.push({ questionId: question.id, answerIds: [], answer: '', isForReview: false, isLocked: false, questionType: question.questiontype });
    });

    if (!this.hasActiveCountdownVal) {
      this.maxTime = calculateTotalSecondsByMinute(this.examDetails.maxtime);
      this.config = { ...this.config, leftTime: this.maxTime }
    }
  }

  async startExam(examId, userId) {
    const currentDateTime = moment().format('YYYY-MM-DD HH:mm:ss');
    await (await this.examService.startExam(examId, userId, currentDateTime, this.userExamId)).subscribe((response) => {
      if (!response.success) {
        this.router.navigate(['exam-list', 'available']);
        this.snackbarService.showSnackbar(response.message, 'top', 10000);
      } else {
        this.sequence = response.sequence;
      }
    },
      (errorMessage) => {
      }
    )
  }

  async fetchSysConfig() {
    (await this.sysConfig.fetchSysConfig())
      .pipe(
        catchError(() => {
          return Observable.call(throwError(() => "Error"));
        })
      )
      .subscribe((response) => {
        const data = JSON.parse(JSON.stringify(response));

        data.metrics.forEach((item) => {
          item.scale = 0;
        })

        this.metrics = data.metrics;

      });
  }

  async fetchQuestions(examId, userId) {

    (await this.examService.fetchExamQuestions(examId, userId))
      .pipe(
        catchError(() => {
          this.openSnackBar();
          this.router.navigate(['exam-list', 'available']);
          return Observable.call(throwError(() => "Error"));
        })
      )
      .subscribe(async (questions) => {
        const data = JSON.parse(JSON.stringify(questions));
        this.examQuestions = data.questions;
        this.examQuestions.forEach(item => {
          if (item.questiontype == 5) {
            this.wordCount = parseFloat(item.wordcount);
            item.wordCount = this.wordCount;
            this.wordCountList.push({ id: item.id, value: this.wordCount });
            this.answerWordCountList.push({ id: item.id, value: 0 });
            this.essayQuestions.push(item.text);
            delete item.wordcount;
          }
          if (item.questiontype == 3 && item.options.length > 1) {
            const answer = item.options.at(0);
            delete item.options;
            item.options = [answer]
          }

          item.options.forEach(option => {
            option.answer = this.addMatCardSmImage(option.answer);
          })
        })


        this.examDetails = data.examDetails;
        this.sequence = this.examDetails.sequence;
        this.setExam();
        // this.reviewQuestionSetup();
        if (this.examMode != this.constant.examMode.Continue) {
          await this.startExam(examId, userId);
        }

      });
  }

  addMatCardSmImage(htmlContent: string): string {
    // Create a temporary DOM element to parse the HTML content
    const tempElement = document.createElement('div');
    tempElement.innerHTML = htmlContent;

    // Get all img elements within the HTML content
    const imgElements = tempElement.getElementsByTagName('img');

    // Add the mat-card-sm-image attribute to each img element
    for (let i = 0; i < imgElements.length; i++) {
      imgElements[i].setAttribute('mat-card-sm-image', 'true');
    }

    // Return the updated HTML content
    return tempElement.innerHTML;
  }

  reviewQuestionSetup() {
    this.reviewQuestions = this.examQuestions.map((item, index) => {
      item.text = index + 1 + '. ' + item.text;
      return item;
    })

  }

  async fetchExamResults(examId, userId) {

    (await this.examService.fetchExamResults(examId, userId, this.userExamId))
      .pipe(
        catchError(() => {
          this.openSnackBar();
          this.router.navigate(['exam-list', 'available']);
          return Observable.call(throwError(() => "Error"));
        })
      )
      .subscribe(async (result) => {
        this.examDetails = result.examDetails;
        this.examResult = result.examResult.reverse();
        this.examResult.forEach(item => {
          item.starttime = moment.utc(item.starttime).format('MM/DD/YYYY hh:mm:ss a');
          item.endtime = moment.utc(item.endtime).format('MM/DD/YYYY hh:mm:ss a');
        })
      });
  }

  async fetchEssayResult(userExamId, index) {
    const payload = {
      userId: this.userId,
      examId: this.examId,
      type: 1,
      userExamId
    }

    await (await this.examService.fetchEssayResult(payload))
      .pipe(
        catchError(() => {
          return Observable.call(throwError(() => "Error"));
        })
      )
      .subscribe((response) => {
        const data = JSON.parse(JSON.stringify(response));
        this.essayFinalScore = data.totalScore;


        if(data.hasData){
          if (index != null) {
            this.examResult[index].essayResult = data.exam;
            this.examResult[index].hasData = true;
          }
        }else{
          if (index != null) {
            this.examResult[index].hasData = false;
          }
        }
      });
  }

  async fetchFinalMetrics(index, mainIndex, userExamId, questionId, essayResultId) {

    await (await this.examService.fetchFinalMetrics({ userExamId, questionId, essayResultId }))
      .pipe(
        catchError(() => {
          return Observable.call(throwError(() => "Error"));
        })
      )
      .subscribe((response) => {
        const result = JSON.parse(JSON.stringify(response));

        if (index != null) {
          this.examResult[mainIndex].essayResult[index].metrics = result.data;

          // console.log(this.essayResult)
        }
      });
  }

  onResultPanelOpened(index: number, userExamId) {
    // Check if data is already loaded for the given index
    if (!this.examResult[index].userStrengths) {
      this.fetchStrengths(this.userId, this.examId, this.examResult[index].sequence, index, userExamId);
    }


    if (!this.examResult[index].essayResult) {
      this.fetchEssayResult(userExamId, index);
    }
  }

  onResultPanelOpenedEssay(index: number, mainIndex: number, userExamId, questionId, essayResultId) {
    // Check if data is already loaded for the given index
    if (!this.examResult[mainIndex].essayResult[index].metrics) {
      this.fetchFinalMetrics(index, mainIndex, userExamId, questionId, essayResultId);
    }
  }


  splitTime(time) {
    let result = { hour: 0, minute: 0 };
    if (!(time == "" || time == null)) {
      const items = time.split(":");
      result.hour = parseInt(items[0]);
      result.minute = parseInt(items[1]);
    }
    return result;
  }

  async fetchStrengths(userId, examId, sequence, index, userExamId) {
    (await this.examService.fetchStrengths(userId, examId, sequence, userExamId))
      .pipe(
        catchError(() => {
          this.openSnackBar();
          return Observable.call(throwError(() => "Error"));
        })
      )
      .subscribe((response) => {
        const data = JSON.parse(JSON.stringify(response));
        this.userStrengths = data;
        if (index != null) {
          this.examResult[index].userStrengths = data;
        }
      });
  }

  calculateAnsweredQuestions() {
    const answeredLength = this.selectedAnswers.filter(item => item.answerIds != null).length
    this.answeredLength = answeredLength;
    this.percentageAnswered = (answeredLength / this.examQuestions.length) * 100;
  }

  doesObjectExist(array, targetId) {
    return array.some(item => item.id === targetId);
  }

  deleteObjectById(id: any) {
    const storedTimeData = localStorage.getItem('__time');

    if (storedTimeData) {
      const timeArray = JSON.parse(storedTimeData);

      // Find the index of the item with the matching id
      const itemIndex = timeArray.findIndex(item => item.id == id);

      if (itemIndex !== -1) {
        timeArray.splice(itemIndex, 1);

        // Save the updated array back to localStorage
        localStorage.setItem('__time', JSON.stringify(timeArray));
      }
    }
  }

  hasActiveCountdown() {
    if (localStorage.getItem('__time')) {
      this.time = JSON.parse(localStorage.getItem('__time')) || [];
      if (this.doesObjectExist(this.time, this.examId)) {
        return true;
      } else {
        this.time.push({ id: this.examId, time: 0 })
      }
    } else {
      const time = [{ id: this.examId, time: 0 }]
      localStorage.setItem("__time", JSON.stringify(time))
      return false;
    }

  }

  initCountdown() {
    const storedTimeData = localStorage.getItem("__time");

    if (storedTimeData) {
      const timeArray = JSON.parse(storedTimeData);
      const matchingItem = timeArray.find(item => item.id == this.examId) || { id: this.examId, time: DEFAULT };
      this.config = { ...this.config, leftTime: matchingItem.time };
    } else {
      this.config = { ...this.config, leftTime: DEFAULT };
    }
  }

  handleEvent(ev: CountdownEvent) {
    if (ev.action === 'notify') {
      // Update or add the current value in the __time array
      let timeArray = JSON.parse(localStorage.getItem('__time')) || [];
      const existingItemIndex = timeArray.findIndex(item => item.id == this.examId);

      if (existingItemIndex !== -1) {
        // Update existing item
        timeArray[existingItemIndex].time = ev.left / 1000;
      } else {
        timeArray.push({ id: this.examId, time: ev.left / 1000 });
      }

      localStorage.setItem('__time', JSON.stringify(timeArray));
    }

    if (ev.left === 0) {
      this.isTimerFinish = true;
    }
  }


  handleSubmitEvent(ev: CountdownEvent) {
    if (ev.left == 0) {
      // Submit exam when countdown is 0
      this.isExamSubmitted = true;
      this.evaluateAnswer();
    }
  }

  update(newItem: string) {
    this.answer = newItem;
  }

  toggleShowAllAnswers() {
    this.showAllAnswers = !this.showAllAnswers;
  }

  getNextQuestion() {
    const question = this.examQuestions[this.currentQuestionIndex];
    if (question) {
      if (question.questiontype == 5 && !this.isWordCountCompliant(question.id)) {
        this.dialogService.openDialog('Word Count', this.constant.wordCountMinimum, 'No', true, (confirmed) => {
          if (confirmed) {
            if (this.currentQuestionIndex < this.examQuestions.length - 1) {
              this.currentQuestionIndex++;
              return;
            }
          } else {
            console.log('Close');
          }
        });
      } else {
        if (this.currentQuestionIndex < this.examQuestions.length - 1) {
          this.currentQuestionIndex++;
        }
      }

    }

    // this.calculateAnsweredQuestions();

  }

  getPreviousQuestion() {
    const question = this.examQuestions[this.currentQuestionIndex];
    if (question) {
      if (question.questiontype == 5 && !this.isWordCountCompliant(question.id)) {
        this.dialogService.openDialog('Word Count', this.constant.wordCountMinimum, 'No', true, (confirmed) => {
          if (confirmed) {
            if (this.currentQuestionIndex > 0) {
              this.currentQuestionIndex--;
              return;
            }
          } else {
            console.log('Close');
          }
        });
      } else {
        if (this.currentQuestionIndex > 0) {
          this.currentQuestionIndex--;
        }
      }

    }
    // this.calculateAnsweredQuestions();

  }

  navigateToQuestion(index: number) {
    this.currentQuestionIndex = index;
    this.calculateAnsweredQuestions();
  }

  selectAnswer(optionIndex: number) {
    this.userAnswers[this.currentQuestionIndex] = optionIndex;
  }

  toggleAnswerCheck(option: any, index: number) {
    const questionIndex = this.currentQuestionIndex;

    // Toggle the checkbox's checked state
    option.isChecked = !option.isChecked;

    // Update the selected answers based on the checked state
    if (option.isChecked) {
      // If the checkbox is checked, add the answer ID to the selectedAnswers array
      this.selectedAnswers[questionIndex].answerIds.push(option.id);
    } else {
      // If the checkbox is unchecked, remove the answer ID from the selectedAnswers array
      const answerIndex = this.selectedAnswers[questionIndex].answerIds.indexOf(option.id);
      if (answerIndex !== -1) {
        this.selectedAnswers[questionIndex].answerIds.splice(answerIndex, 1);
      }
    }
  }


  endExam() {
    let result = confirm("Changes you made may not be saved.");

    if (result) {
      this.router.navigate(['exam-list', 'available']);
    }
  }

}

@Component({
  selector: 'exam-alert',
  templateUrl: 'alert.component.html',
  styles: [
    `
    .example-pizza-party {
      color: hotpink;
    }
  `,
  ],
  standalone: true,
})
export class ExamAlertComponent { }