<div style="margin: 20px">
    <mat-toolbar>
        <h2>Select Account</h2>
        <span class="spacer"></span>
        <mat-icon mat-dialog-close class="close-profile" style="float: right;">close</mat-icon>
    </mat-toolbar>
    <table mat-table [dataSource]="dataSource">
        <ng-container matColumnDef="position">
            <th mat-header-cell *matHeaderCellDef style="width: 180px;"> Subscription ID</th>
            <td mat-cell *matCellDef="let element" style="font-size: 13px;"> {{element.subscriptionid}} </td>
        </ng-container>

        <!-- Name Column -->
        <ng-container matColumnDef="name">
            <th mat-header-cell *matHeaderCellDef> Name </th>
            <td mat-cell *matCellDef="let element" style="font-size: 13px;"> {{element.name}} </td>
        </ng-container>

        <ng-container matColumnDef="type">
            <th mat-header-cell *matHeaderCellDef style="width: 80px;"> Type </th>
            <td mat-cell *matCellDef="let element" style="font-size: 13px;"> {{element.type_name}} </td>
        </ng-container>

        <tr mat-header-row *matHeaderRowDef="displayedColumns"></tr>
        <tr mat-row *matRowDef="let row; columns: displayedColumns;" (click)="switchSubscription(row)"
            [mat-dialog-close]="true" class="tbl-rows">
        </tr>
    </table>
    <mat-paginator [pageSizeOptions]="[5, 10, 25, 100]" showFirstLastButtons></mat-paginator>

</div>