import { Component, Input } from '@angular/core';

@Component({
  selector: 'app-strengths',
  templateUrl: './strengths.component.html',
  styleUrls: ['./strengths.component.css']
})
export class StrengthsComponent {
  @Input() data: any;
  ngOnInit() {
  }

}
