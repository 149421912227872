import { Directive, HostListener } from '@angular/core';

@Directive({
  selector: '[appNoCopy]'
})
export class NoCopyDirective {
  @HostListener('copy', ['$event'])
  onCopy(event: ClipboardEvent): void {
    event.preventDefault();
  }
}
