import { Injectable } from '@angular/core';
import { Subject, timer } from 'rxjs';
import { debounceTime, switchMap } from 'rxjs/operators';
import { AppConstants } from 'src/app/app.constants';
import { AuthService } from 'src/app/service/auth.service';
import { DialogService } from 'src/app/service/dialog.service';
import { Router } from '@angular/router';
import { Title } from '@angular/platform-browser'; // Import Title service
import { SessionService } from 'src/app/service/session.service';
import { AuditTrailService } from 'src/app/service/audit.trail.service';

@Injectable({
  providedIn: 'root',
})
export class InactivityService {
  private activity: Subject<void> = new Subject<void>();
  public inactivityTimeout: number = this.constant.sessionTimeoutMin * 60 * 1000; // 5 mins in milliseconds

  constructor(
    private dialogService: DialogService,
    private constant: AppConstants,
    private authService: AuthService,
    private sessionService: SessionService,
    private router: Router,
    private titleService: Title,
    private auditTrailService: AuditTrailService

  ) {
    this.setupInactivityTracking();
  }

  private setupInactivityTracking(): void {
    document.addEventListener('mousemove', this.handleUserActivity.bind(this));
    document.addEventListener('keydown', this.handleUserActivity.bind(this));

    this.activity.pipe(debounceTime(this.inactivityTimeout)).subscribe(() => {
      const excludedRoutes = ['/login', '/signup', '/confirm-user'];
      if (!excludedRoutes.includes(this.router.url)) {
        console.log("out")
        this.handleInactivityTimeout();
      }
    });
  }

  private resetTitle(): void {
    const excludedRoutes = ['/login'];
    if (excludedRoutes.includes(this.router.url)) {
      this.titleService.setTitle('QuizBrew - Login');
    }
  }

  private handleUserActivity(): void {
    this.activity.next();
    if (this.authService.getUser()) {
      this.sessionService.setSessionExpiry();
    }
    // Reset the title when there is user activity
    this.resetTitle();
  }

  private async handleInactivityTimeout(): Promise<void> {
    await (await this.auditTrailService.addAuditTrail("timeout", null, this.authService.getUser().email, 9, 1, { message: "Session Timed out" }, null)).subscribe((response) => { });
    this.authService.logOut();
    this.router.navigate(['login']);
    this.dialogService.openDialog(this.constant.sessionTimeoutTitle, this.constant.sessionTimeoutMessage, 'Okay', false, async (confirmed) => {
    });
    setTimeout(() => {
      this.titleService.setTitle('Session Timeout');
    }, 1000);
  }
}
