
import { Component, OnInit, ViewChild } from '@angular/core';
import { ActivatedRoute, Router, RouterModule } from '@angular/router';
import { NgFor } from '@angular/common';
import { MatListModule } from '@angular/material/list';
import { MatButtonModule } from '@angular/material/button';
import { MatCardModule } from '@angular/material/card';
import { MatDialog, MatDialogModule } from '@angular/material/dialog';
import { MatGridListModule } from '@angular/material/grid-list';
import { CommonModule } from '@angular/common';
import { MatSidenavModule } from '@angular/material/sidenav';
import { MatMenuModule } from '@angular/material/menu';
import { MatToolbarModule } from '@angular/material/toolbar';
import { MatIconModule } from '@angular/material/icon';
import { MatExpansionModule } from '@angular/material/expansion';
import { MatTooltipModule } from '@angular/material/tooltip';
import { MatTable, MatTableDataSource, MatTableModule } from '@angular/material/table';
import { MatPaginator, MatPaginatorModule } from '@angular/material/paginator';
import { Observable, catchError, from, map, startWith, throwError } from 'rxjs';
import { FormGroup, FormControl, Validators } from '@angular/forms';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { MatInputModule } from '@angular/material/input';
import { MatFormFieldModule } from '@angular/material/form-field';
import { MatSelectModule } from '@angular/material/select';
import { MatCheckboxModule } from '@angular/material/checkbox';
import { SnackbarService } from 'src/app/lib/snackbar/snackbar.service';
import { DialogService } from 'src/app/service/dialog.service';
import { MatRadioChange, MatRadioModule } from '@angular/material/radio';
import { AppConstants } from '../../app.constants';
import { MatAutocompleteModule } from '@angular/material/autocomplete';
import { MatTabsModule } from '@angular/material/tabs';
import { MatProgressBarModule } from '@angular/material/progress-bar';
import { AWSS3Service } from 'src/app/service/aws.s3.service';
import { CategoryService } from 'src/app/service/category.service';
import { ExamService } from 'src/app/service/exam.service';
import { ExamQuestionModel } from 'src/app/model/exam-question';
import { AuditTrailTableComponent } from "../../components/audit-trail-table/audit-trail-table.component";
import { AuditTrailService } from 'src/app/service/audit.trail.service';
import { FlexLayoutModule } from "@angular/flex-layout";
import { SelectionModel } from '@angular/cdk/collections';
import { UserService } from 'src/app/service/user.service';
import { timeToMinutes, minutesToTime } from "../../common-function";
import { AuthService } from 'src/app/service/auth.service';
import { MemberService } from 'src/app/service/member.service';
import { SubscriptionService } from 'src/app/service/subscription.service';
import * as moment from 'moment';
import { CommaDelimiterModule } from 'src/app/lib/comma-delimiter-module/comma-delimiter.module';
import { ExamResultComponent } from 'src/app/components/exam-result/exam-result.component';

@Component({
  selector: 'app-finalize-result',
  templateUrl: './finalize-result.component.html',
  styleUrls: ['./finalize-result.component.css']
})
export class FinalizeResultComponent {
  displayedColumns: string[] = ['select', 'position', 'email', 'exam', 'count', 'action'];
  dataSource = new MatTableDataSource<any>;
  selection = new SelectionModel<any>(true, []);
  public dataForm: FormGroup;
  hasError = {
    value: false,
    message: "No answer selected"
  }
  shopName: any;
  isAddMode: boolean = false;
  isEditMode: boolean = false;

  addedUsers: any[] = [];
  userEmailControls: FormControl[] = [];
  users: any[] = [];
  examDetails = null;


  // Mass upload
  @ViewChild('fileInput')
  hasSubmit: boolean = false;
  selectedExam: null;
  examFilter: number | null = null;
  searchFilter: string = '';

  groupType = 1;
  isAdmin: boolean = false;
  isAccountAdmin: boolean = false;
  user: any;
  isGroup: boolean = false;
  remaining = 0;










  paramsExamId;
  paramsUserId;

  essayResult = [];
  essayMetrics = [];
  examResult = [];
  userStrengths: any;
  essayFinalScore = 0;

  constructor(
    private router: Router, public dialog: MatDialog,
    private examService: ExamService,
    private categoryService: CategoryService,
    private snackbarService: SnackbarService,
    private dialogService: DialogService,
    private _Activatedroute: ActivatedRoute,
    private constant: AppConstants,
    private s3: AWSS3Service,
    private auditTrailService: AuditTrailService,
    private userService: UserService,
    private authService: AuthService,
    private memberService: MemberService,

    private subscriptionService: SubscriptionService

  ) {
  }
  async ngOnInit() {
    this.paramsExamId = this._Activatedroute.snapshot.paramMap.get('examId');
    this.paramsUserId = this._Activatedroute.snapshot.paramMap.get('userId');
    this.dataForm = new FormGroup({
      examControl: new FormControl('', [Validators.required]),
      isActive: new FormControl(true)
    });
    await this.initUser();
    this.fetchExamResults();
  }

  async refresh() {
    this.selection.clear();
  }
  async initUser() {
    this.user = this.authService.getUser();
    if (this.user != null) {
      this.isAdmin = this.authService.extractId(this.user.azp) === 1;
      this.isAccountAdmin = this.authService.extractId(this.user.azp) === 2;
      this.isGroup = this.user.group !== this.constant.invidualNoGroup;
    }
  }

  dataChanged(item) {
    if (item.isNew == 0) {
      item.isEdited = true;
    }
  }

  backAction() {
    this.isAddMode = false;
    this.userEmailControls = [];
    this.addedUsers = [];
  }

  ngModelChange() {
    if (typeof this.dataForm.value.examControl == 'string') {
      this.examDetails = null;
      this.selectedExam = null;
      this.userEmailControls = [];
      this.addedUsers = [];
    }
  }


  onResultPanelOpened(index: number, userExamId) {
    // Check if data is already loaded for the given index
    if (!this.examResult[index].userStrengths) {
      this.fetchStrengths(this.paramsUserId, this.paramsExamId, this.examResult[index].sequence, index, userExamId);
    }

    if (!this.examResult[index].essayResult) {
      this.fetchEssayResult(userExamId, index);
    }
  }

  onResultPanelOpenedEssay(index: number, mainIndex: number, userExamId, questionId, essayResultId, gpt) {
    // Check if data is already loaded for the given index
    if (!this.examResult[mainIndex].essayResult[index].metrics) {
      this.fetchFinalMetrics(index, mainIndex, userExamId, questionId, essayResultId, gpt);
    }
  }

  async fetchExamResults() {
    await (await this.examService.fetchExamResults(this.paramsExamId, this.paramsUserId, null))
      .subscribe((response) => {
        const data = JSON.parse(JSON.stringify(response));

        this.examResult = data.examResult.reverse();
        this.examResult.forEach(item => {
          item.starttime = moment.utc(item.starttime).format('MM/DD/YYYY hh:mm:ss a');
          item.endtime = moment.utc(item.endtime).format('MM/DD/YYYY hh:mm:ss a');
        })
      });
  }

  async fetchStrengths(userId, examId, sequence, index, userExamId) {
    (await this.examService.fetchStrengths(userId, examId, sequence, userExamId))
      .pipe(
        catchError(() => {
          return Observable.call(throwError(() => "Error"));
        })
      )
      .subscribe((response) => {
        const data = JSON.parse(JSON.stringify(response));
        this.userStrengths = data;
        if (index != null) {
          this.examResult[index].userStrengths = data;
        }
      });
  }

  extractEssayData(text, metrics) {

    const configIdMap = metrics.reduce((map, row) => {
      map[row.value] = row.configid;
      return map;
    }, {});


    // More flexible regex to handle potential variations in format
    const componentRegex = /\[(\d+)]\s?(.+?):\s?Score:\s?(\d+)\/?(\d+)?\s?\n?\((.*?)\)\n/g;
    const totalScoreRegex = /Total Score:\s?(\d+\/\d+)/;
    const detailedFeedbackRegex = /Detailed Feedback:\s*\n?(-\s?(.+\n)+)/;

    let match;
    const components = [];

    // Extract components using the regex
    while ((match = componentRegex.exec(text)) !== null) {
      components.push({
        id: parseInt(match[1], 10),
        component: match[2],
        Score: match[3] + (match[4] ? '/' + match[4] : ''),
        Feedback: match[5].trim(),
        configId: configIdMap[match[2]] || null
      });
    }

    if (components.length === 0) {
      console.error('No components were extracted. Check the regular expression and input text.');
    }

    const totalScoreMatch = text.match(totalScoreRegex);
    const totalScore = totalScoreMatch ? parseInt(totalScoreMatch[1].split('/')[0], 10) : 0;

    const detailedFeedbackMatch = text.match(detailedFeedbackRegex);
    const overallFeedback = detailedFeedbackMatch ? detailedFeedbackMatch[1].trim() : '';


    return {
      components,
      totalScore,
      overallFeedback
    };
  }

  async fetchEssayResult(userExamId, index) {
    const payload = {
      userId: this.paramsUserId,
      examId: this.paramsExamId,
      userExamId
    }

    await (await this.examService.fetchEssayResult(payload))
      .pipe(
        catchError(() => {
          return Observable.call(throwError(() => "Error"));
        })
      )
      .subscribe((response) => {
        const data = JSON.parse(JSON.stringify(response));
        console.log(response)
        this.essayFinalScore = data.totalScore;

        if (data.hasData) {
          data.exam.forEach(item => {
            item.gptResponse = item.gptresult.replace(/\n/g, '<br>')
              .replace(/#/g, '')
              .replace(/[\(\)]/g, '');
          });

          if (index != null) {
            this.examResult[index].essayResult = data.exam;
          }
        } else {

        }
      });
  }

  async fetchFinalMetrics(index, mainIndex, userExamId, questionId, essayResultId, gpt) {

    await (await this.examService.fetchFinalMetrics({ userExamId, questionId, essayResultId }))
      .pipe(
        catchError(() => {
          return Observable.call(throwError(() => "Error"));
        })
      )
      .subscribe((response) => {
        const result = JSON.parse(JSON.stringify(response));

        result.data.forEach((item) => {
          item.selected = true;
          item.htmlFormat = item.description.trim().replace(/\n/g, '<br>');
          item.formattedDetails = this.calculateAndReplaceRubric(item.scale, item.htmlFormat);
        })

        if (index != null) {
          this.examResult[mainIndex].essayResult[index].metrics = result.data;
          this.examResult[mainIndex].essayResult[index].isSubmitted = result.isSubmitted;
          this.examResult[mainIndex].essayResult.forEach((item) => {
            item.extractedData = this.extractEssayData(gpt, result.data)
          })

          // console.log(this.essayResult)
        }
      });
  }

  calculateEssayScore(finalpoints, maxscore) {
    return Math.round((finalpoints / 100) * maxscore);
  }

  async fetchEssayMetrics(questionId: number) {
    (await this.examService.fetchEssayMetrics(questionId))
      .pipe(
        catchError(() => {
          return Observable.call(throwError(() => "Error"));
        })
      )
      .subscribe((response) => {
        const data = JSON.parse(JSON.stringify(response));

        data.forEach((item) => {
          item.selected = true;
          // item.formattedDetails = item.description.trim().replace(/\n/g, '<br>');
          item.htmlFormat = item.description.trim().replace(/\n/g, '<br>');
          item.formattedDetails = this.calculateAndReplaceRubric(item.scale, item.htmlFormat);

        })

      });
  }

  calculateAndReplaceRubric(points: number, criteria: string): string {

    let division = points / 5;
    let value = criteria
      .replace('{5}', `${points}`)
      .replace('{4}', `${division * 4}`)
      .replace('{3}', `${division * 3}`)
      .replace('{2}', `${division * 2}`)
      .replace('{1}', `${division * 1}`);

    return value;
  }

  async submitHandler(data, mainIndex, essayIndex) {
    this.dialogService.openDialog('Submit', this.constant.updateResultConfirmation, 'No', true, async (confirmed) => {
      if (confirmed) {
        await this.onSubmit(data, mainIndex, essayIndex);
      } else {
        console.log('Close');
      }
    });

  }

  async onSubmit(data, mainIndex, essayIndex) {

    this.examResult[mainIndex].essayResult[essayIndex].isSubmitted = true;

    data.metrics.forEach((item) => {
      delete item.formattedDetails;
      delete item.description;
      delete item.htmlFormat;
    })

    const payload = {
      examId: this.paramsExamId,
      finalEssayPoint: data.metrics,
      essayResultId: data.essayresultid,
      questionId: data.questionid,
      userExamId: data.userexamid,
      email: data.email,
      accountId: this.user.accountId,
    }
    console.log(payload);

    // return;
    await (await this.examService.finalizeEssayResult(payload))
      .subscribe((response) => {
        console.log(response)
        this.snackbarService.showSnackbar(response.toString(), 'top', 2000);
        this.fetchExamResults();
      });
  }

  async exportExamResult(item) {
    const payload = {
      examId: this.examDetails.id,
      userId: item.userid,
      accountId: this.user.accountId,
      // userExamId: item.userexamid
    };
    (await this.examService.exportExamResult(payload))
      .pipe(
        catchError((err) => {
          return Observable.call(throwError(() => "Error"));
        })
      )
      .subscribe((response) => {
        if (response.hasData) {
          const blob = new Blob([response.content], { type: 'text/csv' });
          const url = window.URL.createObjectURL(blob);
          const a = document.createElement('a');
          a.href = url;
          a.download = this.examDetails.name + '_' + moment().format('YYYY-MM-DD hhmmss') + '.csv';
          a.click();
          window.URL.revokeObjectURL(url);
          this.auditTrailService.sendNotification(true);
        } else {
          this.snackbarService.showSnackbar(response.content, 'top', 10000);
        }
      });
  }

  viewResult(item) {
    const payload = {
      examId: this.examDetails.id,
      userId: item.userid,
      accountId: this.user.accountId,
    };

    this.router.navigate(['masterfile/exam-result', this.examDetails.id, item.userid])
  }


}
