<div class="main-div">
    <mat-card style="border: 0; box-shadow: none;">
        <mat-card-header>
            <mat-card-title>You are not authorized</mat-card-title>
            <mat-card-subtitle>
                You tried to access a page you did not have prior authorization for.
            </mat-card-subtitle>
        </mat-card-header>
    </mat-card>
</div>

