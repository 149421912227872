import { HttpClient, HttpErrorResponse, HttpHeaders } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable, catchError, map, throwError } from 'rxjs';
import { AWSS3Service } from './aws.s3.service';
import { HttpAxiosService } from '../auth/auth.interceptor.axios';
import { AppConstants } from '../app.constants';
import { AuthService } from './auth.service';
import { environment } from 'src/environments/environment';

@Injectable({
  providedIn: 'root'
})
export class ExamService {
  // baseURL = 'https://api.virtuascript.net';
  baseURL = environment.apiUrl;
  
  user: any;
  constructor(
    private http: HttpClient,
    private s3: AWSS3Service,
    private axios: HttpAxiosService,
    private constant: AppConstants,
    private authService: AuthService,

  ) { 
    this.user = this.authService.getUser();
  }

  updateUserState(){
    this.user = this.authService.getUser();
  }

  async fetchExamQuestions(examId: number, userId: number): Promise<Observable<any>> {
    const response = this.http.post("/exam/questions", { userId, examId });
    return response;
  }

  async fetchExamResults(examId: number, userId: number,  userExamId): Promise<Observable<any>> {
    const response = this.http.post("/exam/result", { userId, examId, userExamId});
    return response;
  }

  async startExam(examId: number, userId: number, startTime, userExamId): Promise<Observable<any>> {
    const response = this.http.post("/exam/start", { userId, examId, startTime, userExamId: userExamId, email: this.user.email });
    return response;
  }

  async validateExam(examId: number): Promise<Observable<any>> {
    const response = this.http.post("/exam/validate-exam", { examId });
    return response;
  }

  async fetchExam(userId: number): Promise<Observable<any>> {
    const response = this.http.post("/exam/get-user-exam", { userId });
    return response;
  }

  async getAllExam(): Promise<Observable<any>> {
    const response = this.http.get<any[]>("/masterfile/exam");
    return response;
  }

  async getAllExamFilter(params): Promise<Observable<any>> {
    const response = this.http.post("/masterfile/exam/get", params);
    return response;
  }

  async fetchStrengths(userId: number, examId: number, sequence: number, userExamId): Promise<Observable<any>> {
    const response = this.http.post("/exam/evaluate-strength", { userId, examId, sequence, userExamId });
    return response;
  }

  async evaluateAnswer(params: any) {
    const response = await this.http.post("/exam/submit-exam", {...params, email: this.user.email})
    return response;
  }

  //Masterfile
  async addExam(params: any) {
    const response = await this.http.post(`/masterfile/exam`, {...params, email: this.user.email})
    return response;
  }

  async getExamCatalogue(params: any) {
    const response = await this.http.post(`/masterfile/exam/catalogue`, params)
    return response;
  }

  async getPopularExam(): Promise<Observable<any>> {
    const response = this.http.get<any[]>("/masterfile/exam/popular");
    return response;
  }
  async editExam(params: any) {
    const response = await this.http.post("/masterfile/exam/update", {...params, email: this.user.email})
    return response;
  }

  async deleteExam(ids) {
    const response = await this.http.post("/masterfile/exam/delete", {ids, email: this.user.email})
    return response;
  }

  async fetchQuestions(examId: number): Promise<Observable<any>> {
    const response = await this.http.post("/masterfile/question", { examId })
    return response;
  }

  async fetchEssayMetrics(questionId: number): Promise<Observable<any>> {
    const response = await this.http.post("/masterfile/question/essay-metrics", { questionId })
    return response;
  }

  async fetchEssayResult(payload): Promise<Observable<any>> {
    const response = await this.http.post("/masterfile/exam/essay-result", payload)
    return response;
  }

  async finalizeEssayResult(payload) {
    const response = await this.http.post("/masterfile/exam/finalize-essay-result", payload)
    return response;
  }

  async fetchFinalMetrics(payload): Promise<Observable<any>> {
    const response = await this.http.post("/masterfile/question/final-metrics", payload)
    return response;
  }
  async addQuestion(params: any) {
    const response = await this.http.post("/masterfile/question/add", {...params, email: this.user.email})
    return response;
  }

  async editQuestion(params: any) {
    const response = await this.http.post("/masterfile/question/update", {...params, email: this.user.email})
    return response;
  }

  async deleteQuestionAnswers(ids) {
    const response = await this.http.post("/masterfile/question/delete", {ids, email: this.user.email})
    return response;
  }

  async fetchAnswers(questionId: number): Promise<Observable<any>> {
    const response = await this.http.post("/masterfile/question/answer", { questionId })
    return response;
  }

  async exportExam(): Promise<Observable<any>> {
    const response = this.http.post("/masterfile/exam/export", {email: this.user.email});
    return response;
  }

  async exportExamResult(params): Promise<Observable<any>> {
    const response = this.http.post("/masterfile/exam/export-exam-result", {...params, email: this.user.email});
    return response;
  }

  async exportExamTemplate(): Promise<Observable<any>> {
    const response = this.http.post("/masterfile/exam/export-template", {email: this.user.email});
    return response;
  }

  async importExam(key) {
    try {
      const getPresignedURL = await this.s3.generatePresignedURL(key, "getObject");
      const payload = { "signedURL": getPresignedURL, email: this.user.email };

      const response = await this.axios.post(`${this.baseURL}/masterfile/exam/import`, payload,
      {
        headers: {
          "x-api-key": environment.apiKey
        }
      });
      return response;
    } catch (error) {
      throw { error: "Error while importing data: " + error };
    }
  }

  async exportQuestion(examId): Promise<Observable<any>> {
    const response = this.http.post<any[]>("/masterfile/question/export", {examId, email: this.user.email});
    return response;
  }

  async exportQuestionTemplate(examId): Promise<Observable<any>> {
    const response = this.http.post<any[]>("/masterfile/question/export-template", {examId, email: this.user.email});
    return response;
  }

  async importQuestions(examid, key) {
    try {
      const getPresignedURL = await this.s3.generatePresignedURL(key, "getObject");
      const payload = { "signedURL": getPresignedURL, examId: examid, email: this.user.email };

      const response = await this.axios.post(`${this.baseURL}/masterfile/question/import`, payload,
      {
        headers: {
          "x-api-key": environment.apiKey
        }
      });
      return response;
    } catch (error) {
      throw { error: "Error while importing data: " + error };
    }
  }

}
