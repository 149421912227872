<mat-card style="width: 100%;">
    <mat-card-header>
        <mat-card-title class="header-style">Pay Model</mat-card-title>
    </mat-card-header>

    <mat-tab-group mat-stretch-tabs="false" mat-align-tabs="start"
        (selectedIndexChange)="onSelectedIndexChange($event)">
        <mat-tab label="Result">
            <div class="table-container">
                <mat-toolbar>
                    <mat-form-field *ngIf="!isAddMode && !isEditMode" style="margin-top: 20px;">
                        <mat-label>Filter</mat-label>
                        <input matInput (keyup)="applyFilter($event)" #input>
                    </mat-form-field>
                    <span class="toolbar-item-spacer"></span>
                    <!-- large screen -->
                    <div fxHide fxHide.xs="true" fxHide.gt-xs="false">
                        <button *ngIf="!isAddMode && !isEditMode" style="margin-right: 10px;" mat-flat-button
                            color="primary" matTooltip="Refresh data" (click)="refresh()">
                            <mat-icon>refresh</mat-icon>Refresh
                        </button>
                        <button *ngIf="!isAddMode && !isEditMode" mat-flat-button color="accent"
                            [disabled]="selection.isEmpty()" (click)="deleteHandler()" class="demo-button"
                            style="margin-right: 10px;">
                            <mat-icon>delete</mat-icon>Delete
                        </button>
                        <button *ngIf="!isAddMode && !isEditMode" mat-flat-button color="primary"
                            (click)="exportAction()" class="demo-button" style="margin-right: 10px;">
                            <mat-icon> insert_drive_file</mat-icon>CSV
                        </button>
                        <button *ngIf="!isAddMode && !isEditMode" mat-flat-button color="primary" (click)="addAction()"
                            class="demo-button" style="margin-left: 10px;">
                            Add data
                        </button>
                        <button mat-raised-button *ngIf="isAddMode || isEditMode" (click)="backAction()"
                            class="demo-button" style="margin-left: 10px;">
                            Back
                        </button>
                    </div>

                    <!-- Small Screen -->
                    <div fxShow fxShow.xs="true" fxShow.gt-xs="false">
                        <button *ngIf="!isAddMode && !isEditMode" class="refresh" mat-icon-button color="primary"
                            matTooltip="Refresh data" (click)="refresh()">
                            <mat-icon>refresh</mat-icon>
                        </button>
                        <button mat-icon-button *ngIf="!isAddMode && !isEditMode" (click)="deleteHandler()"
                            matTooltip="Delete" [disabled]="selection.isEmpty()">
                            <mat-icon color="warn">delete</mat-icon>
                        </button>
                        <button *ngIf="!isAddMode && !isEditMode" mat-icon-button color="primary"
                            matTooltip="Export Data" (click)="exportAction()" class="demo-button">
                            <mat-icon> insert_drive_file</mat-icon>
                        </button>
                        <button *ngIf="!isAddMode && !isEditMode" mat-icon-button color="primary" matTooltip="Add Data"
                            (click)="addAction()" class="demo-button">
                            <mat-icon> add_circle</mat-icon>
                        </button>
                        <button mat-raised-button *ngIf="isAddMode || isEditMode" (click)="backAction()"
                            class="demo-button" style="margin-left: 10px;">
                            Back
                        </button>
                    </div>

                </mat-toolbar>

                <div class="mat-elevation-z0 table-container" style="margin-top: 15px;"
                    *ngIf="!isAddMode && !isEditMode">
                    <table mat-table [dataSource]="dataSource" class="demo-table">
                        <ng-container matColumnDef="select">
                            <th mat-header-cell *matHeaderCellDef>
                                <mat-checkbox (change)="$event ? toggleAllRows() : null"
                                    [checked]="selection.hasValue() && isAllSelected()"
                                    [indeterminate]="selection.hasValue() && !isAllSelected()"
                                    [aria-label]="checkboxLabel()">
                                </mat-checkbox>
                            </th>
                            <td mat-cell *matCellDef="let row">
                                <mat-checkbox (click)="$event.stopPropagation()"
                                    (change)="$event ? selection.toggle(row) : null"
                                    [checked]="selection.isSelected(row)" [aria-label]="checkboxLabel(row)">
                                </mat-checkbox>
                            </td>
                        </ng-container>
                        <ng-container matColumnDef="position">
                            <th mat-header-cell *matHeaderCellDef>ID</th>
                            <td mat-cell *matCellDef="let element">{{element.id}}</td>
                        </ng-container>

                        <ng-container matColumnDef="name">
                            <th mat-header-cell *matHeaderCellDef>Name</th>
                            <td mat-cell *matCellDef="let element">{{element.name}}</td>
                        </ng-container>

                        <ng-container matColumnDef="frequency">
                            <th mat-header-cell *matHeaderCellDef>Frequency</th>
                            <td mat-cell *matCellDef="let element">{{element.frequency ==
                                0?'N/A':element.frequencyText}}
                            </td>
                        </ng-container>

                        <ng-container matColumnDef="payment">
                            <th mat-header-cell *matHeaderCellDef>Payment</th>
                            <td mat-cell *matCellDef="let element">{{element.payment ==
                                0?'N/A':'₱'+element.payment+'.00'}}</td>
                        </ng-container>

                        <ng-container matColumnDef="percandidate">
                            <th mat-header-cell *matHeaderCellDef>Price Per Candidate</th>
                            <td mat-cell *matCellDef="let element" style="text-align: right;">
                                ₱{{element.percandidate}}.00</td>
                        </ng-container>

                        <ng-container matColumnDef="countcandidate">
                            <th mat-header-cell *matHeaderCellDef>Candidate Count</th>
                            <td mat-cell *matCellDef="let element">{{element.countcandidate}}</td>
                        </ng-container>

                        <ng-container matColumnDef="total">
                            <th mat-header-cell *matHeaderCellDef>Total Price</th>
                            <td mat-cell *matCellDef="let element" style="text-align: right;">₱{{element.total}}.00</td>
                        </ng-container>

                        <ng-container matColumnDef="order">
                            <th mat-header-cell *matHeaderCellDef>Order</th>
                            <td mat-cell *matCellDef="let element">{{element.orderno}}</td>
                        </ng-container>
                        <!-- Action Column -->
                        <ng-container matColumnDef="action">
                            <th mat-header-cell *matHeaderCellDef style="text-align: center;">Action</th>
                            <td mat-cell *matCellDef="let element" style="text-align: center;">
                                <button mat-icon-button (click)="editItem(element)">
                                    <mat-icon color="primary">edit</mat-icon>
                                </button>
                                <button mat-icon-button (click)="delete([element.id])">
                                    <mat-icon color="warn">delete</mat-icon>
                                </button>
                            </td>
                        </ng-container>

                        <tr mat-header-row *matHeaderRowDef="displayedColumns"></tr>
                        <tr mat-row *matRowDef="let row; columns: displayedColumns;"></tr>
                    </table>
                    <mat-paginator [pageSizeOptions]="[5, 10, 20]" showFirstLastButtons></mat-paginator>

                </div>
            </div>

            <div class="add" *ngIf="isAddMode || isEditMode">
                <mat-card style="margin: 0;border: 0;box-shadow: none;">
                    <mat-card-content class="formContent">
                        <form [formGroup]="dataForm" (ngSubmit)="onSubmit()" class="form">
                            <mat-form-field appearance="outline" class="example-full-width form-field"
                                style="width: 320px;">
                                <mat-label>Name</mat-label>
                                <input matInput formControlName="name">
                                <mat-error *ngIf="checkError('name', 'required')">Model Name is required</mat-error>
                                <mat-error *ngIf="checkError('name', 'maxlength')">Model Name should be less than 20
                                    characters</mat-error>
                            </mat-form-field>
                            <div fxLayout="column" fxLayout.gt-xs="row">
                                <mat-form-field style="width: 320px;">
                                    <mat-label>Frequency</mat-label>
                                    <mat-select formControlName="frequency">
                                        <mat-option [value]="0">None</mat-option>
                                        <mat-option *ngFor="let item of frequency" [value]="item.id">{{ item.text
                                            }}</mat-option>
                                    </mat-select>
                                </mat-form-field>
                            </div>
                            <div fxLayout="column" fxLayout.gt-xs="row">
                                <mat-form-field floatLabel="always" appearance="outline" class="form-field">
                                    <mat-label>Payment</mat-label>
                                    <input matInput type="text" formControlName="payment" class="amount-right-align"
                                        placeholder="0" appCommaDelimiter>
                                    <span matTextPrefix>₱&nbsp;</span>
                                    <span matTextSuffix>.00</span>
                                </mat-form-field>
                            </div>
                            <div fxLayout="column" fxLayout.gt-xs="row" style="width: 320px;">
                                <mat-form-field floatLabel="always" appearance="outline" class="form-field">
                                    <mat-label>Price Per Candidate</mat-label>
                                    <input matInput type="text" required formControlName="perCandidate"
                                        class="amount-right-align" placeholder="0" appCommaDelimiter>
                                    <span matTextPrefix>₱&nbsp;</span>
                                    <span matTextSuffix>.00</span>
                                    <mat-error *ngIf="checkError('perCandidate', 'required')">Price is
                                        required</mat-error>
                                </mat-form-field>

                                <mat-form-field appearance="outline" class="form-field" floatLabel="always">
                                    <mat-label>Candidate Count</mat-label>
                                    <input matInput type="number" formControlName="countCandidate">
                                    <mat-error *ngIf="checkError('countCandidate', 'required')">Count is
                                        required</mat-error>
                                </mat-form-field>
                            </div>

                            <div fxLayout="column" fxLayout.gt-xs="row" style="width: 320px;">
                                <mat-form-field floatLabel="always" appearance="outline" class="form-field">
                                    <mat-label>Total Price</mat-label>
                                    <input matInput type="text" formControlName="total" class="amount-right-align"
                                        placeholder="0" appCommaDelimiter>
                                    <span matTextPrefix>₱&nbsp;</span>
                                    <span matTextSuffix>.00</span>
                                    <mat-error *ngIf="checkError('total', 'required')">Total is
                                        required</mat-error>
                                </mat-form-field>

                                <mat-form-field appearance="outline" class="form-field" floatLabel="always">
                                    <mat-label>Order</mat-label>
                                    <input matInput type="number" formControlName="orderNo">
                                </mat-form-field>
                            </div>

                            <mat-toolbar style="background-color: white;margin-bottom: 20px;">
                                <div class="content" fxLayout="row" fxLayout.xs="column" fxFlexFill>
                                    <div fxFlex="80" fxFlex.xs="55">
                                        <mat-checkbox formControlName="status">Status</mat-checkbox>

                                    </div>
                                    <div fxFlex="20">
                                        <button mat-raised-button type="submit" style="margin-right: 10px;float: right;"
                                            color="primary">Submit</button>
                                    </div>

                                </div>
                            </mat-toolbar>

                        </form>
                    </mat-card-content>
                </mat-card>

            </div>
        </mat-tab>

        <mat-tab label="Audit Trail">
            <app-audit-trail-table [module]="8"></app-audit-trail-table>
        </mat-tab>
    </mat-tab-group>

</mat-card>