import { Injectable } from '@angular/core';
import axios from 'axios';
import { HttpClient, HttpErrorResponse, HttpHeaders } from '@angular/common/http';
import { AWSS3Service } from './aws.s3.service';
import { Observable } from 'rxjs';
import { AppConstants } from '../app.constants';
import { AuthService } from './auth.service';
import { environment } from 'src/environments/environment';

@Injectable({
  providedIn: 'root',
})
export class MemberService {
  // baseURL = 'https://api.virtuascript.net';
  baseURL = environment.apiUrl;

  // baseURL: `${environment.apiUrl}${req.url}`,
  user: any;
  constructor(
    private s3: AWSS3Service,
    private http: HttpClient,
    private constant: AppConstants,
    private authService: AuthService,

  ) {
    this.user = this.authService.getUser();
  }

  updateUserState() {
    this.user = this.authService.getUser();
  }

  async getMember(params): Promise<Observable<any>> {
    const response = await this.http.post("/account/member", { ...params })
    return response;
  }

  async addMember(params: any) {
    const response = await this.http.post("/account/member/add", { ...params, email: this.user.email })
    return response;
  }

  async addSubscriptionMember(params: any) {
    const response = await this.http.post("/account/member/subscription", { ...params, email: this.user.email })
    return response;
  }
  async UpdateMember(ids,  status: number) {
    const response = await this.http.post("/account/member/update-status", { ids,  status: status, email: this.user.email, accountId: this.user.accountId })
    return response;
  }

  async deleteMember(ids) {
    const response = await this.http.post("/account/member/delete", { ids, email: this.user.email })
    return response;
  }

  async export(): Promise<Observable<any>> {
    const response = this.http.post("/account/group/export", { email: this.user.email });
    return response;
  }

}
