import { Directive, HostListener, ElementRef, Renderer2 } from '@angular/core';

@Directive({
  selector: '[appNumberLimiter]'
})
export class NumberLimiterDirective {

  private readonly maxValue = 1000000000;

  constructor(private el: ElementRef, private renderer: Renderer2) { }

  @HostListener('input', ['$event'])
  onInputChange(event: any) {
    let input = event.target.value.replace(/,/g, '');
    if (!input) {
      this.renderer.setProperty(this.el.nativeElement, 'value', '');
      return;
    }

    const inputNumber = parseFloat(input);
    if (isNaN(inputNumber)) {
      this.renderer.setProperty(this.el.nativeElement, 'value', '');
      return;
    }

    if (inputNumber > this.maxValue) {
      input = this.maxValue.toString();
    }

    const formattedInput = this.formatNumber(input);
    this.renderer.setProperty(this.el.nativeElement, 'value', formattedInput);
  }

  private formatNumber(value: string): string {
    if (!value) return '';
    const [integer, decimal] = value.split('.');
    const formattedInteger = integer.replace(/\B(?=(\d{3})+(?!\d))/g, ',');
    return decimal ? `${formattedInteger}.${decimal}` : formattedInteger;
  }
}
