<div class="main-container">
    <mat-card style="margin: 0;border: 0;box-shadow: none;">
        <mat-card-header class="header">
            <mat-card-title>Select your preferred subscription plan </mat-card-title>
            <mat-card-subtitle>Already have subscription? Input subscription code <span (click)="openSubscription()" class="code-here">here</span></mat-card-subtitle>
        </mat-card-header>
    
        <mat-card-content>
            <div class="content" fxLayout="row" fxLayout.xs="column" fxFlexFill>
    
                <div class="sec1" fxFlex fxFlex.xs *ngFor="let item of listPlan">
                    <mat-card class="plan-card">
                        <mat-card-header class="plan-header">
                            <mat-card-title style="margin-bottom: 10px;">{{item.name}}</mat-card-title>
                        </mat-card-header>
                        <mat-card-content class="plan-content">
                            <div class="first-section">
                                <p fxLayoutAlign="center">₱ {{item.percandidate}}</p>
                                <span fxLayoutAlign="center">per candidate</span>
                            </div>
    
                            <div class="second-section">
                                <p fxLayoutAlign="center">{{item.countcandidate}}</p>
                                <p fxLayoutAlign="center">candidates</p>
                                <p fxLayoutAlign="center" class="price">₱ {{item.total}}</p>
                                <span fxLayoutAlign="center">total</span>
    
                            </div>
    
                            <div class="third-section" fxLayoutAlign="center">
                                <button (click)="subscribe(item.id)" mat-flat-button color="primary" class="demo-button" style="margin-left: 10px;">
                                   Subscribe
                                </button>
                            </div>
                        </mat-card-content>
                    </mat-card>
                </div>
    
            </div>
        </mat-card-content>
    </mat-card>
</div>