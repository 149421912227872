import { Injectable } from '@angular/core';
import { HttpClient, HttpErrorResponse, HttpHeaders } from '@angular/common/http';
import { HttpAxiosService } from '../auth/auth.interceptor.axios';
import axios from 'axios';
import { AppConstants } from '../app.constants';
import { environment } from 'src/environments/environment';

@Injectable({
  providedIn: 'root',
})
export class AWSS3Service {
  // baseURL = 'https://api.virtuascript.net';
  baseURL = environment.apiUrl
  // baseURL: `${environment.apiUrl}${req.url}`,

  constructor(
    private http: HttpClient,
    private axios: HttpAxiosService,
    private constant: AppConstants
  ) { }

  async uploadFile(file) {
    try {
      const uploadPresignedURL = await this.generatePresignedURL(file.name, "putObject") as string;
      const response = await axios.put(uploadPresignedURL, file);
      if (response.status == 200) {
        return file.name;
      }
    } catch (error) {
      throw { error: "Error uploading file: " + error };
    }
  }

  async generatePresignedURL(key, action) {
    try {
      const response = await this.axios.post(`${this.baseURL}/utils/presigned-url/generate`, { "key": key, "action": action },
        {
          headers: {
            "x-api-key": environment.apiKey
          }
        });
      return response.data;
    } catch (error) {
      console.error("Error getting pre-signed URL:", error);
      throw { error: "Error getting pre-signed URL: " + error };
    }
  }

  async uploadFilePub(file) {
    try {
      const uploadPresignedURL = await this.generatePresignedURLPub(file.name, "putObject") as string;
      const response = await axios.put(uploadPresignedURL, file);
      if (response.status == 200) {
        return file.name;
      }
    } catch (error) {
      throw { error: "Error uploading file: " + error };
    }
  }

  async generatePresignedURLPub(key, action) {
    try {
      const response = await this.axios.post(`${this.baseURL}/utils/presigned-url/generate-file`, { "key": key, "action": action },
        {
          headers: {
            "x-api-key": environment.apiKey
          }
        });
      return response.data;
    } catch (error) {
      console.error("Error getting pre-signed URL:", error);
      throw { error: "Error getting pre-signed URL: " + error };
    }
  }

}
