import { Directive, HostListener, ElementRef, Renderer2 } from '@angular/core';

@Directive({
  selector: '[appDecimalFormatter]'
})
export class DecimalFormatterDirective {

  constructor(private el: ElementRef, private renderer: Renderer2) { }

  @HostListener('input', ['$event'])
  onInputChange(event: any) {
    let input = event.target.value.replace(/,/g, ''); // Remove existing commas

    // If the input is empty, reset the value and show the suffix
    if (!input) {
      this.renderer.setProperty(this.el.nativeElement, 'value', '');
      this.toggleSuffix(true);
      return;
    }

    // Ensure only valid characters (digits and a single decimal point)
    input = input.replace(/[^0-9.]/g, '');

    // Ensure only one decimal point
    const parts = input.split('.');
    if (parts.length > 2) {
      input = parts[0] + '.' + parts.slice(1).join('');
    }

    // Format the input with commas
    const formattedInput = this.formatNumber(input);

    // Set the formatted value to the input field
    this.renderer.setProperty(this.el.nativeElement, 'value', formattedInput);

    // Toggle suffix visibility based on presence of decimal point
    this.toggleSuffix(!formattedInput.includes('.'));
  }

  private formatNumber(value: string): string {
    if (!value) return '';
    const [integer, decimal] = value.split('.');
    const formattedInteger = integer.replace(/\B(?=(\d{3})+(?!\d))/g, ',');
    return decimal !== undefined ? `${formattedInteger}.${decimal}` : formattedInteger;
  }

  private toggleSuffix(visible: boolean) {
    const suffixElement = this.el.nativeElement.parentElement.querySelector('.suffix');
    if (suffixElement) {
      this.renderer.setStyle(suffixElement, 'display', visible ? 'inline' : 'none');
    }
  }
}
