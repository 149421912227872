import { Component, Input } from '@angular/core';

@Component({
  selector: 'app-exam-result',
  templateUrl: './exam-result.component.html',
  styleUrls: ['./exam-result.component.css']
})
export class ExamResultComponent {
   today = new Date();
   @Input() data: any;
  
}
