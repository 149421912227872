import { Component, OnInit, ViewChild } from '@angular/core';
import { ActivatedRoute, Router, RouterModule } from '@angular/router';
import { NgFor } from '@angular/common';
import { MatListModule } from '@angular/material/list';
import { MatButtonModule } from '@angular/material/button';
import { MatCardModule } from '@angular/material/card';
import { MatDialog, MatDialogModule } from '@angular/material/dialog';
import { MatGridListModule } from '@angular/material/grid-list';
import { CommonModule } from '@angular/common'; // Import CommonModule
import { Observable, catchError, map, startWith, throwError } from 'rxjs';
import { MatTable, MatTableDataSource, MatTableModule } from '@angular/material/table';
import { MatPaginator, MatPaginatorModule } from '@angular/material/paginator';
import { ExamService } from 'src/app/service/exam.service';
import { DialogService } from 'src/app/service/dialog.service';
import { MatAutocompleteModule } from '@angular/material/autocomplete';
import { FormGroup, FormControl, Validators } from '@angular/forms';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { MatInputModule } from '@angular/material/input';
import { MatFormFieldModule } from '@angular/material/form-field';
import { MatCheckboxModule } from '@angular/material/checkbox';
import { SnackbarService } from 'src/app/lib/snackbar/snackbar.service';
import { UserService } from 'src/app/service/user.service';
import { AuditTrailService } from 'src/app/service/audit.trail.service';
import { MAT_DIALOG_DATA } from '@angular/material/dialog';
import { Inject } from '@angular/core';

@Component({
  selector: 'app-audit-trail-values',
  templateUrl: './audit-trail-values.component.html',
  styleUrls: ['./audit-trail-values.component.css'],
  standalone: true,
  imports: [MatDialogModule, MatInputModule, MatPaginatorModule,MatTableModule,MatButtonModule, MatCheckboxModule, MatAutocompleteModule, MatFormFieldModule, CommonModule, ReactiveFormsModule]
})
export class AuditTrailValuesComponent {
  displayedColumns: string[] = ['fieldname', 'previousvalue', 'newvalue'];
  dataSource = new MatTableDataSource<any>;
  public dataForm: FormGroup;
  exam: any[];
  userId: any;
  filteredExam: Observable<any[]>;
  pageSize: number = 5;
  moduleList: any[];
  @ViewChild(MatPaginator) paginator: MatPaginator;
  @ViewChild(MatTable) table: MatTable<any>;
  action: any;
  constructor(
    private examService: ExamService,
    private userService: UserService,
    private snackbarService: SnackbarService,
    private _Activatedroute: ActivatedRoute,
    private router: Router,
    private auditTrailService: AuditTrailService,
    @Inject(MAT_DIALOG_DATA) public data: { details }

  ) { }
  ngOnInit() {
    this.userId = this.router.url.split("/").pop();
    this.fetchData(this.data.details.auditlogid);
    this.action = this.data.details.action;
  }

  ngAfterViewInit() {
    this.dataSource.paginator = this.paginator;
  }
  async fetchData(module) {
    (await this.auditTrailService.fetchAuditTrailValues(module))
      .pipe(
        catchError(() => {
          return Observable.call(throwError(() => "Error"));
        })
      )
      .subscribe((response) => {
        const data = JSON.parse(JSON.stringify(response));

        this.dataSource.data = data;
        this.paginator.pageSize = this.pageSize;
        this.dataSource.paginator = this.paginator;
      });
  }

}
