
import { CommonModule } from '@angular/common';
import { Component, Inject, Input } from '@angular/core';
import { FlexLayoutModule } from '@angular/flex-layout';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { MatButtonModule } from '@angular/material/button';
import { MatCardModule } from '@angular/material/card';
import { MatCheckboxModule } from '@angular/material/checkbox';
import { MAT_DIALOG_DATA, MatDialogModule } from '@angular/material/dialog';
import { MatFormFieldControl, MatFormFieldModule } from '@angular/material/form-field';
import { MatGridListModule } from '@angular/material/grid-list';
import { MatIconModule } from '@angular/material/icon';
import { MatInputModule } from '@angular/material/input';
import { MatMenuModule } from '@angular/material/menu';
import { MatPaginatorModule } from '@angular/material/paginator';
import { MatSelectModule } from '@angular/material/select';
import { MatTableDataSource, MatTableModule } from '@angular/material/table';
import { MatToolbarModule } from '@angular/material/toolbar';
import { SnackbarService } from 'src/app/lib/snackbar/snackbar.service';
import { AuthService } from 'src/app/service/auth.service';
import { SubscriptionService } from 'src/app/service/subscription.service';
import { UserService } from 'src/app/service/user.service';
import { EditorChangeContent, EditorChangeSelection, QuillEditorComponent, QuillModule } from 'ngx-quill';
import Quill from 'quill';
import { DecimalFormatterDirective } from 'src/app/directives/decimal.directive';
import { MatRadioModule } from '@angular/material/radio';
import { AppConstants } from 'src/app/app.constants';

@Component({
  selector: 'app-preview-exam',
  templateUrl: './preview-exam.component.html',
  styleUrls: ['./preview-exam.component.css'],
  // standalone: true,
  // imports: [MatCardModule, MatRadioModule, MatInputModule,MatFormFieldModule, FormsModule, MatCheckboxModule, QuillModule, MatTableModule, MatDialogModule, ReactiveFormsModule, MatPaginatorModule, MatButtonModule, MatGridListModule, CommonModule, MatMenuModule, MatToolbarModule, MatIconModule, MatInputModule, MatFormFieldModule, MatSelectModule, MatCardModule, FlexLayoutModule],
})
export class PreviewExamComponent {
  displayedColumns: string[] = ['position', 'name', 'type'];
  dataSource = new MatTableDataSource<any>;
  user: any;
  isAdmin: any;
  subscriptionList: any[] = [];
  number = '';
  text: any[] = [];
  mcq = 0;

  essay = '';
  questionEditor: any;
  constructor(
    private authService: AuthService,
    private subscriptionService: SubscriptionService,
    private snackbarService: SnackbarService,
    private userService: UserService,
    @Inject(MAT_DIALOG_DATA) public data: any,
    private constant: AppConstants
  ) {
  }

  textAnswerTooltip = this.constant.textAnswerToolTip;
  wholeNumberTooltip = this.constant.wholeNumberToolTip;
  decimalNumberTooltip = this.constant.decimalNumberToolTip;
  ngOnInit() {
    this.user = this.authService.getUser();
    this.isAdmin = this.authService.extractId(this.user.azp) === 1;
    this.user.id = this.authService.extractId(this.user.aud);
  }

  questionEditorInstance(editorInstance: any) {
    this.questionEditor = editorInstance;
    this.questionEditor.disable();
  }

  getWordCount(text) {
    var matches = text.match(/\S+/g);
    return matches ? matches.length : 0;
  }

  async getUserRole() {
    (await this.userService.fetchUsers())
      .subscribe((response) => {
        const data = JSON.parse(JSON.stringify(response));
        const user = data.find(x => x.id == this.user.id);
        if (user) {
          this.user.azp = `${user.role}.${this.user.azp}`;
          localStorage.setItem('user', JSON.stringify(this.user));
          window.location.reload();
        }
      });
  }

  async switchSubscription(data) {
    this.user.accountId = data.id;
    this.user.accountName = data.name;
    this.user.group = data.group_name;
    this.snackbarService.showSnackbar("Account switched to " + data.name, 'top', 5000);
    await this.getUserRole();

  }
}
