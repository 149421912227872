<mat-dialog-actions align="end">
    <mat-icon mat-dialog-close class="close-profile">close</mat-icon>
</mat-dialog-actions>
<div mat-dialog-content *ngIf="user != null" style="margin: 0px;">
    <div class="wrapper">
        <div id="one">
            <ngx-avatars size="150" [name]="user.name" [src]="user.picture"></ngx-avatars>
            <!-- <img mat-card-lg-image [src]="user.picture" alt="user Profile" class="user-img"> -->
        </div>
        <div id="two">
            <h2>{{user.given_name}} {{user.family_name}} | {{isAdmin ? "Admin": ""}}
                {{!(isAdmin || isAccountAdmin)? "Candidate": ""}}
                {{isAccountAdmin ? "Account Admin": ""}}</h2>
            <p>{{user.email}}</p>
            <p *ngIf="subscription">Active Account: <span *ngIf="user.accountName"
                    style="color: blue;text-align: center;">
                    {{subscription.subscriptionid}}
                    <span class="p-color"> Count: </span>
                    {{subscription.remaining_count}}
                </span>
            </p>
            <p *ngIf="subscription">Account Name: <span *ngIf="user.accountName"
                    style="color: blue;text-align: center;">
                    {{user.accountName}}
                    <span class="p-color"> Type: </span> {{subscription.type_name}}
                </span>
            </p>

            <span *ngIf="!user.accountName">N/A</span>

        </div>
    </div>

    <mat-divider></mat-divider>
    <div style="margin-top: 10px;">
        <div class="dense-1" style="float: right;">
            <button mat-stroked-button (click)="switchAccount()" [disabled]="subscriptionList.length == 0">
                Switch Account
            </button>
        </div>
        <h2>Subscriptions:</h2>
        <table mat-table [dataSource]="dataSource">
            <ng-container matColumnDef="position">
                <th mat-header-cell *matHeaderCellDef style="width: 180px;"> Subscription ID</th>
                <td mat-cell *matCellDef="let element"> {{element.subscriptionid}} </td>
            </ng-container>

            <!-- Name Column -->
            <ng-container matColumnDef="name">
                <th mat-header-cell *matHeaderCellDef>Name </th>
                <td mat-cell *matCellDef="let element"> {{element.name}} </td>
            </ng-container>

            <ng-container matColumnDef="remaining">
                <th mat-header-cell *matHeaderCellDef style="width: 80px;"> Count</th>
                <td mat-cell *matCellDef="let element"> {{element.remaining_count}} </td>
            </ng-container>

            <ng-container matColumnDef="type">
                <th mat-header-cell *matHeaderCellDef style="width: 100px;">Type</th>
                <td mat-cell *matCellDef="let element"> {{element.type_name}} </td>
            </ng-container>
            <ng-container matColumnDef="status">
                <th mat-header-cell *matHeaderCellDef style="width: 100px;">Status</th>
                <td mat-cell *matCellDef="let element"> {{element.statusname}} </td>
            </ng-container>
            <tr mat-header-row *matHeaderRowDef="displayedColumns"></tr>
            <tr mat-row *matRowDef="let row; columns: displayedColumns;"></tr>
        </table>
        <h3 fxLayoutAlign="center" *ngIf="subscriptionList.length == 0"><br> No data to display!</h3>

        <mat-paginator  [pageSizeOptions]="[5, 10, 25, 100]" showFirstLastButtons></mat-paginator>
    
    </div>
    <br>
</div>