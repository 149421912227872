<mat-card appearance="outlined" style="margin-top: 10px;">
    <mat-card-header>
        <mat-card-title>{{pageTitle}}</mat-card-title>
    </mat-card-header>
    <div class="table-container">
        <mat-form-field style="margin: 10px;" class="dense-3" appearance="outline" floatLabel="always">
            <mat-label>Filter</mat-label>
            <input matInput (keyup)="applyFilter($event)" #input>
        </mat-form-field>

        <button class="refresh" mat-flat-button color="primary" matTooltip="Refresh data" (click)="refresh()">
            <mat-icon>refresh</mat-icon>Refresh
        </button>
        <div class="mat-elevation-z0">
            <table mat-table [dataSource]="dataSource" class="demo-table">
                <ng-container matColumnDef="position">
                    <th mat-header-cell *matHeaderCellDef>No.</th>
                    <td mat-cell *matCellDef="let element">{{element.userexamid}}</td>
                </ng-container>

                <ng-container matColumnDef="name">
                    <th mat-header-cell *matHeaderCellDef>Exam name</th>
                    <td mat-cell *matCellDef="let element">
                        <p class="name">{{element.name}}</p>
                    </td>
                </ng-container>

                <ng-container matColumnDef="subscription">
                    <th mat-header-cell *matHeaderCellDef>Subscription</th>
                    <td mat-cell *matCellDef="let element">
                        <p class="name" *ngIf="!element.subscription">Free</p>
                        <p class="name" *ngIf="element.subscription">{{element.subscription}}</p>
                    </td>
                </ng-container>

                <!-- Action Column -->
                <ng-container matColumnDef="action">
                    <th mat-header-cell *matHeaderCellDef style="text-align: center;">Action</th>
                    <td mat-cell *matCellDef="let element" style="text-align: center;">
                        <button *ngIf="element.isCompleted == null" class="action-btn" mat-stroked-button
                            color="primary"  (click)="openDialog(element.id, 0, element.userexamid)">Start</button>
                        <button *ngIf="element.retake == 2 && element.endtime != null"
                            class="action-btn" mat-stroked-button color="primary"
                            (click)="openDialog(element.id, 2, element.userexamid)">Retake</button>
                        <button *ngIf="element.isCompleted != null && element.endtime == null"
                            class="dense-2 action-btn" mat-stroked-button color="primary" 
                            (click)="continueExam(element.id, element.userexamid)">Continue</button>
                        <button
                            *ngIf="((element.isCompleted != null && element.endtime != null) || element.sequence >= 1) && pageType == 'done'"
                            class="action-btn" mat-button color="warn"
                            (click)="redirect(element.id, 3, element.userexamid)">Details</button>
                    </td>
                </ng-container>

                <tr mat-header-row *matHeaderRowDef="displayedColumns"></tr>
                <tr mat-row *matRowDef="let row; columns: displayedColumns;"></tr>
            </table>
         
            <h3 fxLayoutAlign="center" *ngIf="dataSource.data.length == 0"><br> No data to display!</h3>

            <mat-paginator [pageSizeOptions]="[5, 10, 25, 100]" showFirstLastButtons
                aria-label="Select page of users"></mat-paginator>
        </div>
    </div>

</mat-card>