import { Component, Inject, OnInit, ViewChild } from '@angular/core';
import { ExamService } from '../../service/exam.service';
import { Router, RouterModule } from '@angular/router';
import { NgFor } from '@angular/common';
import { MatListModule } from '@angular/material/list';
import { MatButtonModule } from '@angular/material/button';
import { MatCardModule } from '@angular/material/card';
import { MatDialog, MatDialogConfig, MatDialogModule } from '@angular/material/dialog';
import { MatGridListModule } from '@angular/material/grid-list';
import { CommonModule } from '@angular/common';
import { MatSidenavModule } from '@angular/material/sidenav';
import { MatMenuModule } from '@angular/material/menu';
import { MatToolbarModule } from '@angular/material/toolbar';
import { MatIconModule } from '@angular/material/icon';
import { MatExpansionModule } from '@angular/material/expansion';
import { MatTooltipModule } from '@angular/material/tooltip';
import { MatTable, MatTableDataSource, MatTableModule } from '@angular/material/table';
import { MatPaginator, MatPaginatorModule } from '@angular/material/paginator';
import { Observable, catchError, from, map, startWith, throwError } from 'rxjs';
import { FormGroup, FormControl, Validators } from '@angular/forms';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { MatInputModule } from '@angular/material/input';
import { MatFormFieldModule } from '@angular/material/form-field';
import { MatSelectModule } from '@angular/material/select';
import { AppConstants } from '../../app.constants';
import { MatCheckboxModule } from '@angular/material/checkbox';
import { SnackbarService } from 'src/app/lib/snackbar/snackbar.service';
import { DialogService } from 'src/app/service/dialog.service';
import { MatTabsModule } from '@angular/material/tabs';
import { MatProgressBarModule } from '@angular/material/progress-bar';
import { MatAutocompleteModule } from '@angular/material/autocomplete';
import { TimePickerExam } from 'src/app/components/timepicker/timepicker.component';
import { timeToMinutes, minutesToTime } from "../../common-function";
import { AWSS3Service } from 'src/app/service/aws.s3.service';
import { CategoryService } from 'src/app/service/category.service';
import { ExamModel } from 'src/app/model/exam';
import * as moment from 'moment';
import { AuditTrailService } from 'src/app/service/audit.trail.service';
import { AuditTrailTableComponent } from "../../components/audit-trail-table/audit-trail-table.component";
import { FlexLayoutModule } from "@angular/flex-layout";
import { SelectionModel } from '@angular/cdk/collections';
import { CommaDelimiterModule } from 'src/app/lib/comma-delimiter-module/comma-delimiter.module';
import { formatWithCommas } from '../../common-function'
import { AuthService } from 'src/app/service/auth.service';

@Component({
  selector: 'app-exam-file',
  templateUrl: './exam-file.component.html',
  styleUrls: ['./exam-file.component.css'],
  standalone: true,
  imports: [MatSidenavModule, FlexLayoutModule, CommaDelimiterModule, FormsModule, MatCheckboxModule, MatSelectModule, ReactiveFormsModule, MatInputModule, MatFormFieldModule,
    MatPaginatorModule, MatTableModule, MatToolbarModule, MatMenuModule, MatIconModule, MatExpansionModule, MatTooltipModule,
    CommonModule, MatGridListModule, MatListModule, NgFor, MatAutocompleteModule, MatCardModule, MatButtonModule, RouterModule, MatDialogModule, MatTabsModule, MatProgressBarModule, AuditTrailTableComponent]
})
export class ExamFileComponent {
  displayedColumns: string[] = ['select', 'position', 'name', 'noquestion', 'actualquestioncount', 'maxtime', 'passrate', 'category', 'paid', 'viewtype','status', 'action'];

  dataSource = new MatTableDataSource<ExamModel>;
  dataSourceAT = new MatTableDataSource<any>;
  selection = new SelectionModel<any>(true, []);

  public dataForm: FormGroup;
  shopName: any;
  isAddMode: boolean = false;
  isEditMode: boolean = false;
  categories: any;
  pageSize: number = 10;
  filteredCategories: Observable<any[]>;

  @ViewChild(MatPaginator) paginator: MatPaginator;
  @ViewChild(MatTable) table: MatTable<ExamModel>;

  // Mass upload
  @ViewChild('fileInput')
  fileInput;
  file: File | null = null;
  hasSubmit: boolean = false;
  examType = this.constant.ExamType;
  user: any;
  isAdmin: boolean = false;
  isAccountAdmin: boolean = false;
  isGroup: boolean = false;
  examList: any[] = [];
  ngAfterViewInit() {
    this.dataSource.paginator = this.paginator;
  }

  constructor(
    private router: Router, public dialog: MatDialog,
    private examService: ExamService,
    private categoryService: CategoryService,
    private snackbarService: SnackbarService,
    private dialogService: DialogService,
    private constant: AppConstants,
    private s3: AWSS3Service,
    private auditTrailService: AuditTrailService,
    private authService: AuthService

  ) {
  }
  async ngOnInit() {
    await this.initUser();
    await this.initPage();
    this.dataForm = new FormGroup({
      examId: new FormControl(''),
      examName: new FormControl('', [Validators.required, Validators.minLength(5), Validators.maxLength(100)]),
      noQuestions: new FormControl('', [Validators.required]),
      passRate: new FormControl('', [Validators.required]),
      maxTime: new FormControl('', [Validators.required]),
      maxTimeText: new FormControl('', [Validators.required]),
      category: new FormControl('', [Validators.required]),
      timeQuestion: new FormControl('', [Validators.required]),
      timeQuestionText: new FormControl('', [Validators.required]),
      isRandom: new FormControl(true),
      description: new FormControl('', [Validators.required, Validators.minLength(5), Validators.maxLength(500)]),
      paid: new FormControl((this.isAccountAdmin) ? 1 : 0, [Validators.required]),
      price: new FormControl('0', [Validators.required]),
      tags: new FormControl('', [Validators.required]),
      level: new FormControl('', [Validators.required]),
      examType: new FormControl(0),
      viewType: new FormControl((this.isAccountAdmin) ? 1 : 0, [Validators.required]),
    });

    this.configureAccountAdmin();
  }

  refresh(){
    this.initPage();
    this.selection.clear();
  }

  configureAccountAdmin() {
    if (this.isAccountAdmin) {
      this.dataForm.get('paid').disable();
      this.dataForm.get('viewType').disable();
    }
  }

  async initUser() {
    this.user = this.authService.getUser();
    if (this.user != null) {
      this.isAdmin = this.authService.extractId(this.user.azp) === 1;
      this.isAccountAdmin = this.authService.extractId(this.user.azp) === 2;
      this.isGroup = this.user.group !== "Individual - No Group";
    }
  }
  async initPage() {

    if (this.isAdmin) {
      await this.fetchData({});
    }

    if (this.isAccountAdmin) {
      const accountId = this.user.accountId || -1;
      await this.fetchData({ accountId });
    }
  }

  private _filterCategories(value: any): any[] {
    if (value != null) {
      if (typeof value === 'string') {
        const filterValue = value.toLowerCase();
        return this.categories.filter(category => category.name.toLowerCase().includes(filterValue));
      } else if (typeof value === 'object' && value.name) {
        return this.categories.filter(category => category.name.toLowerCase() === value.name.toLowerCase());
      }
    }
    return [];
  }

  isAllSelected() {
    const numSelected = this.selection.selected.length;
    const numRows = this.dataSource.data.length;
    return numSelected === numRows;
  }

  /** Selects all rows if they are not all selected; otherwise clear selection. */
  toggleAllRows() {
    if (this.isAllSelected()) {
      this.selection.clear();
      return;
    }

    this.selection.select(...this.dataSource.data);
  }

  async deleteHandler() {
    const ids = this.selection.selected.map(item => item.id)
    await this.delete(ids);
    this.selection.clear();
  }

  /** The label for the checkbox on the passed row */
  checkboxLabel(row?: any): string {
    if (!row) {
      return `${this.isAllSelected() ? 'deselect' : 'select'} all`;
    }
    return `${this.selection.isSelected(row) ? 'deselect' : 'select'} row ${row.position + 1}`;
  }
  // Function to display the selected category
  displayCategory(category: any): string {
    return category ? category.name : '';
  }

  applyFilter(event: Event) {
    const filterValue = (event.target as HTMLInputElement).value;
    this.dataSource.filter = filterValue.trim().toLowerCase();
  }

  applyFilterAT(event: Event) {
    const filterValue = (event.target as HTMLInputElement).value;
    this.dataSourceAT.filter = filterValue.trim().toLowerCase();
  }
  public checkError = (controlName: string, errorName: string) => {
    return this.dataForm.controls[controlName].hasError(errorName);
  }

  async fetchData(params) {
    (await this.examService.getAllExamFilter(params))
      .pipe(
        catchError(() => {
          return Observable.call(throwError(() => "Error"));
        })
      )
      .subscribe((response) => {
        const data = JSON.parse(JSON.stringify(response));
        data.forEach((item) => {
          item.statusName = this.constant.status.find(action => action.id == item.status)?.text || 'Unknown';
          item.price = formatWithCommas(item.price);
          item.maxtime = minutesToTime(item.maxtime);
          const time = this.splitTime(item.maxtime);
          if (!(time.hour == 0 && time.minute == 0)) {
            item.maxtimetext = this.displayDuration(time.hour, time.minute);
          } else {
            item.maxtimetext = "";
          }

          item.timequestion = minutesToTime(item.timequestion);
          const timeQuestion = this.splitTime(item.timequestion);
          if (!(timeQuestion.hour == 0 && timeQuestion.minute == 0)) {
            item.timequestiontext = this.displayDuration(timeQuestion.hour, timeQuestion.minute);
          }
        })
        this.examList = data;
        this.dataSource.data = data;
        this.paginator.pageSize = this.pageSize;
        this.dataSource.paginator = this.paginator;
      });
  }

  async fetchCategory() {
    (await this.categoryService.getAllCategoryMasterfile("name", 3))
      .pipe(
        catchError(() => {
          return Observable.call(throwError(() => "Error"));
        })
      )
      .subscribe((response) => {
        const data = JSON.parse(JSON.stringify(response));
        this.categories = data;
        this.filteredCategories = this.dataForm.get('category').valueChanges.pipe(
          startWith(''),
          map((value: any) => this._filterCategories(value))
        );
      });
  }

  cleanInputValue(value: string): string {
    return value.replace(/,/g, '');
  }

  async onSubmit() {
    if (this.dataForm.valid) {
      let cleanedPrice = this.cleanInputValue(this.dataForm.value.price);

      let defaultParams = {};
      if (this.isAccountAdmin) {
        defaultParams = {paid: 1, viewType: 1}
      }
      const payload = {
        ...this.dataForm.value,
        maxTime: timeToMinutes(this.dataForm.value.maxTime),
        timeQuestion: timeToMinutes(this.dataForm.value.timeQuestion),
        category: this.dataForm.value.category.id,
        price: cleanedPrice,
        accountId: this.user.accountId || null
      }

      if (this.isAddMode) {
        (await this.examService.addExam({...payload, ...defaultParams}))
          .subscribe((response) => {
            this.snackbarService.showSnackbar(response.toString(), 'top', 2000);
            this.initPage();
            this.auditTrailService.sendNotification(true);
            this.backAction();
          });
      }

      if (this.isEditMode) {
        (await this.examService.editExam({...payload, ...defaultParams}))
          .subscribe((response) => {
            this.snackbarService.showSnackbar(response.toString(), 'top', 2000);
            this.initPage();
            this.auditTrailService.sendNotification(true);
            this.backAction();
          });
      }
    }
  }

  async addAction() {
    await this.fetchCategory();
    this.isEditMode = false;
    this.isAddMode = true;
  }

  backAction() {
    this.isAddMode = false;
    this.isEditMode = false;
    this.dataForm.reset();
    this.dataForm.patchValue({
      isRandom: true,
      paid: (this.isAccountAdmin) ? 1 : 0,
      viewType: (this.isAccountAdmin) ? 1 : 0,
      examType: 0,
      price: '0'
    });

    this.configureAccountAdmin();

    setTimeout(() => {
      this.paginator.pageSize = this.pageSize;
      this.dataSource.paginator = this.paginator;
    }, 0);
  }

  async editItem(data) {
    await this.fetchCategory();
    this.dataForm.patchValue({
      examId: data.id,
      examName: data.name,
      noQuestions: data.noquestions,
      passRate: data.passrate,
      maxTime: data.maxtime,
      maxTimeText: data.maxtimetext,
      category: { id: data.category, name: data.categoryname },
      timeQuestion: data.timequestion,
      timeQuestionText: data.timequestiontext,
      isRandom: data.random == 1 ? true : false,
      description: data.description,
      paid: data.paid,
      price: data.price,
      tags: data.tags,
      level: data.level,
      examType: data.examtype,
      viewType: data.viewtype,
    });

    this.isEditMode = true;
    this.isAddMode = false;
  }

  async delete(ids) {
    this.dialogService.openDialog('Delete Exam', this.constant.deleteDataMessage, 'No', true, async (confirmed) => {
      if (confirmed) {
        (await this.examService.deleteExam(ids))
          .subscribe((response) => {
            this.snackbarService.showSnackbar(response.toString(), 'top', 10000);
            this.initPage();
            this.auditTrailService.sendNotification(true);
            this.isAddMode = false;
          });
      }
    });
  }

  goToQuestions(examId) {
    this.router.navigate(['masterfile/exam', examId]);
  }

  showTimePicker(type) {
    const time = (type == "Exam Duration") ? this.dataForm.value.maxTime : this.dataForm.value.timeQuestion;
    const dialogRef = this.dialog.open(TimePickerExam, { data: { time: time, title: type } });
    dialogRef.afterClosed().subscribe(result => {
      const timeResult = result.data.time;
      if (result) {
        let duration = `${timeResult.selectedHour}:${timeResult.selectedMinute}`;
        if (result.data.title == "Exam Duration") {
          this.dataForm.patchValue({
            maxTime: duration,
            maxTimeText: this.displayDuration(timeResult.selectedHour, timeResult.selectedMinute)
          });
        } else {
          this.dataForm.patchValue({
            timeQuestion: duration,
            timeQuestionText: this.displayDuration(timeResult.selectedHour, timeResult.selectedMinute)
          });
        }

      }
    });
  }

  displayDuration(hour, minute) {
    let text = "";
    let minuteText = (minute == 1) ? minute + " minute" : minute + " minutes";
    let hourText = (hour == 1) ? hour + " hour" : hour + " hours";

    if (hour == 0 && minute > 0) {
      text = minuteText;
    }

    if (minute == 0) {
      text = hourText;
    }
    if (minute > 0 && hour > 0) {
      text = `${hourText} and ${minuteText}`
    }

    return text;
  }

  splitTime(time) {
    let result = { hour: 0, minute: 0 };
    if (!(time == "" || time == null)) {
      const items = time.split(":");
      result.hour = parseInt(items[0]);
      result.minute = parseInt(items[1]);
    }
    return result;
  }

  public onSelectedIndexChange(tabIndex: number) {
    if (tabIndex === 0) {
      this.initPage();
    }
  }

  onClickFileInputButton(): void {
    this.fileInput.nativeElement.click();
  }

  onChangeFileInput(): void {
    const files: { [key: string]: File } = this.fileInput.nativeElement.files;
    this.file = files[0];
  }

  async exportAction() {
    (await this.examService.exportExam())
      .pipe(
        catchError((err) => {
          return Observable.call(throwError(() => "Error"));
        })
      )
      .subscribe((response) => {
        const blob = new Blob([response], { type: 'text/csv' });
        const url = window.URL.createObjectURL(blob);
        const a = document.createElement('a');
        a.href = url;
        a.download = 'Exam_data.csv';
        a.click();
        window.URL.revokeObjectURL(url);
        this.auditTrailService.sendNotification(true);
      });
  }
  async exportExamResult(id, examName) {
    (await this.examService.exportExamResult({examId: id}))
      .pipe(
        catchError((err) => {
          return Observable.call(throwError(() => "Error"));
        })
      )
      .subscribe((response) => {
        if (response.hasData) {
          const blob = new Blob([response.content], { type: 'text/csv' });
          const url = window.URL.createObjectURL(blob);
          const a = document.createElement('a');
          a.href = url;
          a.download = examName + '_' + moment().format('YYYY-MM-DD hhmmss') + '.csv';
          a.click();
          window.URL.revokeObjectURL(url);
          this.auditTrailService.sendNotification(true);
        } else {
          this.snackbarService.showSnackbar(response.content, 'top', 10000);
        }

      });
  }

  async importExam() {
    this.hasSubmit = true;
    try {
      const key = await this.s3.uploadFile(this.file);
      const response = await this.examService.importExam(key);
      const data = JSON.parse(JSON.stringify(response.data));
      if (response.status === 200) {
        this.initPage();
        this.auditTrailService.sendNotification(true);
        this.snackbarService.showSnackbar(data.message, 'top', 10000);
      }

      this.file = null;
      this.hasSubmit = false;
    } catch (error) {
      this.snackbarService.showSnackbar("An error occured while processing the request", 'top', 10000);
      this.file = null;
      this.hasSubmit = false;
    }
  }

  async exportExamTemplate() {
    (await this.examService.exportExamTemplate())
      .pipe(
        catchError((err) => {
          return Observable.call(throwError(() => "Error"));
        })
      )
      .subscribe((response) => {
        const blob = new Blob([response], { type: 'text/csv' });
        const url = window.URL.createObjectURL(blob);
        const a = document.createElement('a');
        a.href = url;
        a.download = 'Exam_template.csv';
        a.click();
        window.URL.revokeObjectURL(url);
        this.auditTrailService.sendNotification(true);
      });
  }

}

