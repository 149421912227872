<mat-toolbar>
    <mat-form-field style="margin-top: 20px;">
        <mat-label>Filter</mat-label>
        <input matInput (keyup)="applyFilter($event)" #input>
    </mat-form-field>
    <mat-form-field style="margin-top: 20px;margin-left: 5px;" *ngIf="currentRoute =='/audit-trail'">
        <mat-label>Module</mat-label>
        <mat-select [(value)]="module" (selectionChange)="onModuleChange($event)">
            <mat-option value="">All</mat-option>
            <mat-option *ngFor="let item of moduleList" [value]="item.id">{{ item.name }}</mat-option>
        </mat-select>
    </mat-form-field>
    <span class="toolbar-item-spacer"></span>
    <button style="margin-right: 10px;" mat-flat-button
    color="primary" matTooltip="Refresh data" (click)="refresh()">
    <mat-icon>refresh</mat-icon>Refresh
</button>
    <!-- <button mat-flat-button color="primary" (click)="exportAction()" class="demo-button" style="margin-right: 10px;">
        <mat-icon> insert_drive_file</mat-icon>CSV
    </button> -->
</mat-toolbar>
<div class="table-container">
    <div class="mat-elevation-z0" style="margin-top: 15px;">
        <div class="table-container">
            <table mat-table [dataSource]="dataSource" class="demo-table">
                <ng-container matColumnDef="id">
                    <th mat-header-cell *matHeaderCellDef>ID</th>
                    <td mat-cell *matCellDef="let element">{{element.auditlogid}}</td>
                </ng-container>

                <ng-container matColumnDef="datetime">
                    <th mat-header-cell *matHeaderCellDef>Date Time</th>
                    <td mat-cell *matCellDef="let element">{{element.datetime}}</td>
                </ng-container>
                <ng-container matColumnDef="tablename">
                    <th mat-header-cell *matHeaderCellDef>Table Name/Info</th>
                    <td mat-cell *matCellDef="let element">{{element.tablename}}</td>
                </ng-container>
                <ng-container matColumnDef="recordpk">
                    <th mat-header-cell *matHeaderCellDef>Record Primary Key</th>
                    <td mat-cell *matCellDef="let element">{{element.recordpk == 0 || element.recordpk == null ? 'N/A':
                        element.recordpk}}</td>
                </ng-container>
                <ng-container matColumnDef="user">
                    <th mat-header-cell *matHeaderCellDef>User</th>
                    <td mat-cell *matCellDef="let element">{{element.user}}</td>
                </ng-container>
                <ng-container matColumnDef="actionname">
                    <th mat-header-cell *matHeaderCellDef>Action</th>
                    <td mat-cell *matCellDef="let element">
                        <!-- <p *ngIf="!element.isHaveValues">{{element.action}}</p> -->
                        <p class="action-col" (click)="viewDetails(element)">{{element.action}}</p>
                    </td>
                </ng-container>
                <ng-container matColumnDef="module">
                    <th mat-header-cell *matHeaderCellDef>Module</th>
                    <td mat-cell *matCellDef="let element">{{element.module}}</td>
                </ng-container>

                <tr mat-header-row *matHeaderRowDef="displayedColumnsAT"></tr>
                <tr mat-row *matRowDef="let row; columns: displayedColumnsAT;"></tr>
            </table>
            <mat-paginator [pageSizeOptions]="[5, 10, 20]" showFirstLastButtons></mat-paginator>
        </div>
    </div>
</div>