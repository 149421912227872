import { Component, OnInit, ViewChild } from '@angular/core';
import { ActivatedRoute, Router, RouterModule } from '@angular/router';
import { NgFor } from '@angular/common';
import { MatListModule } from '@angular/material/list';
import { MatButtonModule } from '@angular/material/button';
import { MatCardModule } from '@angular/material/card';
import { MatDialog, MatDialogModule } from '@angular/material/dialog';
import { MatGridListModule } from '@angular/material/grid-list';
import { CommonModule } from '@angular/common'; // Import CommonModule
import { Observable, catchError, map, startWith, throwError } from 'rxjs';
import { MatSidenavModule } from '@angular/material/sidenav';
import { MatMenuModule } from '@angular/material/menu';
import { MatToolbarModule } from '@angular/material/toolbar';
import { MatIconModule } from '@angular/material/icon';
import { MatExpansionModule } from '@angular/material/expansion';
import { MatTooltipModule } from '@angular/material/tooltip';
import { MatTable, MatTableDataSource, MatTableModule } from '@angular/material/table';
import { MatPaginator, MatPaginatorModule } from '@angular/material/paginator';
import { ExamService } from 'src/app/service/exam.service';
import { DialogService } from 'src/app/service/dialog.service';
import { MatAutocompleteModule } from '@angular/material/autocomplete';
import { FormGroup, FormControl, Validators } from '@angular/forms';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { MatInputModule } from '@angular/material/input';
import { MatFormFieldModule } from '@angular/material/form-field';
import { MatCheckboxModule } from '@angular/material/checkbox';
import { SnackbarService } from 'src/app/lib/snackbar/snackbar.service';
import { UserService } from 'src/app/service/user.service';
import { UserExamModel } from 'src/app/model/user-exam';
import { AuditTrailService } from 'src/app/service/audit.trail.service';
import { MatTabsModule } from '@angular/material/tabs';
import { FlexLayoutModule } from "@angular/flex-layout";
import { AuditTrailTableComponent } from "../../components/audit-trail-table/audit-trail-table.component";
import { AddUserExamModalComponent } from 'src/app/components/add-user-exam-modal/add-user-exam-modal.component';
import * as moment from 'moment';
import { AuthService } from 'src/app/service/auth.service';

@Component({
  selector: 'app-user-exam-list',
  templateUrl: './user-exam-list.component.html',
  styleUrls: ['./user-exam-list.component.css'],
  standalone: true,
  imports: [MatInputModule, FormsModule, FlexLayoutModule, MatSidenavModule, MatPaginatorModule, MatTableModule, MatToolbarModule, MatMenuModule, MatIconModule,
    MatExpansionModule, MatTooltipModule, CommonModule, MatGridListModule, MatListModule, NgFor, MatCardModule,
    MatButtonModule, RouterModule, MatDialogModule, MatCheckboxModule, MatFormFieldModule, MatTabsModule, AuditTrailTableComponent]
})
export class UserExamListComponent implements OnInit {

  isFetching: boolean = false;
  displayedColumns: string[] = ['position', 'name', 'subscription', 'action'];
  dataSource = new MatTableDataSource<UserExamModel>;
  userId: any;
  user: any;
  pageSize: number = 5;
  currentPath = "user-exam";
  email = "Loading .. .";
  @ViewChild(MatPaginator) paginator: MatPaginator;
  @ViewChild(MatTable) table: MatTable<UserExamModel>;

  constructor(
    private router: Router,
    public dialog: MatDialog,
    private userService: UserService,
    private _Activatedroute: ActivatedRoute,
    private dialogService: DialogService,
    private snackbarService: SnackbarService,
    private auditTrailService: AuditTrailService,
    private examService: ExamService,
    private authService: AuthService,

  ) {
  }
  ngOnInit() {
    this.initPath();
    this.initUser();
    this.userId = this._Activatedroute.snapshot.paramMap.get('id');
    this.fetchUserExam();
    this.getEmail();
  }

  refresh() {
    this.fetchUserExam();

  }

  async initUser() {
    this.user = this.authService.getUser();
  }

  initPath() {
    const parts = this.router.url.split("/");
    this.currentPath = parts[2];
  }

  ngAfterViewInit() {
    this.dataSource.paginator = this.paginator;
  }

  applyFilter(event: Event) {
    const filterValue = (event.target as HTMLInputElement).value;
    this.dataSource.filter = filterValue.trim().toLowerCase();
  }

  openDialog() {

    const dialogRef = this.dialog.open(AddUserExamModalComponent, {
      data: {
        userIds: [this.userId]
      }
    });
    dialogRef.afterClosed().subscribe(result => {
      if (result) {
        setTimeout(() => {
          this.fetchUserExam();
        }, 2000)
      }
    });
  }

  redirect(examId: number) {
    if (this.currentPath == 'user-exam') {
      this.router.navigate(['masterfile/user-exam', examId]);
    } else {
      this.router.navigate(['masterfile/account-member']);
    }
  }

  async fetchUserExam() {
    let payload = {};
    if (this.currentPath == 'user-exam') {
      payload = {
        userId: this.userId
      }
    } else {
      payload = {
        userId: this.userId,
        accountId: this.user.accountId
      }
    }
    await (await this.userService.getMasterUserExam(payload))
      .pipe(
        catchError(() => {
          this.router.navigate(['masterfile/user-exam', this.userId]);
          return Observable.call(throwError(() => "Error"));
        })
      )
      .subscribe(
        (response) => {
          response.forEach((item) => {
            item.isActive = item.active == 1 ? true : false
          })
          const data = JSON.parse(JSON.stringify(response));

          this.dataSource.data = data;
          this.paginator.pageSize = this.pageSize;
          this.dataSource.paginator = this.paginator;

        },
        (error) => {
          console.error('Request failed with error')
        })
  }


  async getEmail() {
    (await this.userService.fetchUsers())
      .pipe(
        catchError(() => {
          return Observable.call(throwError(() => "Error"));
        })
      )
      .subscribe((response) => {
        const data = JSON.parse(JSON.stringify(response));
        const user = data.find(x => x.id == this.userId);
        if (user) {
          this.email = user.email;
        }
      });
  }

  async deleteUserExam(id) {
    this.dialogService.openDialog('Delete this exam', 'Are you sure you want to remove this exam?', 'No', true, async (confirmed) => {
      if (confirmed) {
        (await this.userService.deleteUserExam({ userExamId: id }))
          .subscribe((response) => {
            this.snackbarService.showSnackbar(response.toString(), 'top', 2500);
            this.fetchUserExam();
            this.auditTrailService.sendNotification(true);
          });
      } else {
        console.log('Close');
      }
    });
  }

  async toggleUserExam(id, examMode) {
    const mode = !examMode;
    (await this.userService.toggleUserExam({ userExamId: id, mode: mode }))
      .subscribe((response) => {
        this.snackbarService.showSnackbar(response.toString(), 'top', 2500);
        this.fetchUserExam();
        this.auditTrailService.sendNotification(true);
      });
  }

  async toggleRetake(id) {
    (await this.userService.toggleRetake({ userExamId: id, retake: 2 }))
      .subscribe((response) => {
        this.snackbarService.showSnackbar(response.toString(), 'top', 2500);
        this.fetchUserExam();
        this.auditTrailService.sendNotification(true);
      });
  }

  async exportExamResult(item, type) {
    let payload = {};
    let name = item == null ? this.user.group : item.exam_name;
    if (type == 1) {
      payload = {
        examId: item.examid,
        userId: item.userid,
        accountId: item.accountid,
        userExamId: item.id
      };
    } else {
      payload = {
        userId: this.userId,
        accountId: this.user.accountId
      };
    }
    (await this.examService.exportExamResult(payload))
      .pipe(
        catchError((err) => {
          return Observable.call(throwError(() => "Error"));
        })
      )
      .subscribe((response) => {
        if (response.hasData) {
          const blob = new Blob([response.content], { type: 'text/csv' });
          const url = window.URL.createObjectURL(blob);
          const a = document.createElement('a');
          a.href = url;
          a.download = name + '_' + moment().format('YYYY-MM-DD hhmmss') + '.csv';
          a.click();
          window.URL.revokeObjectURL(url);
          this.auditTrailService.sendNotification(true);
        } else {
          this.snackbarService.showSnackbar(response.content, 'top', 10000);
        }
      });
  }

}

