import { Component, OnInit, ViewChild } from '@angular/core';
import { ExamService } from '../../service/exam.service';
import { Router, RouterModule } from '@angular/router';
import { NgFor } from '@angular/common';
import { MatListModule } from '@angular/material/list';
import { MatButtonModule } from '@angular/material/button';
import { MatCardModule } from '@angular/material/card';
import { MatDialog, MatDialogModule } from '@angular/material/dialog';
import { MatGridListModule } from '@angular/material/grid-list';
import { CommonModule } from '@angular/common';
import { MatSidenavModule } from '@angular/material/sidenav';
import { MatMenuModule } from '@angular/material/menu';
import { MatToolbarModule } from '@angular/material/toolbar';
import { MatIconModule } from '@angular/material/icon';
import { MatExpansionModule } from '@angular/material/expansion';
import { MatTooltipModule } from '@angular/material/tooltip';
import { MatTable, MatTableDataSource, MatTableModule } from '@angular/material/table';
import { MatPaginator, MatPaginatorModule } from '@angular/material/paginator';
import { Observable, catchError, from, map, startWith, throwError } from 'rxjs';
import { FormGroup, FormControl, Validators } from '@angular/forms';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { MatInputModule } from '@angular/material/input';
import { MatFormFieldModule } from '@angular/material/form-field';
import { MatSelectModule } from '@angular/material/select';
import { MatCheckboxModule } from '@angular/material/checkbox';
import { SnackbarService } from 'src/app/lib/snackbar/snackbar.service';
import { DialogService } from 'src/app/service/dialog.service';
import { MatAutocompleteModule } from '@angular/material/autocomplete';
import { MatTabsModule } from '@angular/material/tabs';
import { MatProgressBarModule } from '@angular/material/progress-bar';
import { CategoryService } from 'src/app/service/category.service';
import { AWSS3Service } from 'src/app/service/aws.s3.service';
import { CategoryModel } from 'src/app/model/category';
import { AuditTrailTableComponent } from "../../components/audit-trail-table/audit-trail-table.component";
import { AuditTrailService } from 'src/app/service/audit.trail.service';
import { FlexLayoutModule } from "@angular/flex-layout";
import { SelectionModel } from '@angular/cdk/collections';
import { AppConstants } from 'src/app/app.constants';

@Component({
  selector: 'app-category',
  templateUrl: './category.component.html',
  styleUrls: ['./category.component.css'],
  standalone: true,
  imports: [MatSidenavModule, FlexLayoutModule, MatAutocompleteModule, MatTabsModule, FormsModule, MatProgressBarModule, MatCheckboxModule, MatSelectModule, ReactiveFormsModule, MatInputModule, MatFormFieldModule,
    MatPaginatorModule, MatTableModule, MatToolbarModule, MatMenuModule, MatIconModule, MatExpansionModule, MatTooltipModule,
    CommonModule, MatGridListModule, MatListModule, NgFor, MatCardModule, MatButtonModule, RouterModule, MatDialogModule, AuditTrailTableComponent]
})
export class CategoryComponent {
  displayedColumns: string[] = ['select', 'position', 'name', 'level', 'main', 'action'];
  dataSource = new MatTableDataSource<CategoryModel>;
  dataSourceAT = new MatTableDataSource<any>;
  selection = new SelectionModel<any>(true, []);
  filteredLevelCategories: Observable<any[]>;

  public dataForm: FormGroup;
  isAddMode: boolean = false;
  isEditMode: boolean = false;
  hasAction: any;
  categories: any;
  categoriesLevel: any;
  pageSize: number = 10;

  @ViewChild(MatPaginator) paginator: MatPaginator;
  @ViewChild(MatTable) table: MatTable<CategoryModel>;

  // Mass upload
  @ViewChild('fileInput')
  fileInput;
  file: File | null = null;
  hasSubmit: boolean = false;
  level = this.constant.level;
  selectedLevel: number = this.level[0].id;
  isMainVisible: boolean = false;

  ngAfterViewInit() {
    this.dataSource.paginator = this.paginator;
  }

  constructor(
    private router: Router, public dialog: MatDialog,
    private examService: ExamService,
    private snackbarService: SnackbarService,
    private dialogService: DialogService,
    private s3: AWSS3Service,
    private categoryService: CategoryService,
    private auditTrailService: AuditTrailService,
    private constant: AppConstants,

  ) {
  }
  ngOnInit() {
    this.fetchData();
    this.fetchByLevel(0);
    this.dataForm = new FormGroup({
      categoryId: new FormControl(''),
      name: new FormControl('', [Validators.required, Validators.maxLength(50)]),
      level: new FormControl('', [Validators.required]),
      main: new FormControl(0)
    });

    this.hasAction = null;
  }

  refresh(){
    this.fetchData();
    this.fetchByLevel(0);
    this.selection.clear();
  }
  isAllSelected() {
    const numSelected = this.selection.selected.length;
    const numRows = this.dataSource.data.length;
    return numSelected === numRows;
  }

  /** Selects all rows if they are not all selected; otherwise clear selection. */
  toggleAllRows() {
    if (this.isAllSelected()) {
      this.selection.clear();
      return;
    }

    this.selection.select(...this.dataSource.data);
  }

  /** The label for the checkbox on the passed row */
  checkboxLabel(row?: any): string {
    if (!row) {
      return `${this.isAllSelected() ? 'deselect' : 'select'} all`;
    }
    return `${this.selection.isSelected(row) ? 'deselect' : 'select'} row ${row.position + 1}`;
  }

  private _filterCategories(value: any): any[] {
    if (value != null) {
      if (typeof value === 'string') {
        const filterValue = value.toLowerCase();
        return this.categoriesLevel.filter(category => category.name.toLowerCase().includes(filterValue));
      } else if (typeof value === 'object' && value.name) {
        return this.categoriesLevel.filter(category => category.name.toLowerCase() === value.name.toLowerCase());
      }
    }
    return [];
  }

  displayCategory(category: any): string {
    return category ? category.name : '';
  }

  async deleteHandler() {
    const ids = this.selection.selected.map(item => item.id)
    await this.delete(ids);
  }

  public checkError = (controlName: string, errorName: string) => {
    return this.dataForm.controls[controlName].hasError(errorName);
  }

  applyFilter(event: Event) {
    const filterValue = (event.target as HTMLInputElement).value;
    this.dataSource.filter = filterValue.trim().toLowerCase();
  }

  async toggleMainFieldVisibility(): Promise<void> {
    const mainControl = this.dataForm.get('main');
    if (this.selectedLevel > 1 && !(this.selectedLevel == 4)) {
      let id = this.selectedLevel - 1
      if (this.isEditMode) {
        id = 0;
      }
      await this.fetchByLevel(id);
      mainControl.setValidators([Validators.required]);
    } else {
      mainControl.clearValidators();
    }
    mainControl.updateValueAndValidity();
    this.dataForm.updateValueAndValidity();
  }
  isFormValid(): boolean {
    const mainControl = this.dataForm.get('main');

    if (this.isMainVisible && !mainControl.value) {
      return false;
    }

    return this.dataForm.valid;
  }
  isMainFieldVisible(): boolean {
    this.isMainVisible = this.selectedLevel > 1 && !(this.selectedLevel == 4);
    return this.isMainVisible;
  }

  async fetchData() {
    (await this.categoryService.getAllCategoryMasterfile("id", 0))
      .pipe(
        catchError(() => {
          return Observable.call(throwError(() => "Error"));
        })
      )
      .subscribe((response) => {
        const data = JSON.parse(JSON.stringify(response));
        this.dataSource.data = data;
        this.paginator.pageSize = this.pageSize;
        this.dataSource.paginator = this.paginator;
      });
  }

  async fetchByLevel(level) {
    (await this.categoryService.getAllCategoryMasterfile("id", level))
      .pipe(
        catchError(() => {
          return Observable.call(throwError(() => "Error"));
        })
      )
      .subscribe((response) => {
        const data = JSON.parse(JSON.stringify(response));
        this.categoriesLevel = data;

        this.filteredLevelCategories = this.dataForm.get('main').valueChanges.pipe(
          startWith(''),
          map((value: any) => this._filterCategories(value))
        );
      });
  }

  async onSubmit() {
    if (this.dataForm.valid) {
      if (this.isAddMode) {
        (await this.categoryService.addCategory(this.dataForm.value))
          .subscribe((response) => {
            this.snackbarService.showSnackbar(response.toString(), 'top', 2000);
            this.fetchData();
            this.auditTrailService.sendNotification(true);
            this.backAction();
          });
      }

      if (this.isEditMode) {
        (await this.categoryService.editCategory(this.dataForm.value))
          .subscribe((response) => {
            this.snackbarService.showSnackbar(response.toString(), 'top', 2000);
            this.fetchData();
            this.auditTrailService.sendNotification(true);
            this.backAction();
          });
      }

    }
  }

  public onSelectedIndexChange(tabIndex: number) {
    if (tabIndex === 0) {
      this.fetchData();
    }
  }

  addAction() {
    this.isEditMode = false;
    this.isAddMode = true;
  }

  backAction() {
    this.isAddMode = false;
    this.isEditMode = false;
    this.dataForm.reset();
    this.selectedLevel = 0;
    setTimeout(() => {
      this.paginator.pageSize = this.pageSize;
      this.dataSource.paginator = this.paginator;
    }, 0);
  }

  async editItem(data) {
    try {
      this.selectedLevel = data.level;
      this.dataForm.patchValue({
        categoryId: data.id,
        name: data.name,
        level: data.level,
      });

      if (this.selectedLevel > 1) {
        await this.toggleMainFieldVisibility();

        const mainOption = this.categoriesLevel.find(option => option.id == data.main);

        if (mainOption) {
          this.dataForm.patchValue({
            main: mainOption,
          });
        } else {
          this.dataForm.patchValue({
            main: null,
          });
        }

      }

      this.isEditMode = true;
      this.isAddMode = false;
    } catch (error) {
      console.error("An error occurred while editing item:", error);
    }
  }


  async delete(ids) {
    this.dialogService.openDialog('Delete Category', 'Are you sure you want to delete the selected data?', 'No', true, async (confirmed) => {
      if (confirmed) {
        (await this.categoryService.deleteCategory(ids))
          .subscribe((response) => {
            this.selection.clear();
            this.snackbarService.showSnackbar(response.toString(), 'top', 50000);
            this.fetchData();
            this.auditTrailService.sendNotification(true);
          });
      } else {
        console.log('Close');
      }
    });
  }

  async exportAction() {
    (await this.categoryService.exportCategory())
      .pipe(
        catchError((err) => {
          return Observable.call(throwError(() => "Error"));
        })
      )
      .subscribe((response) => {
        const blob = new Blob([response], { type: 'text/csv' });
        const url = window.URL.createObjectURL(blob);
        const a = document.createElement('a');
        a.href = url;
        a.download = 'Category.csv';
        a.click();
        window.URL.revokeObjectURL(url);
        this.auditTrailService.sendNotification(true);
      });
  }

  async importCategory() {
    this.hasSubmit = true;
    try {
      const key = await this.s3.uploadFile(this.file);
      const response = await this.categoryService.importCategory(key);
      if (response.status === 200) {
        this.fetchData();
        this.auditTrailService.sendNotification(true);
        this.snackbarService.showSnackbar(response.data.message, 'top', 10000);
      }

      this.file = null;
      this.hasSubmit = false;
    } catch (error) {
      this.snackbarService.showSnackbar("An error occured while processing the request", 'top', 10000);
      this.file = null;
      this.hasSubmit = false;
    }
  }

  async exportCategoryTemplate() {
    (await this.categoryService.exportCategoryTemplate())
      .pipe(
        catchError((err) => {
          return Observable.call(throwError(() => "Error"));
        })
      )
      .subscribe((response) => {
        const blob = new Blob([response], { type: 'text/csv' });
        const url = window.URL.createObjectURL(blob);
        const a = document.createElement('a');
        a.href = url;
        a.download = 'Category_template.csv';
        a.click();
        window.URL.revokeObjectURL(url);
        this.auditTrailService.sendNotification(true);
      });
  }

  onClickFileInputButton(): void {
    this.fileInput.nativeElement.click();
  }

  onChangeFileInput(): void {
    const files: { [key: string]: File } = this.fileInput.nativeElement.files;
    this.file = files[0];
  }
}
