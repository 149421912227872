import { Component, OnInit, ViewChild } from '@angular/core';
import { ActivatedRoute, Router, RouterModule } from '@angular/router';
import { NgFor } from '@angular/common';
import { MatListModule } from '@angular/material/list';
import { MatButtonModule } from '@angular/material/button';
import { MatCardModule } from '@angular/material/card';
import { MatDialog, MatDialogModule } from '@angular/material/dialog';
import { MatGridListModule } from '@angular/material/grid-list';
import { CommonModule } from '@angular/common';
import { MatSidenavModule } from '@angular/material/sidenav';
import { MatMenuModule } from '@angular/material/menu';
import { MatToolbarModule } from '@angular/material/toolbar';
import { MatIconModule } from '@angular/material/icon';
import { MatExpansionModule } from '@angular/material/expansion';
import { MatTooltipModule } from '@angular/material/tooltip';
import { MatTable, MatTableDataSource, MatTableModule } from '@angular/material/table';
import { MatPaginator, MatPaginatorModule } from '@angular/material/paginator';
import { Observable, catchError, from, map, startWith, throwError } from 'rxjs';
import { FormGroup, FormControl, Validators } from '@angular/forms';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { MatInputModule } from '@angular/material/input';
import { MatFormFieldModule } from '@angular/material/form-field';
import { MatSelectModule } from '@angular/material/select';
import { MatCheckboxModule } from '@angular/material/checkbox';
import { SnackbarService } from 'src/app/lib/snackbar/snackbar.service';
import { DialogService } from 'src/app/service/dialog.service';
import { MatRadioChange, MatRadioModule } from '@angular/material/radio';
import { AppConstants } from '../../app.constants';
import { MatAutocompleteModule } from '@angular/material/autocomplete';
import { MatTabsModule } from '@angular/material/tabs';
import { MatProgressBarModule } from '@angular/material/progress-bar';
import { AWSS3Service } from 'src/app/service/aws.s3.service';
import { CategoryService } from 'src/app/service/category.service';
import { ExamService } from 'src/app/service/exam.service';
import { ExamQuestionModel } from 'src/app/model/exam-question';
import { AuditTrailTableComponent } from "../../components/audit-trail-table/audit-trail-table.component";
import { AuditTrailService } from 'src/app/service/audit.trail.service';
import { FlexLayoutModule } from "@angular/flex-layout";
import { SelectionModel } from '@angular/cdk/collections';
import { UserService } from 'src/app/service/user.service';
import { timeToMinutes, minutesToTime } from "../../common-function";
import { AuthService } from 'src/app/service/auth.service';
import { MemberService } from 'src/app/service/member.service';
import { SubscriptionService } from 'src/app/service/subscription.service';
import * as moment from 'moment';

@Component({
  selector: 'app-exam-assignment',
  templateUrl: './exam-assignment.component.html',
  styleUrls: ['./exam-assignment.component.css'],
  standalone: true,
  imports: [MatProgressBarModule, MatSidenavModule, FlexLayoutModule, FormsModule, MatCheckboxModule, MatSelectModule, ReactiveFormsModule, MatInputModule, MatFormFieldModule,
    MatPaginatorModule, MatTableModule, MatToolbarModule, MatMenuModule, MatIconModule, MatExpansionModule, MatTooltipModule, MatAutocompleteModule,
    CommonModule, MatGridListModule, MatListModule, NgFor, MatCardModule, MatButtonModule, RouterModule, MatDialogModule, MatRadioModule, MatTabsModule, AuditTrailTableComponent]
})
export class ExamAssignmentComponent {
  displayedColumns: string[] = ['select', 'position', 'email', 'exam', 'count', 'action'];
  dataSource = new MatTableDataSource<any>;
  selection = new SelectionModel<any>(true, []);
  public dataForm: FormGroup;
  hasError = {
    value: false,
    message: "No answer selected"
  }
  shopName: any;
  isAddMode: boolean = false;
  isEditMode: boolean = false;
  categories: any[];
  pageSize: number = 5;
  examId: any;
  exam: any;
  addedUsers: any[] = [];
  userEmailControls: FormControl[] = [];
  selectedAnswer: number;
  deletedUser: any[] = [];

  filteredExam: Observable<any[]>;
  users: any[] = [];
  examDetails = null;
  @ViewChild(MatPaginator) paginator: MatPaginator;
  @ViewChild(MatTable) table: MatTable<ExamQuestionModel>;

  // Mass upload
  @ViewChild('fileInput')
  hasSubmit: boolean = false;
  selectedExam: null;
  examFilter: number | null = null;
  searchFilter: string = '';

  groupType = 1;
  isAdmin: boolean = false;
  isAccountAdmin: boolean = false;
  user: any;
  isGroup: boolean = false;
  remaining = 0;
  constructor(
    private router: Router, public dialog: MatDialog,
    private examService: ExamService,
    private categoryService: CategoryService,
    private snackbarService: SnackbarService,
    private dialogService: DialogService,
    private _Activatedroute: ActivatedRoute,
    private constant: AppConstants,
    private s3: AWSS3Service,
    private auditTrailService: AuditTrailService,
    private userService: UserService,
    private authService: AuthService,
    private memberService: MemberService,

    private subscriptionService: SubscriptionService

  ) {
  }
  async ngOnInit() {
    this.dataForm = new FormGroup({
      examControl: new FormControl('', [Validators.required]),
      isActive: new FormControl(true)
    });
    await this.initUser();
    await this.initPage();
    this.fetchRemainingCount();
  }

  async refresh() {
    await this.initPage();
    this.fetchRemainingCount();
    this.getUsers();
    this.selection.clear();
  }
  async initUser() {
    this.user = this.authService.getUser();
    if (this.user != null) {
      this.isAdmin = this.authService.extractId(this.user.azp) === 1;
      this.isAccountAdmin = this.authService.extractId(this.user.azp) === 2;
      this.isGroup = this.user.group !== this.constant.invidualNoGroup;
    }
  }

  async initPage() {

    if (this.isAdmin) {
      await this.getAllExam({});
    }

    if (this.isAccountAdmin) {
      const accountId = this.user.accountId || -1;
      // const paid = this.isGroup ? null : 1;
      const accountType = this.isGroup ? 2 : 1;
      if (this.isGroup) {
        await this.getAllExam({ paid: 1, accountId, accountType });
      } else {
        await this.getAllExam({ paid: 1, accountType, viewType: 0 });
      }

    }
  }

  isAllSelected() {
    const numSelected = this.selection.selected.length;
    const numRows = this.dataSource.data.length;
    return numSelected === numRows;
  }

  /** Selects all rows if they are not all selected; otherwise clear selection. */
  toggleAllRows() {
    if (this.isAllSelected()) {
      this.selection.clear();
      return;
    }

    this.selection.select(...this.dataSource.data);
  }

  toggleAllRowsDisable() {
    // Iterate over each row in the data source
    this.dataSource.data.forEach(row => {
      // Only toggle selection for rows that are not disabled (has_exam !== 1)
      if (row.has_exam !== 1) {
        // Check if the row is currently selected
        const isSelected = this.selection.isSelected(row);

        // Toggle selection based on the current state
        if (isSelected) {
          this.selection.deselect(row);
        } else {
          this.selection.select(row);
        }
      }
    });
  }

  /** The label for the checkbox on the passed row */
  checkboxLabel(row?: any): string {
    if (!row) {
      return `${this.isAllSelected() ? 'deselect' : 'select'} all`;
    }
    return `${this.selection.isSelected(row) ? 'deselect' : 'select'} row ${row.position + 1}`;
  }

  ngAfterViewInit() {
    this.dataSource.paginator = this.paginator;
  }

  private _filterExam(value: any): any[] {
    if (value != null) {
      if (typeof value === 'string') {
        const filterValue = value.toLowerCase();
        return this.exam.filter(item => item.name.toLowerCase().includes(filterValue));
      } else if (typeof value === 'object' && value.name) {
        return this.exam.filter(item => item.name.toLowerCase() === value.name.toLowerCase());
      }
    }
    return [];
  }

  // Function to display the selected category
  displayCategory(category: any): string {
    return category ? category.name : '';
  }

  public checkError = (controlName: string, errorName: string) => {
    return this.dataForm.controls[controlName].hasError(errorName);
  }

  applyFilter(event?: Event) {
    // If the search filter input element is provided, get its value
    if (event && event.target) {
      this.searchFilter = (event.target as HTMLInputElement).value.trim().toLowerCase();
    }

    // Filter the data source based on the search filter
    let filteredData = this.dataSource.data.filter(row => {
      // Apply search filter on row's name property
      return row.email.toLowerCase().includes(this.searchFilter);
    });

    // If an exam filter is selected, further filter the data source based on the exam filter
    if (this.examFilter !== null) {
      // Retrieve the original data from the service or store
      const originalData = this.users;

      // Filter the original data based on the exam filter
      const filteredByExam = originalData.filter(row => row.has_exam === this.examFilter);

      // Apply search filter on the filtered data
      filteredData = filteredByExam.filter(row => {
        // Apply search filter on row's name property
        return row.email.toLowerCase().includes(this.searchFilter);
      });
    }

    // Update the data source with the filtered data
    this.dataSource.data = filteredData;

    // If the search filter is cleared, reset the data source to its original state
    if (this.searchFilter === '' && this.examFilter === null) {
      this.fetchUsersExam();
    }
  }

  dataChanged(item) {
    if (item.isNew == 0) {
      item.isEdited = true;
    }
  }

  backAction() {
    this.isAddMode = false;
    this.userEmailControls = [];
    this.addedUsers = [];
  }

  addUser(email, userId) {
    this.isAddMode = true;
    const id = this.addedUsers.length > 0 ? this.addedUsers[this.addedUsers.length - 1].id + 1 : 1;
    const control = new FormControl(email, [Validators.required, Validators.email]);
    control.valueChanges.subscribe((value) => {
      const index = this.userEmailControls.indexOf(control);
      if (index !== -1) {
        this.addedUsers[index].email = value;
      }
    });

    if (email !== '') {
      // control.disable();
    }
    this.userEmailControls.push(control);
    this.addedUsers.push({ userId: userId, id: id, email: email, isNew: (email == '' ? true : false), isEdited: (email == '' ? true : false) });
  }

  removeUser(item, index: number) {
    this.addedUsers.splice(index, 1);
    this.userEmailControls.splice(index, 1);
    if (item.isNew == 0) {
      this.deletedUser.push(item.id);
    }
  }

  ngModelChange() {
    if (typeof this.dataForm.value.examControl == 'string') {
      this.examDetails = null;
      this.selectedExam = null;
      this.userEmailControls = [];
      this.addedUsers = [];
    }
  }
  async getUsers() {
    this.examDetails = this.dataForm.value.examControl;
    this.selectedExam = this.dataForm.value.examControl.id
    if (this.isGroup) {
      this.fetchUsersExam();
    } else {
      await this.fetchAssignedUsersExam();
    }

  }

  async fetchRemainingCount() {
    const payload = {
      accountId: this.user.accountId,
    }
    await (await this.subscriptionService.getRemainingCount(payload))
      .pipe(
        catchError(() => {
          return Observable.call(throwError(() => "Error"));
        })
      )
      .subscribe((response) => {
        this.remaining = response;
      });
  }


  //Group
  async fetchUsersExam() {
    this.selection.clear();
    const payload = {
      accountId: this.user.accountId,
      examId: this.examDetails.id
    }
    await (await this.memberService.getMember(payload))
      .pipe(
        catchError(() => {
          return Observable.call(throwError(() => "Error"));
        })
      )
      .subscribe((response) => {
        const data = JSON.parse(JSON.stringify(response));
        this.dataSource.data = data;
        this.users = data;
        this.paginator.pageSize = this.pageSize;
        this.dataSource.paginator = this.paginator;

      });
  }

  //Individual
  async fetchAssignedUsersExam() {
    this.selection.clear();
    const payload = {
      accountId: this.user.accountId,
      examControl: { id: this.examDetails.id }
    }
    await (await this.userService.fetchUsersExam(payload))
      .pipe(
        catchError(() => {
          return Observable.call(throwError(() => "Error"));
        })
      )
      .subscribe((response) => {
        const data = JSON.parse(JSON.stringify(response));
        
        // for (const item of data) {
        //   this.addUser(item.email, item.userid);
        // }
        this.dataSource.data = data;
        this.users = data;
        this.paginator.pageSize = this.pageSize;
        this.dataSource.paginator = this.paginator;

      });
  }

  displayDuration(hour, minute) {
    let text = "";
    let minuteText = (minute == 1) ? minute + " minute" : minute + " minutes";
    let hourText = (hour == 1) ? hour + " hour" : hour + " hours";

    if (hour == 0 && minute > 0) {
      text = minuteText;
    }

    if (minute == 0) {
      text = hourText;
    }
    if (minute > 0 && hour > 0) {
      text = `${hourText} and ${minuteText}`
    }

    return text;
  }

  splitTime(time) {
    let result = { hour: 0, minute: 0 };
    if (!(time == "" || time == null)) {
      const items = time.split(":");
      result.hour = parseInt(items[0]);
      result.minute = parseInt(items[1]);
    }
    return result;
  }

  async getAllExam(params) {
    (await this.examService.getAllExamFilter(params))
      .pipe(
        catchError(() => {
          return Observable.call(throwError(() => "Error"));
        })
      )
      .subscribe((response) => {
        const data = JSON.parse(JSON.stringify(response));

        data.forEach((item) => {
          item.maxtime = minutesToTime(item.maxtime);
          const time = this.splitTime(item.maxtime);
          if (!(time.hour == 0 && time.minute == 0)) {
            item.maxtimetext = this.displayDuration(time.hour, time.minute);
          } else {
            item.maxtimetext = "";
          }
        })

        this.exam = data;
        this.filteredExam = this.dataForm.get('examControl').valueChanges.pipe(
          startWith(''),
          map((value: any) => this._filterExam(value))
        );
      });
  }

  getSelectedAnswer(data) {
    for (const item of data) {
      if (item.correct == 1) {
        this.selectedAnswer = item.id;
      }
    }
  }

  async onSubmit() {
    if (this.dataForm.valid) {
      let userIds = this.selection.selected.filter(item => item.has_exam == 0).map(item => item.userid);
      if (userIds.length > this.remaining) {
        this.snackbarService.showSnackbar("User limit exceeded!", 'top', 5000);
      } else {
        const payload = {
          ...this.dataForm.value,
          userIds: userIds,
          accountId: this.user.accountId
        }
        await this.addUserExam(payload);
      }

    }
  }

  async addUserExam(payload) {
    await (await this.userService.addUserExam(payload))
      .subscribe((response) => {
        const data = JSON.parse(JSON.stringify(response));
        this.fetchRemainingCount();
        this.snackbarService.showSnackbar(data.message, 'top', 2000);
        this.getUsers();
      });
  }

  async reassign(item) {
    const payload = {
      ...this.dataForm.value,
      userIds: [item.userid],
      accountId: this.user.accountId
    }

    await this.addUserExam(payload);

  }

  async submitEmails() {
    if (this.addedUsers.length > 0) {
      if (this.addedUsers.length > this.remaining) {
        this.snackbarService.showSnackbar("User limit exceeded!", 'top', 5000);
      } else {
        let error = false;
        for (const form of this.userEmailControls) {
          if (form.invalid) {
            error = true;
            form.markAsTouched();
          }
        }

        if (!error) {
          const userEmails = this.addedUsers.filter(item => item.isNew)
          if (userEmails.length == 0) {
            this.snackbarService.showSnackbar("Please add new email/s", 'top', 5000);
          } else {
            const payload = {
              ...this.dataForm.value,
              userEmails: userEmails,
              accountId: this.user.accountId,
              type: "email",
              isAdd: true
            }

            await (await this.userService.addUserExam(payload))
              .subscribe(async (response) => {
                const data = JSON.parse(JSON.stringify(response));
                await this.fetchAssignedUsersExam();
                this.fetchRemainingCount();
                this.backAction();
                this.snackbarService.showSnackbar(data.message, 'top', 5000);
              });
          }

        }
      }

    } else {
      this.snackbarService.showSnackbar("Please add new email/s", 'top', 5000);
    }
  }

  async exportExamResult(item) {
    console.log(item)
    const payload = {
      examId: this.examDetails.id,
      userId: item.userid,
      accountId: this.user.accountId,
      // userExamId: item.userexamid
    };
    (await this.examService.exportExamResult(payload))
      .pipe(
        catchError((err) => {
          return Observable.call(throwError(() => "Error"));
        })
      )
      .subscribe((response) => {
        if (response.hasData) {
          const blob = new Blob([response.content], { type: 'text/csv' });
          const url = window.URL.createObjectURL(blob);
          const a = document.createElement('a');
          a.href = url;
          a.download = this.examDetails.name + '_' + moment().format('YYYY-MM-DD hhmmss') + '.csv';
          a.click();
          window.URL.revokeObjectURL(url);
          this.auditTrailService.sendNotification(true);
        } else {
          this.snackbarService.showSnackbar(response.content, 'top', 10000);
        }
      });
  }

  viewResult(item) {
    const payload = {
      examId: this.examDetails.id,
      userId: item.userid,
      accountId: this.user.accountId,
    };

    this.router.navigate(['masterfile/exam-result', this.examDetails.id, item.userid])
  }


}
