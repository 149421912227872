<div class="main-container">
    <mat-drawer-container style="height: 100%;">
        <mat-drawer-content class="drawer-content">
            <mat-card style="width: 70%;" appearance="outlined" class="card-content">
                <div *ngIf="isTimerFinish && !isExamSubmitted" class="alert alert-danger">{{examTimeUsedUp}}
                    <br>
                    <br>
                    <span class="countdown-message">Exam will be automatically submitted in:
                        <countdown style="padding-bottom: -5px;" #cd (event)="handleSubmitEvent($event)"
                            [config]="{ leftTime: examSubmissionTimerSec, format: 's' }">
                        </countdown>
                    </span>
                </div>
                <div class="exam-bar-triggers">
                    <mat-toolbar style="background-color: white;">
                        <div class="exam-title">
                            <span>{{examDetails?.name}} </span>
                            <span *ngIf="!(examMode == 3)">({{examDetails?.noquestions}}
                                question/s)</span>
                        </div>
                        <span class="toolbar-item-spacer"></span>
                        <div class="exam-triggers" *ngIf="!isExamSubmitted &&  !(examMode == 3) && examDetails">
                            <mat-icon style="margin-bottom: -6px;" color="primary">av_timer</mat-icon>
                            <span class="min">
                                <countdown style="padding-bottom: -5px;" #cd (event)="handleEvent($event)"
                                    [config]="config">
                                </countdown> Min
                            </span>
                            <button (click)="submitExam()" color="primary" mat-flat-button>Finish Exam</button>
                        </div>
                    </mat-toolbar>
                </div>
                <!-- <mat-progress-bar *ngIf="examDetails?.hasresult == null" class="mat-progress-bar" mode="determinate"
                            [value]="percentageAnswered"></mat-progress-bar> -->

                <div class="questions" *ngIf="!isExamSubmitted && examQuestions.length > 0" appNoCopy>
                    <mat-card appearance="outlined">
                        <mat-card-header>
                            <mat-card-subtitle>Question {{ currentQuestionIndex + 1 }}:
                                <mat-checkbox *ngIf="!isTimerFinish"
                                    [(ngModel)]="selectedAnswers[currentQuestionIndex].isForReview" class="pr-4">
                                    For Review
                                </mat-checkbox>
                            </mat-card-subtitle>
                        </mat-card-header>
                        <div class="question-card">
                            <quill-editor #questionEditor [(ngModel)]="examQuestions[currentQuestionIndex].text"
                                style="width: 100%;" (onEditorCreated)="questionEditorInstance($event)"
                                [modules]="modules"></quill-editor>
                        </div>

                        <!-- <div class="question-section">
                            <div [innerHTML]="examQuestions[currentQuestionIndex].text"></div>
                        </div> -->
                        <mat-card-content>
                            <mat-divider></mat-divider>

                            <li *ngFor="let option of examQuestions[currentQuestionIndex].options; let i = index"
                                class="option-list">

                                <!-- Multiple Choice -->
                                <span *ngIf="examQuestions[currentQuestionIndex].questiontype == 1 ||
                                examQuestions[currentQuestionIndex].questiontype == 2">
                                    <mat-radio-group [(ngModel)]="selectedAnswers[currentQuestionIndex].answerIds[0]"
                                        aria-label="Select an option"
                                        *ngIf="examQuestions[currentQuestionIndex].correctcount === 1">
                                        <mat-radio-button
                                            [disabled]="isTimerFinish || selectedAnswers[currentQuestionIndex].isLocked"
                                            class="option" [value]="option.id">
                                            <span [innerHTML]="option.answer"></span>
                                        </mat-radio-button>
                                    </mat-radio-group>

                                    <mat-checkbox
                                        [disabled]="isTimerFinish || selectedAnswers[currentQuestionIndex].isLocked"
                                        [checked]="selectedAnswers[currentQuestionIndex].answerIds.includes(option.id)"
                                        class="option" *ngIf="examQuestions[currentQuestionIndex].correctcount > 1"
                                        (change)="toggleAnswerCheck(option, i)">
                                        <span [innerHTML]="option.answer"></span>
                                    </mat-checkbox>
                                </span>

                                <!-- Text Answer -->
                                <span style="margin: 0 auto"
                                    *ngIf="examQuestions[currentQuestionIndex].questiontype == 3">
                                    <mat-form-field floatLabel="always" appearance="outline"
                                        class="full-width form-field">
                                        <mat-label>Text Answer
                                            <mat-icon [matTooltip]="textAnswerTooltip"
                                                style="scale: 1.3;cursor: pointer;margin: 1px 5px 0 5px;"
                                                color="primary" aria-label="Example home icon" fontIcon="info">
                                            </mat-icon>
                                        </mat-label>
                                        <input matInput type="text"
                                            [disabled]="isTimerFinish || selectedAnswers[currentQuestionIndex].isLocked"
                                            [(ngModel)]="selectedAnswers[currentQuestionIndex].answer">
                                    </mat-form-field>
                                </span>

                                <!-- Number Answer -->
                                <span *ngIf="examQuestions[currentQuestionIndex].questiontype == 4">
                                    <app-popover *ngIf="popoverVisible" [triggerElement]="triggerElement"
                                        [content]="popoverContent"></app-popover>
                                    <mat-form-field floatLabel="always"
                                        *ngIf="examQuestions[currentQuestionIndex].isInteger" appearance="outline"
                                        class="form-field">
                                        <mat-label>Whole Number Answer
                                            <mat-icon [matTooltip]="wholeNumberTooltip"
                                                style="scale: 1.3;cursor: pointer;margin: 1px 5px 0 5px;"
                                                color="primary" aria-label="Example home icon" fontIcon="info">
                                            </mat-icon>
                                        </mat-label>
                                        <input matInput placeholder="0"
                                            [disabled]="isTimerFinish || selectedAnswers[currentQuestionIndex].isLocked"
                                            appDecimalFormatter class="amount-right-align"
                                            [(ngModel)]="selectedAnswers[currentQuestionIndex].answer" />
                                    </mat-form-field>

                                    <mat-form-field *ngIf="!examQuestions[currentQuestionIndex].isInteger"
                                        floatLabel="always" appearance="outline" class="form-field">
                                        <mat-label>Decimal Number Answer
                                            <mat-icon [matTooltip]="decimalNumberTooltip"
                                                style="scale: 1.3;cursor: pointer;margin: 1px 5px 0 5px;"
                                                color="primary" aria-label="Example home icon" fontIcon="info">
                                            </mat-icon>
                                        </mat-label>
                                        <input matInput
                                            [disabled]="isTimerFinish || selectedAnswers[currentQuestionIndex].isLocked"
                                            [(ngModel)]="selectedAnswers[currentQuestionIndex].answer"
                                            class="amount-right-align" placeholder="0.0" appDecimalFormatter>
                                        <!-- <span matTextSuffix>.00</span> -->
                                    </mat-form-field>
                                </span>

                                <!-- Essay -->
                                <span *ngIf="examQuestions[currentQuestionIndex].questiontype == 5">

                                    <mat-form-field floatLabel="always" appearance="outline"
                                        class="full-width form-field">
                                        <mat-label>Write the answer here</mat-label>
                                        <textarea rows="3" (paste)="(false)"
                                            [disabled]="isTimerFinish || selectedAnswers[currentQuestionIndex].isLocked"
                                            [(ngModel)]="selectedAnswers[currentQuestionIndex].answer"
                                            cdkTextareaAutosize matInput></textarea>
                                    </mat-form-field>
                                    <mat-chip style="margin-bottom: 5px;"
                                        [color]="'accent'">{{getWordCount(selectedAnswers[currentQuestionIndex].answer,
                                        examQuestions[currentQuestionIndex].id)}}
                                        {{getWordCount(selectedAnswers[currentQuestionIndex].answer,
                                        examQuestions[currentQuestionIndex].id) > 1 ? 'words':
                                        'word' }}
                                    </mat-chip>

                                    <mat-chip class="required_chip"
                                        style="margin-bottom: 5px;float: right;background-color: #f44336;">
                                        {{examQuestions[currentQuestionIndex].wordCount}} words required
                                    </mat-chip>
                                </span>

                            </li>

                        </mat-card-content>
                    </mat-card>
                </div>

                <app-exam-result *ngIf="isExamSubmitted && !(examMode == 3)" [data]="examResult"></app-exam-result>
                <div class="sub-details" *ngIf="isExamSubmitted && !(examMode == 3)">
                    <div style="padding: 10px;" *ngIf="userStrengths != null && userStrengths.length > 0">
                        <h2>Strengths</h2>
                        <div *ngFor="let strengths of userStrengths;">
                            <app-strengths [data]="strengths"></app-strengths>
                        </div>
                    </div>
                </div>

                <div class="sub-details essay-result" id="temporaryResult"
                    *ngIf="isExamSubmitted && !(examMode == 3) && essayQuestions.length > 0">
                    <div class="essay-details">
                        <span class="final-score">
                            <h2>Final Score: 0</h2>
                        </span>
                        <h2>Essay</h2>
                        <p style="font-weight: 500;">Status: <span style="font-weight: 400;">Ongoing Assessment</span>
                        </p>
                    </div>

                    <mat-accordion>

                        <mat-expansion-panel *ngFor="let essay of essayQuestions;">
                            <mat-expansion-panel-header>
                                <mat-panel-title>
                                    Topic: <span style="font-weight: 350;margin-left: 5px;"> {{essay}}</span>
                                </mat-panel-title>
                                <mat-panel-description>
                                    <span style="padding-left: 400px;">
                                        Score: 0
                                    </span>
                                </mat-panel-description>
                            </mat-expansion-panel-header>
                            <div *ngFor="let metrics of metrics; let i = index">
                                <div style="position: relative">
                                    <span class="fraction">{{metrics.value}}</span>
                                </div>
                                <mat-progress-bar mode="determinate" [value]="0"></mat-progress-bar>
                                <span class="percentage" [ngStyle]="{'left': (0) + '%'}">0</span>
                            </div>
                        </mat-expansion-panel>
                    </mat-accordion>
                </div>

                <div *ngIf="examMode == 3">
                    <mat-accordion>
                        <mat-expansion-panel *ngFor="let result of examResult; let mainIndex = index"
                            [disabled]="examResult[mainIndex].score === null"
                            (opened)="onResultPanelOpened(mainIndex, result.userexamid)">
                            <mat-expansion-panel-header>
                                <mat-panel-title>
                                    {{examResult[mainIndex].examnumber}} - {{examResult[mainIndex].sequence}}
                                </mat-panel-title>
                                <mat-panel-description>
                                    {{ examResult[mainIndex].score === null ? 'Exam not yet done' :
                                    examResult[mainIndex].endtime + ' -
                                    Exam completed' }}
                                    <span *ngIf="examResult[mainIndex].score !== null"
                                        style="padding-left: 100px;">{{examResult[mainIndex].percentage}}%
                                        {{examResult[mainIndex].score}}/{{examResult[mainIndex].maxscore}} -
                                        {{examResult[mainIndex].grade}}</span>
                                </mat-panel-description>
                            </mat-expansion-panel-header>
                            <app-exam-result [data]="examResult[mainIndex]"></app-exam-result>
                            <div class="strengths">
                                <div style="padding: 10px;" *ngIf="examResult[mainIndex].userStrengths">
                                    <h2>Strengths</h2>
                                    <div *ngFor="let strengths of examResult[mainIndex].userStrengths;">
                                        <app-strengths [data]="strengths"></app-strengths>
                                    </div>
                                </div>
                            </div>
                            <div class="sub-details essay-result" *ngIf="examResult[mainIndex].hasData">
                                <div class="essay-details">
                                    <span class="final-score">
                                        <h2>Final Score: {{essayFinalScore}}</h2>
                                    </span>
                                    <h2>Essay</h2>
                                    <p style="font-weight: 500;">Status: <span
                                            style="font-weight: 400;">{{examResult[mainIndex].grade}}</span> </p>
                                </div>


                                <mat-accordion>
                                    <mat-expansion-panel
                                        *ngFor="let essay of examResult[mainIndex].essayResult; let essayIndex = index"
                                        (opened)="onResultPanelOpenedEssay(essayIndex, mainIndex, essay.userexamid, essay.questionid, essay.essayresultid, essay.gptresult)">
                                        <mat-expansion-panel-header>
                                            <mat-panel-title>
                                                Topic: <span style="font-weight: 350;margin-left: 5px;">
                                                    {{essay.question}}</span>
                                            </mat-panel-title>
                                            <mat-panel-description>
                                                <span style="padding-left: 400px;" class="float-right">
                                                    Score: {{essay.score}}
                                                </span>
                                            </mat-panel-description>
                                        </mat-expansion-panel-header>


                                        <div
                                            *ngFor="let metrics of examResult[mainIndex].essayResult[essayIndex].metrics; let i = index">
                                            <div style="position: relative">
                                                <span class="fraction">{{metrics?.value}}</span>
                                            </div>
                                            <mat-progress-bar mode="determinate"
                                                [value]="metrics?.value"></mat-progress-bar>
                                            <span class="percentage"
                                                [ngStyle]="{'left': (metrics?.finalpoint * metrics?.scale) + '%'}">{{metrics?.finalpoint}}</span>
                                        </div>

                                    </mat-expansion-panel>
                                </mat-accordion>







                            </div>

                        </mat-expansion-panel>

                    </mat-accordion>
                </div>

                <mat-toolbar style="padding-left: 10px;background-color: white;"
                    *ngIf="!isExamSubmitted && !(examMode == 3)">
                    <div *ngIf="!isTimerFinish">
                        <button mat-stroked-button [disabled]="currentQuestionIndex === 0"
                            (click)="getPreviousQuestion()"><mat-icon>keyboard_arrow_left</mat-icon>Previous</button>
                        <button mat-stroked-button [disabled]="currentQuestionIndex === examQuestions.length - 1"
                            (click)="getNextQuestion()" style="margin-left: 8px;">Next<mat-icon
                                iconPositionEnd>keyboard_arrow_right</mat-icon></button>
                    </div>

                    <span class="spacer"></span>

                </mat-toolbar>
                <div *ngIf="!isTimerFinish && !isExamSubmitted && !(examMode == 3)"
                    style="padding-left: 10px;margin-bottom: 10px">
                    <button mat-flat-button color="primary" (click)="reviewList.toggle()" class="review-list-button">
                        Review List
                    </button>
                    <button *ngIf="!isTimerFinish" style="margin-left: 10px;" (click)="allAnswer.toggle()"
                        mat-flat-button color="primary">
                        {{ showAllAnswers ? 'Hide Answers' : 'Show All Answers' }}
                    </button>
                </div>
            </mat-card>

        </mat-drawer-content>
        <mat-drawer #allAnswer mode="over" opened="false" position="end" style="padding: 0cm;width: 52vh;">
            <mat-toolbar color="default">
                <mat-toolbar-row fxLayoutAlign="space-between center">
                    <div>
                        <span>All Answer/s</span>
                    </div>

                    <div>
                        <button mat-icon-button (click)="allAnswer.toggle()">
                            <mat-icon>close</mat-icon>
                        </button>
                    </div>
                </mat-toolbar-row>
            </mat-toolbar>

            <ul style="list-style-type: none;">
                <li *ngFor="let question of examQuestions; let i = index" style="margin-top: 10px;">
                    <p (click)="navigateToQuestion(i)" (click)="allAnswer.toggle()"
                        style="cursor: pointer;text-decoration: underline;font-weight: 500;">
                        Question {{i + 1}}
                    </p>

                    <ng-container *ngIf="(question.questiontype == 1 || question.questiontype == 2) 
                    && selectedAnswers[i]?.answerIds && selectedAnswers[i]?.answerIds.length > 0">
                        <div class="ellipsis-container">
                            <p>Answer:
                                <span *ngFor="let answerId of selectedAnswers[i]?.answerIds; let j = index"
                                    [innerHTML]="getSelectedAnswerText(answerId, question)">
                                    <span *ngIf="j < selectedAnswers[i]?.answerIds.length - 1">, </span>
                                </span>
                            </p>
                        </div>
                    </ng-container>

                    <ng-container *ngIf="!(question.questiontype == 1 || question.questiontype == 2) 
                    && selectedAnswers[i]?.answer != ''">
                        <div class="ellipsis-container">
                            <p>Answer:
                                <span [innerHTML]="selectedAnswers[i]?.answer">
                                </span>
                            </p>
                        </div>
                    </ng-container>


                    <p style="color: red;" *ngIf="question.questiontype == 1 || question.questiontype == 2">
                        <span *ngIf="!(selectedAnswers[i]?.answerIds && selectedAnswers[i]?.answerIds.length > 0)">
                            No Answer yet!
                        </span>
                    </p>

                    <p style="color: red;" *ngIf="!(question.questiontype == 1 || question.questiontype == 2)">
                        <span *ngIf="selectedAnswers[i]?.answer == ''">
                            No Answer yet!
                        </span>
                    </p>

                    <!-- <ng-container *ngIf="!selectedAnswers[i]?.answerIds || selectedAnswers[i]?.answerIds.length === 0">
                        <p style="color: red;">No answer selected</p>
                    </ng-container> -->
                </li>
            </ul>

        </mat-drawer>

        <mat-drawer #reviewList mode="over" opened="false" position="start" style="width: 45vh;">
            <mat-toolbar color="default">
                <mat-toolbar-row fxLayoutAlign="space-between center">
                    <div>
                        <span>Review Answer</span>
                    </div>

                    <div>
                        <button style="float: right;" mat-icon-button (click)="reviewList.toggle()">
                            <mat-icon>close</mat-icon>
                        </button>
                    </div>
                </mat-toolbar-row>
            </mat-toolbar>

            <ul style="list-style-type: none;">
                <li *ngFor="let question of examQuestions; let i = index" style="margin-top: 10px;">
                    <ng-container *ngIf="selectedAnswers[i]?.isForReview">
                        <div class="ellipsis-container">
                            <p (click)="navigateToQuestion(i)" (click)="reviewList.toggle()"
                                style="cursor: pointer; text-decoration: underline; font-weight: 500;">{{i+1}}
                                <span [innerHTML]="'. '+question.text"></span>
                            </p>
                        </div>

                        <ng-container *ngIf="(question.questiontype == 1 || question.questiontype == 2) 
                        && selectedAnswers[i]?.answerIds && selectedAnswers[i]?.answerIds.length > 0">
                            <div class="ellipsis-container">
                                <p>Answer:
                                    <span *ngFor="let answerId of selectedAnswers[i]?.answerIds; let j = index"
                                        [innerHTML]="getSelectedAnswerText(answerId, question)">
                                        <span *ngIf="j < selectedAnswers[i]?.answerIds.length - 1">, </span>
                                    </span>
                                </p>
                            </div>
                        </ng-container>

                        <ng-container *ngIf="!(question.questiontype == 1 || question.questiontype == 2) 
                        && selectedAnswers[i]?.answer != ''">
                            <div class="ellipsis-container">
                                <p>Answer:
                                    <span [innerHTML]="selectedAnswers[i]?.answer">
                                    </span>
                                </p>
                            </div>
                        </ng-container>

                        <p style="color: red;" *ngIf="question.questiontype == 1 || question.questiontype == 2">
                            <span *ngIf="!(selectedAnswers[i]?.answerIds && selectedAnswers[i]?.answerIds.length > 0)">
                                No Answer yet!
                            </span>
                        </p>

                        <p style="color: red;" *ngIf="!(question.questiontype == 1 || question.questiontype == 2)">
                            <span *ngIf="selectedAnswers[i]?.answer == ''">
                                No Answer yet!
                            </span>
                        </p>


                    </ng-container>
                </li>
            </ul>
            <p *ngIf="!hasReviewAnswers()">
                No tagged for review yet!
            </p>
        </mat-drawer>
    </mat-drawer-container>
</div>