import { CanActivateFn, Router } from '@angular/router';
import { Injectable, inject } from '@angular/core';
import { AuthService } from '../service/auth.service';

export const authGuard: CanActivateFn = (route, state) => {
  const authService = inject(AuthService);
  const token = authService.getToken();
  const router = inject(Router);

  if (token) {

    const user = authService.getUser();
    const isGroup = user.group !== "Individual - No Group";
    const isAdmin = authService.extractId(user.azp) === 1;
    const isAccountAdmin = authService.extractId(user.azp) === 2;
    const hasId = /\d+$/.test(state.url);

    const excludedForIndividual = [
      '/masterfile/exam',
      '/masterfile/category',
      '/masterfile/account-member',
      '/masterfile/user-exam'
    ];
    
    if (state.url.includes('masterfile')) {
      const stateUrlBase = state.url.split('/').slice(0, -1).join('/');
      let urlToCheck = hasId ? stateUrlBase : state.url;
    
      if (!isGroup && !isAdmin && excludedForIndividual.includes(urlToCheck)) {
        router.navigate(['unauthorized']);
        return false;
      } else if (isGroup && urlToCheck.includes('category') && !isAdmin) {
        router.navigate(['unauthorized']);
        return false;
      } else if (isAccountAdmin && urlToCheck.includes('user-exam')) {
        router.navigate(['unauthorized']);
        return false;
      }
    }
    
    
    // Check if the user is an admin and trying to access 'masterfile' routes
    if ((authService.extractId(user.azp) === 1 || authService.extractId(user.azp) === 2) && state.url.includes('masterfile')) {
      return true;
    } else if (authService.extractId(user.azp) !== 1 && state.url.includes('masterfile')) {
      // For non-admin users trying to access 'masterfile' routes, redirect to unauthorized page
      router.navigate(['unauthorized']);
      return false;
    } else {
      // For other routes, allow access
      return true;
    }
  } else {
    router.navigate(['login']);
    return false;
  }
};

export const authGuardLogin: CanActivateFn = (route, state) => {
  const authService = inject(AuthService);
  const token = authService.getToken();
  const router = inject(Router);
  if (token) {
    router.navigate(['exam-list', 'available']);
    return false;
  } else {
    return true;
  }
};
