import { Component, Inject, OnInit, ViewChild } from '@angular/core';
import { Router, RouterModule } from '@angular/router';
import { MatButtonModule } from '@angular/material/button';
import { MAT_DIALOG_DATA, MatDialog, MatDialogModule, MatDialogRef } from '@angular/material/dialog';
import { CommonModule } from '@angular/common';
import { Observable } from 'rxjs';
import { FormGroup, FormControl } from '@angular/forms';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { MatSelectModule } from '@angular/material/select';
import { MatFormFieldModule } from '@angular/material/form-field';

@Component({
  selector: 'app-timepicker',
  templateUrl: './timepicker.component.html',
  styleUrls: ['./timepicker.component.css'],
  standalone: true,
  imports: [MatDialogModule, MatButtonModule, FormsModule, MatSelectModule, MatFormFieldModule, CommonModule, ReactiveFormsModule]
})
export class TimePickerExam {
  public timeForm: FormGroup;
  exam: any[];
  userId: any;
  filteredExam: Observable<any[]>;
  title: string = "";
  hours: number[] = Array.from({ length: 24 }, (_, i) => i); // Array of hours (0-23)
  minutes: number[] = Array.from({ length: 60 }, (_, i) => i); // Array of minutes (0-59)

  constructor(
    private router: Router,
    @Inject(MAT_DIALOG_DATA) private data: any,
    public dialogRef: MatDialogRef<TimePickerExam>,
  ) { }

  ngOnInit() {
    let hour = 0;
    let min = 0;
    this.title = this.data.title;
    if (!(this.data.time == "" || this.data.time == null)) {
      const items = this.data.time.split(":");
      hour = parseInt(items[0]);
      min = parseInt(items[1]);
    }

    this.userId = this.router.url.split("/").pop();
    this.timeForm = new FormGroup({
      selectedHour: new FormControl(hour),
      selectedMinute: new FormControl(min),
    });

  }

  minutesToTime(totalMinutes) {
    const hours = Math.floor(totalMinutes / 60);
    const minutes = totalMinutes % 60;
    return `${hours}:${minutes < 10 ? '0' : ''}${minutes}`;
  }

  async onSubmit() {
    this.dialogRef.close({ data: {time: this.timeForm.value, title: this.title} });
  }
}
