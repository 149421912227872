import { HttpClient, HttpErrorResponse, HttpHeaders } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable, catchError, map, throwError, Subject } from 'rxjs';
import { AWSS3Service } from './aws.s3.service';
import { HttpAxiosService } from '../auth/auth.interceptor.axios';
import axios from 'axios';
import { AppConstants } from '../app.constants';
import { environment } from 'src/environments/environment';

@Injectable({
  providedIn: 'root'
})
export class SystemConfigurationService {
  apiUrl = environment.apiUrl;

  // baseURL: `${environment.apiUrl}${req.url}`,
  private subject = new Subject<any>();

  constructor(
    private http: HttpClient,
    private s3: AWSS3Service,
    private constant: AppConstants

  ) { }

  async fetchSysConfig(): Promise<Observable<any>> {
    const response = this.http.post("/utils/sys-config",  { sortBy: "name"});
    return response;
  }

}
