import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { TabVisibilityService } from './tab-visibility.service';

@NgModule({
  declarations: [],
  imports: [
    CommonModule
  ],
  providers: [TabVisibilityService]
})
export class TabVisibilityModule { }
