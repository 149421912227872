import { Component, Input, OnInit, OnDestroy, HostListener } from '@angular/core';
import { Router } from '@angular/router';
import { AuthService } from '../../service/auth.service';
import {
  SocialAuthService,
  GoogleLoginProvider,
  SocialUser,
} from '@abacritt/angularx-social-login';
import { SnackbarService } from 'src/app/lib/snackbar/snackbar.service';
import { Subscription } from 'rxjs';
import { AppConstants } from 'src/app/app.constants';
import { UserService } from 'src/app/service/user.service';
import { SessionService } from 'src/app/service/session.service';
import { AuditTrailService } from 'src/app/service/audit.trail.service';
import { ExamService } from 'src/app/service/exam.service';
import { CategoryService } from 'src/app/service/category.service';
import { SubscriptionService } from 'src/app/service/subscription.service';
import { SwitchAccountModalComponent } from '../switch-account-modal/switch-account-modal.component';
import { MatDialog } from '@angular/material/dialog';

@Component({
  selector: 'app-login',
  templateUrl: './login.component.html',
  styleUrls: ['./login.component.css']
})

export class LoginComponent implements OnInit, OnDestroy {
  constructor(
    private router: Router,
    private socialAuthService: SocialAuthService,
    private authService: AuthService,
    private snackbarService: SnackbarService,
    private constant: AppConstants,
    private userService: UserService,
    private sessionService: SessionService,
    private auditTrail: AuditTrailService,
    private examService: ExamService,
    private categoryService: CategoryService,
    private subscriptionService: SubscriptionService,

    public dialog: MatDialog,

  ) { }

  @Input() initialLoad: any;
  socialUser!: SocialUser;
  user: any;
  userId = 0;
  private authSubscription: Subscription | undefined;

  ngOnInit(): void {
    this.authSubscription = this.socialAuthService.authState.subscribe((user) => {
      const state = localStorage.getItem("state");
      if (user && (state == null)) {
        this.validateGoogleToken(user.idToken);
      }
    });
    localStorage.removeItem('state');
  }

  ngOnDestroy() {
    // Unsubscribe to avoid memory leaks
    this.authSubscription?.unsubscribe();
  }

  saveGoogleLogin() {
    this.user.aud = `${this.user.id}.${this.user.aud}`;
    this.user.azp = `${this.user.ur}.${this.user.azp}`;
    delete this.user.id;
    delete this.user.ur;

    localStorage.setItem('user', JSON.stringify(this.user));
  }

  async validateGoogleToken(token: string) {
    await (await this.authService.validateGoogleToken(token))
      .subscribe((response) => {
        const data = JSON.parse(JSON.stringify(response));
        if (data.isValid) {
          this.validateUser(data.message);
        } else {
          this.snackbarService.showSnackbar('Something went wrong.', 'top', 5000);
        }
      });
  }

  async validateUser(params: any) {
    await (await this.authService.validateUser(params.email))
      .subscribe((response) => {
        const data = JSON.parse(JSON.stringify(response));
        if (data.message !== undefined) {
          if (data.message != null) {
            this.snackbarService.showSnackbar(data.message, 'top', 10000);
          } else {
            this.user = { ...params, id: data.user.at(0).id, ur: data.user.at(0).role, hasUsername: data.user.at(0).hasUsername }
            this.userId = this.user.id;
            this.login(params.email);
          }
        } else {
          this.snackbarService.showSnackbar(this.constant.serverError, 'top', 5000);
        }

      });
  }

  async login(email: string) {
    try {
      await (await this.authService.login(email))
        .subscribe(async (response) => {
          const data = JSON.parse(JSON.stringify(response));
          if (data.isSuccessful) {
            if (this.user.hasUsername == 0) {
              const details = JSON.stringify(this.authService.decodeJWT(data.accessToken));
              const ids = [this.user.id]
              await this.updateUserName({ username: JSON.parse(details).username, adEmail: email, ids })
            }

            this.saveGoogleLogin();
            const expiry = this.sessionService.getExpiryTimeStamp();
            this.authService.setTokenCookie(data.accessToken, expiry);
            localStorage.setItem('state', "1");
            this.router.navigate(['exam-list', "available"]);
            this.examService.updateUserState();
            this.categoryService.updateUserState();
            this.userService.updateUserState();
            await this.addAuditTrail('cognito', null, this.user.email, 5, 1, { valid: 1, message: "Login Successfully" }, null);
            await this.getSubscription();
          } else {
            this.snackbarService.showSnackbar(this.constant.serverError, 'top', 5000);
          }

        });
    } catch (error) {
      this.snackbarService.showSnackbar(this.constant.serverError, 'top', 5000);
    }
  }

  async getSubscription() {
    const payload = { groupAdmin: this.userId, status: 1 }
    await (await this.subscriptionService.getSubscription(payload))
      .subscribe((response) => {
        const data = JSON.parse(JSON.stringify(response));
        if (data.length > 0) {
          const account = data.at(0);
          this.user.accountId = account.id;
          this.user.accountName = account.name;
          this.user.group = account.group_name;
          localStorage.setItem('user', JSON.stringify(this.user));
          if (data.length > 1) {
            this.switchAccount();
          }
        }
      });
  }

  async updateUserName(params: any) {
    await (await this.userService.UpdateUser(params.ids, params.username, null, params.adEmail))
      .subscribe((response) => { });
  }

  async addAuditTrail(tablename, recordpk, user, action, module, previousData, newData) {
    await (await this.auditTrail.addAuditTrail(tablename, recordpk, user, action, module, previousData, newData))
      .subscribe((response) => { });
  }

  switchAccount() {
    const dialogRef = this.dialog.open(SwitchAccountModalComponent, {
      width: '850px',
      disableClose: true
    });

    dialogRef.afterClosed().subscribe(result => {
      if (result) {
        this.user = this.authService.getUser();
      }
    });
  }
}
