<mat-dialog-actions align="end">
    <mat-icon mat-dialog-close class="close-profile">close</mat-icon>
</mat-dialog-actions>
<mat-toolbar style="background-color: white;">
    <div class="exam-title">
        <span>{{data.examName}} </span>

    </div>
    <span class="toolbar-item-spacer"></span>
    <div class="exam-triggers">
        <mat-icon style="margin-bottom: -6px;" color="primary">av_timer</mat-icon>
        <span class="min" style="margin-right: 10px;">
            00:00:00 Min
        </span>
        <button color="primary" mat-flat-button>Finish Exam</button>
    </div>
</mat-toolbar>
<mat-card appearance="outlined" *ngIf="data">
    <mat-card-header>
        <mat-card-subtitle>Question 1:
            <mat-checkbox class="pr-4">
                For Review
            </mat-checkbox>
        </mat-card-subtitle>
    </mat-card-header>
    <div class="question-card">
        <quill-editor #questionEditor [(ngModel)]="data.question" style="width: 100%;"
            (onEditorCreated)="questionEditorInstance($event)" [modules]="{toolbar: null}"></quill-editor>
    </div>

    <!-- <div class="question-section">
        <div [innerHTML]="data.text"></div>
    </div> -->
    <mat-card-content>

        <li *ngFor="let option of data.options; let i = index" class="option-list">

            <!-- Multiple Choice -->
            <span *ngIf="data.questionType == 1 ||
            data.questionType == 2">
                <mat-radio-group aria-label="Select an option" [(ngModel)]="mcq" *ngIf="data.correctCount <= 1">
                    <mat-radio-button class="option" [value]="option.id">
                        <span [innerHTML]="option.answer"></span>
                    </mat-radio-button>
                </mat-radio-group>

                <mat-checkbox class="option" *ngIf="data.correctCount > 1">
                    <span [innerHTML]="option.answer"></span>
                </mat-checkbox>
            </span>
        </li>

        <div style="margin-top: 10px;">
            <!-- Text Answer -->
            <span style="margin: 0 auto" *ngIf="data.questionType == 3">
                <mat-form-field floatLabel="always" appearance="outline" class="full-width form-field">
                    <mat-label>Text Answer <mat-icon [matTooltip]="textAnswerTooltip"
                            style="scale: 1.3;cursor: pointer;margin: 1px 5px 0 5px;" color="primary"
                            aria-label="Example home icon" fontIcon="info">
                        </mat-icon></mat-label>
                    <input matInput type="text" [(ngModel)]="text">
                </mat-form-field>
            </span>

            <!-- Number Answer -->
            <span *ngIf="data.questionType == 4">

                <mat-form-field floatLabel="always" *ngIf="data.isInteger" appearance="outline" class="form-field">
                    <mat-label>Whole Number Answer <mat-icon [matTooltip]="wholeNumberTooltip"
                            style="scale: 1.3;cursor: pointer;margin: 1px 5px 0 5px;" color="primary"
                            aria-label="Example home icon" fontIcon="info">
                        </mat-icon>
                    </mat-label>
                    <input matInput appDecimalFormatter class="amount-right-align" placeholder="0"
                        [(ngModel)]="number" />
                </mat-form-field>

                <mat-form-field *ngIf="!data.isInteger" floatLabel="always" appearance="outline" class="form-field">
                    <mat-label>Decimal Number Answer <mat-icon 
                        [matTooltip]="decimalNumberTooltip"
                            style="scale: 1.3;cursor: pointer;margin: 1px 5px 0 5px;" color="primary"
                            aria-label="Example home icon" fontIcon="info">
                        </mat-icon></mat-label>
                    <input matInput [(ngModel)]="number" class="amount-right-align" placeholder="0" appDecimalFormatter>
                    <span matTextSuffix>.00</span>
                </mat-form-field>
            </span>

            <!-- Essay -->
            <span *ngIf="data.questionType == 5">

                <mat-form-field floatLabel="always" appearance="outline" class="full-width form-field">
                    <mat-label>Write the answer here</mat-label>
                    <textarea rows="3" (paste)="(false)" [(ngModel)]="essay" cdkTextareaAutosize matInput></textarea>
                </mat-form-field>
                <mat-chip style="margin-bottom: 5px;" [color]="'accent'">{{getWordCount(essay)}}
                    {{getWordCount(essay) > 1 ? 'words':
                    'word' }}
                </mat-chip>
            </span>

        </div>


    </mat-card-content>
</mat-card>

<mat-toolbar style="padding-left: 10px;background-color: white;">
    <div>
        <button mat-stroked-button><mat-icon>keyboard_arrow_left</mat-icon>Previous</button>
        <button mat-stroked-button style="margin-left: 8px;">Next<mat-icon
                iconPositionEnd>keyboard_arrow_right</mat-icon></button>
    </div>

    <span class="spacer"></span>

</mat-toolbar>