<h2 mat-dialog-title>{{ title }}</h2>
<div mat-dialog-content>
    <form [formGroup]="timeForm" (ngSubmit)="onSubmit()" class="example-form">
        <mat-form-field appearance="outline" class="timepicker form-field">
            <mat-label>hours</mat-label>
            <mat-select [formControl]="timeForm.get('selectedHour')">
                <mat-option *ngFor="let hour of hours" [value]="hour">{{ hour }}</mat-option>
            </mat-select>
        </mat-form-field>
        <mat-form-field appearance="outline" class="timepicker form-field">
            <mat-label>minutes</mat-label>
            <mat-select [formControl]="timeForm.get('selectedMinute')">
                <mat-option *ngFor="let minute of minutes" [value]="minute">{{ minute }}</mat-option>
            </mat-select>
        </mat-form-field>
    </form>
    <div align="center">
        <button mat-button mat-dialog-close cdkFocusInitial>Close</button>
        <button mat-button color="primary" [mat-dialog-close]="true" (click)="onSubmit()"
            [disabled]="!timeForm.valid">Set</button>
    </div>
</div>
