<mat-card style="width: 100%;">
  <mat-card-header>
    <mat-card-title class="header-style">Category/s List</mat-card-title>
  </mat-card-header>

  <mat-tab-group mat-stretch-tabs="false" mat-align-tabs="start" (selectedIndexChange)="onSelectedIndexChange($event)">
    <mat-tab label="Result">
      <div class="table-container">
        <mat-toolbar>
          <mat-form-field *ngIf="!isAddMode && !isEditMode" style="margin-top: 20px;">
            <mat-label>Filter</mat-label>
            <input matInput (keyup)="applyFilter($event)" #input>
          </mat-form-field>
          <span class="toolbar-item-spacer"></span>
          <!-- large screen -->
          <div fxHide fxHide.xs="true" fxHide.gt-xs="false">
            <button *ngIf="!isAddMode && !isEditMode" style="margin-right: 10px;" mat-flat-button color="primary" matTooltip="Refresh data"
              (click)="refresh()">
              <mat-icon>refresh</mat-icon>Refresh
            </button>
            <button *ngIf="!isAddMode && !isEditMode" mat-flat-button color="accent" [disabled]="selection.isEmpty()"
              (click)="deleteHandler()" class="demo-button" style="margin-right: 10px;">
              <mat-icon>delete</mat-icon>Delete
            </button>
            <button *ngIf="!isAddMode && !isEditMode" mat-flat-button color="primary" (click)="exportAction()"
              class="demo-button" style="margin-right: 10px;">
              <mat-icon> insert_drive_file</mat-icon>CSV
            </button>
            <button *ngIf="!isAddMode && !isEditMode" mat-flat-button color="primary" (click)="addAction()"
              class="demo-button" style="margin-left: 10px;">
              Add data
            </button>
            <button mat-raised-button *ngIf="isAddMode || isEditMode" (click)="backAction()" class="demo-button"
              style="margin-left: 10px;">
              Back
            </button>
          </div>

          <!-- Small Screen -->
          <div fxShow fxShow.xs="true" fxShow.gt-xs="false">
            <button *ngIf="!isAddMode && !isEditMode" class="refresh" mat-icon-button color="primary" matTooltip="Refresh data" (click)="refresh()">
              <mat-icon>refresh</mat-icon>
            </button>
            <button mat-icon-button *ngIf="!isAddMode && !isEditMode" (click)="deleteHandler()" matTooltip="Delete"
              [disabled]="selection.isEmpty()">
              <mat-icon color="warn">delete</mat-icon>
            </button>
            <button *ngIf="!isAddMode && !isEditMode" mat-icon-button color="primary" matTooltip="Export Data"
              (click)="exportAction()" class="demo-button">
              <mat-icon> insert_drive_file</mat-icon>
            </button>
            <button *ngIf="!isAddMode && !isEditMode" mat-icon-button color="primary" matTooltip="Add Data"
              (click)="addAction()" class="demo-button">
              <mat-icon> add_circle</mat-icon>
            </button>
            <button mat-raised-button *ngIf="isAddMode || isEditMode" (click)="backAction()" class="demo-button"
              style="margin-left: 10px;">
              Back
            </button>
          </div>

        </mat-toolbar>

        <div class="mat-elevation-z0" style="margin-top: 15px;" *ngIf="!isAddMode && !isEditMode">
          <table mat-table [dataSource]="dataSource" class="demo-table">
            <ng-container matColumnDef="select">
              <th mat-header-cell *matHeaderCellDef>
                <mat-checkbox (change)="$event ? toggleAllRows() : null"
                  [checked]="selection.hasValue() && isAllSelected()"
                  [indeterminate]="selection.hasValue() && !isAllSelected()" [aria-label]="checkboxLabel()">
                </mat-checkbox>
              </th>
              <td mat-cell *matCellDef="let row">
                <mat-checkbox (click)="$event.stopPropagation()" (change)="$event ? selection.toggle(row) : null"
                  [checked]="selection.isSelected(row)" [aria-label]="checkboxLabel(row)">
                </mat-checkbox>
              </td>
            </ng-container>
            <ng-container matColumnDef="position">
              <th mat-header-cell *matHeaderCellDef>ID</th>
              <td mat-cell *matCellDef="let element">{{element.id}}</td>
            </ng-container>

            <ng-container matColumnDef="name">
              <th mat-header-cell *matHeaderCellDef>Name</th>
              <td mat-cell *matCellDef="let element">{{element.name}}</td>
            </ng-container>

            <ng-container matColumnDef="level">
              <th mat-header-cell *matHeaderCellDef>Level</th>
              <td mat-cell *matCellDef="let element">{{element.level}}</td>
            </ng-container>

            <ng-container matColumnDef="main">
              <th mat-header-cell *matHeaderCellDef>Main</th>
              <td mat-cell *matCellDef="let element">{{element.main == 0?'N/A':element.main}}</td>
            </ng-container>

            <!-- Action Column -->
            <ng-container matColumnDef="action">
              <th mat-header-cell *matHeaderCellDef style="text-align: center;">Action</th>
              <td mat-cell *matCellDef="let element" style="text-align: center;">
                <button mat-icon-button (click)="editItem(element)">
                  <mat-icon color="primary">edit</mat-icon>
                </button>
                <button mat-icon-button (click)="delete([element.id])">
                  <mat-icon color="warn">delete</mat-icon>
                </button>
              </td>
            </ng-container>

            <tr mat-header-row *matHeaderRowDef="displayedColumns"></tr>
            <tr mat-row *matRowDef="let row; columns: displayedColumns;"></tr>
          </table>
          <mat-paginator [pageSizeOptions]="[5, 10, 20]" showFirstLastButtons></mat-paginator>

        </div>
      </div>

      <div class="add" *ngIf="isAddMode || isEditMode">
        <mat-card style="margin: 0;border: 0;box-shadow: none;">
          <mat-card-content class="formContent">
            <form [formGroup]="dataForm" (ngSubmit)="onSubmit()" class="form">
              <mat-form-field appearance="outline" class="example-full-width form-field" style="width: 320px;">
                <mat-label>Category Name</mat-label>
                <input matInput formControlName="name">
                <mat-error *ngIf="checkError('name', 'required')">Category Name is required</mat-error>
                <mat-error *ngIf="checkError('name', 'maxlength')">Category Name should be less than 20
                  characters</mat-error>
              </mat-form-field>
              <div fxLayout="column" fxLayout.gt-xs="row">
                <mat-form-field style="width: 320px;">
                  <mat-label>Level</mat-label>
                  <mat-select formControlName="level" [(value)]="selectedLevel" required
                    (selectionChange)="toggleMainFieldVisibility()">
                    <mat-option>None</mat-option>
                    <mat-option *ngFor="let item of level" [value]="item.id">{{ item.text }}</mat-option>
                  </mat-select>
                  <mat-error *ngIf="checkError('level', 'required')">Category level is
                    required</mat-error>
                </mat-form-field>
              </div>

              <mat-form-field appearance="outline" class="form-field" *ngIf="isMainFieldVisible()">
                <mat-label>Group</mat-label>
                <input matInput [formControl]="dataForm.get('main')" [matAutocomplete]="categoryAuto">
                <mat-autocomplete #categoryAuto="matAutocomplete" [displayWith]="displayCategory">
                  <mat-option *ngFor="let category of filteredLevelCategories | async" [value]="category">
                    {{ category.name }}
                  </mat-option>
                </mat-autocomplete>
                <mat-error *ngIf="checkError('main', 'required')">Group is
                  required</mat-error>
              </mat-form-field>

              <mat-toolbar style="background-color: white;margin-bottom: 20px;">
                <div class="content" fxLayout="row" fxLayout.xs="column" fxFlexFill>

                  <div fxFlex="20">
                    <button style="margin-top: 12px;margin-left:20px;float: right;" mat-raised-button type="submit"
                      color="primary" [disabled]="!isEditMode && !isFormValid()">Submit</button>
                  </div>
                </div>
              </mat-toolbar>

            </form>
          </mat-card-content>
        </mat-card>

      </div>
    </mat-tab>

    <mat-tab label="Upload Category">
      <mat-card class="example-card">
        <mat-card-header>
          <p class="template-link" (click)="exportCategoryTemplate()">Download Template</p>
        </mat-card-header>
        <mat-card-content>

          <input type="file" style="display: none;" (click)="fileInput.value = null" #fileInput accept=".csv"
            (change)="onChangeFileInput()" />
          <mat-form-field appearance="outline" class="" style="width: 300px;">
            <textarea placeholder="Browse" rows="1" matInput cdkTextareaAutosize type="text" readonly
              [ngModel]="file?.name" (click)="onClickFileInputButton()"></textarea>
            <button mat-icon-button matSuffix color="primary" class="file-select-button"
              (click)="onClickFileInputButton()">
              <mat-icon>attach_file</mat-icon>
            </button>

          </mat-form-field>
          <button mat-raised-button style="margin-left: 10px;margin-bottom: 5px;"
            [disabled]="file == null || file == undefined" (click)="importCategory()">Upload</button>
          <mat-divider></mat-divider>
          <mat-progress-bar *ngIf="hasSubmit" mode="indeterminate"></mat-progress-bar>
          <p class="instruction">
            <span class="insTitle">Instructions: </span><br>
            - Category: The column value must be unique. Example: Fundamental of Software Testing<br>
          </p>
        </mat-card-content>
      </mat-card>
    </mat-tab>
    <mat-tab label="Audit Trail">
      <app-audit-trail-table [module]="5"></app-audit-trail-table>
    </mat-tab>
  </mat-tab-group>

</mat-card>