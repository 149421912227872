<!-- <div>
    <div class="card" *ngIf="data">
        <div class="result" *ngFor="let item of data">
            <p><span style="font-weight: 500;">Topic:</span> {{item?.topic}} </p>
            <div class="grid">
                <div class="item">
                    <span style="font-weight: 500;">Rating:</span> {{item?.rating}}
                </div>
                <div class="item">
                    <span style="font-weight: 500;">Score:</span> {{item?.score}}
                </div>
            </div>
            <p></p>
            <span style="font-weight: 500;">Strengths</span>
            <div *ngFor="let strengths of data?.userStrengths;">
                <app-strengths [data]="strengths"></app-strengths>
            </div>

        </div>

    </div>
</div> -->

<mat-accordion *ngIf="data">
    <mat-expansion-panel *ngFor="let essay of data; let essayIndex = index"
        (opened)="onResultPanelOpenedEssay(essayIndex, mainIndex, essay.userexamid, essay.questionid, essay.essayresultid, essay.gptresult)">
        <mat-expansion-panel-header>
            <mat-panel-title>
                Topic: {{essay.question}}
            </mat-panel-title>
            <mat-panel-description>
                <span style="padding-left: 460px;" class="float-right">
                    Score: {{essay.score}}
                </span>
            </mat-panel-description>
        </mat-expansion-panel-header>


        <div *ngFor="let metric of data?.metrics;">
            <div style="position: relative">
                <span class="fraction">{{data?.categoryName}}</span>
              </div>
              <mat-progress-bar mode="determinate" [value]="metric?.value"></mat-progress-bar>
              <span class="percentage" [ngStyle]="{'left': (metric?.value * 10) + '%'}">{{metric?.value}}</span>
        </div>

    </mat-expansion-panel>
</mat-accordion>