import { NgModule, CUSTOM_ELEMENTS_SCHEMA, NO_ERRORS_SCHEMA } from '@angular/core';
import { BrowserModule } from '@angular/platform-browser';
import { AngularMaterialModule } from './angular-material.module';
import { AppComponent } from './app.component';
import { LoginComponent } from './components/login/login.component';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
import { AppRoutingModule } from './app-routing.module';
import { FlexLayoutModule } from '@angular/flex-layout';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { CountdownModule } from 'ngx-countdown';
import { HTTP_INTERCEPTORS, HttpClientModule } from '@angular/common/http';
import {
  SocialLoginModule,
  SocialAuthServiceConfig,
  GoogleLoginProvider,
  GoogleSigninButtonDirective
} from '@abacritt/angularx-social-login';
import { ExamComponent } from './exam/exam.component';
import { ExamResultComponent } from './components/exam-result/exam-result.component';
import { MatDialogModule } from '@angular/material/dialog';
import { StrengthsComponent } from './components/strengths/strengths.component';
import { CustomBreakPointsProvider, CUSTOM_HEIGHT_BREAKPOINTS } from './layout/breakpoints';
import { BreadcrumbsComponent } from './components/breadcrumbs/breadcrumbs.component';
import { AuthInterceptor } from 'src/app/auth/auth.interceptor';
import { UnauthorizedComponent } from './components/unauthorized/unauthorized.component';
import { ConfirmUserComponent } from './components/confirm-user/confirm-user.component';
import { UserRegistrationComponent } from './components/user-registration/user-registration.component';
import { DataProtectionPolicyComponent } from './components/data-protection-policy/data-protection-policy.component';
import { NgxCaptchaModule } from 'ngx-captcha';
import { RecaptchaComponent } from './components/recaptcha/recaptcha.component';
import { InactivityModule } from './lib/inactivity/inactivity.module';
import { AppConstants } from './app.constants';
import { CookieService } from 'ngx-cookie-service';
import { UserProfileComponent } from './components/user-profile/user-profile.component';
import { QuillModule } from 'ngx-quill'
import { NoCopyDirective } from './directives/no-copy.directive';
import { CommaDelimiterModule } from './lib/comma-delimiter-module/comma-delimiter.module';
import { NumberLimiterDirective } from './directives/number.limiter.directive';
import { DecimalFormatterDirective } from './directives/decimal.directive';
import { PopoverComponent } from './components/popover/popover.component';
import { PreviewExamComponent } from './components/preview-exam/preview-exam.component';
import { TabVisibilityModule } from './lib/tab-visibility/tab-visibility.module';
import { AvatarModule } from 'ngx-avatars';
import { ExamResultEssayComponent } from './components/exam-result-essay/exam-result-essay.component';
import { FinalizeResultComponent } from './masterfile/finalize-result/finalize-result.component';
import { MaxValueDirective } from './directives/max-value.directive';

const constant = new AppConstants();

@NgModule({
  declarations: [
    AppComponent,
    LoginComponent,
    ExamComponent,
    ExamResultComponent,
    StrengthsComponent,
    BreadcrumbsComponent,
    UnauthorizedComponent,
    ConfirmUserComponent,
    UserRegistrationComponent,
    DataProtectionPolicyComponent,
    RecaptchaComponent,
    UserProfileComponent,
    NoCopyDirective,
    NumberLimiterDirective,
    DecimalFormatterDirective,
    PopoverComponent,
    PreviewExamComponent,
    ExamResultEssayComponent,
    FinalizeResultComponent,
    MaxValueDirective
  ],
  imports: [
    BrowserModule,
    BrowserAnimationsModule,
    AngularMaterialModule,
    AppRoutingModule,
    FlexLayoutModule.withConfig({}, CUSTOM_HEIGHT_BREAKPOINTS),
    FormsModule,
    ReactiveFormsModule,
    CountdownModule,
    HttpClientModule,
    SocialLoginModule,
    MatDialogModule,
    AvatarModule,
    NgxCaptchaModule,
    InactivityModule,
    QuillModule.forRoot(),
    CommaDelimiterModule,
    TabVisibilityModule
  ],
  providers: [
    {
      provide: 'SocialAuthServiceConfig',
      useValue: {
        autoLogin: false,
        providers: [
          {
            id: GoogleLoginProvider.PROVIDER_ID,
            provider: new GoogleLoginProvider(
              constant.googleClientId,
              {
                scopes: 'email profile',
                oneTapEnabled: false
              }
            )
          }

        ],
        onError: (err) => {
          console.error(err);
        }
      } as SocialAuthServiceConfig,
    },
    {
      provide: HTTP_INTERCEPTORS,
      useClass: AuthInterceptor,
      multi: true
    },
    CookieService,
  ],
  bootstrap: [AppComponent],
  schemas: [CUSTOM_ELEMENTS_SCHEMA, NO_ERRORS_SCHEMA]
})
export class AppModule { }
