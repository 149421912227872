import { HttpClient, HttpErrorResponse, HttpHeaders } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable, catchError, map, throwError, Subject } from 'rxjs';
import { AWSS3Service } from './aws.s3.service';
import { HttpAxiosService } from '../auth/auth.interceptor.axios';
import axios from 'axios';
import { AppConstants } from '../app.constants';
import { environment } from 'src/environments/environment';

@Injectable({
  providedIn: 'root'
})
export class AuditTrailService {
  baseURL = environment.apiUrl
  // baseURL: `${environment.apiUrl}${req.url}`,
  private subject = new Subject<any>();

  constructor(
    private http: HttpClient,
    private s3: AWSS3Service,
    private constant: AppConstants

  ) { }

  sendNotification(value: any) {
    this.subject.next(value);
  }

  getNotification() {
    return this.subject.asObservable();
  }

  async fetchAuditTrail(module): Promise<Observable<any>> {
    const response = this.http.post<any[]>("/utils/audit-trail/get", { module });
    return response;
  }

  async fetchAuditTrailValues(auditlogid): Promise<Observable<any>> {
    const response = this.http.post<any[]>("/utils/audit-trail/get-values", { auditlogid });
    return response;
  }

  async addAuditTrail(tablename, recordpk, user, action, module, previousData, newData ) {
    const response = await this.http.post("/utils/audit-trail", { tablename, recordpk, user, action, module, previousData, newData })
    return response;
  }

}
