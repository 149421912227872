<!-- <div  gdAreas="header header header | sidebar content sidebar2 | footer footer footer"
      gdColumns="20%!"
      gdGap="1em"
      
      gdAreas.xs="header | sidebar | content | sidebar2 | footer" 
      gdColumns.xs="none"      
      gdGap.xs="10px" 

      gdAreas.sm="header header | sidebar content | sidebar2 sidebar2 | footer footer"
      gdColumns.sm="120px auto 120px" 

      [ngStyle]="{'max-width': 'auto'}"      
      [ngStyle.xs]="{'max-width': '800px'}">

   <div class="blocks" gdArea="header">
    <h2>Strengths</h2>
   </div>
   <div class="blocks" gdArea="sidebar">Sidebar</div>
   <div class="blocks" gdArea="sidebar2">Sidebar 2</div>
   <div class="blocks" gdArea="content">
     Content
     <br /> More content than we had before so this column is now quite tall.
  </div>
   <div class="blocks" gdArea="footer">Footer</div>

</div> -->

<!-- use (progress - 1) for smooth moving -->
<div style="position: relative">
  <span class="fraction">{{data?.categoryName}}</span>
</div>
<mat-progress-bar mode="determinate" [value]="data?.strengthPercentage"></mat-progress-bar>
<span class="percentage" [ngStyle]="{'left': (data?.initialPercentage) + '%'}">{{data?.strengthPercentage}}%</span>