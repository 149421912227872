<mat-card style="width: 100%;">
    <mat-card-header>
        <mat-card-title class="header-style">Exam/s List</mat-card-title>
    </mat-card-header>
    <mat-tab-group mat-stretch-tabs="false" mat-align-tabs="start"
        (selectedIndexChange)="onSelectedIndexChange($event)">
        <mat-tab label="Result">
            <div class="table-container">
                <mat-toolbar>
                    <mat-form-field *ngIf="!isAddMode && !isEditMode" style="margin-top: 20px;">
                        <mat-label>Filter</mat-label>
                        <input matInput (keyup)="applyFilter($event)" #input>
                    </mat-form-field>
                    <span class="toolbar-item-spacer"></span>

                    <!-- large screen -->
                    <div fxHide fxHide.xs="true" fxHide.gt-xs="false">
                        <button *ngIf="!isAddMode && !isEditMode" style="margin-right: 10px;" mat-flat-button
                            color="primary" matTooltip="Refresh data" (click)="refresh()">
                            <mat-icon>refresh</mat-icon>Refresh
                        </button>
                        <button *ngIf="!isAddMode && !isEditMode" mat-flat-button color="accent"
                            [disabled]="selection.isEmpty()" (click)="deleteHandler()" class="demo-button"
                            style="margin-right: 10px;">
                            <mat-icon>delete</mat-icon>Delete
                        </button>
                        <button *ngIf="!isAddMode && !isEditMode" mat-flat-button color="primary"
                            (click)="exportAction()" class="demo-button" style="margin-right: 10px;">
                            <mat-icon> insert_drive_file</mat-icon>CSV
                        </button>
                        <button *ngIf="!isAddMode && !isEditMode" mat-flat-button color="primary" (click)="addAction()"
                            class="demo-button" style="margin-left: 10px;">
                            Add data
                        </button>
                        <button mat-raised-button *ngIf="isAddMode || isEditMode" (click)="backAction()"
                            class="demo-button" style="margin-left: 10px;">
                            Back
                        </button>
                    </div>

                    <!-- Small Screen -->
                    <div fxShow fxShow.xs="true" fxShow.gt-xs="false">
                        <button *ngIf="!isAddMode && !isEditMode" class="refresh" mat-icon-button color="primary"
                            matTooltip="Refresh data" (click)="refresh()">
                            <mat-icon>refresh</mat-icon>
                        </button>
                        <button mat-icon-button *ngIf="!isAddMode && !isEditMode" (click)="deleteHandler()"
                            matTooltip="Delete" [disabled]="selection.isEmpty()">
                            <mat-icon color="warn">delete</mat-icon>
                        </button>
                        <button *ngIf="!isAddMode && !isEditMode" mat-icon-button color="primary"
                            matTooltip="Export Data" (click)="exportAction()" class="demo-button">
                            <mat-icon> insert_drive_file</mat-icon>
                        </button>
                        <button *ngIf="!isAddMode && !isEditMode" mat-icon-button color="primary" matTooltip="Add Data"
                            (click)="addAction()" class="demo-button">
                            <mat-icon> add_circle</mat-icon>
                        </button>
                        <button mat-raised-button *ngIf="isAddMode || isEditMode" (click)="backAction()"
                            class="demo-button" style="margin-left: 10px;">
                            Back
                        </button>
                    </div>
                </mat-toolbar>

                <div class="mat-elevation-z0" style="margin-top: 15px;" *ngIf="!isAddMode && !isEditMode">
                    <div class="table-container">
                        <table mat-table [dataSource]="dataSource" class="demo-table">
                            <ng-container matColumnDef="select">
                                <th mat-header-cell *matHeaderCellDef>
                                    <mat-checkbox (change)="$event ? toggleAllRows() : null"
                                        [checked]="selection.hasValue() && isAllSelected()"
                                        [indeterminate]="selection.hasValue() && !isAllSelected()"
                                        [aria-label]="checkboxLabel()">
                                    </mat-checkbox>
                                </th>
                                <td mat-cell *matCellDef="let row">
                                    <mat-checkbox (click)="$event.stopPropagation()"
                                        (change)="$event ? selection.toggle(row) : null"
                                        [checked]="selection.isSelected(row)" [aria-label]="checkboxLabel(row)">
                                    </mat-checkbox>
                                </td>
                            </ng-container>
                            <ng-container matColumnDef="position">
                                <th mat-header-cell *matHeaderCellDef>ID</th>
                                <td mat-cell *matCellDef="let element">{{element.id}}</td>
                            </ng-container>

                            <ng-container matColumnDef="name">
                                <th mat-header-cell *matHeaderCellDef>Name</th>
                                <td mat-cell *matCellDef="let element" (click)="goToQuestions(element.id)">
                                    <p class="name" style="cursor: pointer;">{{element.name}}</p>
                                </td>
                            </ng-container>

                            <ng-container matColumnDef="noquestion">
                                <th mat-header-cell *matHeaderCellDef>No. of Question</th>
                                <td mat-cell *matCellDef="let element">{{element.noquestions}}</td>
                            </ng-container>

                            <ng-container matColumnDef="actualquestioncount">
                                <th mat-header-cell *matHeaderCellDef>Actual No. of Question</th>
                                <td mat-cell *matCellDef="let element" style="color: red;">
                                    {{element.actualquestioncount}}
                                </td>
                            </ng-container>

                            <ng-container matColumnDef="maxtime">
                                <th mat-header-cell *matHeaderCellDef>Max Time</th>
                                <td mat-cell *matCellDef="let element">{{element.maxtimetext}}</td>
                            </ng-container>

                            <ng-container matColumnDef="passrate">
                                <th mat-header-cell *matHeaderCellDef>Pass Rate</th>
                                <td mat-cell *matCellDef="let element">{{element.passrate}}%</td>
                            </ng-container>

                            <ng-container matColumnDef="category">
                                <th mat-header-cell *matHeaderCellDef>Category</th>
                                <td mat-cell *matCellDef="let element">{{element.categoryname}}</td>
                            </ng-container>
                            <ng-container matColumnDef="paid">
                                <th mat-header-cell *matHeaderCellDef>Paid</th>
                                <td mat-cell *matCellDef="let element">{{element.paid == 0 ? "No": "Yes"}}
                                </td>
                            </ng-container>
                            <ng-container matColumnDef="viewtype">
                                <th mat-header-cell *matHeaderCellDef>View</th>
                                <td mat-cell *matCellDef="let element">{{element.viewtype == 0 ? "Public": "Private"}}
                                </td>
                            </ng-container>

                            <ng-container matColumnDef="status">
                                <th mat-header-cell *matHeaderCellDef>Status</th>
                                <td mat-cell *matCellDef="let element">{{element.statusName}}
                                </td>
                            </ng-container>
                            <!-- Action Column -->
                            <ng-container matColumnDef="action">
                                <th mat-header-cell *matHeaderCellDef style="text-align: center;">Action</th>
                                <td mat-cell *matCellDef="let element">
                                    <button mat-icon-button matTooltip="Exam Result"
                                        (click)="exportExamResult(element.id, element.name)">
                                        <mat-icon color="accent">insert_drive_file</mat-icon>
                                    </button>
                                    <button mat-icon-button (click)="editItem(element)">
                                        <mat-icon color="primary">edit</mat-icon>
                                    </button>
                                    <button mat-icon-button (click)="delete([element.id])">
                                        <mat-icon color="warn">delete</mat-icon>
                                    </button>

                                </td>
                            </ng-container>

                            <tr mat-header-row *matHeaderRowDef="displayedColumns"></tr>
                            <tr mat-row *matRowDef="let row; columns: displayedColumns;"></tr>
                        </table>
                        <h3 fxLayoutAlign="center" *ngIf="examList.length == 0"><br> No data to display!</h3>
                        <mat-paginator [pageSizeOptions]="[5, 10, 25, 100]" showFirstLastButtons
                            aria-label="Select page of users"></mat-paginator>
                    </div>
                </div>
            </div>

            <div class="add" *ngIf="isAddMode || isEditMode">
                <mat-card style="margin: 0;border: 0;box-shadow: none;">
                    <mat-card-content class="formContent">
                        <form [formGroup]="dataForm" (ngSubmit)="onSubmit()" class="form">
                            <div fxLayout="row" fxLayout.sm="column" fxLayout.xs="column" fxFlexFill>
                                <div fxFlex="50" fxFlex.sm="70" fxFlex.xs="70" class="col col-1">
                                    <mat-form-field appearance="outline" class="form-field">
                                        <mat-label>Exam Name</mat-label>
                                        <textarea matInput cdkTextareaAutosize formControlName="examName"></textarea>
                                        <mat-error *ngIf="checkError('examName', 'required')">Exam Name is
                                            required</mat-error>
                                        <mat-error *ngIf="checkError('examName', 'minlength')">Exam Name should be more
                                            than 5
                                            characters</mat-error>
                                        <mat-error *ngIf="checkError('examName', 'maxlength')">Exam Name should be less
                                            than 20
                                            characters</mat-error>
                                    </mat-form-field>
                                    <mat-form-field appearance="outline" class="example-full-width form-field">
                                        <mat-label>Description</mat-label>
                                        <textarea required rows="3" cdkTextareaAutosize matInput
                                            formControlName="description"></textarea>
                                        <mat-error *ngIf="checkError('description', 'required')">Description is
                                            required</mat-error>
                                        <mat-error *ngIf="checkError('description', 'minlength')">Description should be
                                            more
                                            than 5
                                            characters</mat-error>
                                        <mat-error *ngIf="checkError('description', 'maxlength')">Description should be
                                            less
                                            than 50
                                            characters</mat-error>
                                    </mat-form-field>
                                    <mat-form-field appearance="outline" class="form-field">
                                        <mat-label>Category</mat-label>
                                        <input matInput [formControl]="dataForm.get('category')"
                                            [matAutocomplete]="categoryAuto">
                                        <mat-autocomplete #categoryAuto="matAutocomplete"
                                            [displayWith]="displayCategory">
                                            <mat-option *ngFor="let category of filteredCategories | async"
                                                [value]="category">
                                                {{ category.name }}
                                            </mat-option>
                                        </mat-autocomplete>
                                        <mat-error *ngIf="checkError('category', 'required')">Category is
                                            required</mat-error>
                                    </mat-form-field>

                                    <div fxLayout="column" fxLayout.gt-xs="row">
                                        <mat-form-field appearance="outline" class="form-field"
                                            (click)="showTimePicker('Exam Duration')">
                                            <mat-label>Exam Duration</mat-label>
                                            <input matInput type="text" formControlName="maxTimeText" readonly />
                                            <mat-error *ngIf="checkError('maxTime', 'required')">Exam Duration is
                                                required</mat-error>
                                        </mat-form-field>

                                        <mat-form-field appearance="outline" class="form-field"
                                            (click)="showTimePicker('Time Question')">
                                            <mat-label>Time Question</mat-label>
                                            <input matInput type="text" formControlName="timeQuestionText" readonly />
                                            <mat-error *ngIf="checkError('timeQuestion', 'required')">Time Question is
                                                required</mat-error>
                                        </mat-form-field>

                                    </div>

                                    <div fxLayout="column" fxLayout.gt-xs="row">
                                        <mat-form-field appearance="outline" class="form-field">
                                            <mat-label>Number of Question</mat-label>
                                            <input matInput type="number" formControlName="noQuestions" />
                                            <mat-error *ngIf="checkError('noQuestions', 'required')">Number of Question
                                                is
                                                required</mat-error>
                                        </mat-form-field>

                                        <mat-form-field appearance="outline" class="form-field">
                                            <mat-label>Pass Rate</mat-label>
                                            <input matInput type="number" formControlName="passRate" />
                                            <mat-error *ngIf="checkError('passRate', 'required')">Pass Rate is
                                                required</mat-error>
                                        </mat-form-field>
                                    </div>
                                </div>
                                <div fxFlex="50" fxFlex.sm="70" fxFlex.xs="70" class="col col-2">
                                    <!-- <mat-form-field appearance="outline" class="form-field">
                                        <mat-label>Exam Type</mat-label>
                                        <mat-select formControlName="examType" required>
                                            <mat-option *ngFor="let item of examType" [value]="item.id">{{ item.text
                                                }}</mat-option>
                                        </mat-select>
                                        <mat-error *ngIf="checkError('examType', 'required')">Exam Type is
                                            required</mat-error>
                                    </mat-form-field> -->
                                    <mat-form-field floatLabel="always" appearance="outline" class="form-field">
                                        <mat-label>Tags</mat-label>
                                        <textarea placeholder="Enter tags separated by commas." matInput
                                            cdkTextareaAutosize formControlName="tags"></textarea>
                                        <mat-error *ngIf="checkError('tags', 'required')">Tags is
                                            required</mat-error>
                                    </mat-form-field>

                                    <div fxLayout="column" fxLayout.gt-xs="row">
                                        <mat-form-field appearance="outline" class="form-field">
                                            <mat-label>Level</mat-label>
                                            <mat-select formControlName="level" required>
                                                <mat-option [value]="0">Easy</mat-option>
                                                <mat-option [value]="1">Hard</mat-option>
                                            </mat-select>
                                            <mat-error *ngIf="checkError('level', 'required')">Level is
                                                required</mat-error>
                                        </mat-form-field>

                                        <mat-form-field appearance="outline" class="form-field">
                                            <mat-label>Paid</mat-label>
                                            <mat-select formControlName="paid" required>
                                                <mat-option [value]="1">Yes</mat-option>
                                                <mat-option [value]="0">No</mat-option>
                                            </mat-select>
                                            <mat-error *ngIf="checkError('paid', 'required')">Paid is
                                                required</mat-error>
                                        </mat-form-field>
                                    </div>

                                    <div fxLayout="column" fxLayout.gt-xs="row">
                                        <mat-form-field floatLabel="always" appearance="outline" class="form-field">
                                            <mat-label>Price</mat-label>
                                            <input matInput type="text" required formControlName="price"
                                                class="amount-right-align" placeholder="0" appCommaDelimiter>
                                            <span matTextPrefix>₱&nbsp;</span>
                                            <span matTextSuffix>.00</span>
                                            <mat-error *ngIf="checkError('price', 'required')">Price is
                                                required</mat-error>
                                        </mat-form-field>

                                        <mat-form-field appearance="outline" class="form-field">
                                            <mat-label>View Type</mat-label>
                                            <mat-select formControlName="viewType" required>
                                                <mat-option [value]="1">Private</mat-option>
                                                <mat-option [value]="0">Public</mat-option>
                                            </mat-select>
                                            <mat-error *ngIf="checkError('viewType', 'required')">View Type is
                                                required</mat-error>
                                        </mat-form-field>
                                    </div>

                                    <mat-card class="registration-card">
                                        <mat-card-content class="formContent">

                                            <mat-toolbar style="background-color: white;margin-bottom: 20px;">
                                                <div class="content" fxLayout="row" fxLayout.xs="column" fxFlexFill>
                                                    <div fxFlex="80" fxFlex.xs="55">
                                                        <mat-checkbox formControlName="isRandom">Random
                                                            Question</mat-checkbox>

                                                    </div>
                                                    <div fxFlex="20">
                                                        <button mat-raised-button type="submit"
                                                            style="margin-right: 10px;float: right;"
                                                            color="primary">Submit</button>
                                                    </div>
                                                </div>
                                            </mat-toolbar>
                                        </mat-card-content>
                                    </mat-card>
                                </div>
                            </div>

                        </form>
                    </mat-card-content></mat-card>
            </div>
        </mat-tab>
        <mat-tab label="Upload Exam">
            <mat-card class="example-card">
                <mat-card-header>
                    <p class="template-link" (click)="exportExamTemplate()">Download Template</p>
                </mat-card-header>
                <mat-card-content>

                    <input type="file" style="display: none;" (click)="fileInput.value = null" #fileInput accept=".csv"
                        (change)="onChangeFileInput()" />
                    <mat-form-field appearance="outline" class="" style="width: 300px;">
                        <textarea placeholder="Browse" rows="1" matInput cdkTextareaAutosize type="text" readonly
                            [ngModel]="file?.name" (click)="onClickFileInputButton()"></textarea>
                        <button mat-icon-button matSuffix color="primary" class="file-select-button"
                            (click)="onClickFileInputButton()">
                            <mat-icon>attach_file</mat-icon>
                        </button>

                    </mat-form-field>
                    <button mat-raised-button style="margin-left: 10px;margin-bottom: 5px;"
                        [disabled]="file == null || file == undefined" (click)="importExam()">Upload</button>
                    <mat-divider></mat-divider>
                    <mat-progress-bar *ngIf="hasSubmit" mode="indeterminate"></mat-progress-bar>
                    <p class="instruction">
                        <span class="insTitle">Instructions: </span><br>
                        - Exam name should be unique <br>
                        - Exam Duration in minutes format <br>
                        - Time Question in minutes format
                    </p>
                </mat-card-content>

                <mat-card-actions>

                </mat-card-actions>
            </mat-card>
        </mat-tab>
        <mat-tab label="Audit Trail" *ngIf="isAdmin">
            <app-audit-trail-table [module]="3"></app-audit-trail-table>
        </mat-tab>
    </mat-tab-group>

</mat-card>