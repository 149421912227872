import { CommonModule } from '@angular/common';
import { Component } from '@angular/core';
import { FlexLayoutModule } from '@angular/flex-layout';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { MatButtonModule } from '@angular/material/button';
import { MatCardModule } from '@angular/material/card';
import { MatDialog, MatDialogModule } from '@angular/material/dialog';
import { MatFormFieldModule } from '@angular/material/form-field';
import { MatGridListModule } from '@angular/material/grid-list';
import { MatIconModule } from '@angular/material/icon';
import { MatInputModule } from '@angular/material/input';
import { MatMenuModule } from '@angular/material/menu';
import { MatPaginatorModule } from '@angular/material/paginator';
import { MatSelectModule } from '@angular/material/select';
import { MatToolbarModule } from '@angular/material/toolbar';
import { AuthService } from 'src/app/service/auth.service';
import { PayModelService } from '../service/pay.model.service';
import { Observable, catchError, throwError } from 'rxjs';
import { formatWithCommas } from '../common-function';
import { Router } from '@angular/router';
import { SubscriptionModalComponent } from '../components/subscription-modal/subscription-modal.component';

@Component({
  selector: 'app-subscription-plan',
  templateUrl: './subscription-plan.component.html',
  styleUrls: ['./subscription-plan.component.css'],
  standalone: true,
  imports: [MatCardModule, FormsModule, MatDialogModule, ReactiveFormsModule, MatPaginatorModule, MatButtonModule, MatGridListModule, CommonModule, MatMenuModule, MatToolbarModule, MatIconModule, MatInputModule, MatFormFieldModule, MatSelectModule, MatCardModule, FlexLayoutModule],
})
export class SubscriptionPlanComponent {
  user: any;
  isAdmin: any;
  dataSource: any;
  paginator: any;
  pageSize: any;
  listPlan: any[];
  constructor(
    private authService: AuthService,
    private payModelService: PayModelService,
    private router: Router, 
    public dialog: MatDialog,

  ) {
  }
  ngOnInit() {
    this.fetchData();
  }

  async fetchData() {
    (await this.payModelService.getPayModel({asc:"ASC", orderBy:"orderno"}))
      .pipe(
        catchError(() => {
          return Observable.call(throwError(() => "Error"));
        })
      )
      .subscribe((response) => {
        const data = JSON.parse(JSON.stringify(response));

        data.forEach((item) => {
          item.total = formatWithCommas(item.total);
          item.percandidate = formatWithCommas(item.percandidate);
        })

        const removeFreePackage = data.filter(item => item.orderno != null)

        this.listPlan = removeFreePackage;
      });
  }

  subscribe(id){
    this.router.navigate(['subscription-plan', id]);
  }

  openSubscription() {
    this.dialog.open(SubscriptionModalComponent, {
      width: '450px',
      height: '300px',
    });
  }
}


