import { Component, OnInit, ViewChild, ChangeDetectorRef, ElementRef } from '@angular/core';
import { ActivatedRoute, Router, RouterModule } from '@angular/router';
import { NgFor } from '@angular/common';
import { MatListModule } from '@angular/material/list';
import { MatButtonModule } from '@angular/material/button';
import { MatCardModule } from '@angular/material/card';
import { MatDialog, MatDialogModule } from '@angular/material/dialog';
import { MatGridListModule } from '@angular/material/grid-list';
import { CommonModule } from '@angular/common';
import { MatSidenavModule } from '@angular/material/sidenav';
import { MatMenuModule } from '@angular/material/menu';
import { MatToolbarModule } from '@angular/material/toolbar';
import { MatIconModule } from '@angular/material/icon';
import { MatExpansionModule } from '@angular/material/expansion';
import { MatTooltipModule } from '@angular/material/tooltip';
import { MatTable, MatTableDataSource, MatTableModule } from '@angular/material/table';
import { MatPaginator, MatPaginatorModule } from '@angular/material/paginator';
import { Observable, catchError, firstValueFrom, from, map, startWith, throwError } from 'rxjs';
import { FormGroup, FormControl, Validators } from '@angular/forms';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { MatInputModule } from '@angular/material/input';
import { MatFormFieldModule } from '@angular/material/form-field';
import { MatSelectModule } from '@angular/material/select';
import { MatCheckboxModule } from '@angular/material/checkbox';
import { SnackbarService } from 'src/app/lib/snackbar/snackbar.service';
import { DialogService } from 'src/app/service/dialog.service';
import { MatRadioChange, MatRadioModule } from '@angular/material/radio';
import { AppConstants } from '../../app.constants';
import { MatAutocompleteModule } from '@angular/material/autocomplete';
import { MatTabsModule } from '@angular/material/tabs';
import { MatProgressBarModule } from '@angular/material/progress-bar';
import { AWSS3Service } from 'src/app/service/aws.s3.service';
import { CategoryService } from 'src/app/service/category.service';
import { ExamService } from 'src/app/service/exam.service';
import { ExamQuestionModel } from 'src/app/model/exam-question';
import { AuditTrailTableComponent } from "../../components/audit-trail-table/audit-trail-table.component";
import { AuditTrailService } from 'src/app/service/audit.trail.service';
import { FlexLayoutModule } from "@angular/flex-layout";
import { SelectionModel } from '@angular/cdk/collections';
import { EditorChangeContent, EditorChangeSelection, QuillEditorComponent, QuillModule } from 'ngx-quill';
import Quill from 'quill';
import ImageResize from 'quill-image-resize-module--fix-imports-error';
import { CommaDelimiterModule } from 'src/app/lib/comma-delimiter-module/comma-delimiter.module';
import { formatWithCommas, cleanInputValue } from '../../common-function'

import { LiveAnnouncer } from '@angular/cdk/a11y';
import { COMMA, ENTER } from '@angular/cdk/keycodes';
import { ChangeDetectionStrategy, inject, signal } from '@angular/core';
import { MatChipEditedEvent, MatChipInputEvent, MatChipsModule } from '@angular/material/chips';
import { SystemConfigurationService } from 'src/app/service/sys.config.service';
import { PreviewExamComponent } from 'src/app/components/preview-exam/preview-exam.component';
import { DecimalFormatterDirective } from 'src/app/directives/decimal.directive';

export interface Answer {
  answer: string;
  id: number;
  isNew: number;
}

Quill.register('modules/imageResize', ImageResize);
@Component({
  selector: 'app-questions',
  templateUrl: './questions.component.html',
  styleUrls: ['./questions.component.css'],
  standalone: true,
  imports: [MatProgressBarModule, CommaDelimiterModule, MatChipsModule, QuillModule, MatSidenavModule, FlexLayoutModule, FormsModule, MatCheckboxModule, MatSelectModule, ReactiveFormsModule, MatInputModule, MatFormFieldModule,
    MatPaginatorModule, MatTableModule, MatToolbarModule, MatMenuModule, MatIconModule, MatExpansionModule, MatTooltipModule, MatAutocompleteModule,
    CommonModule, MatGridListModule, MatListModule, MatListModule, NgFor, MatCardModule, MatButtonModule, RouterModule, MatDialogModule, MatRadioModule, MatTabsModule, AuditTrailTableComponent],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class QuestionsComponent {
  displayedColumns: string[] = ['select', 'position', 'question', 'asnwerscount', 'correctcount', 'isactive', 'category', 'questiontype', 'action'];
  dataSource = new MatTableDataSource<ExamQuestionModel>;
  selection = new SelectionModel<any>(true, []);
  public dataForm: FormGroup;
  questionHTML = '';
  hasError = {
    value: false,
    message: "No answer selected or provided."
  }
  shopName: any;
  isAddMode: boolean = false;
  isEditMode: boolean = false;
  categories: any[];
  pageSize: number = 5;
  examId: any;
  exam: any;

  answerOptions: any[] = [];
  selectedAnswer: number;
  answerCount = 0;
  deletedAnswer: any[] = [];
  questionType = this.constant.QuestionType;
  filteredCategories: Observable<any[]>;

  @ViewChild(MatPaginator) paginator: MatPaginator;
  @ViewChild(MatTable) table: MatTable<ExamQuestionModel>;

  // Mass upload
  @ViewChild('fileInput')
  fileInput;
  file: File | null = null;
  hasSubmit: boolean = false;

  output = "";
  @ViewChild('editor') editor: QuillEditorComponent;
  @ViewChild('questionEditor') editorQuestion: QuillEditorComponent;
  quill: QuillEditorComponent[];
  quillEditors = [];
  filePath = "";
  modules = {
    toolbar: {
      container: [
        ['bold', 'italic', 'underline', 'strike'],        // toggled buttons
        ['blockquote', 'code-block'],

        [{ 'header': 1 }, { 'header': 2 }],               // custom button values
        [{ 'list': 'ordered' }, { 'list': 'bullet' }],
        [{ 'script': 'sub' }, { 'script': 'super' }],      // superscript/subscript
        [{ 'indent': '-1' }, { 'indent': '+1' }],          // outdent/indent
        [{ 'direction': 'rtl' }],                         // text direction

        [{ 'size': ['small', false, 'large', 'huge'] }],  // custom dropdown
        [{ 'header': [1, 2, 3, 4, 5, 6, false] }],

        [{ 'color': [] }, { 'background': [] }],          // dropdown with defaults from theme
        [{ 'font': [] }],
        [{ 'align': [] }],

        ['clean'],                                         // remove formatting button

        ['link', 'image', 'video']
      ],
      handlers: {
        // image: this.imageHandler.bind(this),
      },
    },
    imageResize: {}
  };
  questionOutput = "";
  questionEditor: any;

  answers: Answer[] = [];
  oldAnswers: Answer[] = [];
  answerId = 0;
  oldAnswerId = 0;
  oldQuestionType = 0;

  separatorKeysCodes: number[] = [ENTER, COMMA];
  addOnBlur = true;
  isShow = false;

  questionHandler = {
    isMultipleCorrect: false,
    isMultipleChoice: false,
    isNumber: false,
    isText: false,
    isEssay: false
  };
  masterfile = { assessmentMetrics: [] };
  assessmentMetrics = [];
  audience = [];
  purpose = [];
  selectedAudience = 0;
  selectedPurpose = 0;
  metricsTotalScale = 0;
  essayConfig = { value: '' };
  hasClickSubmit = false;
  finalEssayConfig = '';
  constructor(
    private router: Router, public dialog: MatDialog,
    private examService: ExamService,
    private categoryService: CategoryService,
    private snackbarService: SnackbarService,
    private dialogService: DialogService,
    private _Activatedroute: ActivatedRoute,
    private constant: AppConstants,
    private s3: AWSS3Service,
    private auditTrailService: AuditTrailService,
    private changeDetectorRef: ChangeDetectorRef,
    private el: ElementRef,

    private sysConfig: SystemConfigurationService

  ) {
    this.filePath = constant.imageAnswerFilePath;
    this.modules = {
      toolbar: {
        container: [
          ['bold', 'italic', 'underline', 'strike'],        // toggled buttons
          ['blockquote', 'code-block'],

          [{ 'header': 1 }, { 'header': 2 }],               // custom button values
          [{ 'list': 'ordered' }, { 'list': 'bullet' }],
          [{ 'script': 'sub' }, { 'script': 'super' }],      // superscript/subscript
          [{ 'indent': '-1' }, { 'indent': '+1' }],          // outdent/indent
          [{ 'direction': 'rtl' }],                         // text direction

          [{ 'size': ['small', false, 'large', 'huge'] }],  // custom dropdown
          [{ 'header': [1, 2, 3, 4, 5, 6, false] }],

          [{ 'color': [] }, { 'background': [] }],          // dropdown with defaults from theme
          [{ 'font': [] }],
          [{ 'align': [] }],

          ['clean'],                                         // remove formatting button

          ['link', 'image', 'video']
        ],
        handlers: {
          image: this.imageHandler.bind(this),
        },
      },
      imageResize: {}
    };
  }

  ngOnInit() {
    this.examId = this._Activatedroute.snapshot.paramMap.get('id');
    this.fetchData(this.examId);
    this.dataForm = new FormGroup({
      questionId: new FormControl(''),
      examId: new FormControl(this.examId),
      question: new FormControl('', [Validators.required, Validators.minLength(5)]),
      isActive: new FormControl(true, [Validators.required]),
      categoryControl: new FormControl('', [Validators.required]),
      questionType: new FormControl('', [Validators.required]),
      numberAnswer: new FormControl(''),
      wordCount: new FormControl('', [Validators.required]),
      passRate: new FormControl('', [Validators.required]),
      maxPoint: new FormControl('', [Validators.required]),
      // difficultyLevel: new FormControl('')
    });

    this.getAllExam(this.examId);
    this.fetchSysConfig();

  }

  add(event: MatChipInputEvent): void {
    const input = event.input;
    const value = event.value;

    // Add 
    if ((value || '').trim()) {
      const answer = { answer: value.trim(), id: 0, isNew: 1 };
      this.answers.push(answer);
      this.hasError.value = false;
    }

    // Reset the input value
    if (input) {
      input.value = '';
    }
  }

  remove(item: Answer): void {
    const index = this.answers.indexOf(item);

    if (index >= 0) {
      this.answers.splice(index, 1);
      this.deletedAnswer.push(item.id);
      // console.log('deleted: ', this.deletedAnswer)
    }
  }

  edit(item: Answer, event: any): void {
    const input = event.input;
    const value = event.value;

    if (value.trim()) {
      // Edit 
      item.answer = value.trim();
    }

    // Reset the input value
    if (input) {
      input.value = item.answer;
    }
  }

  getQuestionLabel() {
    return this.questionHandler.isEssay ? 'Topic' : 'Question';
  }


  async validateType(id, answers) {
    // console.log(this.dataForm);

    const type = this.questionType.find(x => x.id === id);
    // console.log('type: ', type)
    let answer = '';

    // Reset all question types to false
    this.resetQuestionTypes();

    // Set the appropriate question type to true
    if (type.text === "Multiple Correct Answers"
      || type.text === "Multiple Choice") {
      this.questionHandler.isMultipleCorrect = true;
      this.questionHandler.isMultipleChoice = true;
      this.clearValidators();
      if (answers) {
        answers.forEach(item => {
          item.isEdited = false;
        })

        this.answerOptions = answers;
      }
    } else if (type.text === "Multiple Choice") {
    } else if (type.text === "Text Answer") {
      this.questionHandler.isText = true;
      if (answers) {
        answers.forEach(item => {
          item.isNew = 0;
        })
        this.answers = answers;
        this.oldAnswers = answers;
      }
      this.setQuestionValidators(false, 'textAnswer', answer);
    } else if (type.text === "Number Answer") {
      this.questionHandler.isNumber = true;
      if (answers) {
        answer = (answers.length > 0) ? formatWithCommas(answers.at(0).answer) : '';
        this.answerId = (answers.length > 0) ? answers.at(0).id : 0;
        this.oldAnswerId = this.answerId;
      }
      this.setQuestionValidators(true, 'numberAnswer', answer);
    } else if (type.text === "Essay") {
      this.questionHandler.isEssay = true;
      if (this.editItem) {
        const questionId = this.dataForm.value.questionId;
        // await this.fetchEssayMetrics(questionId);
      }

      // if (answers) {
      //   answer = (answers.length > 0) ? answers.at(0).answer : '';
      //   this.answerId = (answers.length > 0) ? answers.at(0).id : 0;
      //   this.oldAnswerId = this.answerId;
      // }
      // this.setQuestionValidators(true, 'essay', answer);
    }
  }

  // Helper method to reset all question types
  resetQuestionTypes() {
    this.questionHandler.isMultipleCorrect = false;
    this.questionHandler.isMultipleChoice = false;
    this.questionHandler.isText = false;
    this.questionHandler.isNumber = false;
    this.questionHandler.isEssay = false;
  }

  clearValidators() {
    const wordCountControl = this.dataForm.get('wordCount');
    const passRateControl = this.dataForm.get('passRate');
    const maxPointControl = this.dataForm.get('maxPoint');

    wordCountControl.clearValidators();
    wordCountControl.updateValueAndValidity();

    passRateControl.clearValidators();
    passRateControl.updateValueAndValidity();

    maxPointControl.clearValidators();
    maxPointControl.updateValueAndValidity();
  }
  setQuestionValidators(isRequired: boolean, fieldName, value) {
    const fieldControl = this.dataForm.get(fieldName);

    const numberControl = this.dataForm.get('numberAnswer');
    if (isRequired && (fieldName != 'textAnswer')) {
      fieldControl.setValidators([Validators.required]);
      fieldControl.setValue(value);
      if (fieldName == 'numberAnswer') {
        this.clearValidators();
      } else {
        numberControl.clearValidators();
        numberControl.updateValueAndValidity();
      }
      fieldControl.updateValueAndValidity();
    } else if ((fieldName == 'textAnswer')) {
      this.clearValidators();

      numberControl.clearValidators();
      numberControl.updateValueAndValidity();
    } else {
      fieldControl.clearValidators();
      fieldControl.updateValueAndValidity();
    }
  }

  uploadFileWithTimestamp(file: File): File {
    // Get the current date and time
    const currentDateTime = new Date().toISOString().replace(/[-:.]/g, '');

    // Extract the filename and file extension
    const filenameParts = file.name.split('.');
    const filename = filenameParts[0];
    const fileExtension = filenameParts.length > 1 ? `.${filenameParts[1]}` : '';

    // Construct the new filename with the current date and time
    const newFilename = `${currentDateTime}-${filename}${fileExtension}`;

    // Create a new File object with the updated filename
    return new File([file], newFilename, { type: file.type });
  }

  editorInstance(editorInstance: any, index) {
    this.quillEditors[index] = editorInstance;
  }

  questionEditorInstance(editorInstance: any) {
    this.questionEditor = editorInstance;
  }
  initQuillEditors() {
    this.answerOptions.forEach((option, index) => {
      option.modules = {
        toolbar: {
          container: this.modules.toolbar.container.slice(), // Clone the toolbar container array
          handlers: {
            image: this.createImageHandler(index),
          },
        },
        imageResize: {}
      };
    });
  }

  createImageHandler(index: number) {
    return () => {
      const input = document.createElement('input');
      input.setAttribute('type', 'file');
      input.setAttribute('accept', 'image/*');
      input.click();

      const examId = this.examId;
      input.onchange = async () => {
        const file = input.files[0];
        const fileWithTimeStamp = this.uploadFileWithTimestamp(file);
        const fileName = `exam/${examId}/${fileWithTimeStamp.name}`;
        var newFile = new File([fileWithTimeStamp], fileName, {
          type: file.type,
        });

        const range = this.quillEditors[index].getSelection(true);
        this.quillEditors[index].insertEmbed(range.index, 'image', `${window.location.origin}/assets/images/loader_circular.gif`);

        this.quillEditors[index].setSelection(range.index + 1);
        await this.s3.uploadFilePub(newFile);
        const link = `${this.filePath}/${fileName}`;
        this.quillEditors[index].deleteText(range.index, 1);
        this.quillEditors[index].insertEmbed(range.index, 'image', link);
        this.quillEditors[index].setSelection(range.index + 1);
        const content = this.quillEditors[index].root.innerHTML;
        this.answerOptions[index].answer = content;
      };
    };
  }

  async imageHandler() {
    const input = document.createElement('input');
    input.setAttribute('type', 'file');
    input.setAttribute('accept', 'image/*');
    input.click();

    const examId = this.examId;
    input.onchange = async () => {
      const file = input.files[0];
      const fileName = `exam/${examId}/${file.name}`;
      var newFile = new File([file], fileName, {
        type: file.type,
      });

      const range = this.questionEditor.getSelection(true);
      this.questionEditor.insertEmbed(range.index, 'image', `${window.location.origin}/assets/images/loader_circular.gif`);

      this.questionEditor.setSelection(range.index + 1);
      await this.s3.uploadFilePub(newFile);
      const link = `${this.filePath}/${fileName}`;
      this.questionEditor.deleteText(range.index, 1);
      this.questionEditor.insertEmbed(range.index, 'image', link);
      this.questionEditor.setSelection(range.index + 1);
      this.dataForm.patchValue({
        question: this.questionHTML
      });
    };
  }


  changedQuestionEditor(data) {

    if (data.html !== undefined) {
      this.questionHTML = data.html;
    }

  }

  changedEditor(event: EditorChangeContent | EditorChangeSelection, item) {
    this.dataChanged(item);
  }

  refresh() {
    this.fetchData(this.examId);
    this.getAllExam(this.examId);

    this.selection.clear();
  }

  isAllSelected() {
    const numSelected = this.selection.selected.length;
    const numRows = this.dataSource.data.length;
    return numSelected === numRows;
  }
  async deleteHandler() {
    const ids = this.selection.selected.map(item => item.id)
    await this.delete(ids);
    this.selection.clear();
  }

  /** Selects all rows if they are not all selected; otherwise clear selection. */
  toggleAllRows() {
    if (this.isAllSelected()) {
      this.selection.clear();
      return;
    }

    this.selection.select(...this.dataSource.data);
  }

  /** The label for the checkbox on the passed row */
  checkboxLabel(row?: any): string {
    if (!row) {
      return `${this.isAllSelected() ? 'deselect' : 'select'} all`;
    }
    return `${this.selection.isSelected(row) ? 'deselect' : 'select'} row ${row.position + 1}`;
  }

  ngAfterViewInit() {
    this.dataSource.paginator = this.paginator;
  }

  onClickFileInputButton(): void {
    this.fileInput.nativeElement.click();
  }

  onChangeFileInput(): void {
    const files: { [key: string]: File } = this.fileInput.nativeElement.files;
    this.file = files[0];
  }

  async exportAction() {
    (await this.examService.exportQuestion(this.examId))
      .pipe(
        catchError((err) => {
          this.snackbarService.showSnackbar(this.constant.errorExportCSV, 'top', 3000);
          return Observable.call(throwError(() => "Error"));
        })
      )
      .subscribe((response) => {
        const blob = new Blob([response], { type: 'text/csv' });
        const url = window.URL.createObjectURL(blob);
        const a = document.createElement('a');
        a.href = url;
        a.download = 'Questions.csv';
        a.click();
        window.URL.revokeObjectURL(url);
        this.auditTrailService.sendNotification(true);
      });
  }


  async importQuestions() {
    this.hasSubmit = true;
    try {
      const key = await this.s3.uploadFile(this.file);
      const response = await this.examService.importQuestions(this.examId, key);
      const data = JSON.parse(JSON.stringify(response.data));
      if (response.status === 200) {
        this.fetchData(this.examId);
        this.auditTrailService.sendNotification(true);
        this.snackbarService.showSnackbar(data.message, 'top', 10000);
      }

      this.file = null;
      this.hasSubmit = false;
    } catch (error) {
      this.snackbarService.showSnackbar("An error occured while processing the request", 'top', 10000);
      this.file = null;
      this.hasSubmit = false;
    }
  }

  async exportQuestionTemplate() {
    (await this.examService.exportQuestionTemplate(this.examId))
      .pipe(
        catchError((err) => {
          return Observable.call(throwError(() => "Error"));
        })
      )
      .subscribe((response) => {
        const blob = new Blob([response], { type: 'text/csv' });
        const url = window.URL.createObjectURL(blob);
        const a = document.createElement('a');
        a.href = url;
        a.download = 'Question_template.csv';
        a.click();
        window.URL.revokeObjectURL(url);
        this.auditTrailService.sendNotification(true);
      });
  }

  private _filterCategories(value: any): any[] {
    if (value != null) {
      if (typeof value === 'string') {
        const filterValue = value.toLowerCase();
        return this.categories.filter(category => category.name.toLowerCase().includes(filterValue));
      } else if (typeof value === 'object' && value.name) {
        return this.categories.filter(category => category.name.toLowerCase() === value.name.toLowerCase());
      }
    }
    return [];
  }

  // Function to display the selected category
  displayCategory(category: any): string {
    return category ? category.name : '';
  }

  public checkError = (controlName: string, errorName: string) => {
    return this.dataForm.controls[controlName].hasError(errorName);
  }

  addAnswer() {
    const listCount = this.answerOptions.length;
    const id = listCount == 0 ? 1 : this.answerOptions[listCount - 1].id + 1
    this.answerOptions.push({ id: id, answer: '', isNew: 1, isChecked: false, isEdited: true });
    this.initQuillEditors();
  }

  removeAnswer(data, index: number) {
    this.answerOptions.splice(index, 1);
    if (data.isNew == 0) {
      this.deletedAnswer.push(data.id)
    }
  }

  // Function to toggle the checked state of an answer
  toggleAnswerCheck(option: any, index: number) {
    this.answerOptions[index].isChecked = !option.isChecked;
    if (option.isNew == 0) {
      option.isEdited = true;
    }

    if (!this.hasSelectedAnswer()) {
      this.hasError.value = true;
    } else {
      this.hasError.value = false;
    }
  }

  applyFilter(event: Event) {
    const filterValue = (event.target as HTMLInputElement).value;
    this.dataSource.filter = filterValue.trim().toLowerCase();
  }

  async fetchData(examId) {
    (await this.examService.fetchQuestions(examId))
      .pipe(
        catchError(() => {
          return Observable.call(throwError(() => "Error"));
        })
      )
      .subscribe((response) => {
        const data = JSON.parse(JSON.stringify(response));
        this.dataSource.data = data;
        this.paginator.pageSize = this.pageSize;
        this.dataSource.paginator = this.paginator;
      });
  }

  async fetchCategory() {
    (await this.categoryService.getAllCategoryMasterfile('name', 4))
      .pipe(
        catchError(() => {
          return Observable.call(throwError(() => "Error"));
        })
      )
      .subscribe((response) => {
        const data = JSON.parse(JSON.stringify(response));
        this.categories = data;
        this.filteredCategories = this.dataForm.get('categoryControl').valueChanges.pipe(
          startWith(''),
          map((value: any) => this._filterCategories(value))
        );
      });
  }

  async fetchSysConfig() {
    (await this.sysConfig.fetchSysConfig())
      .pipe(
        catchError(() => {
          return Observable.call(throwError(() => "Error"));
        })
      )
      .subscribe((response) => {
        const data = JSON.parse(JSON.stringify(response));
        this.purpose = data.purpose;
        this.audience = data.audience;
        data.metrics.forEach((item) => {
          item.selected = true;
          item.scale = 10;
          item.configid = item.id;
          item.htmlFormat = item.description.trim().replace(/\n/g, '<br>');
          item.formattedDetails = this.calculateAndReplaceRubric(item.scale, item.htmlFormat);
        })
        this.masterfile.assessmentMetrics = data.metrics;
        this.assessmentMetrics = data.metrics;
        this.essayConfig.value = data.gpt_params[0].value;
        
        this.calculateMetricsScale();
      });
  }

  // Utility function to format the number
  formatNumber(value: number | string): string {
    const num = Number(value); // Convert value to a number
    return Number.isInteger(num) ? num.toString() : num.toFixed(1).replace(/\.0$/, '');
  }
  calculateAndReplaceRubric(points: number, criteria: string): string {

    let division = points/5;
    let value = criteria
      .replace('{5}', `${points}`)
      .replace('{4}', `${division*4}`)
      .replace('{3}', `${division*3}`)
      .replace('{2}', `${division*2}`)
      .replace('{1}', `${division*1}`);

    return value;
  }


  async fetchEssayMetrics(questionId: number) {
    (await this.examService.fetchEssayMetrics(questionId))
      .pipe(
        catchError(() => {
          return Observable.call(throwError(() => "Error"));
        })
      )
      .subscribe((response) => {
        const data = JSON.parse(JSON.stringify(response));

        data.forEach((item) => {
          item.selected = true;
          // item.formattedDetails = item.description.trim().replace(/\n/g, '<br>');
          item.htmlFormat = item.description.trim().replace(/\n/g, '<br>');
          item.formattedDetails = this.calculateAndReplaceRubric(item.scale, item.htmlFormat);

        })
        this.assessmentMetrics = data;
        this.calculateMetricsScale();
      });
  }

  async fetchAnswers(questionId: number, item) {
    (await this.examService.fetchAnswers(questionId))
      .pipe(
        catchError(() => {
          return Observable.call(throwError(() => "Error"));
        })
      )
      .subscribe((response) => {
        const data = JSON.parse(JSON.stringify(response));

        this.initQuillEditors();

        this.validateType(item.type, data);
        this.dataForm.patchValue({
          questionType: item.type
        });
      });
  }

  scaleColor(metricsTotalScale) {
    if (metricsTotalScale > 100) {
      return 'warn';
    } else if (metricsTotalScale == 100) {
      return 'primary';
    }

    return '';
  }

  getHTMLText(htmlContent: string): string {
    // Create a temporary DOM element
    const tempDiv = document.createElement("div");

    // Assign the HTML content to the temporary element
    tempDiv.innerHTML = htmlContent;

    // Use textContent to get only the text
    return tempDiv.textContent || "";
  }

  calculateMetricsScale() {
    let total = 0;
    for (const item of this.assessmentMetrics) {
      if (item.selected && !(item.scale == '' || item.scale == 0)) {
        const value = cleanInputValue(item.scale);
        total += parseFloat(value);
      }
    }

    this.assessmentMetrics.forEach((item) => {
      item.htmlFormat = item.description.trim().replace(/\n/g, '<br>');
      item.formattedDetails = this.calculateAndReplaceRubric(item.scale, item.htmlFormat);
    })

    this.metricsTotalScale = total;
  }

  getValue(type) {
    let value = { value: '', description: '' }
    if (type == 'audience') {
      let audience = this.audience.find(x => x.id == this.selectedAudience)
      if (audience) {
        value = audience;
      }
    } else {
      let purpose = this.purpose.find(x => x.id == this.selectedPurpose)
      if (purpose) {
        value = purpose;
      }
    }

    return value;
  }

  getMetricsConfig() {
    let config = "";
    let selectedMetrics = this.assessmentMetrics.filter(x => x.selected == true);
    let index = 1;
    for (const item of selectedMetrics) {
      const value = (item.scale == '') ? 0 : item.scale;
      config = config + `${index}. ${item.value} (${value}points) ${item.formattedDetails}`
      index++;
    }

    return config;
  }

  updateEssayConfig() {
    if (this.questionHandler.isEssay) {
      let data = this.dataForm.value;
      let config = this.essayConfig.value;
      let audience = this.getValue('audience');
      let purpose = this.getValue('purpose');

      let structuredConfig = config
        .replace('{topic}', data.question.replace(/"/g, ''))
        .replace('{purpose}', purpose.value)
        .replace('{wordCount}', data.wordCount)
        .replace('{audience}', audience.value)
        .replace('{metrics}', this.getMetricsConfig().replace(/<br>/g, ''))
        .replace(/\n/g, '')
        .replace(/\\/g, '');
      // .replace('{rating}', data.passRate);

      this.finalEssayConfig = structuredConfig;
    }
  }

  toggleMetrics(option, index) {
    this.assessmentMetrics[index].selected = !option.selected;
    this.calculateMetricsScale();

    this.updateEssayConfig();
  }

  async getAllExam(examId) {
    (await this.examService.getAllExam())
      .pipe(
        catchError(() => {
          return Observable.call(throwError(() => "Error"));
        })
      )
      .subscribe((response) => {
        const data = JSON.parse(JSON.stringify(response));
        this.exam = data.filter(x => x.id == examId)[0]
      });
  }

  getSelectedAnswer(data) {
    for (const item of data) {
      if (item.correct == 1) {
        this.selectedAnswer = item.id;
      }
    }
  }

  hasSelectedAnswer() {
    this.answerCount = 0;
    const countList = this.answerOptions.length;
    let count = 0;
    if (countList > 0) {
      for (const item of this.answerOptions) {
        if (!item.isChecked) {
          count++;
        } else {
          this.answerCount++;
        }
      }
    }

    return count != countList;
  }

  async submitHandler() {
    this.updateEssayConfig();
    // console.log('valid form: ', this.dataForm.valid)
    // console.log(this.dataForm)

    this.dataForm.markAllAsTouched();
    this.hasClickSubmit = true;
    this.getMetricsConfig();
    if (this.dataForm.valid) {
      if (this.questionHandler.isMultipleCorrect && this.answerOptions.length > 0) {
        if (!this.hasSelectedAnswer()) {
          this.hasError.value = true;
          return;
        }
      } else if (this.questionHandler.isText && this.answers.length == 0) {
        this.hasError.value = true;
        return;
      }

      if ((this.dataForm.value.questionType != this.oldQuestionType) && this.isEditMode) {
        this.dialogService.openDialog('Submit', this.constant.changeQuestionTypeMessage, 'No', true, async (confirmed) => {
          if (confirmed) {
            await this.onSubmit();
          } else {
            console.log('Close');
          }
        });
      } else {
        await this.onSubmit();
      }
    }

  }

  isEssayConfigValid() {
    if (!this.questionHandler.isEssay) {
      return true;
    }

    if (this.selectedPurpose == 0 || this.selectedAudience == 0 || !(this.metricsTotalScale == 100)) {
      return false;
    }

    this.updateEssayConfig();
    return true;

  }

  async onSubmit() {
    // console.log(this.answerOptions);
    // console.log(this.answers);
    // return;
    if (this.dataForm.valid && this.isEssayConfigValid()) {

      this.answerOptions.forEach((item) => {
        delete item.modules;
      });

      const isMCQ = this.questionHandler.isMultipleChoice || this.questionHandler.isMultipleCorrect;
      const finalAnswer = (isMCQ) ? this.answerOptions : this.answers;
      const removedComma = cleanInputValue(this.dataForm.value.numberAnswer);

      const params = {
        ...this.dataForm.value,
        newAnswers: finalAnswer.filter(item => item.isNew == 1),
        deletedAnswer: this.deletedAnswer,
        editedAnswer: this.answerOptions.filter(item => item.isEdited),
        numberAnswer: this.questionHandler.isNumber ? removedComma : this.dataForm.value.numberAnswer,
        purposeConfigId: this.selectedPurpose,
        audienceConfigId: this.selectedAudience,
        essayConfig: this.finalEssayConfig,
        selectedMetrics: this.assessmentMetrics
      }

      // console.log(params)
      // return;

      if (this.isAddMode) {

        const payload = {
          ...this.dataForm.value,
          numberAnswer: this.questionHandler.isNumber ? removedComma : this.dataForm.value.numberAnswer,
          answers: finalAnswer,
          purposeConfigId: this.selectedPurpose,
          audienceConfigId: this.selectedAudience,
          essayConfig: this.finalEssayConfig,
          selectedMetrics: this.assessmentMetrics
        }

        await (await this.examService.addQuestion(payload))
          .subscribe((response) => {
            this.snackbarService.showSnackbar(response.toString(), 'top', 2000);
            this.fetchData(this.examId);
            this.answerOptions = [];
            this.answers = [];
            this.auditTrailService.sendNotification(true);
            this.backAction();
          });
      }

      if (this.isEditMode) {
        const payload = {
          ...this.dataForm.value,
          newAnswers: finalAnswer.filter(item => item.isNew == 1),
          deletedAnswer: this.deletedAnswer,
          editedAnswer: this.answerOptions.filter(item => item.isEdited),
          // answerId: this.answerId,
          numberAnswer: this.questionHandler.isNumber ? removedComma : this.dataForm.value.numberAnswer,
          // oldAnswer: this.oldAnswers,
          // oldAnswerId: this.oldAnswerId
          purposeConfigId: this.selectedPurpose,
          audienceConfigId: this.selectedAudience,
          essayConfig: this.finalEssayConfig,
          selectedMetrics: this.assessmentMetrics
        }
        // console.log(payload)
        // return;

        await (await this.examService.editQuestion(payload))
          .subscribe((response) => {
            this.snackbarService.showSnackbar(response.toString(), 'top', 2000);
            this.fetchData(this.examId);
            this.auditTrailService.sendNotification(true);
            this.backAction();
          });
      }
    }
  }

  preview() {
    const itemIsInteger = Number.isInteger(parseFloat(this.dataForm.value.numberAnswer));
    const dialogRef = this.dialog.open(PreviewExamComponent, {
      width: '850px',
      // height: '300px',
      data: {
        ... this.dataForm.value,
        options: this.answerOptions,
        textAnswers: this.answers,
        isInteger: itemIsInteger,
        correctCount: this.answerCount,
        examName: this.exam.name
      }
    });

  }

  public onSelectedIndexChange(tabIndex: number) {
    if (tabIndex === 0) {
      this.fetchData(this.examId);
    }
  }

  dataChanged(item) {
    if (this.isEditMode && item.isNew == 0) {
      item.isEdited = true;
    }
  }

  removeImageIfPresent(content: string): string {
    if (content && content.includes('<img')) {
      return content.replace(/<img[^>]*>/g, '');
    } else {
      return content;
    }
  }


  async addAction() {
    await this.fetchCategory();
    this.isEditMode = false;
    this.isAddMode = true;
    if (this.masterfile.assessmentMetrics.length > 0) {
      this.assessmentMetrics = this.masterfile.assessmentMetrics;
      this.calculateMetricsScale();
    }
  }

  backAction() {
    this.fetchData(this.examId);
    this.isAddMode = false;
    this.isEditMode = false;
    this.dataForm.reset();
    this.dataForm.patchValue({
      examId: this.examId,
      isActive: true,
      categoryControl: '',
    });

    this.hasError.value = false;
    this.answerOptions = [];
    this.answers = [];
    this.selectedAnswer = null;
    this.deletedAnswer = [];
    setTimeout(() => {
      this.paginator.pageSize = this.pageSize;
      this.dataSource.paginator = this.paginator;
    }, 0);

    this.questionHandler.isEssay = false;
    this.questionHandler.isMultipleChoice = false;
    this.questionHandler.isNumber = false;
    this.questionHandler.isText = false;
    this.selectedAudience = 0;
    this.selectedPurpose = 0;
    this.assessmentMetrics = [];
  }

  async editItem(data) {
    console.log(data)
    await this.fetchAnswers(data.id, data);
    await this.fetchCategory();
    await this.fetchEssayMetrics(data.id);
    this.dataForm.patchValue({
      questionId: data.id,
      question: data.question,
      numCorrect: data.correctcount,
      categoryControl: { id: data.categoryid, name: data.categoryname },
      timeQuestion: data.timequestion,
      isRandom: data.random == 1 ? true : false,
      questionType: data.type
    });

    if (data.type == 5) {
      this.dataForm.patchValue({
        wordCount: data.wordcount,
        passRate: data.essaypassrate,
        maxPoint: data.maxpoint
      })

      this.selectedAudience = data.audienceconfigid;
      this.selectedPurpose = data.purposeconfigid;
    }

    this.oldQuestionType = data.type;
    this.isEditMode = true;
    this.isAddMode = false;
    this.answerCount = data.correctcount;
  }

  async delete(ids) {
    this.dialogService.openDialog('Delete question and answers', this.constant.deleteQuestionAndMessage, 'No', true, async (confirmed) => {
      if (confirmed) {
        (await this.examService.deleteQuestionAnswers(ids))
          .subscribe((response) => {
            this.snackbarService.showSnackbar(response.toString(), 'top', 2000);
            this.auditTrailService.sendNotification(true);
            this.fetchData(this.examId);
          });
      } else {
        console.log('Close');
      }
    });
  }

  goToQuestions(examId) {
    this.router.navigate(['masterfile/exam', examId]);
  }

}
