<mat-card style="width: 100%;">
    <mat-card-header>
        <mat-card-title class="header-style">User Exam</mat-card-title>
        <span class="toolbar-item-spacer"></span>

        <div class="button-group">
            <div style="margin-bottom: 50px;">
                <button mat-icon-button color="primary" matTooltip="Approve" [disabled]="selection.isEmpty()"
                    *ngIf="!isAddMode && !isEditMode" (click)="handleApprovalStatusChange(1)" class="demo-button"
                    style="margin-bottom: 0px;">
                    <mat-icon class="icon-display"> thumb_up</mat-icon>
                </button>
                <button mat-icon-button color="warn" matTooltip="Disapprove" [disabled]="selection.isEmpty()"
                    *ngIf="!isAddMode && !isEditMode" (click)="handleApprovalStatusChange(2)" class="demo-button"
                    style="margin-bottom: 10px;">
                    <mat-icon class="icon-display"> thumb_down</mat-icon>
                </button>
                <button mat-icon-button color="accent" matTooltip="Add User Exam" [disabled]="selection.isEmpty()"
                    *ngIf="!isAddMode && !isEditMode" (click)="addUserExam()" class="demo-button"
                    style="margin-bottom: 10px;">
                    <mat-icon class="icon-display">library_add</mat-icon>
                </button>
                <button mat-icon-button color="warn" matTooltip="Delete" (click)="handleDeleteUser()"
                    [disabled]="selection.isEmpty()" style="margin-bottom: 10px;" *ngIf="!isAddMode && !isEditMode">
                    <mat-icon class="icon-display">delete</mat-icon>
                </button>
            </div>
        </div>
    </mat-card-header>

    <mat-tab-group mat-stretch-tabs="false" mat-align-tabs="start">
        <mat-tab label="Result">
            <div class="table-container">
                <mat-toolbar>
                    <mat-form-field *ngIf="!isAddMode && !isEditMode" style="margin-top: 20px;">
                        <mat-label>Filter</mat-label>
                        <input matInput (keyup)="applyFilter($event)" #input>
                    </mat-form-field>
                    <span class="toolbar-item-spacer"></span>

                    <!-- large screen -->
                    <div fxHide fxHide.xs="true" fxHide.gt-xs="false">
                        <button *ngIf="!isAddMode && !isEditMode" style="margin-right: 10px;" mat-flat-button
                            color="primary" matTooltip="Refresh data" (click)="refresh()">
                            <mat-icon>refresh</mat-icon>Refresh
                        </button>
                        <button *ngIf="!isAddMode && !isEditMode" mat-flat-button color="primary"
                            (click)="exportAction()" class="demo-button" style="margin-right: 10px;">
                            <mat-icon> insert_drive_file</mat-icon>CSV
                        </button>
                        <button *ngIf="!isAddMode && !isEditMode" mat-flat-button color="primary" (click)="addAction()"
                            class="demo-button" style="margin-left: 10px;">
                            Add data
                        </button>
                        <button mat-raised-button *ngIf="isAddMode || isEditMode" (click)="backAction()"
                            class="demo-button" style="margin-left: 10px;">
                            Back
                        </button>
                    </div>

                    <!-- Small Screen -->
                    <div fxShow fxShow.xs="true" fxShow.gt-xs="false">
                        <button *ngIf="!isAddMode && !isEditMode" class="refresh" mat-icon-button color="primary"
                            matTooltip="Refresh data" (click)="refresh()">
                            <mat-icon>refresh</mat-icon>
                        </button>
                        <button *ngIf="!isAddMode && !isEditMode" mat-icon-button color="primary"
                            matTooltip="Export Data" (click)="exportAction()" class="demo-button">
                            <mat-icon> insert_drive_file</mat-icon>
                        </button>
                        <button *ngIf="!isAddMode && !isEditMode" mat-icon-button color="primary" matTooltip="Add Data"
                            (click)="addAction()" class="demo-button">
                            <mat-icon> add_circle</mat-icon>
                        </button>
                        <button mat-raised-button *ngIf="isAddMode || isEditMode" (click)="backAction()"
                            class="demo-button" style="margin-left: 10px;">
                            Back
                        </button>
                    </div>
                </mat-toolbar>

                <div class="mat-elevation-z0" style="margin-top: 15px;" *ngIf="!isAddMode && !isEditMode">
                    <div class="table-container">
                        <table mat-table [dataSource]="dataSource" class="demo-table">
                            <ng-container matColumnDef="select">
                                <th mat-header-cell *matHeaderCellDef>
                                    <mat-checkbox (change)="$event ? toggleAllRows() : null"
                                        [checked]="selection.hasValue() && isAllSelected()"
                                        [indeterminate]="selection.hasValue() && !isAllSelected()"
                                        [aria-label]="checkboxLabel()">
                                    </mat-checkbox>
                                </th>
                                <td mat-cell *matCellDef="let row">
                                    <mat-checkbox (click)="$event.stopPropagation()"
                                        (change)="$event ? selection.toggle(row) : null"
                                        [checked]="selection.isSelected(row)" 
                                        [disabled]="!row.confirmed"
                                        [aria-label]="checkboxLabel(row)">
                                    </mat-checkbox>
                                </td>
                            </ng-container>

                            <ng-container matColumnDef="position">
                                <th mat-header-cell *matHeaderCellDef>ID</th>
                                <td mat-cell *matCellDef="let element">{{element.id}}</td>
                            </ng-container>

                            <ng-container matColumnDef="email">
                                <th mat-header-cell *matHeaderCellDef>Email/Username</th>
                                <td mat-cell *matCellDef="let element" (click)="goToExam(element.id)">
                                    <p class="email table-col">{{element.email}} </p>
                                </td>
                            </ng-container>
                            <ng-container matColumnDef="confirmed">
                                <th mat-header-cell *matHeaderCellDef>Confirmed</th>
                                <td mat-cell *matCellDef="let element" class="table-col">
                                    <p>{{element.confirmed == 1 ?'Yes':'No'}} </p>
                                </td>
                            </ng-container>
                            <ng-container matColumnDef="approved">
                                <th mat-header-cell *matHeaderCellDef>Approved</th>
                                <td mat-cell *matCellDef="let element" class="table-col">
                                    <p *ngIf="element.approved == 0">For Approval</p>
                                    <p *ngIf="element.approved != 0">{{element.approved == 1 ?'Yes':'No'}} </p>
                                </td>
                            </ng-container>
                            <ng-container matColumnDef="role">
                                <th mat-header-cell *matHeaderCellDef style="text-align: center;">Role</th>
                                <td mat-cell *matCellDef="let element" style="text-align: center;" class="table-col">
                                    <div class="dense-3">
                                        <mat-form-field style="width: 230px;padding-top: 10px">
                                            <mat-select [(value)]="element.role" required
                                                (selectionChange)="toggleUserRole($event, element.id)">
                                                <mat-option *ngFor="let item of role" [value]="item.id">{{ item.text
                                                    }}</mat-option>
                                            </mat-select>
                                        </mat-form-field>
                                    </div>
                                </td>

                            </ng-container>
                            <!-- Action Column -->
                            <ng-container matColumnDef="action">
                                <th mat-header-cell *matHeaderCellDef style="text-align: center;">Action</th>
                                <td mat-cell *matCellDef="let element" style="text-align: center;">
                                    <button mat-icon-button matTooltip="Approve" color="primary"
                                        *ngIf="element.approved === 0 && element.confirmed == 1" (click)="updateUserApproval([element.id],1)"
                                        class="demo-button">
                                        <mat-icon> thumb_up</mat-icon>
                                    </button>
                                    <button mat-icon-button matTooltip="Disapprove" color="warn"
                                        *ngIf="element.approved === 0 && element.confirmed == 1" (click)="updateUserApproval([element.id],2)"
                                        class="demo-button">
                                        <mat-icon> thumb_down</mat-icon>

                                    </button>
                                    <button mat-button color="primary" style="margin-bottom: 15px;"
                                        *ngIf="!isAddMode && !isEditMode" (click)="goToExam(element.id)"
                                        class="demo-button">
                                        Exam
                                    </button>
                                    <button mat-icon-button (click)="deleteUser([element.id])">
                                        <mat-icon color="warn">delete</mat-icon>
                                    </button>

                                </td>
                            </ng-container>

                            <tr mat-header-row *matHeaderRowDef="displayedColumns"></tr>
                            <tr mat-row *matRowDef="let row; columns: displayedColumns;"></tr>
                        </table>
                        <mat-paginator [pageSizeOptions]="[5, 10, 25, 100]" showFirstLastButtons
                            aria-label="Select page of users"></mat-paginator>
                    </div>
                </div>
            </div>

            <div class="add" *ngIf="isAddMode || isEditMode">
                <mat-card class="registration-card">
                    <mat-card-content class="formContent">
                        <form [formGroup]="dataForm" (ngSubmit)="onSubmit()" class="form">
                            <div fxLayout="column" fxLayout.gt-xs="row">
                                <mat-form-field appearance="outline" class="form-field">
                                    <mat-label>First name</mat-label>
                                    <input matInput formControlName="firstname" required>
                                    <mat-error *ngIf="checkError('firstname', 'required')">First name is
                                        required</mat-error>
                                </mat-form-field>

                                <mat-form-field appearance="outline" class="example-full-width form-field">
                                    <mat-label>Last name</mat-label>
                                    <input matInput formControlName="lastname" required>
                                    <mat-error *ngIf="checkError('lastname', 'required')">Last name is
                                        required</mat-error>
                                </mat-form-field>

                            </div>

                            <mat-form-field appearance="outline" class="example-full-width form-field">
                                <mat-label>Email</mat-label>
                                <input matInput placeholder="pat@example.com" formControlName="email" required>
                                <mat-error *ngIf="checkError('email', 'required')">Email is required</mat-error>
                                <mat-error *ngIf="checkError('email', 'email')">Email is invalid</mat-error>
                            </mat-form-field>
                            <mat-error class="emailLength" *ngIf="checkError('email', 'emailLengthExceeded')">Email
                                length exceeds
                                100 characters</mat-error>

                            <mat-form-field appearance="outline" class="example-full-width form-field">
                                <mat-label>Password</mat-label>
                                <input matInput formControlName="password" [type]="hide ? 'password' : 'text'">
                                <button mat-icon-button matSuffix (click)="hide = !hide"
                                    [attr.aria-label]="'Hide password'" [attr.aria-pressed]="hide">
                                    <mat-icon>{{hide ? 'visibility_off' : 'visibility'}}</mat-icon>
                                </button>
                            </mat-form-field>

                            <mat-toolbar style="background-color: white;margin-bottom: 20px;">
                                <div class="content" fxLayout="row" fxLayout.xs="column" fxFlexFill>
                                    <div fxFlex="80" fxFlex.xs="55">
                                        <section class="ng-section">
                                            <mat-checkbox formControlName="isVerified" class="ng-margin"
                                                [(ngModel)]="isVerified">Email Verified</mat-checkbox>
                                            <mat-checkbox formControlName="isAdmin" class="ng-margin"
                                                [(ngModel)]="isAdmin">Admin</mat-checkbox>
                                        </section>
                                    </div>
                                    <div fxFlex="20">
                                        <button style="margin-top: 12px;float: right;" mat-raised-button type="submit"
                                            color="primary" [disabled]="!dataForm.valid">Submit</button>
                                    </div>
                                </div>
                            </mat-toolbar>

                        </form>
                    </mat-card-content>
                </mat-card>

            </div>
        </mat-tab>
        <mat-tab label="Import User">
            <mat-card class="example-card">
                <mat-card-header>
                    <p class="template-link" (click)="exportUserTemplate()">Download Template</p>
                </mat-card-header>
                <mat-card-content>

                    <input type="file" style="display: none;" (click)="fileInput.value = null" #fileInput accept=".csv"
                        (change)="onChangeFileInput()" />
                    <mat-form-field appearance="outline" class="" style="width: 300px;">
                        <textarea placeholder="Browse" rows="1" matInput cdkTextareaAutosize type="text" readonly
                            [ngModel]="file?.name" (click)="onClickFileInputButton()"></textarea>
                        <button mat-icon-button matSuffix color="primary" class="file-select-button"
                            (click)="onClickFileInputButton()">
                            <mat-icon>attach_file</mat-icon>
                        </button>

                    </mat-form-field>
                    <button mat-raised-button style="margin-left: 10px;margin-bottom: 5px;"
                        [disabled]="file == null || file == undefined" (click)="importUser()">Upload</button>
                    <mat-divider></mat-divider>
                    <mat-progress-bar *ngIf="hasSubmit" mode="indeterminate"></mat-progress-bar>
                    <p class="instruction">
                        <span class="insTitle">Instructions: </span><br>
                        - Email: Ensure that each email is unique. Example: sampleemail@gmail.com<br>
                        - Status: Use 1 for active and 0 for inactive. Example: 1<br>
                        - Role: Assign '0' for the role of a student and '1' for an administrator. Example: 0
                    </p>
                </mat-card-content>

            </mat-card>
        </mat-tab>
        <mat-tab label="Audit Trail">
            <app-audit-trail-table [module]="6"></app-audit-trail-table>
        </mat-tab>
    </mat-tab-group>

</mat-card>