import { Injectable } from '@angular/core';
import { MatDialog, MatDialogRef } from '@angular/material/dialog';
import { DialogComponent } from '../components/dialog/dialog.component';

@Injectable({
  providedIn: 'root',
})
export class DialogService {
  constructor(private dialog: MatDialog) {}

  openDialog(
    title: string,
    content: string,
    button1Label?: string,
    yesButton?: boolean,
    onYesCallback?: (confirmed: boolean) => void
  ): MatDialogRef<DialogComponent> {
    const dialogRef = this.dialog.open(DialogComponent, {
      data: { title, content, button1Label, yesButton },
    });

    if (onYesCallback) {
      dialogRef.afterClosed().subscribe((result) => {
        if (result && result.confirmed) {
          onYesCallback(true);
        } else {
          onYesCallback(false);
        }
      });
    }

    return dialogRef;
  }
}
