import { Injectable } from '@angular/core';
import axios from 'axios';
import { HttpClient, HttpErrorResponse, HttpHeaders } from '@angular/common/http';
import { AWSS3Service } from './aws.s3.service';
import { Observable } from 'rxjs';
import { AppConstants } from '../app.constants';
import { AuthService } from './auth.service';
import { environment } from 'src/environments/environment';

@Injectable({
  providedIn: 'root',
})
export class PayModelService {
  // baseURL = 'https://api.virtuascript.net';
  baseURL = environment.apiUrl;

  // baseURL: `${environment.apiUrl}${req.url}`,
  user: any;
  constructor(
    private s3: AWSS3Service,
    private http: HttpClient,
    private constant: AppConstants,
    private authService: AuthService,

  ) {
    this.user = this.authService.getUser();
  }

  updateUserState() {
    this.user = this.authService.getUser();
  }

  async importCategory(key) {
    try {
      const getPresignedURL = await this.s3.generatePresignedURL(key, "getObject");
      const payload = { "signedURL": getPresignedURL, email: this.user.email };

      const response = await axios.post(`${this.baseURL}/masterfile/category/import`, payload,
        {
          headers: {
            "x-api-key": environment.apiKey
          }
        });
      return response;
    } catch (error) {
      throw { error: "Error while importing data: " + error };
    }
  }

  async getPayModel(data = {}): Promise<Observable<any>> {
    const response = await this.http.post("/account/pay-model", { ...data, sortBy: "name" })
    return response;
  }

  async addPayModel(params: any) {
    const response = await this.http.post("/account/pay-model/add", { ...params, email: this.user.email })
    return response;
  }

  async editPayModel(params: any) {
    const response = await this.http.post("/account/pay-model/update", { ...params, email: this.user.email })
    return response;
  }

  async deletePayModel(ids) {
    const response = await this.http.post("/account/pay-model/delete", { ids, email: this.user.email })
    return response;
  }

  async export(): Promise<Observable<any>> {
    const response = this.http.post("/account/pay-model/export", { email: this.user.email });
    return response;
  }

}
