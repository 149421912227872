import { Injectable } from '@angular/core';
import { HttpClient, HttpErrorResponse, HttpHeaders } from '@angular/common/http';
import { LoadingService } from './loading.service';
import { environment } from 'src/environments/environment';
import { AppConstants } from '../app.constants';
import axios from 'axios';
import { Subject } from 'rxjs/internal/Subject';
import { Router } from '@angular/router';
import { CookieService, CookieOptions } from 'ngx-cookie-service';
import { SessionService } from './session.service';
import { AuditTrailService } from './audit.trail.service';

@Injectable({
  providedIn: 'root'
})
export class AuthService {
  apiUrl = environment.apiUrl;
  private isAuthenticatedSubject = new Subject<boolean>();
  isAuthenticated$ = this.isAuthenticatedSubject.asObservable();

  constructor(
    private http: HttpClient,
    private loadingService: LoadingService,
    private router: Router,
    private cookieService: CookieService

  ) { }

  setTokenCookie(token: string, expiry: number) {
    this.cookieService.set('token', token, {
      expires: new Date(expiry),
      secure: true,
    });
  }

  getToken(): string {
    const token = this.cookieService.get('token');
    if (token) {
      return token;
    }

    return null;
  }

  login(email: string) {
    const data = {
      "email": email,
      "password": "12345678"
    }
    const response = this.http.post("/login", data)
    return response;
  }

  register(params: any) {
    const response = this.http.post('/auth/register', params)
    return response;
  }

  confirm(params: any) {
    const response = this.http.post('/auth/confirm-user', params)
    return response;
  }


  async emailRegistration(email) {
    const response = await this.http.post("/auth/register/email", { email })
    return response;
  }

  async validateRecaptchaToken(data: any) {
    const response = this.http.post('/auth/validate-recaptcha', data)
    return response;
  }

  validateGoogleToken(idToken: string) {
    const response = this.http.post('/auth/validate-token', { idToken })
    return response;
  }

  async validateUser(email: string) {
    const response = this.http.post('/auth/validate-user', { email })
    return response;
  }

  decodeJWT(accessToken) {
    let b64DecodeUnicode = str =>
      decodeURIComponent(
        Array.prototype.map.call(atob(str), c =>
          '%' + ('00' + c.charCodeAt(0).toString(16)).slice(-2)
        ).join(''))

    let parseJwt = token =>
      JSON.parse(
        b64DecodeUnicode(
          token.split('.')[1].replace('-', '+').replace('_', '/')
        )
      )

    return parseJwt(accessToken);
  }

  extractId(inputString) {
    const [idString] = inputString.split('.');
    return parseInt(idString);
  }

  getUser() {
    const user = localStorage.getItem("user");
    if (user) {
      return JSON.parse(user);
    } else {
      return null;
    }
  }

  getUserRole() {
    const user = localStorage.getItem("user");
    if (user) {
      return JSON.parse(user);
    } else {
      return null;
    }
  }

  async logOut() {
    localStorage.removeItem('user');
    this.cookieService.deleteAll('/');
    this.isAuthenticatedSubject.next(false);
    setTimeout(() => {
      window.location.reload();
    }, 1000);
  }

}
