import { Component, OnInit, ViewChild } from '@angular/core';
import { ExamService } from '../../service/exam.service';
import { Router, RouterModule } from '@angular/router';
import { NgFor } from '@angular/common';
import { MatListModule } from '@angular/material/list';
import { MatButtonModule } from '@angular/material/button';
import { MatCardModule } from '@angular/material/card';
import { MatDialog, MatDialogModule } from '@angular/material/dialog';
import { MatGridListModule } from '@angular/material/grid-list';
import { CommonModule } from '@angular/common';
import { MatSidenavModule } from '@angular/material/sidenav';
import { MatMenuModule } from '@angular/material/menu';
import { MatToolbarModule } from '@angular/material/toolbar';
import { MatIconModule } from '@angular/material/icon';
import { MatExpansionModule } from '@angular/material/expansion';
import { MatTooltipModule } from '@angular/material/tooltip';
import { MatTable, MatTableDataSource, MatTableModule } from '@angular/material/table';
import { MatPaginator, MatPaginatorModule } from '@angular/material/paginator';
import { Observable, catchError, from, throwError } from 'rxjs';
import { FormGroup, FormControl, Validators } from '@angular/forms';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { MatInputModule } from '@angular/material/input';
import { MatFormFieldModule } from '@angular/material/form-field';
import { MatSelectModule } from '@angular/material/select';
import { MatCheckboxModule } from '@angular/material/checkbox';
import { SnackbarService } from 'src/app/lib/snackbar/snackbar.service';
import { DialogService } from 'src/app/service/dialog.service';
import { MatAutocompleteModule } from '@angular/material/autocomplete';
import { MatTabsModule } from '@angular/material/tabs';
import { MatProgressBarModule } from '@angular/material/progress-bar';
import { AWSS3Service } from 'src/app/service/aws.s3.service';
import { UserService } from 'src/app/service/user.service';
import { AppConstants } from 'src/app/app.constants';
import { UserModel } from 'src/app/model/user';
import { ExamModel } from 'src/app/model/exam';
import { AuditTrailTableComponent } from "../../components/audit-trail-table/audit-trail-table.component";
import { AuditTrailService } from 'src/app/service/audit.trail.service';
import { MatChipsModule } from '@angular/material/chips';
import { AuthService } from 'src/app/service/auth.service';
import { SelectionModel } from '@angular/cdk/collections';
import { FlexLayoutModule } from "@angular/flex-layout";
import { AddUserExamModalComponent } from 'src/app/components/add-user-exam-modal/add-user-exam-modal.component';

@Component({
  selector: 'app-user-exam-file',
  templateUrl: './user-exam.component.html',
  styleUrls: ['./user-exam.component.css'],
  standalone: true,
  imports: [MatSidenavModule, FlexLayoutModule, MatChipsModule, FormsModule, MatCheckboxModule, MatSelectModule, ReactiveFormsModule, MatInputModule, MatFormFieldModule,
    MatPaginatorModule, MatTableModule, MatToolbarModule, MatMenuModule, MatIconModule, MatExpansionModule, MatTooltipModule,
    CommonModule, MatGridListModule, MatListModule, NgFor, MatCardModule, MatButtonModule, RouterModule, MatDialogModule, MatTabsModule, MatProgressBarModule, MatAutocompleteModule, AuditTrailTableComponent]
})
export class UserExamComponent {
  displayedColumns: string[] = ['select', 'position', 'email', 'confirmed', 'approved', 'role', 'action'];
  dataSource = new MatTableDataSource<ExamModel>;
  selection = new SelectionModel<any>(true, []);
  public dataForm: FormGroup;
  isAddMode: boolean = false;
  isEditMode: boolean = false;
  categories: any;
  pageSize: number = 5;
  hide = true;
  isVerified: boolean = true;;
  isAdmin: boolean = false;;
  @ViewChild(MatPaginator) paginator: MatPaginator;
  @ViewChild(MatTable) table: MatTable<ExamModel>;

  // Mass upload
  @ViewChild('fileInput')
  fileInput;
  file: File | null = null;
  hasSubmit: boolean = false;
  importedList: UserModel[];
  uploadCounter: number = 0;
  role = this.vars.role;
  ngAfterViewInit() {
    this.dataSource.paginator = this.paginator;
  }

  constructor(
    private router: Router, public dialog: MatDialog,
    private examService: ExamService,
    private snackbarService: SnackbarService,
    private dialogService: DialogService,
    private s3: AWSS3Service,
    private userService: UserService,
    private vars: AppConstants,
    private auditTrailService: AuditTrailService,
    private authService: AuthService,

  ) {
  }
  ngOnInit() {
    this.fetchData();
    this.dataForm = new FormGroup({
      email: new FormControl('', [Validators.required, Validators.email, this.emailLengthValidator]),
      firstname: new FormControl('', [Validators.required]),
      lastname: new FormControl('', [Validators.required]),
      password: new FormControl(''),
      isVerified: new FormControl(true),
      isAdmin: new FormControl(false),
    });
  }

  refresh() {
    this.fetchData();
    this.selection.clear();
  }
  emailLengthValidator(control: FormControl) {
    if (control.value && control.value.length > 100) {
      return { 'emailLengthExceeded': true };
    }
    return null;
  }

  isAllSelected(): boolean {
    const numSelected = this.selection.selected.length;
    const numRows = this.dataSource.data.filter(row => this.isRowConfirmed(row)).length;
    return numSelected === numRows;
  }
  
  isRowConfirmed(row: any): boolean {
    return row.confirmed == 1;
  }

  /** Selects all rows if they are not all selected; otherwise clear selection. */
  toggleAllRows(): void {
    const rowsToToggle = this.dataSource.data.filter(row => this.isRowConfirmed(row));
    const currentlySelected = this.selection.selected;
  
    if (this.isAllSelected()) {
      // Deselect only the rows that are currently selected
      const rowsToDeselect = currentlySelected.filter(row => this.isRowConfirmed(row));
      this.selection.deselect(...rowsToDeselect);
    } else {
      // Select only the rows that are not already selected and are not disabled
      const rowsToSelect = rowsToToggle.filter(row => !currentlySelected.includes(row));
      this.selection.select(...rowsToSelect);
    }
  }
  
  

  /** The label for the checkbox on the passed row */
  checkboxLabel(row?: any): string {
    if (!row) {
      return `${this.isAllSelected() ? 'deselect' : 'select'} all`;
    }
    return `${this.selection.isSelected(row) ? 'deselect' : 'select'} row ${row.position + 1}`;
  }

  public checkError = (controlName: string, errorName: string) => {
    return this.dataForm.controls[controlName].hasError(errorName);
  }

  applyFilter(event: Event) {
    const filterValue = (event.target as HTMLInputElement).value;
    this.dataSource.filter = filterValue.trim().toLowerCase();
  }

  async handleApprovalStatusChange(status) {

    if (this.selection.selected.length > 0) {
      const ids = this.selection.selected.map(item => item.id);
      await this.updateUserApproval(ids, status);
    }

  }

  async handleDeleteUser() {
    const ids = this.selection.selected.map(item => item.id)
    await this.deleteUser(ids);
  }

  addUserExam() {
    const ids = this.selection.selected.map(item => item.id)

    const dialogRef = this.dialog.open(AddUserExamModalComponent, {
      data: {
        userIds: ids
      }
    });
    dialogRef.afterClosed().subscribe(result => {
      if (result) {
        setTimeout(() => {
          this.selection.clear();
        }, 2000)
      }
    });
  }

  async fetchData() {
    (await this.userService.fetchUsers())
      .pipe(
        catchError(() => {
          return Observable.call(throwError(() => "Error"));
        })
      )
      .subscribe((response) => {
        const data = JSON.parse(JSON.stringify(response));

        // data.forEach((item) => {
        //   item.roleName = this.getRoleName(item.role)
        // })
        this.dataSource.data = data;
        this.paginator.pageSize = this.pageSize;
        this.dataSource.paginator = this.paginator;

      });
  }

  async deleteUser(ids) {
    this.dialogService.openDialog(this.vars.deleteDataMessage, this.vars.deleteUserConfirmation, 'No', true, async (confirmed) => {
      if (confirmed) {
        (await this.userService.deleteUser(ids))
          .subscribe((response) => {
            this.selection.clear();
            this.snackbarService.showSnackbar(response.toString(), 'top', 2000);
            this.fetchData();
            this.auditTrailService.sendNotification(true);
          });
      }
    });
  }

  async onSubmit() {
    if (this.dataForm.valid) {
      if (this.isAddMode) {
        (await this.userService.addUser(this.dataForm.value))
          .subscribe((response) => {
            this.snackbarService.showSnackbar(response.toString(), 'top', 2600);
            this.fetchData();
            this.auditTrailService.sendNotification(true);
            this.backAction();
          });
      }

    }
  }

  addAction() {
    this.isEditMode = false;
    this.isAddMode = true;
  }

  backAction() {
    this.isAddMode = false;
    this.isEditMode = false;
    this.dataForm.reset();
    this.dataForm.patchValue({
      isVerified: true,
      isAdmin: false
    });
    setTimeout(() => {
      this.paginator.pageSize = this.pageSize;
      this.dataSource.paginator = this.paginator;
    }, 0);
  }

  async deleteItem(data) {
    this.dialogService.openDialog('Delete Exam', 'Are you sure you want to delete this data?', 'No', true, async (confirmed) => {
      if (confirmed) {
        (await this.examService.deleteExam({ examId: data.id }))
          .subscribe((response) => {
            this.snackbarService.showSnackbar(response.toString(), 'top', 2000);
            this.fetchData();
            this.auditTrailService.sendNotification(true);
            this.isAddMode = false;
          });
      } else {
        console.log('Close');
      }
    });
  }

  goToExam(userId) {
    this.router.navigate(['masterfile/user-exam', userId]);
  }

  async updateUserApproval(ids, status) {
    const adEmail = this.authService.getUser().email;
    await (await this.userService.UpdateUser(ids, null, status, adEmail))
      .subscribe((response) => {
        this.selection.clear();
        this.snackbarService.showSnackbar(response.toString(), 'top', 2500);
        this.fetchData();
        this.auditTrailService.sendNotification(true);
      });
  }

  async toggleUserRole(event, id) {
    (await this.userService.toggleUserRole({ userId: id, role: event.value }))
      .subscribe((response) => {
        this.snackbarService.showSnackbar(response.toString(), 'top', 2500);
        this.fetchData();
        this.auditTrailService.sendNotification(true);
      });
  }

  async exportAction() {
    (await this.userService.exportUser())
      .pipe(
        catchError((err) => {
          return Observable.call(throwError(() => "Error"));
        })
      )
      .subscribe((response) => {
        const blob = new Blob([response], { type: 'text/csv' });
        const url = window.URL.createObjectURL(blob);
        const a = document.createElement('a');
        a.href = url;
        a.download = 'User.csv';
        a.click();
        window.URL.revokeObjectURL(url);
        this.auditTrailService.sendNotification(true);
      });
  }

  async massUpload() {
    let data = this.importedList[this.uploadCounter];
    const payload = {
      email: data.Email,
      isVerified: data.Status == '1' ? true : false,
      isAdmin: data.Role == '1' ? true : false,
      firstname: data.Firstname,
      lastname: data.Lastname,
    };

    (await this.userService.addUser(payload))
      .pipe(
        catchError((err) => {
          this.hasSubmit = false;
          this.snackbarService.showSnackbar("An error occured while adding user.", 'top', 3000);
          return Observable.call(throwError(() => "Error"));
        })
      )
      .subscribe((response) => {
        this.uploadCounter++;
        if (this.uploadCounter < this.importedList.length) {
          this.massUpload();
        } else {
          this.hasSubmit = false;
          this.file = null;
          this.uploadCounter = 0;
          this.snackbarService.showSnackbar("User/s created successfully", 'top', 3000);
          this.fetchData();
          this.auditTrailService.sendNotification(true);
        }
      });
  }

  async importUser() {
    this.hasSubmit = true;
    try {
      const key = await this.s3.uploadFile(this.file);
      const response = await this.userService.importUser(key);
      const data = JSON.parse(JSON.stringify(response.data));
      if (response.data.data == null) {
        this.snackbarService.showSnackbar(data.message, 'top', 0);
        this.hasSubmit = false;
        this.file = null;
      } else {
        this.importedList = response.data.data;
        this.massUpload();
      }
    } catch (error) {
      this.snackbarService.showSnackbar("An error occured while processing the request", 'top', 10000);
      this.file = null;
      this.hasSubmit = false;
    }
  }

  async exportUserTemplate() {
    (await this.userService.exportUserTemplate())
      .pipe(
        catchError((err) => {
          return Observable.call(throwError(() => "Error"));
        })
      )
      .subscribe((response) => {
        const blob = new Blob([response], { type: 'text/csv' });
        const url = window.URL.createObjectURL(blob);
        const a = document.createElement('a');
        a.href = url;
        a.download = 'User_template.csv';
        a.click();
        window.URL.revokeObjectURL(url);
        this.auditTrailService.sendNotification(true);
      });
  }

  onClickFileInputButton(): void {
    this.fileInput.nativeElement.click();
  }

  onChangeFileInput(): void {
    const files: { [key: string]: File } = this.fileInput.nativeElement.files;
    this.file = files[0];
  }

}
