<!-- <div class="content" fxLayout="row" fxLayout.xs="column" fxFlexFill>

    <div fxFlex="50" class="sec1" fxFlex.xs="55">
        first-section
    </div>
    <div fxFlex="50" class="sec2">
        second-section
    </div>
</div> -->

<div class="main-div">
    <mat-card style="border: 0; box-shadow: none;">
        <mat-card-header>
            <mat-card-title>Confirm Account</mat-card-title>

        </mat-card-header>
        <mat-card-content class="formContent">
            <form [formGroup]="dataForm" (ngSubmit)="onSubmit()" class="form">
                <mat-form-field appearance="outline" class="example-full-width form-field">
                    <mat-label>Email</mat-label>
                    <input matInput placeholder="pat@example.com" formControlName="username" required>
                    <mat-error *ngIf="checkError('username', 'required')">Email is required</mat-error>
                    <mat-error *ngIf="checkError('username', 'email')">Email is invalid</mat-error>
                </mat-form-field>

                <mat-form-field appearance="outline" class="example-full-width form-field">
                    <mat-label>Confirmation code</mat-label>
                    <input matInput formControlName="code" type="text">
                    <mat-error *ngIf="checkError('code', 'required')">Confirmation code is required</mat-error>

                </mat-form-field>

                <app-recaptcha [data]="recaptcha" (dataEvent)="receiveDataFromCaptcha($event)"></app-recaptcha>
                <mat-toolbar style="background-color: white;margin-bottom: 20px;">
                    <div class="content" fxLayout="row" fxLayout.xs="column" fxFlexFill>
                        <div fxFlex="80" fxFlex.xs="55">
                            <p class="data-protection" (click)="openDialog()">Read the data protection policy</p>
                        </div>
                        <div fxFlex="20" >
                            <button mat-raised-button type="submit" style="margin-top: 12px;float: right;" color="primary"
                                [disabled]="!dataForm.valid || recaptcha.expired">Confirm</button>
                        </div>
                    </div>
                </mat-toolbar>
            </form>
        </mat-card-content>
    </mat-card>

</div>


<!-- <div fxLayout="row" fxLayout.sm="column" fxLayout.xs="column" fxFlexFill>
    <div fxFlex="50" fxFlex.sm="70" fxFlex.xs="70" class="dashboard">asfsdh</div>
    <div fxFlex="50" fxFlex.sm="70" fxFlex.xs="70" class="dashboard">dashboard</div>
</div> -->