import { CommonModule } from '@angular/common';
import { Component, Input } from '@angular/core';
import { FlexLayoutModule } from '@angular/flex-layout';
import { FormControl, FormGroup, FormsModule, ReactiveFormsModule, Validators } from '@angular/forms';
import { MatButtonModule } from '@angular/material/button';
import { MatCardModule } from '@angular/material/card';
import { MatDialogModule } from '@angular/material/dialog';
import { MatFormFieldModule } from '@angular/material/form-field';
import { MatGridListModule } from '@angular/material/grid-list';
import { MatIconModule } from '@angular/material/icon';
import { MatInputModule } from '@angular/material/input';
import { MatMenuModule } from '@angular/material/menu';
import { MatPaginatorModule } from '@angular/material/paginator';
import { MatSelectModule } from '@angular/material/select';
import { MatToolbarModule } from '@angular/material/toolbar';
import { AuthService } from 'src/app/service/auth.service';
import { PayModelService } from '../service/pay.model.service';
import { Observable, catchError, throwError } from 'rxjs';
import { formatWithCommas } from '../common-function';
import { ActivatedRoute, Router } from '@angular/router';
import { AppConstants } from '../app.constants';
import { GroupService } from '../service/group.service';
import { SubscriptionService } from '../service/subscription.service';
import * as moment from 'moment';
import { SnackbarService } from '../lib/snackbar/snackbar.service';
import { UserService } from '../service/user.service';
import { MatChipInputEvent, MatChipsModule } from '@angular/material/chips';
import { ENTER, COMMA } from '@angular/cdk/keycodes';

export interface Admin {
  user: string;
  id: number;
  isNew: number;
}

@Component({
  selector: 'app-subscribe',
  templateUrl: './subscribe.component.html',
  styleUrls: ['./subscribe.component.css'],
  standalone: true,
  imports: [MatCardModule, FormsModule, MatChipsModule, MatDialogModule, ReactiveFormsModule, MatPaginatorModule, MatButtonModule, MatGridListModule, CommonModule, MatMenuModule, MatToolbarModule, MatIconModule, MatInputModule, MatFormFieldModule, MatSelectModule, MatCardModule, FlexLayoutModule],
})
export class SubscribeComponent {
  user: any;
  isAdmin: any;
  isAccountAdmin: any;
  dataSource: any;
  paginator: any;
  pageSize: any;
  listPlan: any[];
  planId: any;
  plan: any;
  type = this.constant.subscriptionType;
  expiry: any;
  public dataForm: FormGroup;
  accountAdmin: Admin[] = [];
  separatorKeysCodes: number[] = [ENTER, COMMA];
  hasError = {
    value: false,
    message: "Admin is required."
  }

  constructor(
    private authService: AuthService,
    private payModelService: PayModelService,
    private _Activatedroute: ActivatedRoute,
    private constant: AppConstants,
    private groupService: GroupService,
    private subscriptionService: SubscriptionService,
    private router: Router,
    private userService: UserService,

    private snackbarService: SnackbarService,

  ) {
  }
  ngOnInit() {
    this.planId = this._Activatedroute.snapshot.paramMap.get('id');
    this.fetchData();
    this.initUser();
    this.dataForm = new FormGroup({
      planId: new FormControl(''),
      selectedPlan: new FormControl('', [Validators.required, Validators.maxLength(50)]),
      name: new FormControl(`${this.user.given_name}'s Subscription Plan`, [Validators.required]),
      // accountAdmin: new FormControl(this.user.email, [Validators.required]),
      type: new FormControl(1, [Validators.required]),
      active: new FormControl(true),
      status: new FormControl(0),
      expiryDate: new FormControl(this.initExpirationDate())
    });
  }

  add(event: MatChipInputEvent): void {
    const input = event.input;
    const value = event.value;
    // this.user.email
    // Add 
    if ((value || '').trim()) {
      const user = { user: value.trim(), id: 0, isNew: 1 };
      this.accountAdmin.push(user);
      this.hasError.value = false;
    }

    // Reset the input value
    if (input) {
      input.value = '';
    }
  }

  remove(item: Admin): void {
    const index = this.accountAdmin.indexOf(item);

    if (index >= 0) {
      this.accountAdmin.splice(index, 1);
      // this.deletedAnswer.push(item.id);
      // console.log('deleted: ', this.deletedAnswer)
    }

    if (this.accountAdmin.length == 0) {
      this.hasError.value = true;
    }
  }

  adminEvent() {
    console.log('test')
  }

  edit(item: Admin, event: any): void {
    const input = event.input;
    const value = event.value;

    if (value.trim()) {
      // Edit 
      item.user = value.trim();
    }

    // Reset the input value
    if (input) {
      input.value = item.user;
    }
  }

  async initUser() {
    this.user = this.authService.getUser();
    if (this.user != null) {
      this.isAdmin = this.authService.extractId(this.user.azp) === 1;
      this.isAccountAdmin = this.authService.extractId(this.user.azp) === 2;
      this.accountAdmin = [{ id: 1, user: this.user.email, isNew: 1 }]
    }

  }

  initExpirationDate() {
    const oneYearFromNow = moment().add(1, 'year');
    this.expiry = moment(oneYearFromNow).utcOffset(0, true).format('YYYY-MM-DD');
    return moment(oneYearFromNow).format('ll');
  }

  public checkError = (controlName: string, errorName: string) => {
    return this.dataForm.controls[controlName].hasError(errorName);
  }

  async fetchData() {
    (await this.payModelService.getPayModel())
      .pipe(
        catchError(() => {
          return Observable.call(throwError(() => "Error"));
        })
      )
      .subscribe((response) => {
        const data = JSON.parse(JSON.stringify(response));
        data.forEach((item) => {
          item.total = formatWithCommas(item.total);
          item.percandidate = formatWithCommas(item.percandidate);
        })

        let plan = data.filter((item) => item.id == this.planId);
        this.plan = plan.at(0).name;
        this.dataForm.patchValue({
          selectedPlan: this.plan

        });
        this.listPlan = data;
      });
  }

  redirect() {
    this.router.navigate(['subscription-plan']);
  }

  async onSubmit() {
    const data = this.dataForm.value;

    if (this.accountAdmin.length == 0) {
      this.hasError.value = true;
      return;
    }

    if (data.type == 2) {
      this.addGroup();
    } else {
      // 1 groupid is individual no group
      this.addSubscription(1);
    }

  }

  async addSubscription(groupid) {
    const data = this.dataForm.value;
    const userId = this.authService.extractId(this.user.aud);
    const start = new Date().toISOString();
    const end = this.expiry;
    const payload = { model: { id: this.planId }, ownerId: userId, group: { id: groupid }, accountAdminList: this.accountAdmin, start: start, end: end, ...data };
    (await this.subscriptionService.addSubscription({ ...payload }))
      .subscribe((response) => {
        const data = JSON.parse(JSON.stringify(response));
        if (data.success) {
          this.snackbarService.showSnackbar(data.message, 'top', 2600);
          if (this.isAdmin) {
            this.router.navigate(['account/subscription']);
          } else if (this.isAccountAdmin) {
            this.router.navigate(['masterfile/exam-assignment']);
          } else {
            this.router.navigate(['exam-list/available']);
          }
        }else{
          this.snackbarService.showSnackbar(data.message, 'top', 2600);
        }
       
      });
  }


  async addGroup() {
    const form = this.dataForm.value;
    const userId = this.authService.extractId(this.user.aud);
    // let assigned = null;

    // if (this.user.email != form.accountAdmin) {
    //   assigned = form.accountAdmin;
    // }

    await (await this.groupService.addGroup(
      { name: `${this.user.given_name}'s Group`, groupAdmin: { id: userId }, active: true, accountAdminList: this.accountAdmin, group: 'subscription' }))
      .subscribe((response) => {
        const group = JSON.parse(JSON.stringify(response));
        if (group.success == true) {
          this.addSubscription(group.id);
        } else {
          this.snackbarService.showSnackbar(group.message, 'top', 2600);
        }
      });
  }

  isGroup() {
    return this.dataForm.value.type == 2;
  }
}


