import { Component, ViewChild } from '@angular/core';
import { MatDialog } from '@angular/material/dialog';
import { AuthService } from 'src/app/service/auth.service';
import { SubscriptionService } from 'src/app/service/subscription.service';
import { SwitchAccountModalComponent } from '../switch-account-modal/switch-account-modal.component';
import { MatTableDataSource } from '@angular/material/table';
import { AppConstants } from 'src/app/app.constants';
import { MatPaginator } from '@angular/material/paginator';

@Component({
  selector: 'app-user-profile',
  templateUrl: './user-profile.component.html',
  styleUrls: ['./user-profile.component.css']
})
export class UserProfileComponent {
  dataSource = new MatTableDataSource<any>;
  displayedColumns: string[] = ['position', 'name', 'remaining', 'type', 'status'];

  user: any;
  isAdmin: any;
  isAccountAdmin: any;
  subscriptionList: any[] = [];
  subscription: any;
  pageSize: number = 5;

  @ViewChild(MatPaginator) paginator: MatPaginator;

  constructor(
    private authService: AuthService,
    private subscriptionService: SubscriptionService,
    public dialog: MatDialog,
    private constant: AppConstants

  ) {
  }
  ngOnInit() {
    this.user = this.authService.getUser();
    this.user.id = this.authService.extractId(this.user.aud);
    this.isAdmin = this.authService.extractId(this.user.azp) === 1;
    this.isAccountAdmin = this.authService.extractId(this.user.azp) === 2;
    this.getSubscription(this.user.id);
  }

  ngAfterViewInit() {
    this.dataSource.paginator = this.paginator;
  }

  async getSubscription(userId) {
    await (await this.subscriptionService.getSubscription({ groupAdmin: userId }))
      .subscribe((response) => {
        const data = JSON.parse(JSON.stringify(response));

        // Map through the data to add the statusname based on the status value
        const statusMap = Object.keys(this.constant.subscriptionStatus).reduce((acc, key) => {
          acc[this.constant.subscriptionStatus[key]] = key;
          return acc;
        }, {});

        const updatedData = data.map(item => ({
          ...item,
          statusname: statusMap[item.status]
        }));

        this.subscription = updatedData.find(item => item.id == this.user.accountId);
        this.subscriptionList = updatedData;
        this.dataSource.data = updatedData;
        this.paginator.pageSize = this.pageSize;
      });
  }

  switchAccount() {
    const dialogRef = this.dialog.open(SwitchAccountModalComponent, {
      width: '850px',
      // height: '300px',
    });

    dialogRef.afterClosed().subscribe(result => {
      if (result) {
        this.user = this.authService.getUser();
      }
    });
  }


}
