import { HttpClient, HttpErrorResponse, HttpHeaders } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable, catchError, map, throwError } from 'rxjs';
import { AWSS3Service } from './aws.s3.service';
import { HttpAxiosService } from '../auth/auth.interceptor.axios';
import axios from 'axios';
import { AppConstants } from '../app.constants';
import { AuthService } from './auth.service';
import { environment } from 'src/environments/environment';


@Injectable({
  providedIn: 'root'
})
export class UserService {
  // baseURL = 'https://api.virtuascript.net';
  baseURL = environment.apiUrl;

  // baseURL: `${environment.apiUrl}${req.url}`,
  user: any;
  constructor(
    private http: HttpClient,
    private s3: AWSS3Service,
    private constant: AppConstants,
    private authService: AuthService,

  ) {
    this.user = this.authService.getUser();
  }

  updateUserState(){
    this.user = this.authService.getUser();
  }

  async fetchUsers(): Promise<Observable<any>> {
    const response = this.http.get<any[]>("/masterfile/user");
    return response;
  }

  async fetchUsersExam(params: any) {
    const response = await this.http.post("/masterfile/user/users-exam", {...params})
    return response;
  }

  async addUserExam(params: any) {
    const response = await this.http.post("/masterfile/user/exam", {...params, email: this.user.email})
    return response;
  }

  async UpdateUser(ids, username: string, approved: number, adEmail: string) {
    const response = await this.http.post("/masterfile/user/update", { ids, username, approved: approved, adEmail: adEmail })
    return response;
  }

  async deleteUserExam(params: any) {
    const response = await this.http.post("/masterfile/user/exam/delete", {...params, email: this.user.email})
    return response;
  }

  async toggleUserExam(params: any) {
    const response = await this.http.post("/masterfile/user/exam/toggle-status", {...params, email: this.user.email})
    return response;
  }

  async toggleRetake(params: any) {
    const response = await this.http.post("/masterfile/user/exam/toggle-retake", {...params, email: this.user.email})
    return response;
  }

  async getMasterUserExam(params: any): Promise<Observable<any>> {
    const response = await this.http.post("/masterfile/user/exam/get", { ...params })
    return response;
  }
  
  async exportUser(): Promise<Observable<any>> {
    const response = this.http.post("/masterfile/user/export", { email: this.user.email });
    return response;
  }

  async exportUserTemplate(): Promise<Observable<any>> {
    const response = this.http.post("/masterfile/user/export-template", { email: this.user.email });
    return response;
  }

  async importUser(key) {
    try {
      const getPresignedURL = await this.s3.generatePresignedURL(key, "getObject");
      const payload = { "signedURL": getPresignedURL };

      const response = await axios.post(`${this.baseURL}/masterfile/user/import`, payload,
        {
          headers: {
            "x-api-key": environment.apiKey
          }
        });
      return response;
    } catch (error) {
      throw { error: "Error while importing data: " + error };
    }
  }

  async addUser(params: any) {
    const response = await this.http.post("/masterfile/user/add", {...params, adEmail: this.user.email})
    return response;
  }

  async deleteUser(ids) {
    const response = await this.http.post("/masterfile/user/delete", { ids, adEmail: this.user.email })
    return response;
  }

  async toggleUserRole(params: any) {
    const response = await this.http.post("/masterfile/user/toggle-role", {...params, email: this.user.email})
    return response;
  }

}
