import { Component, OnInit, ViewChild } from '@angular/core';
import { Router, RouterModule } from '@angular/router';
import { NgFor } from '@angular/common';
import { MatListModule } from '@angular/material/list';
import { MatButtonModule } from '@angular/material/button';
import { MatCardModule } from '@angular/material/card';
import { MatDialog, MatDialogConfig, MatDialogModule } from '@angular/material/dialog';
import { MatGridListModule } from '@angular/material/grid-list';
import { CommonModule } from '@angular/common';
import { MatSidenavModule } from '@angular/material/sidenav';
import { MatMenuModule } from '@angular/material/menu';
import { MatToolbarModule } from '@angular/material/toolbar';
import { MatIconModule } from '@angular/material/icon';
import { MatExpansionModule } from '@angular/material/expansion';
import { MatTooltipModule } from '@angular/material/tooltip';
import { MatTable, MatTableDataSource, MatTableModule } from '@angular/material/table';
import { MatPaginator, MatPaginatorModule } from '@angular/material/paginator';
import { Observable, catchError, from, throwError } from 'rxjs';
import { FormGroup, FormControl, Validators } from '@angular/forms';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { MatInputModule } from '@angular/material/input';
import { MatFormFieldModule } from '@angular/material/form-field';
import { MatSelectModule } from '@angular/material/select';
import { MatCheckboxModule } from '@angular/material/checkbox';
import { SnackbarService } from 'src/app/lib/snackbar/snackbar.service';
import { DialogService } from 'src/app/service/dialog.service';
import { MatAutocompleteModule } from '@angular/material/autocomplete';
import { MatTabsModule } from '@angular/material/tabs';
import { MatProgressBarModule } from '@angular/material/progress-bar';
import { CategoryModel } from 'src/app/model/category';
import { AuditTrailService } from '../service/audit.trail.service';
import { AppConstants } from '../app.constants';
import * as moment from 'moment';
import { AuditTrailValuesComponent } from '../components/audit-trail-values/audit-trail-values.component';
import { AuditTrailTableComponent } from '../components/audit-trail-table/audit-trail-table.component';

@Component({
  selector: 'app-audit-trail',
  templateUrl: './audit-trail.component.html',
  styleUrls: ['./audit-trail.component.css'],
  standalone: true,
  imports: [MatSidenavModule, MatAutocompleteModule, MatTabsModule, FormsModule, MatProgressBarModule, MatCheckboxModule, MatSelectModule, ReactiveFormsModule, MatInputModule, MatFormFieldModule,
    MatPaginatorModule, MatTableModule, MatToolbarModule, MatMenuModule, MatIconModule, MatExpansionModule, MatTooltipModule,
    CommonModule, MatGridListModule, MatListModule, NgFor, MatCardModule, MatButtonModule, RouterModule, MatDialogModule, AuditTrailTableComponent]
})
export class AuditTrailComponent {

  displayedColumns: string[] = ['id', 'datetime', 'tablename', 'recordpk', 'user', 'actionname', 'module'];
  dataSource = new MatTableDataSource<any>;
  public dataForm: FormGroup;
  isAddMode: boolean = false;
  isEditMode: boolean = false;
  hasAction: any;
  categories: any;
  pageSize: number = 10;
  module: string = "";
  moduleList: any[];
  @ViewChild(MatPaginator) paginator: MatPaginator;
  @ViewChild(MatTable) table: MatTable<any>;

  // Mass upload
  @ViewChild('fileInput')
  fileInput;
  file: File | null = null;
  hasSubmit: boolean = false;

  ngAfterViewInit() {
    this.dataSource.paginator = this.paginator;
  }

  constructor(
    private router: Router, public dialog: MatDialog,
    private snackbarService: SnackbarService,
    private dialogService: DialogService,
    private constant: AppConstants,
    private auditTrailService: AuditTrailService,


  ) {
  }
  ngOnInit() {

  }

}

