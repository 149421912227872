<mat-card style="width: 100%;">
    <mat-toolbar style="background-color: transparent;">
        <mat-card-header>
            <mat-card-title class="header-style">Exam Assignment</mat-card-title>
            <mat-card-subtitle>Remaining Count ({{remaining}})</mat-card-subtitle>
        </mat-card-header>
        <span class="toolbar-item-spacer"></span>
        <button style="margin-right: 10px;" mat-flat-button color="primary" matTooltip="Refresh data"
            (click)="refresh()">
            <mat-icon>refresh</mat-icon>Refresh
        </button>
    </mat-toolbar>


    <div class="add">
        <div class="wrapper">
            <div id="one">
                <form [formGroup]="dataForm">
                    <mat-form-field appearance="outline" class="example-full-width form-field">
                        <mat-label>Exam</mat-label>
                        <input matInput (ngModelChange)="ngModelChange()" [formControl]="dataForm.get('examControl')"
                            [matAutocomplete]="categoryAuto">
                        <mat-autocomplete #categoryAuto="matAutocomplete" [displayWith]="displayCategory"
                            (optionSelected)="getUsers()">
                            <mat-option *ngFor="let category of filteredExam | async" [value]="category">
                                {{ category.name }}
                            </mat-option>
                        </mat-autocomplete>
                        <mat-error *ngIf="checkError('examControl', 'required')">Exam is
                            required</mat-error>
                    </mat-form-field>
                </form>
                <mat-card *ngIf="examDetails != null">
                    <mat-card-header>
                        <mat-card-subtitle>
                            Description
                        </mat-card-subtitle>
                    </mat-card-header>
                    <br>
                    <mat-card-content>
                        <p>{{examDetails.description}}</p>
                        <mat-divider></mat-divider><br>
                    </mat-card-content>
                    <div class="content" fxLayout="row" fxLayout.xs="column" fxFlexFill>
                        <div class="sec1" fxFlex fxFlex.xs>
                            <p>No. of Question: <span class="details">{{examDetails.noquestions}}</span></p>
                            <p>Duration: <span class="details">{{examDetails.maxtimetext}}</span></p>
                            <p>Difficulty: <span class="details">{{examDetails.level == 0? "Easy": "Hard"}}</span></p>
                        </div>
                        <div class="sec3" fxFlex fxFlex.xs>
                            <p *ngIf="examDetails.viewtype == 0">View as: <span class="details"
                                    style="color: green;">Public</span></p>
                            <p *ngIf="examDetails.viewtype == 1">View as: <span class="details"
                                    style="color: red;">Private</span></p>
                            <p *ngIf="examDetails.paid == 0">Type: <span class="details"
                                    style="color: green;">Free</span></p>
                            <p *ngIf="examDetails.paid == 1">Type: <span class="details" style="color: blue;">With
                                    Subscription</span></p>
                            <p>Group: <span class="details">{{user.group}}</span></p>
                        </div>

                    </div>
                </mat-card>
            </div>
            <div id="two">
                <div *ngIf="!isAddMode">
                    <mat-toolbar>
                        <span>User/s</span>
                        <span class="example-spacer"></span>
                        <div class="dense-2">
                            <mat-form-field appearance="outline" style="margin-top: 75px;">
                                <mat-label>Filter</mat-label>
                                <input matInput (keyup)="applyFilter($event)" #input>
                            </mat-form-field>
                        </div>
                        <button *ngIf="!isGroup" [disabled]="selectedExam == null" mat-flat-button color="primary"
                            type="button" (click)="addUser('')" class="demo-button">
                            Add User
                        </button>

                    </mat-toolbar>
                    <mat-card-content *ngIf="selectedExam != null">
                        <mat-divider></mat-divider>
                        <br>
                        <table mat-table [dataSource]="dataSource" class="demo-table">
                            <ng-container matColumnDef="select">
                                <th mat-header-cell *matHeaderCellDef>
                                    <mat-checkbox (change)="$event ? toggleAllRows() : null"
                                        [checked]="selection.hasValue() && isAllSelected() || !isGroup"
                                        [indeterminate]="selection.hasValue() && !isAllSelected()"
                                        [aria-label]="checkboxLabel()" [disabled]="remaining == 0 || !isGroup">
                                    </mat-checkbox>
                                </th>
                                <td mat-cell *matCellDef="let row">
                                    <mat-checkbox (click)="$event.stopPropagation()"
                                        (change)="$event ? selection.toggle(row) : null"
                                        [checked]="selection.isSelected(row) ||row.exam_count > 0"
                                        [aria-label]="checkboxLabel(row)"
                                        [disabled]="row.exam_count > 0 || remaining == 0 || !isGroup">
                                    </mat-checkbox>
                                </td>
                            </ng-container>
                            <ng-container matColumnDef="position">
                                <th mat-header-cell *matHeaderCellDef>User ID</th>
                                <td mat-cell *matCellDef="let element">{{element.userid}}</td>
                            </ng-container>

                            <ng-container matColumnDef="email">
                                <th mat-header-cell *matHeaderCellDef>Email</th>
                                <td mat-cell *matCellDef="let element">{{element.email}}</td>
                            </ng-container>

                            <ng-container matColumnDef="exam">
                                <th mat-header-cell *matHeaderCellDef>Has Exam</th>
                                <td mat-cell *matCellDef="let element">{{element.has_exam == 1? 'Yes': 'No'}}</td>
                            </ng-container>

                            <ng-container matColumnDef="count">
                                <th mat-header-cell *matHeaderCellDef>Assigned Count</th>
                                <td mat-cell *matCellDef="let element">{{element.exam_count}}</td>
                            </ng-container>

                            <!-- Action Column -->
                            <ng-container matColumnDef="action">
                                <th mat-header-cell *matHeaderCellDef style="text-align: center;">Action</th>
                                <td mat-cell *matCellDef="let element">

                                    <button mat-icon-button matTooltip="Reassign exam" color="primary"
                                        (click)="reassign(element)"
                                        [disabled]="element.can_assign == 0 || element.exam_count == 0 || remaining == 0">
                                        <mat-icon>assignment</mat-icon>
                                    </button>

                                    <button *ngIf="!isGroup" matSuffix mat-icon-button matTooltip="Exam Result"
                                        (click)="exportExamResult(element)">
                                        <mat-icon color="accent">insert_drive_file</mat-icon>
                                    </button>

                                    <button *ngIf="element.exam_count > 0" mat-icon-button  color="primary"
                                        matTooltip="View Result" (click)="viewResult(element)">
                                        <mat-icon>open_in_new</mat-icon>
                                    </button>
                                    <!-- 
                                    <button mat-icon-button (click)="delete([element.id])">
                                        <mat-icon color="warn">delete</mat-icon>
                                    </button> -->

                                </td>
                            </ng-container>
                            <tr mat-header-row *matHeaderRowDef="displayedColumns"></tr>
                            <tr mat-row *matRowDef="let row; columns: displayedColumns;"></tr>
                        </table>
                        <span *ngIf="users">
                            <p fxLayoutAlign="center" *ngIf="users.length == 0"><br>No user/s found</p>
                        </span>
                        <mat-paginator [pageSizeOptions]="[5, 10, 20]" showFirstLastButtons></mat-paginator>

                    </mat-card-content>
                    <mat-card-subtitle style="margin: 10px;margin-top: 10px;color: red;" *ngIf="selectedExam == null">
                        Please select an
                        exam</mat-card-subtitle>

                    <div fxShow fxShow.xs="true" fxShow.gt-xs="false">
                        <button mat-flat-button color="primary" style="float: right;" (click)="onSubmit()"
                            [disabled]="!dataForm.valid" class="demo-button">
                            Submit
                        </button>
                    </div>

                </div>

                <div *ngIf="isAddMode">
                    <mat-toolbar>
                        <span>User/s</span>
                        <span class="spacer"></span>
                        <button style="margin-right: 10px;" [disabled]="selectedExam == null" mat-flat-button
                            color="default" type="button" (click)="backAction()" class="demo-button">
                            Back
                        </button>
                        <button [disabled]="selectedExam == null" mat-flat-button color="primary" type="button"
                            (click)="addUser('')" class="demo-button">
                            Add User
                        </button>
                    </mat-toolbar>
                    <mat-card-content>
                        <mat-divider></mat-divider>
                        <br>
                        <mat-card-subtitle style="margin: 10px;margin-top: 10px;color: red;"
                            *ngIf="addedUsers.length == 0">
                            No user/s added</mat-card-subtitle>
                        <li *ngFor="let item of addedUsers; let i = index"
                            style="text-decoration-style: none; list-style: none;">
                            <mat-form-field appearance="outline" class="example-full-width">
                                <mat-label>Email</mat-label>
                                <!-- <textarea matInput [ngModelOptions]="{standalone: true}" rows="1" cdkTextareaAutosize
                                        [(ngModel)]="item.email" (ngModelChange)="dataChanged(item)" type="text"></textarea> -->
                                <input matInput cdkFocusInitial (ngModelChange)="dataChanged(item)"
                                    [readonly]="!item.isNew" [formControl]="userEmailControls[i]"
                                    placeholder="Enter Email" type="email">

                                <mat-error *ngIf="userEmailControls[i].hasError('email')">
                                    Please enter a valid email address.
                                </mat-error>
                                <mat-error *ngIf="userEmailControls[i].hasError('required')">
                                    Email is required
                                </mat-error>

                                <button *ngIf="item.isNew" color="warn" matSuffix mat-icon-button aria-label="Clear"
                                    (click)="removeUser(item, i)">
                                    <mat-icon>cancel</mat-icon>
                                </button>

                                <button *ngIf="!item.isNew" matSuffix mat-icon-button matTooltip="Exam Result"
                                    (click)="exportExamResult(item)">
                                    <mat-icon color="accent">insert_drive_file</mat-icon>
                                </button>
                            </mat-form-field>
                        </li>

                    </mat-card-content>

                    <div fxShow fxShow.xs="true" fxShow.gt-xs="false" *ngIf="isAddMode">
                        <button *ngIf="!isGroup && selectedExam != null" mat-flat-button color="primary"
                            style="float: right;" (click)="submitEmails()"
                            [disabled]="!dataForm.valid && addedUsers.length == 0" class="demo-button">
                            Submit Email/s
                        </button>
                    </div>
                </div>

            </div>
            <div fxHide fxHide.xs="true" fxHide.gt-xs="false">
                <mat-toolbar style="background-color: white;">
                    <span class="example-spacer"></span>
                    <button *ngIf="isGroup" mat-flat-button color="primary" (click)="onSubmit()"
                        [disabled]="selection.isEmpty()" class="demo-button">
                        Submit
                    </button>
                    <button *ngIf="isAddMode && selectedExam != null" mat-flat-button color="primary"
                        (click)="submitEmails()" [disabled]="!dataForm.valid && addedUsers.length == 0"
                        class="demo-button">
                        Submit Email/s
                    </button>
                </mat-toolbar>
            </div>

        </div>

    </div>

</mat-card>