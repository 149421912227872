<div>
    <div class="card">
        <h2>Examination Score Report</h2>
        <div class="result">
            <p><span style="font-weight: 500;">DATE:</span> {{data?.examdate | date:'yyyy-MM-dd'}}</p>
            <p><span style="font-weight: 500;">EXAM NUMBER:</span> {{data?.examnumber}}</p>
            <p><span style="font-weight: 500;">Start Time:</span> {{data?.starttime}}</p>
            <p><span style="font-weight: 500;">End Time:</span> {{data?.endtime}}</p>
            <p><span style="font-weight: 500;">Duration:</span> {{data?.duration}}</p>

            <span style="font-weight: 500;">Required Score:</span>
            <div class="progress">
                <div class="progress-required" [data-done]="data?.passrate" [style.width.%]="data?.passrate">
                    {{data?.passrate}}%
                </div>
            </div>

            <span style="font-weight: 500;">Your Score:</span>
            <div class="progress">
                <div class="progress-done" [data-done]="data?.percentage" [style.width.%]="data?.percentage">
                    {{data?.percentage}}%
                </div>
            </div>
           
              <div class="grid">
                <div class="item">
                    <h3><span style="font-weight: 500;">Passing Score:</span> {{data?.passrate}}/100</h3>
                </div>
                <div class="item">
                    <h3><span style="font-weight: 500;">Your Score:</span> {{data?.score}}/{{data?.maxscore}} </h3>
                </div>
                <div class="item">
                    <h3><span style="font-weight: 500;">Grade:</span> {{data?.grade}}</h3>
                </div>
              </div>

        </div>
    </div>
</div>