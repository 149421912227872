<h2 mat-dialog-title>Data Protection Policy</h2>
<mat-dialog-content class="mat-typography">
    <p>We are aware of our responsibility to protect your personal data. We act in accordance with the principles
        introduced by applicable legal regulations and the general principle on good faith in the processing of personal
        data.
    </p>

    <p>We determine clearly and precisely its legitimate and lawful purpose for processing personal data and process
        personal data to the extent necessary and in connection with the products and services we offer.
    </p>

    <p>We transfer your personal data in compliance with applicable laws and only when it is necessary. We take required
        technical and administrative measures to protect your personal data and also we ensure that Data Processors take
        administrative and technical measures regarding data security by signing non-disclosure agreements.
    </p>

</mat-dialog-content>
<mat-dialog-actions align="end">
    <button mat-button mat-dialog-close>Close</button>
</mat-dialog-actions>