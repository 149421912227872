<mat-dialog-actions align="end">
    <mat-icon mat-dialog-close class="close">close</mat-icon>
</mat-dialog-actions>
<div mat-dialog-content>
    <mat-card class="card">
        <form [formGroup]="dataForm">
        <mat-card-header fxLayoutAlign="center" style="margin-bottom: 20px;">
            <mat-card-title>Enter Subscription ID</mat-card-title>
        </mat-card-header>
            <mat-card-content>
                <mat-form-field style="width: 320px;" appearance="outline">
                    <input matInput formControlName="subscriptionCode">
                </mat-form-field>
            </mat-card-content>
            <mat-card-actions fxLayoutAlign="center">
                <button mat-flat-button color="primary" mat-dialog-close (click)="submit()">Submit</button>
            </mat-card-actions>
        </form>
    </mat-card>
</div>