import { FlatTreeControl } from '@angular/cdk/tree';
import { Component } from '@angular/core';
import { CommonModule } from '@angular/common';

import { MatTreeFlatDataSource, MatTreeFlattener, MatTreeModule } from '@angular/material/tree';
import { MatIconModule } from '@angular/material/icon';
import { MatButtonModule } from '@angular/material/button';
import { MatCardModule } from '@angular/material/card';
import { AppConstants } from '../app.constants';
import { SnackbarService } from '../lib/snackbar/snackbar.service';
import { CategoryService } from '../service/category.service';
import { DialogService } from '../service/dialog.service';
import { catchError, Observable, throwError } from 'rxjs';
import { FlexLayoutModule } from "@angular/flex-layout";
import { FormGroup, FormControl, Validators } from '@angular/forms';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { MatInputModule } from '@angular/material/input';
import { MatFormFieldModule } from '@angular/material/form-field';
import { MatSelectModule } from '@angular/material/select';
import { MatMenuModule } from '@angular/material/menu';
import { MatToolbarModule } from '@angular/material/toolbar';
import { MatGridListModule } from '@angular/material/grid-list';
import { MatListModule } from '@angular/material/list';
import { MatDividerModule } from '@angular/material/divider';
import { MatChipsModule } from '@angular/material/chips';
import { formatWithCommas, minutesToTime } from '../common-function';
import { ExamService } from '../service/exam.service';
import { getExamTypeTextById } from '../common-function';
import { MatPaginator, PageEvent, MatPaginatorModule } from '@angular/material/paginator';
import { MatSidenavModule } from '@angular/material/sidenav';
import { ExamComponent } from '../exam/exam.component';
import { ConfirmationDialog } from '../exam-list/exam-list.component';
import { MatDialog } from '@angular/material/dialog';
import { Router } from '@angular/router';
import { AuthService } from '../service/auth.service';
import { MatTooltipModule } from '@angular/material/tooltip';
import { UserService } from '../service/user.service';
import { SubscriptionService } from '../service/subscription.service';

interface FoodNode {
  name: string;
  children?: any[];
}

interface ExampleFlatNode {
  expandable: boolean;
  name: string;
  level: number;
}
export interface Tile {
  color: string;
  cols: number;
  rows: number;
  text: string;
}

@Component({
  selector: 'app-exam-catalogue',
  templateUrl: './exam-catalogue.component.html',
  styleUrls: ['./exam-catalogue.component.css'],
  standalone: true,
  imports: [MatTreeModule, MatTooltipModule, MatSidenavModule, MatListModule, FormsModule, ReactiveFormsModule, MatPaginatorModule, MatChipsModule, MatDividerModule, MatButtonModule, MatGridListModule, CommonModule, MatMenuModule, MatToolbarModule, MatIconModule, MatInputModule, MatFormFieldModule, MatSelectModule, MatCardModule, FlexLayoutModule],
})

export class ExamCatalogueComponent {
  tiles: Tile[] = [
    { text: 'One', cols: 1, rows: 1, color: 'lightblue' },
    { text: 'Two', cols: 1, rows: 1, color: 'lightgreen' },
    { text: 'Three', cols: 1, rows: 1, color: 'lightblue' },
    { text: 'Four', cols: 1, rows: 1, color: 'lightgreen' },
  ];
  examList: any[] = [];
  popularExam: any[] = [];
  pagedList: any[] = [];
  // MatPaginator Inputs
  length: number = 0;
  pageSize: number = 20;  //displaying three cards each row
  pageSizeOptions: number[] = [3, 6, 9, 12, 20];
  pageEvent: PageEvent;
  public dataForm: FormGroup;


  data: any[] = [];
  userExamList: any[] = [];
  pagination: any = {};
  currentPage = 1;
  search: "";
  totalItems = 0;
  totalPages = 0;
  user: any;
  userId = 0;
  searchItems: any[] = [];
  private _transformer = (node: any, level: number) => {
    return {
      expandable: level < 2 && !!node.children && node.children.length > 0, // Expand nodes at level 1
      name: node.name,
      level: level,
    };
  };

  treeControl = new FlatTreeControl<ExampleFlatNode>(
    node => node.level,
    node => node.expandable,
  );

  treeFlattener = new MatTreeFlattener(
    this._transformer,
    node => node.level,
    node => node.expandable,
    node => node.children,
  );

  dataSource = new MatTreeFlatDataSource(this.treeControl, this.treeFlattener);

  constructor(
    private categoryService: CategoryService,
    private snackbarService: SnackbarService,
    private dialogService: DialogService,
    private constant: AppConstants,
    private examService: ExamService,
    public dialog: MatDialog,
    private router: Router,
    private authService: AuthService,
    private userService: UserService,
    private subscriptionService: SubscriptionService

  ) {
  }

  ngOnInit() {
    this.dataForm = new FormGroup({
      search: new FormControl(''),
    });
    this.fetchStructuredCategory();
    this.fetchExamCatalogue("");
    this.fetchPopularExam();
    this.initUser();
    this.fetchUserExam()
  }

  refresh() {
    this.searchItems = [];
    this.fetchStructuredCategory();
    this.fetchExamCatalogue("");
    this.fetchPopularExam();
    this.initUser();
    this.fetchUserExam()
  }

  async initUser() {
    this.user = this.authService.getUser();
    this.userId = this.user != null ? this.authService.extractId(this.user.aud) : 0;

    // if (this.user != null) {
    //   this.isAdmin = this.authService.extractId(this.user.azp) === 1;
    //   this.isAccountAdmin = this.authService.extractId(this.user.azp) === 2;
    //   this.isGroup = this.user.group !== "Individual - No Group";
    // }
  }
  hasChild = (_: number, node: ExampleFlatNode) => node.expandable;
  redirect(examId: number, mode, userExamId) {
    this.router.navigate(['exam', examId, mode, userExamId]);
  }

  showStartButton(element: any): boolean {
    return element.isCompleted == null;
  }

  showRetakeButton(element: any): boolean {
    return element.retake == 2 && element.endtime != null;
  }

  showContinueButton(element: any): boolean {
    return element.isCompleted != null && element.endtime == null;
  }

  showDetailsButton(element: any): boolean {
    return element.isCompleted != null && element.endtime != null || element.sequence >= 1;
  }

  getExamMode(item) {
    if (this.showStartButton(item)) {
      return this.constant.examMode.Start;
    }

    if (this.showContinueButton(item)) {
      return this.constant.examMode.Continue;
    }

    if (this.showRetakeButton(item)) {
      return this.constant.examMode.Retake;
    }
  }

  async fetchRemainingCount(item, mode) {
    const payload = {
      accountId: this.user.accountId,
    }
    await (await this.subscriptionService.getRemainingCount(payload))
      .pipe(
        catchError(() => {
          return Observable.call(throwError(() => "Error"));
        })
      )
      .subscribe((response) => {
        if (response > 0) {
          this.startExam(item, mode, this.user.accountId);
        }
      });
  }

  // async paidModal() {
  //   if (this.user.accountId) {

  //   }
  //   this.dialogService.openDialog("Paid Exam", "Subscription is needed for  you to proceed. \nProceed to subscription?", 'No', true, async (confirmed) => {
  //     if (confirmed) {
  //       this.router.navigate(['subscription-plan']);
  //     }
  //   });
  // }

  validateSubscription(item, mode) {
    if (this.user.accountId) {
      this.fetchRemainingCount(item, mode);
    } else {
      this.dialogService.openDialog("Paid Exam", "Subscription is needed for  you to proceed. \nProceed to subscription?", 'No', true, async (confirmed) => {
        if (confirmed) {
          this.router.navigate(['subscription-plan']);
        }
      });
    }

  }

  async assignExam(examId, mode, accountId) {
    const payload = {
      isActive: true,
      examControl: { id: examId },
      userIds: [this.userId],
      accountId: accountId ? accountId : this.constant.noAccountId
    }

    await (await this.userService.addUserExam(payload))
      .subscribe((response) => {
        const data = JSON.parse(JSON.stringify(response));
        if (data.success) {
          this.redirect(examId, mode, data.id);
        }
      });
  }

  async validateExam(item, mode) {
    const exam = this.isExamAssigned(item.id);
    if (exam && item.paid == 1) {
      //assigned paid exam
      this.snackbarService.showSnackbar("Please proceed to your dashboard to take the exam.", 'top', 5000);
    } else if (exam && item.paid == 0) {
      //not assigned public exam
      await this.startExam(item, mode, null);
    } else if (!exam && item.paid == 1) {
      //not assigned paid exam
      this.validateSubscription(item, mode);
    } else {
      await this.startExam(item, mode, null);
    }

  }

  async startExam(item, mode, accountId) {
    const dialogRef = this.dialog.open(ConfirmationDialog);
    dialogRef.afterClosed().subscribe(async result => {
      if (result) {
        await (await this.examService.validateExam(item.id)).subscribe((response) => {
          if (response) {
            const exam = this.isExamAssigned(item.id);
            if (exam) {
              this.redirect(item.id, this.getExamMode(exam), exam.userexamid);
            } else {
              this.assignExam(item.id, mode, accountId);
            }
          } else {
            this.snackbarService.showSnackbar(this.constant.invalidExam, 'top', 10000);
          }
        },
          (errorMessage) => {
            this.snackbarService.showSnackbar(this.constant.invalidExam, 'top', 10000);
          }
        )
      }
    });
  }

  isExamAssigned(examId) {
    const exam = this.userExamList.find(item => item.id == examId)
    return exam;
  }

  async fetchUserExam() {
    await (await this.examService.fetchExam(this.userId))
      .subscribe(
        (response) => {
          const filteredList = response.filter(element => {
            return this.showStartButton(element) || this.showRetakeButton(element) || this.showContinueButton(element);
          });
          this.userExamList = filteredList;
        },
        (error) => {
          console.error('Request failed with error')
        })
  }

  async fetchStructuredCategory() {
    (await this.categoryService.getStructuredCategory())
      .pipe(
        catchError(() => {
          return Observable.call(throwError(() => "Error"));
        })
      )
      .subscribe((response) => {
        this.dataSource.data = response;

      });
  }

  async fetchPopularExam() {
    (await this.examService.getPopularExam())
      .pipe(
        catchError(() => {
          return Observable.call(throwError(() => "Error"));
        })
      )
      .subscribe((response) => {
        this.popularExam = response;
      });
  }
  displayDuration(hour, minute) {
    let text = "";
    let minuteText = (minute == 1) ? minute + " minute" : minute + " minutes";
    let hourText = (hour == 1) ? hour + " hour" : hour + " hours";

    if (hour == 0 && minute > 0) {
      text = minuteText;
    }

    if (minute == 0) {
      text = hourText;
    }
    if (minute > 0 && hour > 0) {
      text = `${hourText} and ${minuteText}`
    }

    return text;
  }

  splitTime(time) {
    let result = { hour: 0, minute: 0 };
    if (!(time == "" || time == null)) {
      const items = time.split(":");
      result.hour = parseInt(items[0]);
      result.minute = parseInt(items[1]);
    }
    return result;
  }

  // async fetchExam() {
  //   (await this.examService.getAllExam())
  //     .pipe(
  //       catchError(() => {
  //         return Observable.call(throwError(() => "Error"));
  //       })
  //     )
  //     .subscribe((response) => {
  //       const data = JSON.parse(JSON.stringify(response));

  //       data.forEach((item) => {
  //         const tags = item.tags.split(",");
  //         item.tagList = tags;
  //         item.level = (item.level == 1) ? "Hard" : "Easy";
  //         item.viewtype = (item.viewtype == 1) ? "Private" : "Public";
  //         item.examtype = getExamTypeTextById(item.examtype);
  //         item.price = formatWithCommas(item.price);
  //         item.maxtime = minutesToTime(item.maxtime);
  //         const time = this.splitTime(item.maxtime);
  //         if (!(time.hour == 0 && time.minute == 0)) {
  //           item.maxtimetext = this.displayDuration(time.hour, time.minute);
  //         } else {
  //           item.maxtimetext = "";
  //         }

  //         item.timequestion = minutesToTime(item.timequestion);
  //         const timeQuestion = this.splitTime(item.timequestion);
  //         if (!(timeQuestion.hour == 0 && timeQuestion.minute == 0)) {
  //           item.timequestiontext = this.displayDuration(timeQuestion.hour, timeQuestion.minute);
  //         }
  //       })
  //       this.examList = data;
  //       this.pagedList = this.examList.slice(0, 3);
  //       this.length = this.examList.length;
  //     });
  // }

  async fetchExamCatalogue(search) {
    const payload = {
      page: this.currentPage,
      pageSize: this.pageSize,
      search: search
    }

    await (await this.examService.getExamCatalogue(payload))
      .pipe(
        catchError(() => {
          return Observable.call(throwError(() => "Error"));
        })
      )
      .subscribe((response) => {
        const data = JSON.parse(JSON.stringify(response));

        data.rows.forEach((item) => {
          const tags = item.tags.split(",");
          item.tagList = tags;
          item.level = (item.level == 1) ? "Hard" : "Easy";
          item.viewtype = (item.viewtype == 1) ? "Private" : "Public";
          item.examtype = getExamTypeTextById(item.examtype);
          item.price = formatWithCommas(item.price);
          item.maxtime = minutesToTime(item.maxtime);
          const time = this.splitTime(item.maxtime);
          if (!(time.hour == 0 && time.minute == 0)) {
            item.maxtimetext = this.displayDuration(time.hour, time.minute);
          } else {
            item.maxtimetext = "";
          }

          item.timequestion = minutesToTime(item.timequestion);
          const timeQuestion = this.splitTime(item.timequestion);
          if (!(timeQuestion.hour == 0 && timeQuestion.minute == 0)) {
            item.timequestiontext = this.displayDuration(timeQuestion.hour, timeQuestion.minute);
          }
        })

        this.data = data.rows;
        this.pagination = data.pagination;
        this.setPaginationData(this.pagination)
      });
  }

  get startItem(): number {
    return (this.currentPage - 1) * this.pageSize + 1;
  }

  get endItem(): number {
    const endIndex = Math.min(this.currentPage * this.pageSize, this.totalItems);
    return endIndex;
  }

  nextPage(): void {
    if (this.currentPage < this.totalPages) {
      this.currentPage++;
    }
    this.onSearch("", true);

  }

  prevPage(): void {
    if (this.currentPage > 1) {
      this.currentPage--;
    }
    this.onSearch("", true);

  }

  setPaginationData(pagination: any): void {
    this.currentPage = pagination.page;
    this.pageSize = pagination.pageSize;
    this.totalItems = pagination.totalCount;
    this.totalPages = pagination.totalPages;
  }

  async onSearch(skills, isPaginate = false) {
    const search = this.dataForm.value.search;
    let searchVal = "";
    if (skills != "") {
      searchVal = skills;

    }

    if (!(search == null || search == "")) {
      searchVal = search;
    }

    if (!isPaginate) {
      if (!(searchVal == "" || searchVal == null)) {
        if (!this.searchItems.includes(searchVal)) {
          this.searchItems.push(searchVal)
        }
      }
    }

    this.fetchExamCatalogue(this.searchItems);

  }

  removeItem(index) {
    this.searchItems.splice(index, 1);
    const search = this.dataForm.value.search;
    if (search != null) {
      this.dataForm.reset()
    }
    this.onSearch("");
  }

  getPaginationArray(): number[] {
    return Array.from({ length: this.pagination.totalPages }, (_, i) => i + 1);
  }

  onPageChange(page: number): void {
    this.currentPage = page;
    this.onSearch("");
  }

}