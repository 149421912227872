<mat-card appearance="outlined">
    <mat-tab-group mat-stretch-tabs="false" mat-align-tabs="start">
        <mat-tab label="Exam">
            <div class="table-container">
                <mat-toolbar>
                    <mat-card-header *ngIf="user != null" style="padding: 0;margin-top: 20px;">
                        <div mat-card-avatar class="example-header-image">
                            <button routerLink="/masterfile/user-exam" mat-icon-button (click)="redirect()">
                                <mat-icon>keyboard_arrow_left</mat-icon>
                            </button>
                        </div>
                        <mat-card-title>User Exam</mat-card-title>
                        <mat-card-subtitle>{{email}}</mat-card-subtitle>
                    </mat-card-header>
                    <span class="toolbar-item-spacer"></span>
                    <button *ngIf="currentPath != 'user-exam'" mat-flat-button color="accent" class="demo-button" (click)="exportExamResult(null,0)">
                        <mat-icon color="white">insert_drive_file</mat-icon>
                        All Result
                    </button>
                </mat-toolbar>
                <mat-toolbar *ngIf="currentPath == 'user-exam'">
                    <mat-form-field style="margin-top: 20px;">
                        <mat-label>Filter</mat-label>
                        <input matInput (keyup)="applyFilter($event)" #input>
                    </mat-form-field>
                    <span class="toolbar-item-spacer"></span>
                    
                    <!-- large screen -->
                    <div fxHide fxHide.xs="true" fxHide.gt-xs="false">
                        <button style="margin-right: 10px;" mat-flat-button color="primary" matTooltip="Refresh data"
                        (click)="refresh()">
                        <mat-icon>refresh</mat-icon>Refresh
                    </button>
                        <button mat-flat-button color="primary" class="demo-button" (click)="openDialog()">
                            Add exam
                        </button>
                    </div>

                    <!-- Small Screen -->
                    <div fxShow fxShow.xs="true" fxShow.gt-xs="false">
                        <button class="refresh" mat-icon-button color="primary" matTooltip="Refresh data"
                        (click)="refresh()">
                        <mat-icon>refresh</mat-icon>
                    </button>
                        <button mat-icon-button color="primary" matTooltip="Add exam" (click)="openDialog()"
                            class="demo-button">
                            <mat-icon> add_circle</mat-icon>
                        </button>
                    </div>
                </mat-toolbar>

                <div class="mat-elevation-z0">
                    <table mat-table [dataSource]="dataSource" class="demo-table">
                        <ng-container matColumnDef="position">
                            <th mat-header-cell *matHeaderCellDef>No.</th>
                            <td mat-cell *matCellDef="let element">{{element.id}}</td>
                        </ng-container>

                        <ng-container matColumnDef="name">
                            <th mat-header-cell *matHeaderCellDef>Exam name</th>
                            <td mat-cell *matCellDef="let element">
                                <p class="name">{{element.exam_name}}</p>
                            </td>
                        </ng-container>

                        <ng-container matColumnDef="subscription">
                            <th mat-header-cell *matHeaderCellDef>Subscription</th>
                            <td mat-cell *matCellDef="let element">
                                <p class="name">{{element.account_name}}</p>
                            </td>
                        </ng-container>

                        <!-- Action Column -->
                        <ng-container matColumnDef="action" *ngIf="currentPath == 'user-exam'">
                            <th mat-header-cell *matHeaderCellDef style="text-align: center;">Action</th>
                            <td mat-cell *matCellDef="let element" style="text-align: center;">
                                <button style="margin-bottom: 15px;" mat-button color="primary"
                                    *ngIf="element.retake == 1" (click)="toggleRetake(element.id)">
                                    Retake
                                </button>
                                <button style="margin-bottom: 15px;" mat-button
                                    [color]="element.isActive ?'warn':'primary'"
                                    (click)="toggleUserExam(element.id, element.isActive)">
                                    {{element.isActive ? "Disable" : "Enable"}}
                                </button>
                                <button mat-icon-button (click)="deleteUserExam(element.id)">
                                    <mat-icon color="warn">delete</mat-icon>
                                </button>
                            </td>
                        </ng-container>

                        <ng-container matColumnDef="action" *ngIf="currentPath != 'user-exam'">
                            <th mat-header-cell *matHeaderCellDef style="text-align: center;">Action</th>
                            <td mat-cell *matCellDef="let element" style="text-align: center;">
                                <button mat-icon-button matTooltip="Exam Result" (click)="exportExamResult(element, 1)">
                                    <mat-icon color="accent">insert_drive_file</mat-icon>
                                </button>
                            </td>
                        </ng-container>

                        <tr mat-header-row *matHeaderRowDef="displayedColumns"></tr>
                        <tr mat-row *matRowDef="let row; columns: displayedColumns;"></tr>
                    </table>
                    <mat-card-content *ngIf="dataSource.data.length == 0">
                        <p style="margin-top: 10px;">No results found.</p>
                    </mat-card-content>
                    <mat-paginator [pageSizeOptions]="[5, 10, 25, 100]" showFirstLastButtons
                        aria-label="Select page of users"></mat-paginator>
                </div>
            </div>
        </mat-tab>
        <mat-tab label="Audit Trail" *ngIf="currentPath == 'user-exam'">
            <app-audit-trail-table [module]="7"></app-audit-trail-table>
        </mat-tab>
    </mat-tab-group>
</mat-card>