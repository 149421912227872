<mat-card style="width: 100%;">
    <mat-card-header style="padding: 0;" *ngIf="exam != null">
        <div mat-card-avatar class="example-header-image">
            <button routerLink="/masterfile/exam" mat-icon-button>
                <mat-icon>keyboard_arrow_left</mat-icon>
            </button>
        </div>
        <mat-card-title style="padding-top: 5px;left: 0;position: relative;">Question</mat-card-title>
        <mat-card-subtitle>{{exam.name}}</mat-card-subtitle>
    </mat-card-header>
    <mat-progress-bar *ngIf="hasSubmit" mode="indeterminate"></mat-progress-bar>
    <mat-tab-group mat-stretch-tabs="false" mat-align-tabs="start"
        (selectedIndexChange)="onSelectedIndexChange($event)">
        <mat-tab label="Result">
            <div class="table-container">
                <div class="demo-button-container">
                    <mat-toolbar>
                        <mat-form-field *ngIf="!isAddMode && !isEditMode" style="margin-top: 20px;">
                            <mat-label>Filter</mat-label>
                            <input matInput (keyup)="applyFilter($event)" #input>
                        </mat-form-field>
                        <span class="example-spacer"></span>
                        <!-- large screen -->
                        <div fxHide fxHide.xs="true" fxHide.gt-xs="false">
                            <button *ngIf="!isAddMode && !isEditMode" style="margin-right: 10px;" mat-flat-button
                                color="primary" matTooltip="Refresh data" (click)="refresh()">
                                <mat-icon>refresh</mat-icon>Refresh
                            </button>
                            <button *ngIf="!isAddMode && !isEditMode" mat-flat-button color="accent"
                                [disabled]="selection.isEmpty()" (click)="deleteHandler()" class="demo-button"
                                style="margin-right: 10px;">
                                <mat-icon>delete</mat-icon>Delete
                            </button>
                            <button *ngIf="!isAddMode && !isEditMode" mat-flat-button color="primary"
                                (click)="exportAction()" class="demo-button" style="margin-right: 10px;">
                                <mat-icon> insert_drive_file</mat-icon>CSV
                            </button>
                            <button *ngIf="!isAddMode && !isEditMode" mat-flat-button color="primary"
                                (click)="addAction()" class="demo-button" style="margin-left: 10px;">
                                Add data
                            </button>
                            <button mat-raised-button color="primary" *ngIf="isAddMode || isEditMode"
                                (click)="preview()" class="demo-button">
                                Preview
                            </button>
                            <button mat-raised-button *ngIf="isAddMode || isEditMode" (click)="backAction()"
                                class="demo-button" style="margin-left: 10px;">
                                Back
                            </button>
                        </div>

                        <!-- Small Screen -->
                        <div fxShow fxShow.xs="true" fxShow.gt-xs="false">
                            <button *ngIf="!isAddMode && !isEditMode" class="refresh" mat-icon-button color="primary"
                                matTooltip="Refresh data" (click)="refresh()">
                                <mat-icon>refresh</mat-icon>
                            </button>
                            <button mat-icon-button *ngIf="!isAddMode && !isEditMode" (click)="deleteHandler()"
                                matTooltip="Delete" [disabled]="selection.isEmpty()">
                                <mat-icon color="warn">delete</mat-icon>
                            </button>
                            <button *ngIf="!isAddMode && !isEditMode" mat-icon-button color="primary"
                                matTooltip="Export Data" (click)="exportAction()" class="demo-button">
                                <mat-icon> insert_drive_file</mat-icon>
                            </button>
                            <button *ngIf="!isAddMode && !isEditMode" mat-icon-button color="primary"
                                matTooltip="Add Data" (click)="addAction()" class="demo-button">
                                <mat-icon> add_circle</mat-icon>
                            </button>
                            <button mat-raised-button *ngIf="isAddMode || isEditMode" (click)="backAction()"
                                class="demo-button" style="margin-left: 10px;">
                                Back
                            </button>
                        </div>
                    </mat-toolbar>
                </div>
                <div class="mat-elevation-z0" style="margin-top: 15px;" *ngIf="!isAddMode && !isEditMode">
                    <div class="table-container">
                        <table mat-table [dataSource]="dataSource" class="demo-table">
                            <ng-container matColumnDef="select">
                                <th mat-header-cell *matHeaderCellDef>
                                    <mat-checkbox (change)="$event ? toggleAllRows() : null"
                                        [checked]="selection.hasValue() && isAllSelected()"
                                        [indeterminate]="selection.hasValue() && !isAllSelected()"
                                        [aria-label]="checkboxLabel()">
                                    </mat-checkbox>
                                </th>
                                <td mat-cell *matCellDef="let row">
                                    <mat-checkbox (click)="$event.stopPropagation()"
                                        (change)="$event ? selection.toggle(row) : null"
                                        [checked]="selection.isSelected(row)" [aria-label]="checkboxLabel(row)">
                                    </mat-checkbox>
                                </td>
                            </ng-container>
                            <ng-container matColumnDef="position">
                                <th mat-header-cell *matHeaderCellDef>ID</th>
                                <td mat-cell *matCellDef="let element">{{element.id}}</td>
                            </ng-container>

                            <ng-container matColumnDef="question">
                                <th mat-header-cell *matHeaderCellDef>Question</th>
                                <td mat-cell *matCellDef="let element" (click)="editItem(element)">
                                    <!-- <p class="name" style="cursor: pointer;">{{element.question}}</p> -->
                                    <div class="question-container" style="cursor: pointer;"
                                        [innerHTML]="element.question"></div>
                                    <!-- <div class="question-container" [innerHTML]="removeImageIfPresent(element.question)"></div> -->

                                </td>
                            </ng-container>

                            <ng-container matColumnDef="asnwerscount">
                                <th mat-header-cell *matHeaderCellDef style="text-align: center;">Answer/s count</th>
                                <td mat-cell *matCellDef="let element" style="text-align: center;">
                                    {{element.answercount}}
                                </td>
                            </ng-container>

                            <ng-container matColumnDef="correctcount">
                                <th mat-header-cell *matHeaderCellDef style="text-align: center;">Correct count</th>
                                <td mat-cell *matCellDef="let element" style="text-align: center;">
                                    {{element.correctcount}}
                                </td>
                            </ng-container>

                            <ng-container matColumnDef="isactive">
                                <th mat-header-cell *matHeaderCellDef>Active</th>
                                <td mat-cell *matCellDef="let element">{{element.active == 1 ? 'True': 'False'}}</td>
                            </ng-container>

                            <ng-container matColumnDef="category">
                                <th mat-header-cell *matHeaderCellDef>Category</th>
                                <td mat-cell *matCellDef="let element">{{element.categoryname}}</td>
                            </ng-container>

                            <ng-container matColumnDef="questiontype">
                                <th mat-header-cell *matHeaderCellDef>Test Type</th>
                                <td mat-cell *matCellDef="let element">{{element.questiontype}}</td>
                            </ng-container>
                            <!-- Action Column -->
                            <ng-container matColumnDef="action">
                                <th mat-header-cell *matHeaderCellDef style="text-align: center;">Action</th>
                                <td mat-cell *matCellDef="let element" style="text-align: center;">
                                    <button mat-icon-button (click)="editItem(element)">
                                        <mat-icon color="primary">edit</mat-icon>
                                    </button>
                                    <button mat-icon-button (click)="delete([element.id])">
                                        <mat-icon color="warn">delete</mat-icon>
                                    </button>
                                </td>
                            </ng-container>

                            <tr mat-header-row *matHeaderRowDef="displayedColumns"></tr>
                            <tr mat-row *matRowDef="let row; columns: displayedColumns;"></tr>

                        </table>
                        <h3 fxLayoutAlign="center" *ngIf="dataSource.data.length == 0"><br> No data to display!</h3>
                        <mat-paginator [pageSizeOptions]="[5, 10, 25, 100]" showFirstLastButtons
                            aria-label="Select page of users"></mat-paginator>
                    </div>
                </div>
            </div>

            <div class="add" *ngIf="isAddMode || isEditMode">

                <mat-card class="question-card">
                    <mat-card-content class="formContent">
                        <div>
                            <form [formGroup]="dataForm" onsubmit="submitHandler()">
                                <mat-form-field appearance="outline" class="example-full-width form-field">
                                    <mat-label>Question Type</mat-label>
                                    <mat-select formControlName="questionType" required
                                        (selectionChange)="validateType($event.value,null)">
                                        <mat-option *ngFor="let item of questionType" [value]="item.id">{{ item.text
                                            }}</mat-option>
                                    </mat-select>
                                    <mat-error *ngIf="checkError('questionType', 'required')">Question Type is
                                        required</mat-error>
                                </mat-form-field>


                                <mat-form-field *ngIf="questionHandler.isEssay" appearance="outline"
                                    class="example-full-width form-field">
                                    <mat-label>Topic</mat-label>
                                    <textarea required rows="3" cdkTextareaAutosize matInput
                                        formControlName="question"></textarea>
                                    <mat-error *ngIf="checkError('question', 'required')">Topic is
                                        required</mat-error>
                                    <mat-error *ngIf="checkError('question', 'minlength')">Topic should
                                        be
                                        more than
                                        5
                                        characters</mat-error>
                                </mat-form-field>

                                <div *ngIf="!questionHandler.isEssay">
                                    <p class="questionlabel" style="margin-top: 5px;">
                                        {{getQuestionLabel() + '*'}}
                                    </p>

                                    <quill-editor #questionEditor formControlName="question" style="width: 100%;"
                                        [placeholder]="'Enter '+getQuestionLabel() + ' here'"
                                        (onEditorChanged)="changedQuestionEditor($event)"
                                        (onEditorCreated)="questionEditorInstance($event)" [modules]="modules">
                                    </quill-editor>
                                    <mat-error *ngIf="checkError('question', 'required')">{{getQuestionLabel()}} is
                                        required</mat-error>
                                    <mat-error *ngIf="checkError('question', 'minlength')">{{getQuestionLabel()}} should
                                        be
                                        more than
                                        5
                                        characters</mat-error>
                                </div>
                                <mat-form-field appearance="outline" class="example-full-width form-field">
                                    <mat-label>Category</mat-label>
                                    <input matInput [formControl]="dataForm.get('categoryControl')"
                                        [matAutocomplete]="categoryAuto">
                                    <mat-autocomplete #categoryAuto="matAutocomplete" [displayWith]="displayCategory">
                                        <mat-option *ngFor="let category of filteredCategories | async"
                                            [value]="category">
                                            {{ category.name }}
                                        </mat-option>
                                    </mat-autocomplete>
                                    <mat-error *ngIf="checkError('categoryControl', 'required')">Category is
                                        required</mat-error>
                                </mat-form-field>

                                <!-- <mat-checkbox formControlName="isActive"
                                    style="margin-right: 10px;">Active</mat-checkbox> -->

                                <mat-form-field *ngIf="questionHandler.isText" appearance="outline"
                                    class="example-full-width form-field">
                                    <mat-label>Answer</mat-label>
                                    <mat-chip-grid #chipGrid aria-label="Enter answer">
                                        <mat-chip-row *ngFor="let item of answers" (removed)="remove(item)"
                                            [editable]="true" (edited)="edit(item, $event)"
                                            [aria-description]="'press enter to edit ' + item.answer">
                                            {{ item.answer }}
                                            <button matChipRemove [attr.aria-label]="'remove ' + item.answer">
                                                <mat-icon>cancel</mat-icon>
                                            </button>
                                        </mat-chip-row>
                                        <input placeholder="Enter answer" [matChipInputFor]="chipGrid"
                                            [matChipInputSeparatorKeyCodes]="separatorKeysCodes"
                                            [matChipInputAddOnBlur]="addOnBlur" (matChipInputTokenEnd)="add($event)" />
                                    </mat-chip-grid>
                                </mat-form-field>

                                <mat-form-field *ngIf="questionHandler.isNumber" floatLabel="always"
                                    appearance="outline" class="example-full-width form-field">
                                    <mat-label>Answer</mat-label>
                                    <input matInput type="text" required formControlName="numberAnswer"
                                        class="amount-right-align" placeholder="0" appCommaDelimiter>
                                    <mat-error *ngIf="checkError('numberAnswer', 'required')">Answer is
                                        required</mat-error>
                                </mat-form-field>



                                <div class="essay" *ngIf="questionHandler.isEssay">
                                    <!-- <mat-form-field appearance="outline" class="example-full-width form-field">
                                        <mat-label>Write the answer here</mat-label>
                                        <textarea required rows="3" cdkTextareaAutosize matInput
                                            formControlName="essay"></textarea>
                                        <mat-error *ngIf="checkError('essay', 'required')">Essay is
                                            required</mat-error>
                                        <mat-error *ngIf="checkError('essay', 'minlength')">
                                            Essay should be more than 5 characters</mat-error>
                                    </mat-form-field> -->

                                    <h3>
                                        <!-- <mat-checkbox formControlName="isActive"
                                            style="margin-right: 10px;"></mat-checkbox> -->

                                        <div fxLayout="column" fxLayout.gt-xs="row">
                                            <mat-form-field appearance="outline" class="form-field">
                                                <mat-label>Number of words</mat-label>
                                                <input matInput type="number" formControlName="wordCount" />
                                                <mat-error *ngIf="checkError('wordCount', 'required')">Number of words
                                                    is
                                                    required</mat-error>
                                            </mat-form-field>

                                            <mat-form-field appearance="outline" class="form-field">
                                                <mat-label>Passing Rate</mat-label>
                                                <input matInput type="number" formControlName="passRate" />
                                                <mat-error *ngIf="checkError('passRate', 'required')">Passing Rate is
                                                    required</mat-error>
                                            </mat-form-field>

                                            <mat-form-field appearance="outline" class="form-field">
                                                <mat-label>Maximum Points</mat-label>
                                                <input matInput type="number" formControlName="maxPoint" />
                                                <mat-error *ngIf="checkError('maxPoint', 'required')">Maximum Points is
                                                    required</mat-error>
                                            </mat-form-field>
                                        </div>

                                    </h3>

                                    <div class="metrics">
                                        <mat-toolbar>
                                            <p style="font-size: 16px;"
                                                [ngClass]="{'error-color': hasClickSubmit && metricsTotalScale != 100}">
                                                Evaluation Criteria</p>
                                            <span class="example-spacer"></span>
                                            <mat-chip [color]="scaleColor(metricsTotalScale)">{{metricsTotalScale}}%
                                                Total Scale</mat-chip>
                                        </mat-toolbar>
                                        <p class="essay-error" *ngIf="hasClickSubmit && metricsTotalScale != 100">
                                            Total scale must equal 100%
                                        </p>
                                        <mat-list>
                                            <mat-accordion class="criteria-headers-align">
                                                <mat-expansion-panel hideToggle
                                                    *ngFor="let metrics of assessmentMetrics; let i = index">
                                                    <mat-expansion-panel-header>
                                                        <mat-panel-title>
                                                            {{metrics.value}}
                                                        </mat-panel-title>
                                                        <mat-panel-description>
                                                            &nbsp;
                                                            <mat-form-field appearance="fill" style="padding-top: 5px;"
                                                                class="example-full-width scale dense-3"
                                                                *ngIf="metrics.selected">
                                                                <input matInput placeholder="Scale" appCommaDelimiter
                                                                    (change)="calculateMetricsScale()"
                                                                    (click)="$event.stopPropagation()" class="emp_id"
                                                                    [ngModelOptions]="{standalone: true}"
                                                                    [(ngModel)]="metrics.scale">
                                                            </mat-form-field>
                                                        </mat-panel-description>
                                                    </mat-expansion-panel-header>
                                                    <p [innerHTML]="metrics.formattedDetails"></p>
                                                </mat-expansion-panel>
                                            </mat-accordion>


                                            <!-- <mat-list-item *ngFor="let metrics of assessmentMetrics; let i = index">
                                                <mat-checkbox [checked]="metrics.selected" disabled class="option"
                                                    (change)="toggleMetrics(metrics, i)">
                                                    <span style="color: black;">{{metrics.value}}</span>
                                                </mat-checkbox>
                                                <mat-form-field appearance="" class="example-full-width scale dense-3"
                                                    *ngIf="metrics.selected">
                                                    <input matInput placeholder="Scale" appCommaDelimiter
                                                        (change)="calculateMetricsScale()"
                                                        [ngModelOptions]="{standalone: true}"
                                                        [(ngModel)]="metrics.scale">

                                                </mat-form-field>
                                            </mat-list-item> -->
                                        </mat-list>

                                        <!-- <li *ngFor="let metrics of assessmentMetrics; let i = index"
                                            class="option-list">
                                            <mat-checkbox [checked]="metrics.selected" class="option"
                                                (change)="toggleMetrics(metrics, i)">
                                                <span>{{metrics.name}}</span>
                                            </mat-checkbox>
                                            <mat-form-field appearance="outline" class="example-full-width scale dense-3"
                                                *ngIf="metrics.selected">
                                                <input matInput placeholder="Scale" appCommaDelimiter
                                                    (change)="calculateMetricsScale()"
                                                    [ngModelOptions]="{standalone: true}" [(ngModel)]="metrics.scale">
                                            </mat-form-field>
                                        </li> -->
                                    </div>
                                    <div class="purpose">
                                        <mat-toolbar>
                                            <p [ngClass]="{'error-color': hasClickSubmit && selectedPurpose == 0}"
                                                style="font-size: 16px;">Purpose of the Essay</p>
                                        </mat-toolbar>
                                        <p class="essay-error" *ngIf="hasClickSubmit && selectedPurpose == 0">
                                            Purpose of the Essay is required
                                        </p>
                                        <li *ngFor="let item of purpose; let i = index" class="option-list">
                                            <mat-radio-group [(ngModel)]="selectedPurpose"
                                                [ngModelOptions]="{standalone: true}">
                                                <mat-radio-button class="option" [value]="item.id">
                                                    <span>{{item.value}}</span>
                                                </mat-radio-button>
                                            </mat-radio-group>
                                        </li>
                                    </div>
                                    <div class="audience">
                                        <mat-toolbar>
                                            <p [ngClass]="{'error-color': hasClickSubmit && selectedAudience == 0}"
                                                style="font-size: 16px;">Intended Audience</p>
                                            <span class="example-spacer"></span>
                                        </mat-toolbar>
                                        <p class="essay-error" *ngIf="hasClickSubmit && selectedAudience == 0">
                                            Intended Audience is required
                                        </p>
                                        <li *ngFor="let item of audience; let i = index" class="option-list">
                                            <mat-radio-group [(ngModel)]="selectedAudience"
                                                [ngModelOptions]="{standalone: true}">
                                                <mat-radio-button class="option" [value]="item.id">
                                                    <span>{{item.value}}</span>
                                                </mat-radio-button>
                                            </mat-radio-group>
                                        </li>

                                    </div>
                                </div>
                            </form>
                        </div>

                        <!-- <div class="example-chip-list">
                            <mat-chip-grid #chipGrid aria-label="Enter answer">
                                <mat-chip-row *ngFor="let fruit of fruits" (removed)="remove(fruit)" [editable]="true"
                                    (edited)="edit(fruit, $event)"
                                    [aria-description]="'press enter to edit ' + fruit.name">
                                    {{ fruit.name }}
                                    <button matChipRemove [attr.aria-label]="'remove ' + fruit.name">
                                        <mat-icon>cancel</mat-icon>
                                    </button>
                                </mat-chip-row>
                                <input placeholder="Answer" [matChipInputFor]="chipGrid"
                                    [matChipInputSeparatorKeyCodes]="separatorKeysCodes"
                                    [matChipInputAddOnBlur]="addOnBlur" (matChipInputTokenEnd)="add($event)" />
                            </mat-chip-grid>
                        </div> -->



                        <div id="two" style="margin-top: 10px;"
                            *ngIf="questionHandler.isMultipleCorrect || questionHandler.isMultipleChoice">
                            <mat-toolbar>
                                <span>Answer/s</span>
                                <span class="example-spacer"></span>
                                <button mat-flat-button color="primary" type="button" (click)="addAnswer()"
                                    class="demo-button">
                                    Add Answer
                                </button>
                            </mat-toolbar>
                            <mat-card-content *ngIf="answerOptions.length > 0">
                                <mat-divider></mat-divider>
                                <br>
                                <div class="answers">
                                    <li *ngFor="let option of answerOptions; let i = index"
                                        style="text-decoration-style: none; list-style: none;">
                                        <div class="row">
                                            <div class="column left">
                                                <mat-checkbox [checked]="option.isChecked"
                                                    (change)="toggleAnswerCheck(option, i)">
                                                </mat-checkbox>
                                            </div>
                                            <div class="column middle">
                                                <quill-editor #editor [(ngModel)]="option.answer"
                                                    (onEditorChanged)="changedEditor($event, option)"
                                                    (onEditorCreated)="editorInstance($event, i)"
                                                    [modules]="option.modules"></quill-editor>
                                            </div>
                                            <div class="column right">
                                                <button color="warn" matTooltip="Remove" matSuffix mat-icon-button
                                                    aria-label="Clear" (click)="removeAnswer(option, i)">
                                                    <mat-icon>cancel</mat-icon>
                                                </button>
                                            </div>
                                        </div>
                                    </li>
                                </div>
                            </mat-card-content>


                            <mat-card-subtitle style="margin: 10px;" *ngIf="answerOptions.length == 0"> No answer
                                yet!</mat-card-subtitle>

                            <!-- <div fxShow fxShow.xs="true" fxShow.gt-xs="false">
                                <button mat-flat-button color="primary" style="float: right;" (click)="submitHandler()"
                                    [disabled]="!dataForm.valid" class="demo-button">
                                    Submit
                                </button>
                            </div> -->
                        </div>

                        <mat-error *ngIf="hasError.value">{{hasError.message}}</mat-error>

                        <div>
                            <mat-toolbar style="background-color: white;">

                                <span class="example-spacer"></span>
                                <button mat-flat-button color="primary" (click)="submitHandler()" class="demo-button">
                                    Submit
                                </button>
                            </mat-toolbar>
                        </div>
                    </mat-card-content>
                </mat-card>
            </div>
        </mat-tab>
        <mat-tab label="Upload Question/s">

            <mat-card class="example-card">
                <mat-card-header>
                    <p class="template-link" (click)="exportQuestionTemplate()">Download Template</p>
                    <!-- <mat-card-title>Shiba Inu</mat-card-title> -->
                </mat-card-header>
                <mat-card-content>
                    <input type="file" style="display: none;" (click)="fileInput.value = null" #fileInput accept=".csv"
                        (change)="onChangeFileInput()" />
                    <mat-form-field appearance="outline" class="" style="width: 300px;">
                        <textarea placeholder="Browse" rows="1" matInput cdkTextareaAutosize type="text" readonly
                            [ngModel]="file?.name" (click)="onClickFileInputButton()"></textarea>
                        <button mat-icon-button matSuffix color="primary" class="file-select-button"
                            (click)="onClickFileInputButton()">
                            <mat-icon>attach_file</mat-icon>
                        </button>

                    </mat-form-field>
                    <button mat-raised-button style="margin-left: 10px;margin-bottom: 5px;"
                        [disabled]="file == null || file == undefined" (click)="importQuestions()">Upload</button>
                    <mat-divider></mat-divider>
                    <p class="instruction">
                        <span class="insTitle">Instructions: </span><br>
                        - Question should be unique <br>
                        - Exam Duration in minutes format <br>
                        - Time Question in minutes format
                    </p>
                </mat-card-content>

                <mat-card-actions>

                </mat-card-actions>
            </mat-card>

        </mat-tab>
        <mat-tab label="Audit Trail">
            <!-- <app-audit-trail-table [module]="4"></app-audit-trail-table> -->
        </mat-tab>
    </mat-tab-group>

</mat-card>