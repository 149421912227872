import { Component, ElementRef, Input, OnInit, AfterViewInit } from '@angular/core';

@Component({
  selector: 'app-popover',
  templateUrl: './popover.component.html',
  styleUrls: ['./popover.component.css']
})
export class PopoverComponent implements OnInit, AfterViewInit {
  @Input() triggerElement: HTMLElement;
  @Input() content: string;

  constructor(public elRef: ElementRef) { }

  ngOnInit(): void { }

  ngAfterViewInit(): void {
    this.setPosition();
  }

  public setPosition(): void {
    if (this.triggerElement) {
      const rect = this.triggerElement.getBoundingClientRect();
      const popoverElement = this.elRef.nativeElement.querySelector('.popover-content');
      popoverElement.style.top = `${rect.bottom + window.scrollY}px`;
      popoverElement.style.left = `${rect.left + window.scrollX}px`;
    } else {
      // console.error('triggerElement is not defined.');
    }
  }
}
