import {Component, Inject, Input} from '@angular/core';
import {MatDialog, MAT_DIALOG_DATA, MatDialogRef, MatDialogModule} from '@angular/material/dialog';
import {NgIf} from '@angular/common';
import {MatButtonModule} from '@angular/material/button';

@Component({
  selector: 'app-dialog',
  templateUrl: './dialog.component.html',
  standalone: true,
  styleUrls: ['./dialog.component.css'],
  imports: [
    MatButtonModule,
    NgIf,
    MatDialogModule,
  ],
})
export class DialogComponent {
  @Input() title: string;
  @Input() content: string;

  constructor(
    public dialogRef: MatDialogRef<MatDialog>,
    @Inject(MAT_DIALOG_DATA) public data: any
  ) {}

  onYesClick(): void {
    this.dialogRef.close({ confirmed: true });
  }
  
  closeDialog(): void {
    this.dialogRef.close();
  }
}
