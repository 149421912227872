import { Directive, Input, HostListener } from '@angular/core';
import { NgModel } from '@angular/forms';

@Directive({
  selector: '[appMaxValue]'
})
export class MaxValueDirective {
  @Input() appMaxValue: number;

  constructor(private ngModel: NgModel) {}

  @HostListener('input', ['$event']) onInputChange(event: Event): void {
    const inputElement = event.target as HTMLInputElement;
    let value = Number(inputElement.value);

    if (value > this.appMaxValue) {
      value = this.appMaxValue;
      inputElement.value = value.toString();
      this.ngModel.update.emit(value);
    }
  }
}
