<mat-card style="margin: 0;border: 0;box-shadow: none;">
    <mat-card-header fxLayoutAlign="center">
        <mat-card-title>Your Subscription</mat-card-title>
    </mat-card-header>
    <mat-card-content class="formContent" style="margin-top: 20px;">
        <form [formGroup]="dataForm" (ngSubmit)="onSubmit()" class="form">
            <mat-form-field appearance="outline" class="example-full-width form-field" style="width: 320px;">
                <mat-label>Selected Plan</mat-label>
                <input matInput formControlName="selectedPlan" readonly>
            </mat-form-field>

            <div fxLayout="column" fxLayout.gt-xs="row">
                <mat-form-field appearance="outline" class="example-full-width form-field" style="width: 320px;">
                    <mat-label>Plan Name</mat-label>
                    <input matInput formControlName="name">
                    <mat-error *ngIf="checkError('name', 'required')">Plan Name is required</mat-error>
                </mat-form-field>
            </div>

            <div fxLayout="column" fxLayout.gt-xs="row">
                <!-- <mat-form-field appearance="outline" class="example-full-width form-field" style="width: 320px;">
                    <mat-label>Account admin</mat-label>

                    <input matInput formControlName="accountAdmin">
                    <mat-error *ngIf="checkError('accountAdmin', 'required')">Account admin is
                        required</mat-error>
                </mat-form-field> -->

                <mat-form-field appearance="outline" class="example-full-width form-field" floatLabel="always">
                    <mat-label>Account Admin*</mat-label>
                    <mat-chip-grid #chipGrid aria-label="Enter answer">
                        <mat-chip-row *ngFor="let item of accountAdmin" (removed)="remove(item)" [editable]="true"
                            (edited)="edit(item, $event)" [aria-description]="'press enter to edit ' + item.user">
                            {{ item.user }}
                            <button matChipRemove [attr.aria-label]="'remove ' + item.user">
                                <mat-icon>cancel</mat-icon>
                            </button>
                        </mat-chip-row>
                        <input placeholder="Enter email" (change)="adminEvent()" [matChipInputFor]="chipGrid"
                            [matChipInputSeparatorKeyCodes]="separatorKeysCodes" [matChipInputAddOnBlur]="true"
                            (matChipInputTokenEnd)="add($event)" />
                    </mat-chip-grid>
                    <p style="color: red;font-size: 13px;padding-top: 20px;" *ngIf="hasError.value">
                        {{hasError.message}}
                    </p>
                </mat-form-field>
            </div>
            <div>
                <mat-form-field style="width: 320px;" appearance="outline" class="example-full-width form-field">
                    <mat-label>Plan Type</mat-label>
                    <mat-select formControlName="type" required>
                        <mat-option *ngFor="let item of type" [value]="item.id">{{ item.text
                            }}</mat-option>
                    </mat-select>
                    <mat-error *ngIf="checkError('type', 'required')">Type is
                        required</mat-error>
                    <p *ngIf="isGroup()" style="color: red;font-size: 12px;font-style: italic;">Group type might incur
                        additional charges due to added functionality</p>

                </mat-form-field>

            </div>

            <div fxLayout="column" fxLayout.gt-xs="row">
                <mat-form-field appearance="outline" class="example-full-width form-field" style="width: 320px;">
                    <mat-label>Expiry Date</mat-label>
                    <input matInput formControlName="expiryDate" readonly>
                </mat-form-field>
            </div>

            <mat-toolbar style="background-color: white;margin-bottom: 20px;">
                <div class="content" fxLayout="row" fxLayout.xs="column" fxFlexFill>
                    <div fxFlex="100">

                        <button mat-raised-button type="submit" [disabled]="!dataForm.valid"
                            style="margin-right: 10px;float: right;" color="primary">Submit</button>
                        <button (click)="redirect()" mat-stroked-button class="demo-button"
                            style="float: right;margin-right: 10px;">
                            Back
                        </button>
                    </div>

                </div>
            </mat-toolbar>

        </form>
    </mat-card-content>
</mat-card>