<mat-toolbar class="breadcrumbs" *ngIf="path == 'Masterfile' || path == 'Account'">
    <span class="breadcrumb-span" *ngFor="let item of menuHeader; let indx = index">
        <a mat-button class="breadbutton" *ngIf="item.path == 'Masterfile'"
            [matMenuTriggerFor]="masterfileMenu">{{item.path}}</a>
            <a mat-button class="breadbutton" *ngIf="item.path == 'Account'"
            [matMenuTriggerFor]="accountMenu">{{item.path}}</a>
        <a mat-button class="breadbutton" *ngIf="!(item.path == 'Masterfile' || item.path == 'Account')"
            (click)="redirect(item.fullPath)">{{item.path}}</a>
        <mat-icon class="breaddiv" *ngIf="menuHeader.length - 1 != indx">keyboard_arrow_right</mat-icon>
    </span>
    <mat-menu #masterfileMenu [overlapTrigger]="false" yPosition="below">
        <button mat-menu-item (click)="redirect(item.path)" *ngFor="let item of masterfileRoutes;">
            <span>{{item.title}}</span>
        </button>
    </mat-menu>

    <mat-menu #accountMenu [overlapTrigger]="false" yPosition="below">
        <button mat-menu-item (click)="redirect(item.path)" *ngFor="let item of accountRoutes;">
            <span>{{item.title}}</span>
        </button>
    </mat-menu>
</mat-toolbar>