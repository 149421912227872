import { Injectable } from '@angular/core';
import {
    HttpInterceptor,
    HttpRequest,
    HttpHandler,
    HttpEvent,
    HttpResponse,
} from '@angular/common/http';
import { Observable, throwError, BehaviorSubject } from 'rxjs';
import { catchError, switchMap, finalize } from 'rxjs/operators';
import { AuthService } from '../service/auth.service';
import { LoadingService } from '../service/loading.service';
import { Router, NavigationEnd } from '@angular/router';
import { AppConstants } from '../app.constants';
import { SessionService } from '../service/session.service';
import { AuditTrailService } from '../service/audit.trail.service';
import { environment } from 'src/environments/environment';

@Injectable()
export class AuthInterceptor implements HttpInterceptor {
    private isRefreshing = false;
    private refreshTokenSubject: BehaviorSubject<any> = new BehaviorSubject<any>(
        null
    );
    private navigationEndSubject: BehaviorSubject<boolean> = new BehaviorSubject<boolean>(false);

    constructor(
        private authService: AuthService,
        private spinnerService: LoadingService,
        private router: Router,
        private constant: AppConstants,
        private sessionService: SessionService,
        private auditTrailService: AuditTrailService,

    ) {
        // Subscribe to Router events to set navigationEndSubject
        this.router.events.subscribe((event) => {
            if (event instanceof NavigationEnd) {
                this.navigationEndSubject.next(true);
            }
        });
    }

    intercept(
        req: HttpRequest<any>,
        next: HttpHandler
    ): Observable<HttpEvent<any>> {
        this.spinnerService.show(); // Show spinner when API request starts

        const token = this.authService.getToken();

        return next.handle(this.addToken(req, token)).pipe(
            catchError((error) => {
                if (error.status === 401 || error.status === 403 ||  error.status === 0) {
                    return this.handle401Error(req, next);
                } else {
                    return throwError(error);
                }
            }),
            finalize(() => {
                // Hide spinner in the finalize block
                this.spinnerService.hide();
            })
        );
    }

    private addToken(req: HttpRequest<any>, token: string): HttpRequest<any> {
        const url = `${environment.apiUrl}${req.url}`;

        // routes with api key
        const routesWithApiKey = ['/login', '/signup', '/confirm-user', '/masterfile/user-exam'];
        // const url = `https://api.virtuascript.net${req.url}`;
        const user = this.authService.getUser();
        const userId = user != null ? this.authService.extractId(user.aud) : 0;

        const headers = {
            Authorization: `Bearer ${userId}.${token}`,
            "x-api-key": environment.apiKey
        };

        const clonedRequest = req.clone({
            url: url,
            setHeaders: headers,
        });

        return clonedRequest;

    }

    private handle401Error(
        req: HttpRequest<any>,
        next: HttpHandler
    ): Observable<HttpEvent<any>> {
        const user = this.authService.getUser();
        
        if (!this.isRefreshing) {
            this.isRefreshing = true;
            this.refreshTokenSubject.next(null);

            return this.authService.login(user.email).pipe(
                switchMap((response: any) => {
                    if (response && response.accessToken) {
                        const expiry = this.sessionService.getExpiryTimeStamp();
                        this.authService.setTokenCookie(response.accessToken, expiry);
                        this.isRefreshing = false;
                        this.refreshTokenSubject.next(response.accessToken);
                        return next.handle(this.addToken(req, response.accessToken));
                    } else {
                        this.isRefreshing = false;
                        this.spinnerService.hide();
                        this.authService.logOut();
                        this.router.navigate(['login']);
                        return throwError("Invalid token refresh response");
                    }
                }),
                catchError((error) => {
                    this.isRefreshing = false;
                    this.spinnerService.hide();
                    return throwError(error);
                })
            );
        } else {
            return this.refreshTokenSubject.pipe(
                switchMap((token) => {
                    return next.handle(this.addToken(req, token));
                })
            );
        }
    }
}
