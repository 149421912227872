<mat-card style="width: 100%;">
    <mat-card-header>
        <mat-card-title class="header-style">Subscription</mat-card-title>
        <span class="toolbar-item-spacer"></span>

        <div class="button-group">
            <div style="margin-bottom: 50px;">
                <button mat-icon-button color="primary" matTooltip="Approve" [disabled]="selection.isEmpty()"
                    *ngIf="!isAddMode && !isEditMode" (click)="handleApprovalStatusChange(1)" class="demo-button"
                    style="margin-bottom: 0px;">
                    <mat-icon class="icon-display"> thumb_up</mat-icon>
                </button>
                <button mat-icon-button color="warn" matTooltip="Disapprove" [disabled]="selection.isEmpty()"
                    *ngIf="!isAddMode && !isEditMode" (click)="handleApprovalStatusChange(2)" class="demo-button"
                    style="margin-bottom: 10px;">
                    <mat-icon class="icon-display"> thumb_down</mat-icon>
                </button>
                <button mat-icon-button color="warn" matTooltip="Delete" (click)="deleteHandler()"
                    [disabled]="selection.isEmpty()" style="margin-bottom: 10px;" *ngIf="!isAddMode && !isEditMode">
                    <mat-icon class="icon-display">delete</mat-icon>
                </button>
            </div>
        </div>
    </mat-card-header>

    <mat-tab-group mat-stretch-tabs="false" mat-align-tabs="start"
        (selectedIndexChange)="onSelectedIndexChange($event)">
        <mat-tab label="Result">
            <div class="table-container">
                <mat-toolbar>
                    <mat-form-field *ngIf="!isAddMode && !isEditMode" style="margin-top: 20px;">
                        <mat-label>Filter</mat-label>
                        <input matInput (keyup)="applyFilter($event)" #input>
                    </mat-form-field>
                    <span class="toolbar-item-spacer"></span>
                    <!-- large screen -->
                    <div fxHide fxHide.xs="true" fxHide.gt-xs="false">
                        <button *ngIf="!isAddMode && !isEditMode" style="margin-right: 10px;" mat-flat-button color="primary" matTooltip="Refresh data"
                        (click)="refresh()">
                        <mat-icon>refresh</mat-icon>Refresh
                      </button>
                        <button *ngIf="!isAddMode && !isEditMode" mat-flat-button color="primary"
                            (click)="exportAction()" class="demo-button" style="margin-right: 10px;">
                            <mat-icon> insert_drive_file</mat-icon>CSV
                        </button>
                        <button *ngIf="!isAddMode && !isEditMode" mat-flat-button color="primary" (click)="addAction()"
                            class="demo-button" style="margin-left: 10px;">
                            Add data
                        </button>
                        <button mat-raised-button *ngIf="isAddMode || isEditMode" (click)="backAction()"
                            class="demo-button" style="margin-left: 10px;">
                            Back
                        </button>
                    </div>

                    <!-- Small Screen -->
                    <div fxShow fxShow.xs="true" fxShow.gt-xs="false">
                        <button *ngIf="!isAddMode && !isEditMode" class="refresh" mat-icon-button color="primary" matTooltip="Refresh data" (click)="refresh()">
                            <mat-icon>refresh</mat-icon>
                          </button>
                        <button mat-icon-button *ngIf="!isAddMode && !isEditMode" (click)="deleteHandler()"
                            matTooltip="Delete" [disabled]="selection.isEmpty()">
                            <mat-icon color="warn">delete</mat-icon>
                        </button>
                        <button *ngIf="!isAddMode && !isEditMode" mat-icon-button color="primary"
                            matTooltip="Export Data" (click)="exportAction()" class="demo-button">
                            <mat-icon> insert_drive_file</mat-icon>
                        </button>
                        <button *ngIf="!isAddMode && !isEditMode" mat-icon-button color="primary" matTooltip="Add Data"
                            (click)="addAction()" class="demo-button">
                            <mat-icon> add_circle</mat-icon>
                        </button>
                        <button mat-raised-button *ngIf="isAddMode || isEditMode" (click)="backAction()"
                            class="demo-button" style="margin-left: 10px;">
                            Back
                        </button>
                    </div>

                </mat-toolbar>

                <div class="mat-elevation-z0 table-container" style="margin-top: 15px;"
                    *ngIf="!isAddMode && !isEditMode">
                    <table mat-table [dataSource]="dataSource" class="demo-table">
                        <ng-container matColumnDef="select">
                            <th mat-header-cell *matHeaderCellDef>
                                <mat-checkbox (change)="$event ? toggleAllRows() : null"
                                    [checked]="selection.hasValue() && isAllSelected()"
                                    [indeterminate]="selection.hasValue() && !isAllSelected()"
                                    [aria-label]="checkboxLabel()">
                                </mat-checkbox>
                            </th>
                            <td mat-cell *matCellDef="let row">
                                <mat-checkbox (click)="$event.stopPropagation()"
                                    (change)="$event ? selection.toggle(row) : null"
                                    [checked]="selection.isSelected(row)" [aria-label]="checkboxLabel(row)">
                                </mat-checkbox>
                            </td>
                        </ng-container>
                        <ng-container matColumnDef="position">
                            <th mat-header-cell *matHeaderCellDef>ID</th>
                            <td mat-cell *matCellDef="let element">{{element.id}}</td>
                        </ng-container>

                        <ng-container matColumnDef="name">
                            <th mat-header-cell *matHeaderCellDef>Account Name</th>
                            <td mat-cell *matCellDef="let element">{{element.name}}</td>
                        </ng-container>

                        <ng-container matColumnDef="subscriptionid">
                            <th mat-header-cell *matHeaderCellDef>Subscription ID</th>
                            <td mat-cell *matCellDef="let element">{{element.subscriptionid}}</td>
                        </ng-container>

                        <ng-container matColumnDef="model">
                            <th mat-header-cell *matHeaderCellDef>Model</th>
                            <td mat-cell *matCellDef="let element">{{element.model_name}}</td>
                        </ng-container>

                        <ng-container matColumnDef="group">
                            <th mat-header-cell *matHeaderCellDef>Group</th>
                            <td mat-cell *matCellDef="let element">{{element.group_name}}</td>
                        </ng-container>
                        <ng-container matColumnDef="groupadmin">
                            <th mat-header-cell *matHeaderCellDef>Group Admin</th>
                            <td mat-cell *matCellDef="let element" [innerHTML]="element.group_admin_emails"></td>
                        </ng-container>

                        <ng-container matColumnDef="type">
                            <th mat-header-cell *matHeaderCellDef>Type</th>
                            <td mat-cell *matCellDef="let element">{{element.type_name}}</td>
                        </ng-container>

                        <ng-container matColumnDef="start">
                            <th mat-header-cell *matHeaderCellDef>Start</th>
                            <td mat-cell *matCellDef="let element">{{element.startdate}}</td>
                        </ng-container>

                        <ng-container matColumnDef="end">
                            <th mat-header-cell *matHeaderCellDef>End</th>
                            <td mat-cell *matCellDef="let element">{{element.enddate}}</td>
                        </ng-container>

                        <ng-container matColumnDef="status">
                            <th mat-header-cell *matHeaderCellDef>Status</th>
                            <td mat-cell *matCellDef="let element">{{element.statusName}}</td>
                        </ng-container>

                        <!-- Action Column -->
                        <ng-container matColumnDef="action">
                            <th mat-header-cell *matHeaderCellDef style="text-align: center;">Action</th>
                            <td mat-cell *matCellDef="let element" style="text-align: center;">
                                <button mat-icon-button (click)="editItem(element)">
                                    <mat-icon color="primary">edit</mat-icon>
                                </button>
                                <button mat-icon-button (click)="delete([element.id])">
                                    <mat-icon color="warn">delete</mat-icon>
                                </button>
                            </td>
                        </ng-container>

                        <tr mat-header-row *matHeaderRowDef="displayedColumns"></tr>
                        <tr mat-row *matRowDef="let row; columns: displayedColumns;"></tr>
                    </table>
                    <mat-paginator [pageSizeOptions]="[5, 10, 20]" showFirstLastButtons></mat-paginator>

                </div>
            </div>

            <div class="add" *ngIf="isAddMode || isEditMode">
                <mat-card style="margin: 0;border: 0;box-shadow: none;">
                    <mat-card-content class="formContent">
                        <form [formGroup]="dataForm" (ngSubmit)="onSubmit()" class="form">

                            <div fxLayout="column" fxLayout.gt-xs="row">
                                <mat-form-field appearance="outline" class="example-full-width form-field"
                                    style="width: 320px;">
                                    <mat-label>Account Name</mat-label>
                                    <input matInput formControlName="name">
                                    <mat-error *ngIf="checkError('name', 'required')">Account Name is
                                        required</mat-error>
                                    <mat-error *ngIf="checkError('name', 'maxlength')">Account Name should be less than
                                        20
                                        characters</mat-error>
                                </mat-form-field>
                            </div>

                            <div fxLayout="column" fxLayout.gt-xs="row">
                                <mat-form-field appearance="outline" class="example-full-width form-field"
                                    style="min-width: auto;">
                                    <mat-label>Group</mat-label>
                                    <input matInput [formControl]="dataForm.get('group')" [matAutocomplete]="dataAuto">
                                    <mat-autocomplete #dataAuto="matAutocomplete" [displayWith]="displaySelectData">
                                        <mat-option *ngFor="let item of filteredSelectData | async" [value]="item">
                                            {{ item.name }}
                                        </mat-option>
                                    </mat-autocomplete>
                                    <mat-error *ngIf="checkError('group', 'required')">Group is required</mat-error>
                                </mat-form-field>
                            </div>

                            <div fxLayout="column" fxLayout.gt-xs="row">
                                <mat-form-field appearance="outline" class="example-full-width form-field"
                                    style="min-width: auto;">
                                    <mat-label>Model</mat-label>
                                    <input matInput [formControl]="dataForm.get('model')"
                                        [matAutocomplete]="dataAutoModel">
                                    <mat-autocomplete #dataAutoModel="matAutocomplete"
                                        [displayWith]="displaySelectData">
                                        <mat-option *ngFor="let item of filteredSelectDataModel | async" [value]="item">
                                            {{ item.name }}
                                        </mat-option>
                                    </mat-autocomplete>
                                    <mat-error *ngIf="checkError('model', 'required')">Model is required</mat-error>
                                </mat-form-field>
                            </div>
                            <div fxLayout="column" fxLayout.gt-xs="row">
                                <mat-form-field style="width: 320px;" appearance="outline" class="example-full-width form-field">
                                    <mat-label>Type</mat-label>
                                    <mat-select formControlName="type" required>
                                        <mat-option *ngFor="let item of type" [value]="item.id">{{ item.text
                                            }}</mat-option>
                                    </mat-select>
                                    <mat-error *ngIf="checkError('type', 'required')">Type is
                                        required</mat-error>
                                </mat-form-field>
                            </div>

                            <mat-form-field appearance="outline" class="example-full-width form-field"
                                style="width: 320px;">
                                <mat-label>Start and End Date</mat-label>
                                <mat-date-range-input [rangePicker]="picker">
                                    <input formControlName="start" matStartDate placeholder="Start date" (dateChange)="changeDatePicker()">
                                    <input formControlName="end" matEndDate placeholder="End date" (dateChange)="changeDatePicker()">
                                </mat-date-range-input>
                                <mat-hint>MM/DD/YYYY – MM/DD/YYYY</mat-hint>
                                <mat-datepicker-toggle matIconSuffix [for]="picker"></mat-datepicker-toggle>
                                <mat-date-range-picker #picker></mat-date-range-picker>
                                <mat-error *ngIf="checkError('start', 'required')">Start Date is required</mat-error>
                                <mat-error *ngIf="checkError('end', 'required')">End Date is required</mat-error>
                            </mat-form-field>


                            <mat-toolbar style="background-color: white;margin-top: 20px;">
                                <div class="content" fxLayout="row" fxLayout.xs="column" fxFlexFill>
                                    <div fxFlex="80" fxFlex.xs="55">
                                        <mat-checkbox formControlName="active">Active</mat-checkbox>

                                    </div>
                                    <div fxFlex="20">
                                        <button mat-raised-button type="submit" style="margin-right: 10px;float: right;"
                                            color="primary">Submit</button>
                                    </div>

                                </div>
                            </mat-toolbar>

                        </form>
                    </mat-card-content>
                </mat-card>

            </div>
        </mat-tab>

        <mat-tab label="Audit Trail" *ngIf="isAdmin">
            <app-audit-trail-table [module]="10"></app-audit-trail-table>
        </mat-tab>
    </mat-tab-group>

</mat-card>