<mat-accordion>
    <mat-expansion-panel *ngFor="let result of examResult; let mainIndex = index"
        [disabled]="examResult[mainIndex].score === null" (opened)="onResultPanelOpened(mainIndex, result.userexamid)">
        <mat-expansion-panel-header>
            <mat-panel-title>
                {{examResult[mainIndex].examnumber}} - {{examResult[mainIndex].sequence}}
            </mat-panel-title>
            <mat-panel-description>
                {{ examResult[mainIndex].score === null ? 'Exam not yet done' : examResult[mainIndex].endtime + ' -
                Exam completed' }}
                <span *ngIf="examResult[mainIndex].score !== null"
                    style="padding-left: 100px;">{{examResult[mainIndex].percentage}}%
                    {{examResult[mainIndex].score}}/{{examResult[mainIndex].maxscore}} -
                    {{examResult[mainIndex].grade}}</span>
            </mat-panel-description>
        </mat-expansion-panel-header>
        <app-exam-result [data]="examResult[mainIndex]"></app-exam-result>
        <div class="strengths">
            <div style="padding: 10px;" *ngIf="examResult[mainIndex].userStrengths">
                <h2>Strengths</h2>
                <div *ngFor="let strengths of examResult[mainIndex].userStrengths;">
                    <app-strengths [data]="strengths"></app-strengths>
                </div>
            </div>
        </div>
        <div class="sub-details essay-result">
            <div class="essay-details">
                <span class="final-score">
                    <h2>Final Score: {{essayFinalScore}}</h2>
                </span>
                <h2>Essay</h2>
                <p><span style="font-weight: 500;">Status:{{examResult[mainIndex].grade}}</span> </p>
            </div>
        </div>

        <mat-accordion>
            <mat-expansion-panel *ngFor="let essay of examResult[mainIndex].essayResult; let essayIndex = index"
                (opened)="onResultPanelOpenedEssay(essayIndex, mainIndex, essay.userexamid, essay.questionid, essay.essayresultid, essay.gptresult)">
                <mat-expansion-panel-header>
                    <mat-panel-title>
                        Topic: <span style="font-weight: 350;margin-left: 5px;">
                            {{essay.question}}</span>
                    </mat-panel-title>
                    <mat-panel-description>
                        <span style="padding-left: 460px;" class="float-right">
                            Score: {{essay.score}}
                        </span>
                    </mat-panel-description>
                </mat-expansion-panel-header>
                <div class="add">
                    <div class="wrapper">
                        <mat-form-field appearance="outline" class="example-full-width form-field">
                            <mat-label>User Answer</mat-label>
                            <textarea [ngModelOptions]="{standalone: true}" readonly [(ngModel)]="essay.answer" rows="3"
                                cdkTextareaAutosize matInput></textarea>
                        </mat-form-field>
                        <mat-divider></mat-divider>

                        <div id="one">
                            <h3>AI Evaluation Result</h3>
                            <p [innerHTML]="essay.gptResponse"></p>
                        </div>

                        <div id="two">
                            <mat-accordion class="criteria-headers-align">
                                <mat-expansion-panel disabled>
                                    <mat-expansion-panel-header>
                                        <mat-panel-title>
                                            <p style="color: black;">Criteria</p>
                                        </mat-panel-title>
                                        <mat-panel-description>
                                            <mat-icon></mat-icon>
                                            <p style="color: black;margin-right: 20px">Point/s</p>
                                        </mat-panel-description>
                                    </mat-expansion-panel-header>

                                </mat-expansion-panel>
                                <mat-expansion-panel hideToggle
                                    *ngFor="let metrics of examResult[mainIndex].essayResult[essayIndex].metrics; let i = index">
                                    <mat-expansion-panel-header>
                                        <mat-panel-title>
                                            {{metrics.value}}
                                        </mat-panel-title>
                                        <mat-panel-description>
                                            &nbsp;
                                            <mat-form-field appearance="fill" style="padding-top: 5px;width: 80px;"
                                                class="example-full-width scale dense-3">
                                                <input matInput type="number" [appMaxValue]="metrics.scale" min="0"
                                                    (click)="$event.stopPropagation()" class="emp_id"
                                                    [ngModelOptions]="{standalone: true}"
                                                    [disabled]="examResult[mainIndex].essayResult[essayIndex].isSubmitted"
                                                    [(ngModel)]="metrics.finalpoint">
                                            </mat-form-field>
                                        </mat-panel-description>
                                    </mat-expansion-panel-header>
                                    <p [innerHTML]="metrics.formattedDetails"></p>
                                </mat-expansion-panel>
                            </mat-accordion>
                            <button mat-flat-button color="primary"
                                [disabled]="examResult[mainIndex].essayResult[essayIndex].isSubmitted"
                                (click)="submitHandler(examResult[mainIndex].essayResult[essayIndex], mainIndex, essayIndex)"
                                class="demo-button mt-4 float-right">
                                Submit
                            </button>
                        </div>

                    </div>
                </div>
            </mat-expansion-panel>
        </mat-accordion>

    </mat-expansion-panel>

</mat-accordion>