import { Component, OnInit, ViewChild } from '@angular/core';
import { ActivatedRoute, Router, RouterModule } from '@angular/router';
import { NgFor } from '@angular/common';
import { MatListModule } from '@angular/material/list';
import { MatButtonModule } from '@angular/material/button';
import { MatCardModule } from '@angular/material/card';
import { MatDialog, MatDialogModule } from '@angular/material/dialog';
import { MatGridListModule } from '@angular/material/grid-list';
import { ExamService } from '../service/exam.service';
import { ExamModel } from '../model/exam';
import { CommonModule } from '@angular/common';
import { Observable, catchError, throwError } from 'rxjs';
import { MatSidenavModule } from '@angular/material/sidenav';
import { MatMenuModule } from '@angular/material/menu';
import { MatToolbarModule } from '@angular/material/toolbar';
import { MatIconModule } from '@angular/material/icon';
import { MatExpansionModule } from '@angular/material/expansion';
import { MatTooltipModule } from '@angular/material/tooltip';
import { MatTable, MatTableDataSource, MatTableModule } from '@angular/material/table';
import { MatPaginator, MatPaginatorModule } from '@angular/material/paginator';
import { AppConstants } from '../app.constants';
import { AuthService } from '../service/auth.service';
import { MatInputModule } from '@angular/material/input';
import { MatFormFieldModule } from '@angular/material/form-field';
import { SnackbarService } from '../lib/snackbar/snackbar.service';
import { DomSanitizer, SafeHtml } from '@angular/platform-browser';
import { FlexLayoutModule } from "@angular/flex-layout";

@Component({
  selector: 'app-exam-list',
  templateUrl: './exam-list.component.html',
  styleUrls: ['./exam-list.component.css'],
  standalone: true,
  imports: [MatSidenavModule, MatPaginatorModule, MatTableModule, MatToolbarModule, MatMenuModule,
    MatIconModule, MatExpansionModule, MatTooltipModule, CommonModule, MatGridListModule,
    MatInputModule, MatFormFieldModule,FlexLayoutModule,
    MatListModule, NgFor, MatCardModule, MatButtonModule, RouterModule, MatDialogModule],
})
export class ExamListComponent implements OnInit {
  pageSize: number = 10;
  allExam: ExamModel[] = [];
  displayedColumns: string[] = ['position', 'name', 'subscription', 'action'];
  dataSource = new MatTableDataSource<ExamModel>;
  userId: any;
  pageType = 'available';
  pageTitle = "Available Exam/s";
  @ViewChild(MatPaginator) paginator: MatPaginator;
  @ViewChild(MatTable) table: MatTable<ExamModel>;
  constructor(
    private router: Router,
    public dialog: MatDialog,
    private examService: ExamService,
    private snackbarService: SnackbarService,
    private authService: AuthService,
    private constant: AppConstants,
    private _Activatedroute: ActivatedRoute,

  ) {
    this._Activatedroute.paramMap.subscribe(paramMap => {
      this.pageType = paramMap['params'].type;
      this.getUser();
      this.initPage();
    });
  }
  ngOnInit() {
    this.getUser();
    this.fetchExam();
  }

  initPage() {
    if (this.pageType == "available") {
      this.pageTitle = "Available Exam/s";
    } else {
      this.pageTitle = "My Exam";
    }
    this.fetchExam();
  }

  ngAfterViewInit() {
    this.dataSource.paginator = this.paginator;
  }

  applyFilter(event: Event) {
    const filterValue = (event.target as HTMLInputElement).value;
    this.dataSource.filter = filterValue.trim().toLowerCase();
  }

  getUser() {
    const user = this.authService.getUser();
    this.userId = user != null ? this.authService.extractId(user.aud) : 0;
  }

  continueExam(examId: number, userExamId) {
    this.redirect(examId, this.constant.examMode.Continue, userExamId);
  }

  openDialog(examId: number, mode, userExamId) {
    const dialogRef = this.dialog.open(ConfirmationDialog);
    dialogRef.afterClosed().subscribe(async result => {
      if (result) {
        await (await this.examService.validateExam(examId)).subscribe((response) => {
          if (response) {
            this.redirect(examId, mode, userExamId);
          } else {
            this.snackbarService.showSnackbar(this.constant.invalidExam, 'top', 10000);
          }
        },
          (errorMessage) => {
            this.snackbarService.showSnackbar(this.constant.invalidExam, 'top', 10000);
          }
        )
      }
    });
  }

  redirect(examId: number, mode, userExamId) {
    this.router.navigate(['exam', examId, mode, userExamId]);
  }

  init() {
    console.log(this.router.url === '/exam-list')
  }

  showStartButton(element: any): boolean {
    return element.isCompleted == null;
  }

  showRetakeButton(element: any): boolean {
    return element.retake == 2 && element.endtime != null;
  }

  showContinueButton(element: any): boolean {
    return element.isCompleted != null && element.endtime == null;
  }

  showDetailsButton(element: any): boolean {
    return this.pageType !== 'available' && (element.isCompleted != null && element.endtime != null || element.sequence >= 1);
  }

  refresh() {
    this.fetchExam();
  }

  async fetchExam() {
    await (await this.examService.fetchExam(this.userId))
      .pipe(
        catchError(() => {
          this.snackbarService.showSnackbar('Reloading the page', 'top', 2000);
          this.router.navigate(['exam-list', this.pageType]);
          return Observable.call(throwError(() => "Error"));
        })
      )
      .subscribe(
        (response) => {
          const filteredList = response.filter(element => {
            if (this.pageType == 'available') {
              return this.showStartButton(element) || this.showRetakeButton(element) || this.showContinueButton(element);
            } else {
              return this.showDetailsButton(element) && !this.showContinueButton(element);
            }
          });

          if (this.pageType === 'done') {
            const uniqueList = [];
            filteredList.forEach(element => {
              if (!uniqueList.some(item => item.name === element.name && item.subscription === element.subscription)) {
                uniqueList.push(element);
              }
            });
            this.dataSource.data = filteredList;
          } else {
            this.dataSource.data = filteredList;
          }
          this.paginator.pageSize = this.pageSize;
        },
        (error) => {
          console.error('Request failed with error')
        })
  }

}

@Component({
  selector: 'confirmation-dialog',
  templateUrl: 'confirmation-dialog.html',
  standalone: true,
  imports: [MatDialogModule, MatButtonModule],
})
export class ConfirmationDialog {
  constructor(
    private constant: AppConstants,
    private sanitizer: DomSanitizer

  ) {
  }

  startExamMessage = this.constant.startExamMessage
  sanitizeHtml(html: string): SafeHtml {
    return this.sanitizer.bypassSecurityTrustHtml(html);
  }

}
