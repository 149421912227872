import { Injectable } from '@angular/core';
import { MatSnackBar } from '@angular/material/snack-bar';

@Injectable({
  providedIn: 'root' // Register the service at the root level
})
export class SnackbarService {
  constructor(private snackBar: MatSnackBar) {}

  showSnackbar(message: string, position: string = 'bottom', duration: number = 50000): void {
    this.snackBar.open(message, 'Dismiss', {
      duration,
      verticalPosition: position.startsWith('top') ? 'top' : 'bottom',
      horizontalPosition: position.endsWith('left') ? 'start' : 'end'
    });
  }
}
