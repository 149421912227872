import { Component } from '@angular/core';
import { FormControl, FormGroup, Validators } from '@angular/forms';
import { MatDialog } from '@angular/material/dialog';
import { Router } from '@angular/router';
import { SnackbarService } from 'src/app/lib/snackbar/snackbar.service';
import { AuthService } from 'src/app/service/auth.service';
import { RecaptchaModel } from '../../model/recaptcha';
import { DataProtectionPolicyComponent } from '../data-protection-policy/data-protection-policy.component';
import { AppConstants } from 'src/app/app.constants';
import { customEmailValidator } from 'src/app/validators/email.validator';
import { firstValueFrom } from 'rxjs';

@Component({
  selector: 'app-user-registration',
  templateUrl: './user-registration.component.html',
  styleUrls: ['./user-registration.component.css']
})
export class UserRegistrationComponent {
  public dataForm: FormGroup;
  dataProtection: boolean = false;
  recaptcha: RecaptchaModel = { token: '', expired: true };

  constructor(
    private snackbarService: SnackbarService,
    private authService: AuthService,
    private router: Router,
    public dialog: MatDialog,
    private constant: AppConstants
  ) { }
  ngOnInit() {
    this.dataForm = new FormGroup({
      email: new FormControl('', [Validators.required, customEmailValidator(), this.emailLengthValidator]),
      password: new FormControl('12345678'),
      firstname: new FormControl('', [Validators.required]),
      lastname: new FormControl('', [Validators.required]),
      isVerified: new FormControl(true),
      isAdmin: new FormControl(false)
    });
  }
  emailLengthValidator(control: FormControl) {
    if (control.value && control.value.length > 100) {
      return { 'emailLengthExceeded': true };
    }
    return null;
  }

  receiveDataFromCaptcha(data: any): void {
    this.recaptcha = data;
  }
  openDialog() {
    const dialogRef = this.dialog.open(DataProtectionPolicyComponent, { width: '700px' });

    dialogRef.afterClosed().subscribe(result => {
      // console.log(`Dialog result: ${result}`);
    });
  }

  public checkError = (controlName: string, errorName: string) => {
    return this.dataForm.controls[controlName].hasError(errorName);
  }

  async register() {
    try {
      const encryptEmail = btoa(this.dataForm.value.email)
      const response = await firstValueFrom(this.authService.register(this.dataForm.value));

      const data = JSON.parse(JSON.stringify(response));

      // if(data.errorMessage){
      //   this.snackbarService.showSnackbar('Something went wrong.', 'top', 5000);
      //   return;
      // }
      
      if (data.success) {
        this.snackbarService.showSnackbar(this.constant.emailCodeMessage, 'top', 5000);
        this.router.navigate(['confirm-user', encryptEmail]);
      } else {
        this.snackbarService.showSnackbar(data.message || 'Something went wrong.', 'top', 5000);
      }
    } catch (error) {
      console.error('Error occurred during registration:', error);
      this.snackbarService.showSnackbar('An error occurred. Please try again later.', 'top', 5000);
    }
  }

  async onSubmit() {
    if (this.dataForm.valid) {
      try {
        const encryptEmail = btoa(this.dataForm.value.email)
        const payload = {
          token: this.recaptcha.token
        }

        await (await this.authService.validateRecaptchaToken(payload)).subscribe(async (isValid) => {
          if (isValid) {
            await this.register();
            // await (await this.authService.register(this.dataForm.value))
            //   .subscribe((response) => {
            //     const data = JSON.parse(JSON.stringify(response));
            //     if (data.success) {
            //       this.snackbarService.showSnackbar(this.constant.emailCodeMessage, 'top', 5000);
            //       this.router.navigate(['confirm-user', encryptEmail]);
            //     } else {
            //       this.snackbarService.showSnackbar(data.message, 'top', 5000);
            //     }
            //   });
          } else {
            this.snackbarService.showSnackbar("Recaptcha expired", 'top', 2000);
          }
        })
      } catch (error) {
        this.snackbarService.showSnackbar("Service Unavailable", 'top', 2000);
      }
    }
  }

}
