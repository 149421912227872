import { Component, OnInit, ViewChild } from '@angular/core';
import { ExamService } from '../../service/exam.service';
import { Router, RouterModule } from '@angular/router';
import { NgFor } from '@angular/common';
import { MatListModule } from '@angular/material/list';
import { MatButtonModule } from '@angular/material/button';
import { MatCardModule } from '@angular/material/card';
import { MatDialog, MatDialogModule } from '@angular/material/dialog';
import { MatGridListModule } from '@angular/material/grid-list';
import { CommonModule } from '@angular/common';
import { MatSidenavModule } from '@angular/material/sidenav';
import { MatMenuModule } from '@angular/material/menu';
import { MatToolbarModule } from '@angular/material/toolbar';
import { MatIconModule } from '@angular/material/icon';
import { MatExpansionModule } from '@angular/material/expansion';
import { MatTooltipModule } from '@angular/material/tooltip';
import { MatTable, MatTableDataSource, MatTableModule } from '@angular/material/table';
import { MatPaginator, MatPaginatorModule } from '@angular/material/paginator';
import { Observable, catchError, from, map, startWith, throwError } from 'rxjs';
import { FormGroup, FormControl, Validators } from '@angular/forms';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { MatInputModule } from '@angular/material/input';
import { MatFormFieldModule } from '@angular/material/form-field';
import { MatSelectModule } from '@angular/material/select';
import { MatCheckboxModule } from '@angular/material/checkbox';
import { SnackbarService } from 'src/app/lib/snackbar/snackbar.service';
import { DialogService } from 'src/app/service/dialog.service';
import { MatAutocompleteModule } from '@angular/material/autocomplete';
import { MatTabsModule } from '@angular/material/tabs';
import { MatProgressBarModule } from '@angular/material/progress-bar';
import { CategoryService } from 'src/app/service/category.service';
import { AWSS3Service } from 'src/app/service/aws.s3.service';
import { CategoryModel } from 'src/app/model/category';
import { AuditTrailTableComponent } from "../../components/audit-trail-table/audit-trail-table.component";
import { AuditTrailService } from 'src/app/service/audit.trail.service';
import { FlexLayoutModule } from "@angular/flex-layout";
import { SelectionModel } from '@angular/cdk/collections';
import { AppConstants } from 'src/app/app.constants';
import { CommaDelimiterModule } from 'src/app/lib/comma-delimiter-module/comma-delimiter.module';
import { formatWithCommas } from '../../common-function'
import { GroupService } from 'src/app/service/group.service';
import { UserService } from 'src/app/service/user.service';
import { SubscriptionService } from 'src/app/service/subscription.service';
import { JsonPipe } from '@angular/common';
import { MatDatepickerModule } from '@angular/material/datepicker';
import { MatNativeDateModule, MatRippleModule } from '@angular/material/core';
import { PayModelService } from 'src/app/service/pay.model.service';
import * as moment from 'moment';
import { AuthService } from 'src/app/service/auth.service';

@Component({
  selector: 'app-subscription',
  templateUrl: './subscription.component.html',
  styleUrls: ['./subscription.component.css'],
  standalone: true,
  imports: [MatSidenavModule, CommaDelimiterModule, FlexLayoutModule, MatAutocompleteModule, MatTabsModule, FormsModule, MatProgressBarModule, MatCheckboxModule, MatSelectModule, ReactiveFormsModule, MatInputModule, MatFormFieldModule,
    MatPaginatorModule, MatTableModule, MatToolbarModule, MatMenuModule, MatIconModule, MatExpansionModule, MatTooltipModule,
    CommonModule, MatGridListModule, MatNativeDateModule, MatDatepickerModule, MatListModule, NgFor, MatCardModule, MatButtonModule, RouterModule, MatDialogModule, AuditTrailTableComponent]
})
export class SubscriptionComponent {
  displayedColumns: string[] = ['select', 'position', 'name', 'subscriptionid', 'model', 'group','groupadmin', 'type', 'start', 'end', 'status', 'action'];
  dataSource = new MatTableDataSource<CategoryModel>;
  dataSourceAT = new MatTableDataSource<any>;
  selection = new SelectionModel<any>(true, []);
  filteredLevelCategories: Observable<any[]>;

  public dataForm: FormGroup;
  isAddMode: boolean = false;
  isEditMode: boolean = false;
  hasAction: any;
  categories: any;
  categoriesLevel: any;
  pageSize: number = 10;

  @ViewChild(MatPaginator) paginator: MatPaginator;
  @ViewChild(MatTable) table: MatTable<CategoryModel>;

  // Mass upload
  @ViewChild('fileInput')
  fileInput;
  file: File | null = null;
  hasSubmit: boolean = false;
  isMainVisible: boolean = false;
  type = this.constant.subscriptionType;
  selectData: any[];
  filteredSelectData: Observable<any[]>;
  selectDataModel: any[];
  filteredSelectDataModel: Observable<any[]>;
  user: any;
  isAdmin: boolean = false;

  ngAfterViewInit() {
    this.dataSource.paginator = this.paginator;
  }

  constructor(
    private router: Router, public dialog: MatDialog,
    private examService: ExamService,
    private snackbarService: SnackbarService,
    private dialogService: DialogService,
    private s3: AWSS3Service,
    private categoryService: CategoryService,
    private auditTrailService: AuditTrailService,
    private constant: AppConstants,
    private groupService: GroupService,
    private paymodelService: PayModelService,
    private userService: UserService,
    private subscriptionService: SubscriptionService,
    private authService: AuthService

  ) {
  }
  ngOnInit() {
    this.initUser();
    this.fetchData();
    this.dataForm = new FormGroup({
      accountId: new FormControl(''),
      name: new FormControl('', [Validators.required, Validators.maxLength(50)]),
      group: new FormControl('', [Validators.required]),
      model: new FormControl('', [Validators.required]),
      type: new FormControl('', [Validators.required]),
      start: new FormControl('', [Validators.required]),
      end: new FormControl('', [Validators.required]),
      active: new FormControl('', [Validators.required]),
      status: new FormControl(0),
    });

    this.hasAction = null;
  }

  async initUser() {
    this.user = this.authService.getUser();
    if (this.user != null) {
      this.isAdmin = this.authService.extractId(this.user.azp) === 1;

    }
  }

  refresh() {
    this.fetchData();
    this.selection.clear();
  }
  isAllSelected() {
    const numSelected = this.selection.selected.length;
    const numRows = this.dataSource.data.length;
    return numSelected === numRows;
  }

  /** Selects all rows if they are not all selected; otherwise clear selection. */
  toggleAllRows() {
    if (this.isAllSelected()) {
      this.selection.clear();
      return;
    }

    this.selection.select(...this.dataSource.data);
  }

  /** The label for the checkbox on the passed row */
  checkboxLabel(row?: any): string {
    if (!row) {
      return `${this.isAllSelected() ? 'deselect' : 'select'} all`;
    }
    return `${this.selection.isSelected(row) ? 'deselect' : 'select'} row ${row.position + 1}`;
  }

  changeDatePicker(): any {
    this.dataForm.value.start = (moment(this.dataForm.value.start).format('YYYY-MM-DD'));
    this.dataForm.value.end = (moment(this.dataForm.value.end).format('YYYY-MM-DD'));
  }
  async deleteHandler() {
    const ids = this.selection.selected.map(item => item.id)
    await this.delete(ids);
  }

  public checkError = (controlName: string, errorName: string) => {
    return this.dataForm.controls[controlName].hasError(errorName);
  }

  applyFilter(event: Event) {
    const filterValue = (event.target as HTMLInputElement).value;
    this.dataSource.filter = filterValue.trim().toLowerCase();
  }

  cleanInputValue(value: string): string {
    return value.replace(/,/g, '');
  }

  displaySelectData(item: any): string {
    return item ? item.name : '';
  }

  async handleApprovalStatusChange(status) {
    if (this.selection.selected.length > 0) {
      const ids = this.selection.selected.map(item => item.id);
      await this.updateUserApproval(ids, status);
    }

  }

  async updateUserApproval(ids, status) {
    await (await this.subscriptionService.editSubscriptionStatus({ ids, status }))
      .subscribe((response) => {
        this.selection.clear();
        this.snackbarService.showSnackbar(response.toString(), 'top', 2500);
        this.fetchData();
        this.auditTrailService.sendNotification(true);
      });
  }

  private _filterData(value: any, isGroup: boolean): any[] {
    let data = (isGroup) ? this.selectData : this.selectDataModel;
    if (value != null) {
      if (value == '') {
        return data;
      }
      if (typeof value === 'string') {
        const filterValue = value.toLowerCase();
        return data.filter(item => item.name.toLowerCase().includes(filterValue));
      } else if (typeof value === 'object' && value.name) {
        return data.filter(item => item.name.toLowerCase() === value.name.toLowerCase());
      }
    }
    return [];
  }

  async fetchGroup() {
    (await this.groupService.getGroup())
      .pipe(
        catchError(() => {
          return Observable.call(throwError(() => "Error"));
        })
      )
      .subscribe((response) => {
        const data = JSON.parse(JSON.stringify(response));
        this.selectData = data;
        this.filteredSelectData = this.dataForm.get('group').valueChanges.pipe(
          startWith(''),
          map((value: any) => this._filterData(value, true))
        );
      });
  }

  async fetchModel() {
    (await this.paymodelService.getPayModel())
      .pipe(
        catchError(() => {
          return Observable.call(throwError(() => "Error"));
        })
      )
      .subscribe((response) => {
        const data = JSON.parse(JSON.stringify(response));
        this.selectDataModel = data;
        this.filteredSelectDataModel = this.dataForm.get('model').valueChanges.pipe(
          startWith(''),
          map((value: any) => this._filterData(value, false))
        );
      });
  }

  async fetchData() {
    (await this.subscriptionService.getSubscription({}))
      .pipe(
        catchError(() => {
          return Observable.call(throwError(() => "Error"));
        })
      )
      .subscribe((response) => {
        const data = JSON.parse(JSON.stringify(response));
        const subscriptionStatusList = Object.entries(this.constant.subscriptionStatus).map(([name, id]) => ({ id, name }));

        data.forEach((item) => {
          item.statusName = subscriptionStatusList.find(action => action.id == item.status)?.name || 'Unknown';
          item.startdate = moment(item.startdate).format('MM/DD/YYYY');;
          item.enddate = moment(item.enddate).format('MM/DD/YYYY');;
        })
        this.dataSource.data = data;
        this.paginator.pageSize = this.pageSize;
        this.dataSource.paginator = this.paginator;
      });
  }

  handler() {
    const frequency = this.dataForm.get('frequency');
    const payment = this.dataForm.get('payment');
    if (frequency.value != 0) {
      payment.setValidators([Validators.required]);
    } else {
      payment.clearValidators();
    }
  }

  async onSubmit() {
    if (this.dataForm.valid) {
      if (this.isAddMode) {
        const user = this.authService.getUser();
        const userId = user != null ? this.authService.extractId(user.aud) : 0;
        const payload = { ...this.dataForm.value, ownerId: userId };
        (await this.subscriptionService.addSubscription(payload))
          .subscribe((response) => {
            this.snackbarService.showSnackbar(response.toString(), 'top', 2000);
            this.fetchData();
            this.auditTrailService.sendNotification(true);
            this.backAction();
          });
      }

      if (this.isEditMode) {
        const user = this.authService.getUser();
        const userId = user != null ? this.authService.extractId(user.aud) : 0;
        const payload = { ...this.dataForm.value, ownerId: userId };
        (await this.subscriptionService.editSubscription(payload))
          .subscribe((response) => {
            this.snackbarService.showSnackbar(response.toString(), 'top', 2000);
            this.fetchData();
            this.auditTrailService.sendNotification(true);
            this.backAction();
          });
      }

    }
  }

  public onSelectedIndexChange(tabIndex: number) {
    if (tabIndex === 0) {
      this.fetchData();
    }
  }

  async addAction() {
    await this.fetchGroup();
    await this.fetchModel();
    this.isEditMode = false;
    this.isAddMode = true;
  }

  backAction() {
    this.isAddMode = false;
    this.isEditMode = false;
    this.dataForm.reset();
    this.dataForm.patchValue({
      active: true,
      status: true
    });
    setTimeout(() => {
      this.paginator.pageSize = this.pageSize;
      this.dataSource.paginator = this.paginator;
    }, 0);
  }
  private formatDate(date) {
    const d = new Date(date);
    let month = '' + (d.getMonth() + 1);
    let day = '' + d.getDate();
    const year = d.getFullYear();
    if (month.length < 2) month = '0' + month;
    if (day.length < 2) day = '0' + day;
    return [year, month, day].join('-');
  }
  async editItem(data) {
    try {
      await this.fetchGroup();
      await this.fetchModel();
      this.dataForm.patchValue({
        accountId: data.id,
        name: data.name,
        group: { id: data.groupid, name: data.group_name },
        model: { id: data.modelid, name: data.model_name },
        type: data.type,
        start: this.formatDate(data.startdate),
        end: this.formatDate(data.enddate),
        active: data.active,
        status: data.status,
      });

      this.isEditMode = true;
      this.isAddMode = false;
    } catch (error) {
      console.error("An error occurred while editing item:", error);
    }
  }

  async delete(ids) {
    this.dialogService.openDialog('Delete data', 'Are you sure you want to delete the selected data?', 'No', true, async (confirmed) => {
      if (confirmed) {
        (await this.subscriptionService.deleteSubscription(ids))
          .subscribe((response) => {
            this.selection.clear();
            this.snackbarService.showSnackbar(response.toString(), 'top', 2000);
            this.fetchData();
            this.auditTrailService.sendNotification(true);
          });
      } else {
        console.log('Close');
      }
    });
  }

  async exportAction() {
    (await this.subscriptionService.export())
      .pipe(
        catchError((err) => {
          return Observable.call(throwError(() => "Error"));
        })
      )
      .subscribe((response) => {
        const blob = new Blob([response], { type: 'text/csv' });
        const url = window.URL.createObjectURL(blob);
        const a = document.createElement('a');
        a.href = url;
        a.download = 'Account_group.csv';
        a.click();
        window.URL.revokeObjectURL(url);
        this.auditTrailService.sendNotification(true);
      });
  }

  async importCategory() {
    this.hasSubmit = true;
    try {
      const key = await this.s3.uploadFile(this.file);
      const response = await this.categoryService.importCategory(key);
      if (response.status === 200) {
        this.fetchData();
        this.auditTrailService.sendNotification(true);
        this.snackbarService.showSnackbar(response.data.message, 'top', 10000);
      }

      this.file = null;
      this.hasSubmit = false;
    } catch (error) {
      this.snackbarService.showSnackbar("An error occured while processing the request", 'top', 10000);
      this.file = null;
      this.hasSubmit = false;
    }
  }

  async exportCategoryTemplate() {
    (await this.categoryService.exportCategoryTemplate())
      .pipe(
        catchError((err) => {
          return Observable.call(throwError(() => "Error"));
        })
      )
      .subscribe((response) => {
        const blob = new Blob([response], { type: 'text/csv' });
        const url = window.URL.createObjectURL(blob);
        const a = document.createElement('a');
        a.href = url;
        a.download = 'Category_template.csv';
        a.click();
        window.URL.revokeObjectURL(url);
        this.auditTrailService.sendNotification(true);
      });
  }

  onClickFileInputButton(): void {
    this.fileInput.nativeElement.click();
  }

  onChangeFileInput(): void {
    const files: { [key: string]: File } = this.fileInput.nativeElement.files;
    this.file = files[0];
  }
}
