import { Component, Input } from '@angular/core';

@Component({
  selector: 'app-exam-result-essay',
  templateUrl: './exam-result-essay.component.html',
  styleUrls: ['./exam-result-essay.component.css']
})
export class ExamResultEssayComponent {
  today = new Date();
  @Input() data: any;
}
