import { CommonModule } from '@angular/common';
import { Component, ViewChild } from '@angular/core';
import { FlexLayoutModule } from '@angular/flex-layout';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { MatButtonModule } from '@angular/material/button';
import { MatCardModule } from '@angular/material/card';
import { MatDialogModule } from '@angular/material/dialog';
import { MatFormFieldModule } from '@angular/material/form-field';
import { MatGridListModule } from '@angular/material/grid-list';
import { MatIconModule } from '@angular/material/icon';
import { MatInputModule } from '@angular/material/input';
import { MatMenuModule } from '@angular/material/menu';
import { MatPaginator, MatPaginatorModule } from '@angular/material/paginator';
import { MatSelectModule } from '@angular/material/select';
import { MatTableDataSource, MatTableModule } from '@angular/material/table';
import { MatToolbarModule } from '@angular/material/toolbar';
import { SnackbarService } from 'src/app/lib/snackbar/snackbar.service';
import { AuthService } from 'src/app/service/auth.service';
import { SubscriptionService } from 'src/app/service/subscription.service';
import { UserService } from 'src/app/service/user.service';

@Component({
  selector: 'app-switch-account-modal',
  templateUrl: './switch-account-modal.component.html',
  styleUrls: ['./switch-account-modal.component.css'],
  standalone: true,
  imports: [MatCardModule, FormsModule, MatTableModule, MatDialogModule, ReactiveFormsModule, MatPaginatorModule, MatButtonModule, MatGridListModule, CommonModule, MatMenuModule, MatToolbarModule, MatIconModule, MatInputModule, MatFormFieldModule, MatSelectModule, MatCardModule, FlexLayoutModule],
})
export class SwitchAccountModalComponent {
  displayedColumns: string[] = ['position', 'name', 'type'];
  dataSource = new MatTableDataSource<any>;
  user: any;
  isAdmin: any;
  pageSize: number = 5;
  @ViewChild(MatPaginator) paginator: MatPaginator;
  subscriptionList: any[] = [];
  constructor(
    private authService: AuthService,
    private subscriptionService: SubscriptionService,
    private snackbarService: SnackbarService,
    private userService: UserService

  ) {
  }
  ngOnInit() {
    this.user = this.authService.getUser();
    this.isAdmin = this.authService.extractId(this.user.azp) === 1;
    this.user.id = this.authService.extractId(this.user.aud);
    this.getSubscription(this.user.id);
  }

  ngAfterViewInit() {
    this.dataSource.paginator = this.paginator;
  }
  async getUserRole() {
    (await this.userService.fetchUsers())
      .subscribe((response) => {
        const data = JSON.parse(JSON.stringify(response));
        const user = data.find(x => x.id == this.user.id);
        if (user) {
          this.user.azp = `${user.role}.${this.user.azp}`;
          localStorage.setItem('user', JSON.stringify(this.user));
          window.location.reload();
        }
      });
  }

  async getSubscription(userId) {
    await (await this.subscriptionService.getSubscription({ groupAdmin: userId, status: 1 }))
      .subscribe((response) => {
        const data = JSON.parse(JSON.stringify(response));
        this.subscriptionList = data;
        this.dataSource.data = data;
        this.paginator.pageSize = this.pageSize;
      });
  }

  async switchSubscription(data) {
    this.user.accountId = data.id;
    this.user.accountName = data.name;
    this.user.group = data.group_name;
    this.snackbarService.showSnackbar("Account switched to " + data.name, 'top', 5000);
    await this.getUserRole();

  }
}
