import { CommonModule } from '@angular/common';
import { Component } from '@angular/core';
import { FlexLayoutModule } from '@angular/flex-layout';
import { FormControl, FormGroup, FormsModule, ReactiveFormsModule, Validators } from '@angular/forms';
import { MatButtonModule } from '@angular/material/button';
import { MatCardModule } from '@angular/material/card';
import { MatDialogModule } from '@angular/material/dialog';
import { MatFormFieldModule } from '@angular/material/form-field';
import { MatGridListModule } from '@angular/material/grid-list';
import { MatIconModule } from '@angular/material/icon';
import { MatInputModule } from '@angular/material/input';
import { MatMenuModule } from '@angular/material/menu';
import { MatPaginatorModule } from '@angular/material/paginator';
import { MatSelectModule } from '@angular/material/select';
import { MatToolbarModule } from '@angular/material/toolbar';
import { SnackbarService } from 'src/app/lib/snackbar/snackbar.service';
import { AuthService } from 'src/app/service/auth.service';
import { MemberService } from 'src/app/service/member.service';

@Component({
  selector: 'app-subscription-modal',
  templateUrl: './subscription-modal.component.html',
  styleUrls: ['./subscription-modal.component.css'],
  standalone: true,
  imports: [MatCardModule, FormsModule, MatDialogModule, ReactiveFormsModule, MatPaginatorModule, MatButtonModule, MatGridListModule, CommonModule, MatMenuModule, MatToolbarModule, MatIconModule, MatInputModule, MatFormFieldModule, MatSelectModule, MatCardModule, FlexLayoutModule],
})
export class SubscriptionModalComponent {
  public dataForm: FormGroup;
  user: any;
  isAdmin: any;

  subscriptionCode: "";
  constructor(
    private authService: AuthService,
    private memberService: MemberService,
    private snackbarService: SnackbarService,

  ) {
  }
  ngOnInit() {
    this.user = this.authService.getUser();
    this.isAdmin = this.authService.extractId(this.user.azp) === 1;
    this.dataForm = new FormGroup({
      subscriptionCode: new FormControl('', [Validators.required]),
    });
  }

  async addMember() {

  }

  async submit() {
    if (this.dataForm.valid) {
      const payload = {
        members: [{ email: this.user.email }],
        accountId: this.user.accountId,
        status: 0,
        userId: this.authService.extractId(this.user.aud),
        subscriptionCode: this.dataForm.value.subscriptionCode
      }

      await (await this.memberService.addSubscriptionMember(payload))
        .subscribe((response) => {
          this.snackbarService.showSnackbar(response.toString(), 'top', 5000);
          // this.auditTrailService.sendNotification(true);
        });
    }
  }
}
