import { Injectable } from '@angular/core';
import axios from 'axios';
import { HttpClient, HttpErrorResponse, HttpHeaders } from '@angular/common/http';
import { AWSS3Service } from './aws.s3.service';
import { Observable } from 'rxjs';
import { AppConstants } from '../app.constants';
import { AuthService } from './auth.service';
import { environment } from 'src/environments/environment';

@Injectable({
  providedIn: 'root',
})
export class SubscriptionService {
  // baseURL = 'https://api.virtuascript.net';
  baseURL = environment.apiUrl;

  // baseURL: `${environment.apiUrl}${req.url}`,
  user: any;
  constructor(
    private s3: AWSS3Service,
    private http: HttpClient,
    private constant: AppConstants,
    private authService: AuthService,

  ) {
    this.user = this.authService.getUser();
  }

  updateUserState() {
    this.user = this.authService.getUser();
  }

  async importCategory(key) {
    try {
      const getPresignedURL = await this.s3.generatePresignedURL(key, "getObject");
      const payload = { "signedURL": getPresignedURL, email: this.user.email };

      const response = await axios.post(`${this.baseURL}/masterfile/category/import`, payload,
        {
          headers: {
            "x-api-key": environment.apiKey
          }
        });
      return response;
    } catch (error) {
      throw { error: "Error while importing data: " + error };
    }
  }

  async getSubscription(params): Promise<Observable<any>> {
    const response = await this.http.post("/account/subscription", { sortBy: "name", ...params })
    return response;
  }

  async getRemainingCount(params): Promise<Observable<any>> {
    const response = await this.http.post("/account/subscription/remaining-count", { ...params })
    return response;
  }
  async addSubscription(params: any) {
    this.user = this.authService.getUser();
    const response = await this.http.post("/account/subscription/add", { ...params, adEmail: this.user.email })
    return response;
  }

  async editSubscription(params: any) {
   
    const response = await this.http.post("/account/subscription/update", { ...params, email: this.user.email })
    return response;
  }

  async editSubscriptionStatus(params: any) {
    this.user = this.authService.getUser();
    const response = await this.http.post("/account/subscription/update-status", { ...params, email: this.user.email })
    return response;
  }

  async deleteSubscription(ids) {
    const response = await this.http.post("/account/subscription/delete", { ids, email: this.user.email })
    return response;
  }

  async export(): Promise<Observable<any>> {
    const response = this.http.post("/account/subscription/export", { email: this.user.email });
    return response;
  }

}
