<mat-card style="margin: 0;border: 0;box-shadow: none;">
    <mat-card-header>
        <mat-card-title>Exam Catalogue</mat-card-title>
    </mat-card-header>
    <div class="content" fxLayout="row" fxLayout.xs="column" fxFlexFill>
        <div fxFlex="30" class="col" fxFlex.xs="55">
            <mat-card class="skills">
                <div class="skills-roles-label">
                    <p style="margin-top: 10px;">Skills and Roles</p>
                </div>
                <mat-tree [dataSource]="dataSource" [treeControl]="treeControl">
                    <!-- This is the tree node template for leaf nodes -->
                    <mat-tree-node *matTreeNodeDef="let node" matTreeNodePadding class="tree-border tree"
                        (click)="onSearch(node.name, false)">
                        <!-- use a disabled button to provide padding for tree leaf -->
                        <button mat-icon-button disabled></button>

                        <a class="skills-link">{{node.name}}</a>
                    </mat-tree-node>
                    <!-- This is the tree node template for expandable nodes -->
                    <mat-tree-node *matTreeNodeDef="let node;when: hasChild" matTreeNodePadding class="tree-border">
                        <button mat-icon-button matTreeNodeToggle [attr.aria-label]="'Toggle ' + node.name">
                            <mat-icon class="mat-icon-rtl-mirror">
                                {{treeControl.isExpanded(node) ? 'expand_more' : 'chevron_right'}}
                            </mat-icon>
                        </button>
                        {{node.name}}
                    </mat-tree-node>
                </mat-tree>
            </mat-card>

        </div>
        <div fxFlex="70" class="col">
            <mat-card>
                <mat-toolbar style="background-color: rgb(255, 255, 255);margin-top: 20px;">
                    <form [formGroup]="dataForm" (ngSubmit)="onSearch('')">
                        <mat-form-field appearance="outline">
                            <input matInput formControlName="search" placeholder="Search">
                            <mat-icon matSuffix>search</mat-icon>
                        </mat-form-field>
                    </form>
                    <span class="toolbar-item-spacer"></span>
                    <button style="margin-bottom: 20px;margin-right: 10px;" mat-flat-button color="primary"
                        matTooltip="Refresh data" (click)="refresh()">
                        <mat-icon>refresh</mat-icon>Refresh
                    </button>
                </mat-toolbar>
                <p class="popular-label">Popular tests</p>
                <mat-grid-list cols="4" rowHeight="100px">
                    <div *ngFor="let item of popularExam; let i = index">
                        <mat-grid-tile [colspan]="1" [rowspan]="1" class="popular-test">
                            <mat-card class="popular-item"
                                [ngClass]="{ 'even-background': i % 2 === 0, 'odd-background': i % 2 !== 0 }">
                                <mat-card-content
                                    (click)="onSearch(item.exam_name, false)">{{item.exam_name}}</mat-card-content>
                            </mat-card>
                        </mat-grid-tile>
                    </div>

                </mat-grid-list>
            </mat-card>
            <mat-card>
                <mat-card-header>
                    <mat-toolbar>
                        <mat-card-subtitle>{{totalItems}} exam</mat-card-subtitle>
                        <span class="toolbar-item-spacer"></span>
                        <mat-chip-set aria-label="Dog selection" style="float: right;" *ngIf="searchItems.length > 0">
                            <mat-chip color="primary" *ngFor="let item of searchItems;let i = index">
                                {{item}}
                                <button matChipRemove (click)="removeItem(i)">
                                    <mat-icon>cancel</mat-icon>
                                </button>
                            </mat-chip>
                        </mat-chip-set>

                    </mat-toolbar>
                </mat-card-header>
                <mat-list role="list">
                    <mat-divider></mat-divider>
                    <mat-list-item *ngFor="let item of data; let i = index" role="listitem" class="exam-item"
                        style="height: auto;">
                        <mat-card class="exam-card" [ngClass]="{'grey-background': i % 2 === 0}"
                            (click)="validateExam(item, 0)">
                            <mat-card-header>
                                <div class="ellipsis-container">
                                    <span style="font-size: 16px;">{{item.name}} - </span> <span
                                        style="font-weight: lighter;">{{item.description}}</span>
                                </div>
                            </mat-card-header>
                            <mat-chip-set aria-label="Dog selection" *ngIf="item.tags != ''" style="margin-left: 15px;">
                                <mat-chip *ngFor="let tag of item.tagList" color="primary"
                                    style="background-color: rgba(63, 58, 154, 0.319);">
                                    {{tag}}
                                </mat-chip>
                            </mat-chip-set>
                            <mat-card-content style="margin-top: 5px;">
                                <span class="exam-details">
                                    <mat-icon class="icon-display">grain</mat-icon> <span
                                        class="detail">{{item.level}}</span>
                                </span>
                                <span class="exam-details" matTooltip="Total question/s in the exam.">
                                    <mat-icon class="icon-display">format_list_numbered</mat-icon> <span
                                        class="detail">{{item.noquestions}} question/s</span>
                                </span>
                                <span class="exam-details" matTooltip="Time allotted to complete this exam.">
                                    <mat-icon class="icon-display">schedule</mat-icon> <span
                                        class="detail">{{item.maxtimetext}}</span>
                                </span>
                                <span class="exam-details"
                                    [matTooltip]="item.examtype == 'MCQ'?'This is a multiple choice exam.':'Exam Type'">
                                    <mat-icon class="icon-display">scatter_plot</mat-icon> <span
                                        class="detail">{{item.examtype}}</span>
                                </span>
                                <span fxHide fxHide.xs="true" fxHide.gt-xs="false">
                                    <span class="exam-details"
                                        matTooltip="Public exams are accessible to the public can be viewed both on our website and trial accounts. They serve as valuable tools for practice or for screening candidates through traditional problem-solving methods.">
                                        <mat-icon class="icon-display">turned_in_not</mat-icon> <span
                                            class="detail">{{item.viewtype}}</span>
                                    </span>
                                    <span class="exam-details" *ngIf="item.paid==0">
                                        <mat-icon class="icon-display">money_off</mat-icon> <span
                                            class="detail">Free</span>
                                    </span>
                                    <span class="exam-details" *ngIf="item.paid==1">
                                        <mat-icon class="icon-display">attach_money</mat-icon> <span
                                            class="detail">Paid</span>
                                    </span>
                                </span>
                            </mat-card-content>
                            <mat-card-content style="margin-top: 0px;" fxShow fxShow.xs="true" fxShow.gt-xs="false">
                                <span class="exam-details"
                                    matTooltip="Public exams are accessible to the public can be viewed both on our website and trial accounts. They serve as valuable tools for practice or for screening candidates through traditional problem-solving methods.">
                                    <mat-icon class="icon-display">turned_in_not</mat-icon> <span
                                        class="detail">{{item.viewtype}}</span>
                                </span>
                                <span class="exam-details" *ngIf="item.paid==0">
                                    <mat-icon class="icon-display">money_off</mat-icon> <span class="detail">Free</span>
                                </span>
                                <span class="exam-details" *ngIf="item.paid==1">
                                    <mat-icon class="icon-display">attach_money</mat-icon> <span
                                        class="detail">Paid</span>
                                </span>
                            </mat-card-content>
                        </mat-card>
                    </mat-list-item>
                </mat-list>

                <div class="pagination-container">
                    <span style="margin-right: 10px;">Items per page: </span>
                    <div class="dense-3" style="padding-top: 10px;">
                        <mat-form-field class="items-per-page" appearance="outline">
                            <mat-select [(value)]="pageSize" (selectionChange)="onSearch('')">
                                <mat-option [value]="10">10</mat-option>
                                <mat-option [value]="20">20</mat-option>
                                <mat-option [value]="50">50</mat-option>
                            </mat-select>
                        </mat-form-field>
                    </div>

                    <div class="page-info">
                        <span *ngIf="totalItems > 0">Showing {{startItem}} - {{endItem}} of
                            {{totalItems}}</span>
                    </div>

                    <button mat-icon-button (click)="prevPage()" [disabled]="currentPage === 1">
                        <mat-icon>keyboard_arrow_left</mat-icon>
                    </button>
                    <button mat-icon-button (click)="nextPage()" [disabled]="currentPage === totalPages">
                        <mat-icon>keyboard_arrow_right</mat-icon>
                    </button>
                </div>

            </mat-card>
        </div>
    </div>
</mat-card>