import { Component } from '@angular/core';
import { FormControl, FormGroup, Validators } from '@angular/forms';
import { ActivatedRoute, Router } from '@angular/router';
import { SnackbarService } from 'src/app/lib/snackbar/snackbar.service';
import { AuthService } from 'src/app/service/auth.service';
import { RecaptchaModel } from '../../model/recaptcha';
import { DataProtectionPolicyComponent } from '../data-protection-policy/data-protection-policy.component';
import { MatDialog } from '@angular/material/dialog';
import { AppConstants } from 'src/app/app.constants';
import { AuditTrailService } from 'src/app/service/audit.trail.service';

@Component({
  selector: 'app-confirm-user',
  templateUrl: './confirm-user.component.html',
  styleUrls: ['./confirm-user.component.css']
})
export class ConfirmUserComponent {
  public dataForm: FormGroup;
  dataProtection: boolean = false;
  recaptcha: RecaptchaModel = { token: '', expired: true };
  username: string;
  constructor(
    private snackbarService: SnackbarService,
    private authService: AuthService,
    private router: Router,
    public dialog: MatDialog,
    private _Activatedroute: ActivatedRoute,
    private constant: AppConstants,
    private auditTrailService: AuditTrailService

  ) { }

  ngOnInit() {
    this.username = this._Activatedroute.snapshot.paramMap.get('data');
    this.dataForm = new FormGroup({
      username: new FormControl(this.username === null ? '' : atob(this.username),
        [Validators.required, Validators.email]),
      password: new FormControl('12345678'),
      code: new FormControl('', [Validators.required])
    });
  }

  receiveDataFromCaptcha(data: any): void {
    this.recaptcha = data;
  }

  openDialog() {
    const dialogRef = this.dialog.open(DataProtectionPolicyComponent, { width: '700px' });

    dialogRef.afterClosed().subscribe(result => {
      // console.log(`Dialog result: ${result}`);
    });
  }

  public checkError = (controlName: string, errorName: string) => {
    return this.dataForm.controls[controlName].hasError(errorName);
  }

  async sendEmail(email) {
    await (await this.authService.emailRegistration(email))
      .subscribe((response) => { });
  }

  async onSubmit() {
    if (this.dataForm.valid) {
      try {
        const payload = {
          token: this.recaptcha.token
        }

        await (await this.authService.validateRecaptchaToken(payload)).subscribe(async (isValid) => {
          if (isValid) {
            await (await this.authService.confirm(this.dataForm.value))
              .subscribe(async (response) => {
                const data = JSON.parse(JSON.stringify(response));
                if (data.error) {
                  await (await this.auditTrailService.addAuditTrail("invalid code", null, this.dataForm.value.username, 8, 1, { message: "Invalid Verification code" }, null)).subscribe((response) => { });
                  this.snackbarService.showSnackbar("Invalid Verification code", 'top', 5000);

                  return;
                }
                await this.sendEmail(this.dataForm.value.username);
                await (await this.auditTrailService.addAuditTrail("confirm user", null, this.dataForm.value.username, 8, 1, { message: "User confirmed successfully" }, null)).subscribe((response) => { });
                this.snackbarService.showSnackbar("User confirmed successfully!", 'top', 2000);
                this.router.navigate(['login']);
              });
          } else {
            this.snackbarService.showSnackbar("Recaptcha expired", 'top', 5000);
          }
        })
      } catch (error) {
        this.snackbarService.showSnackbar("Service Unavailable", 'top', 5000);
      }
    }
  }
}
