import { AppConstants } from "./app.constants";
const constant = new AppConstants();

export function timeToMinutes(timeString) {
  const [hours, minutes] = timeString.split(':').map(Number);
  return hours * 60 + minutes;
}

export function minutesToTime(totalMinutes) {
  const hours = Math.floor(totalMinutes / 60);
  const minutes = totalMinutes % 60;
  return `${hours}:${minutes < 10 ? '0' : ''}${minutes}`;
}

export function calculateTotalSeconds(hour, minute) {
  // Convert hours and minutes to seconds
  const hoursInSeconds = hour * 3600; // 1 hour = 3600 seconds
  const minutesInSeconds = minute * 60; // 1 minute = 60 seconds

  // Calculate the total time duration in seconds
  const totalSeconds = hoursInSeconds + minutesInSeconds;

  return totalSeconds;
}

export function calculateTotalSecondsByMinute(minute) {
  // Convert hours and minutes to seconds
  const minutesInSeconds = minute * 60; // 1 minute = 60 seconds

  return minutesInSeconds;
}

export function formatWithCommas(value) {
  return value.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ',');
}

export function getExamTypeTextById(id: number): string {
  const examType = constant.ExamType.find(type => type.id == id);
  return examType ? examType.text : '';
}

export function cleanInputValue(value) {
  try {
    if (typeof value == 'number') {
      return value;
    }

    return value.replace(/,/g, '');

  } catch (error) {
    console.log(error);

    return '0';
  }
}