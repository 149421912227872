<mat-card style="width: 100%;">
    <mat-card-header>
        <mat-card-title class="header-style">Account Member</mat-card-title>
        <span class="spacer"></span>

        <div class="button-group">
            <div style="margin-bottom: 50px;">
                <button mat-icon-button color="primary" matTooltip="Approve" [disabled]="selection.isEmpty()"
                    *ngIf="!isAddMode && !isEditMode" (click)="handleApprovalStatusChange(1)" class="demo-button"
                    style="margin-bottom: 0px;">
                    <mat-icon class="icon-display"> thumb_up</mat-icon>
                </button>
                <button mat-icon-button color="warn" matTooltip="Disapprove" [disabled]="selection.isEmpty()"
                    *ngIf="!isAddMode && !isEditMode" (click)="handleApprovalStatusChange(2)" class="demo-button"
                    style="margin-bottom: 10px;">
                    <mat-icon class="icon-display"> thumb_down</mat-icon>
                </button>
                <button mat-icon-button color="warn" matTooltip="Delete" (click)="handleDelete()"
                    [disabled]="selection.isEmpty()" style="margin-bottom: 10px;" *ngIf="!isAddMode && !isEditMode">
                    <mat-icon class="icon-display">delete</mat-icon>
                </button>
            </div>
        </div>
    </mat-card-header>
    <div class="add">
        <div class="wrapper">
            <div *ngIf="isAddMode">
                <mat-toolbar>
                    <span>User/s</span>
                    <span class="spacer"></span>
                    <button mat-flat-button color="default" type="button" (click)="backAction()" class="demo-button">
                        Back
                    </button>
                    <button mat-flat-button color="primary" type="button" (click)="addUser()" class="demo-button">
                        Add User
                    </button>
                </mat-toolbar>
                <mat-card-content>
                    <mat-divider></mat-divider>
                    <br>
                    <li *ngFor="let item of addedUsers; let i = index"
                        style="text-decoration-style: none; list-style: none;">
                        <mat-form-field appearance="outline" class="example-full-width">
                            <mat-label>Email</mat-label>
                            <!-- <textarea matInput [ngModelOptions]="{standalone: true}" rows="1" cdkTextareaAutosize
                                    [(ngModel)]="item.email" (ngModelChange)="dataChanged(item)" type="text"></textarea> -->
                            <input matInput cdkFocusInitial (ngModelChange)="dataChanged(item)"
                                [formControl]="userEmailControls[i]" placeholder="Enter Email" type="email">

                            <mat-error *ngIf="userEmailControls[i].hasError('email')">
                                Please enter a valid email address.
                            </mat-error>
                            <mat-error *ngIf="userEmailControls[i].hasError('required')">
                                Email is required
                            </mat-error>

                            <button color="warn" matSuffix mat-icon-button aria-label="Clear"
                                (click)="removeUser(item, i)">
                                <mat-icon>cancel</mat-icon>
                            </button>
                        </mat-form-field>
                    </li>

                </mat-card-content>
                <mat-card-subtitle style="margin: 10px;margin-top: 10px;color: red;"
                    *ngIf="addedUsers.length == 0 && selectedMember != null">
                    No user/s added</mat-card-subtitle>

                <div fxShow fxShow.xs="true" fxShow.gt-xs="false">
                    <button mat-flat-button color="primary" style="float: right;" (click)="addMember()"
                        [disabled]="!dataForm.valid && addedUsers.length == 0" class="demo-button">
                        Submit Email/s
                    </button>
                </div>
            </div>
            <div *ngIf="!isAddMode">
                <mat-toolbar>
                    <span>User/s</span>
                    <span class="spacer"></span>
                    <div class="dense-2">
                        <mat-form-field appearance="outline" style="margin-top: 75px;">
                            <mat-label>Filter</mat-label>
                            <input matInput (keyup)="applyFilter($event)" #input>
                        </mat-form-field>
                    </div>
                    <button *ngIf="!isAddMode" style="margin-right: 10px;" mat-flat-button color="primary" matTooltip="Refresh data" (click)="refresh()">
                        <mat-icon>refresh</mat-icon>Refresh
                    </button>
                    <button mat-flat-button color="primary" type="button" (click)="addAction()" class="demo-button">
                        Add
                    </button>

                </mat-toolbar>
                <mat-card-content>
                    <mat-divider></mat-divider>
                    <br>
                    <table mat-table [dataSource]="dataSource" class="demo-table">
                        <ng-container matColumnDef="select">
                            <th mat-header-cell *matHeaderCellDef>
                                <mat-checkbox (change)="$event ? toggleAllRows() : null"
                                    [checked]="selection.hasValue() && isAllSelected()"
                                    [indeterminate]="selection.hasValue() && !isAllSelected()"
                                    [aria-label]="checkboxLabel()">
                                </mat-checkbox>
                            </th>
                            <td mat-cell *matCellDef="let row">
                                <mat-checkbox (click)="$event.stopPropagation()"
                                    (change)="$event ? selection.toggle(row) : null"
                                    [checked]="selection.isSelected(row)" [aria-label]="checkboxLabel(row)"
                                    [disabled]="row.has_exam==1">
                                </mat-checkbox>
                            </td>
                        </ng-container>
                        <ng-container matColumnDef="position">
                            <th mat-header-cell *matHeaderCellDef>User ID</th>
                            <td mat-cell *matCellDef="let element">{{element.userid}}</td>
                        </ng-container>

                        <ng-container matColumnDef="email">
                            <th mat-header-cell *matHeaderCellDef>Email</th>
                            <td mat-cell *matCellDef="let element" (click)="goToExam(element.userid)">
                                <p class="email">{{element.email}} </p>
                            </td>
                        </ng-container>

                        <ng-container matColumnDef="exam">
                            <th mat-header-cell *matHeaderCellDef>Has Exam</th>
                            <td mat-cell *matCellDef="let element">{{element.has_exam == 1? 'Yes': 'No'}}</td>
                        </ng-container>

                        <ng-container matColumnDef="status">
                            <th mat-header-cell *matHeaderCellDef>Status</th>
                            <td mat-cell *matCellDef="let element">{{element.status}}</td>
                        </ng-container>

                        <ng-container matColumnDef="action">
                            <th mat-header-cell *matHeaderCellDef>Action</th>
                            <td mat-cell *matCellDef="let element">
                                <button mat-icon-button color="warn" matTooltip="Delete"
                                    (click)="deleteMember([element.id])">
                                    <mat-icon>delete</mat-icon>
                                </button>
                            </td>
                        </ng-container>

                        <tr mat-header-row *matHeaderRowDef="displayedColumns"></tr>
                        <tr mat-row *matRowDef="let row; columns: displayedColumns;"></tr>
                    </table>
                    <span *ngIf="users">
                        <p fxLayoutAlign="center" *ngIf="users.length == 0"><br>No user/s found</p>
                    </span>
                    <mat-paginator [pageSizeOptions]="[5, 10, 20]" showFirstLastButtons></mat-paginator>

                </mat-card-content>

                <div fxShow fxShow.xs="true" fxShow.gt-xs="false">
                    <button mat-flat-button color="warn" style="float: right;" (click)="onSubmit()"
                        [disabled]="selection.isEmpty()" class="demo-button">
                        Delete
                    </button>
                </div>
            </div>

            <div fxHide fxHide.xs="true" fxHide.gt-xs="false" *ngIf="isAddMode">
                <mat-toolbar style="background-color: white;">
                    <span class="example-spacer"></span>
                    <button mat-flat-button color="primary" (click)="addMember()" [disabled]="addedUsers.length == 0"
                        class="demo-button">
                        Submit
                    </button>
                </mat-toolbar>
            </div>

        </div>

    </div>

</mat-card>