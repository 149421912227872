<div class="overlay" *ngIf="loadingService.visibility | async">
    <mat-spinner [diameter]="50" [strokeWidth]="4"></mat-spinner>
</div>

<!-- Header -->
<mat-toolbar color="primary" *ngIf="isAuthenticated" class="appbar">
    <button mat-icon-button (click)="sidenav.toggle()" matTooltip="Menu">
        <mat-icon>menu</mat-icon>
    </button>
    <img mat-card-image style="width: 150px;" src="../assets/images/quizbrew_name_v1.01.png" alt="App name">
    <!-- 
    large screen
    <div fxHide fxHide.xs="true" fxHide.gt-xs="false">
        <span>{{appName}}</span>
    </div>  
    Small Screen
    <div fxShow fxShow.xs="true" fxShow.gt-xs="false">
        <span>{{appNameShort}}</span>
    </div>
     -->
    <span class="toolbar-item-spacer"></span>

    <button *ngIf="user != null" mat-flat-button matTooltip="My Account" [matMenuTriggerFor]="userAccountMenu"
        style="border-radius: 25px;">

        <mat-icon class="avatar-icon">
            <ngx-avatars size="18" textSizeRatio="2" [name]="user.name" [src]="user.picture"></ngx-avatars>
        </mat-icon>
        {{user.given_name}}

    </button>
    <mat-menu #userAccountMenu [overlapTrigger]="false" yPosition="below">
        <button mat-menu-item (click)="openProfile()">
            <mat-icon>supervised_user_circle</mat-icon>
            <span>Profile</span>
        </button>
        <button mat-menu-item (click)="redirect('subscription-plan', null)">
            <mat-icon>rate_review</mat-icon>
            <span>Subscribe</span>
        </button>
        <button mat-menu-item routerLink="#">
            <mat-icon>help</mat-icon><span>Help</span>
        </button>
        <mat-divider></mat-divider>
        <button mat-menu-item (click)="logout()">
            <mat-icon>exit_to_app</mat-icon>Logout
        </button>
    </mat-menu>
</mat-toolbar>
<mat-sidenav-container>
    <mat-sidenav #sidenav style="width: 250px;overflow: hidden;">
        <mat-nav-list>
            <mat-expansion-panel [class.mat-elevation-z0]="true">
                <mat-expansion-panel-header>
                    <mat-icon>dashboard</mat-icon><span style="margin-left: 10px;">Dashboard</span>
                </mat-expansion-panel-header>
                <mat-nav-list (click)="sidenav.toggle()">
                    <a mat-list-item (click)="redirect('exam-list', 'done')">My Exam</a>
                    <a mat-list-item (click)="redirect('exam-list', 'available')">Available Exam</a>
                    <a mat-list-item routerLink="exam-catalogue">Exam Catalogue</a>
                </mat-nav-list>
            </mat-expansion-panel>
            <mat-expansion-panel [class.mat-elevation-z0]="true" *ngIf="isAdmin || isAccountAdmin">
                <mat-expansion-panel-header>
                    <mat-icon>build</mat-icon><span style="margin-left: 10px;">MasterFile</span>
                </mat-expansion-panel-header>
                <mat-nav-list (click)="sidenav.toggle()">
                    <a mat-list-item routerLink="masterfile/exam" *ngIf="isLinkShow">
                        <span>Exam</span>
                    </a>
                    <a mat-list-item routerLink="masterfile/category" *ngIf="isAdmin">
                        <span>Category</span>
                    </a>
                    <a mat-list-item routerLink="masterfile/user-exam" *ngIf="isAdmin">
                        <span>User exam</span>
                    </a>
                    <a mat-list-item routerLink="masterfile/account-member" *ngIf="isLinkShow">
                        <span>Account Member</span>
                    </a>
                    <a mat-list-item routerLink="masterfile/exam-assignment">
                        <span>Assignment</span>
                    </a>
                </mat-nav-list>
            </mat-expansion-panel>
            <mat-expansion-panel [class.mat-elevation-z0]="true" *ngIf="isAdmin">
                <mat-expansion-panel-header>
                    <mat-icon>supervised_user_circle</mat-icon><span style="margin-left: 10px;">Accounts</span>
                </mat-expansion-panel-header>
                <mat-nav-list (click)="sidenav.toggle()">
                    <a mat-list-item routerLink="account/subscription">
                        <span>Subscription</span>
                    </a>
                    <a mat-list-item routerLink="account/pay-model">
                        <span>Pay Model</span>
                    </a>
                    <a mat-list-item routerLink="account/group">
                        <span>Group</span>
                    </a>
                </mat-nav-list>
            </mat-expansion-panel>
            <mat-expansion-panel [class.mat-elevation-z0]="true" *ngIf="isAdmin">
                <mat-expansion-panel-header>
                    <mat-icon>work</mat-icon><span style="margin-left: 10px;">Administration</span>
                </mat-expansion-panel-header>
                <mat-nav-list (click)="sidenav.toggle()">
                    <a mat-list-item routerLink="audit-trail">
                        <span>Audit Trail</span>
                    </a>

                </mat-nav-list>
            </mat-expansion-panel>
            <!-- <a mat-list-item routerLink="audit-trail" *ngIf="isAdmin" (click)="sidenav.toggle()">
                <mat-icon  style="margin-left: 8px;float: left">history</mat-icon><span class="audit-label">Audit Trail</span>
            </a> -->
        </mat-nav-list>

        <div class="footer">
            <a href="https://ogenkisoftlab.com/#" target="_blank">
                <img src="../assets/images/footer-logo.png" alt="" srcset=""></a>
        </div>
    </mat-sidenav>
    <mat-sidenav-content>
        <div style="height: 100vh; padding: 0px 20px 0px 20px;margin-top: 0px;">
            <app-breadcrumbs />
            <router-outlet></router-outlet>
        </div>
    </mat-sidenav-content>

</mat-sidenav-container>