<mat-card style="width: 100%;">
    <mat-card-header>
        <mat-card-title class="header-style">Group</mat-card-title>
    </mat-card-header>

    <mat-tab-group mat-stretch-tabs="false" mat-align-tabs="start"
        (selectedIndexChange)="onSelectedIndexChange($event)">
        <mat-tab label="Result">
            <div class="table-container">
                <mat-toolbar>
                    <mat-form-field *ngIf="!isAddMode && !isEditMode" style="margin-top: 20px;">
                        <mat-label>Filter</mat-label>
                        <input matInput (keyup)="applyFilter($event)" #input>
                    </mat-form-field>
                    <span class="toolbar-item-spacer"></span>
                    <!-- large screen -->
                    <div fxHide fxHide.xs="true" fxHide.gt-xs="false">
                        <button *ngIf="!isAddMode && !isEditMode" style="margin-right: 10px;" mat-flat-button
                            color="primary" matTooltip="Refresh data" (click)="refresh()">
                            <mat-icon>refresh</mat-icon>Refresh
                        </button>
                        <button *ngIf="!isAddMode && !isEditMode" mat-flat-button color="accent"
                            [disabled]="selection.isEmpty()" (click)="deleteHandler()" class="demo-button"
                            style="margin-right: 10px;">
                            <mat-icon>delete</mat-icon>Delete
                        </button>
                        <button *ngIf="!isAddMode && !isEditMode" mat-flat-button color="primary"
                            (click)="exportAction()" class="demo-button" style="margin-right: 10px;">
                            <mat-icon> insert_drive_file</mat-icon>CSV
                        </button>
                        <button *ngIf="!isAddMode && !isEditMode" mat-flat-button color="primary" (click)="addAction()"
                            class="demo-button" style="margin-left: 10px;">
                            Add data
                        </button>
                        <button mat-raised-button *ngIf="isAddMode || isEditMode" (click)="backAction()"
                            class="demo-button" style="margin-left: 10px;">
                            Back
                        </button>
                    </div>

                    <!-- Small Screen -->
                    <div fxShow fxShow.xs="true" fxShow.gt-xs="false">
                        <button *ngIf="!isAddMode && !isEditMode" class="refresh" mat-icon-button color="primary"
                            matTooltip="Refresh data" (click)="refresh()">
                            <mat-icon>refresh</mat-icon>
                        </button>
                        <button mat-icon-button *ngIf="!isAddMode && !isEditMode" (click)="deleteHandler()"
                            matTooltip="Delete" [disabled]="selection.isEmpty()">
                            <mat-icon color="warn">delete</mat-icon>
                        </button>
                        <button *ngIf="!isAddMode && !isEditMode" mat-icon-button color="primary"
                            matTooltip="Export Data" (click)="exportAction()" class="demo-button">
                            <mat-icon> insert_drive_file</mat-icon>
                        </button>
                        <button *ngIf="!isAddMode && !isEditMode" mat-icon-button color="primary" matTooltip="Add Data"
                            (click)="addAction()" class="demo-button">
                            <mat-icon> add_circle</mat-icon>
                        </button>
                        <button mat-raised-button *ngIf="isAddMode || isEditMode" (click)="backAction()"
                            class="demo-button" style="margin-left: 10px;">
                            Back
                        </button>
                    </div>

                </mat-toolbar>

                <div class="mat-elevation-z0 table-container" style="margin-top: 15px;"
                    *ngIf="!isAddMode && !isEditMode">
                    <table mat-table [dataSource]="dataSource" class="demo-table">
                        <ng-container matColumnDef="select">
                            <th mat-header-cell *matHeaderCellDef>
                                <mat-checkbox (change)="$event ? toggleAllRows() : null"
                                    [checked]="selection.hasValue() && isAllSelected()"
                                    [indeterminate]="selection.hasValue() && !isAllSelected()"
                                    [aria-label]="checkboxLabel()">
                                </mat-checkbox>
                            </th>
                            <td mat-cell *matCellDef="let row">
                                <mat-checkbox (click)="$event.stopPropagation()"
                                    (change)="$event ? selection.toggle(row) : null"
                                    [checked]="selection.isSelected(row)" [aria-label]="checkboxLabel(row)">
                                </mat-checkbox>
                            </td>
                        </ng-container>
                        <ng-container matColumnDef="position">
                            <th mat-header-cell *matHeaderCellDef>ID</th>
                            <td mat-cell *matCellDef="let element">{{element.id}}</td>
                        </ng-container>

                        <ng-container matColumnDef="name">
                            <th mat-header-cell *matHeaderCellDef>Name</th>
                            <td mat-cell *matCellDef="let element">{{element.name}}</td>
                        </ng-container>

                        <ng-container matColumnDef="groupadmin">
                            <th mat-header-cell *matHeaderCellDef>Group Admin</th>
                            <td mat-cell *matCellDef="let element" [innerHTML]="element.emails"></td>
                        </ng-container>

                        <ng-container matColumnDef="active">
                            <th mat-header-cell *matHeaderCellDef>Active</th>
                            <td mat-cell *matCellDef="let element">{{element.active == 0?'No':'Yes'}}</td>
                        </ng-container>

                        <!-- Action Column -->
                        <ng-container matColumnDef="action">
                            <th mat-header-cell *matHeaderCellDef style="text-align: center;">Action</th>
                            <td mat-cell *matCellDef="let element" style="text-align: center;">
                                <button mat-icon-button (click)="editItem(element)">
                                    <mat-icon color="primary">edit</mat-icon>
                                </button>
                                <button mat-icon-button (click)="delete([element.id])">
                                    <mat-icon color="warn">delete</mat-icon>
                                </button>
                            </td>
                        </ng-container>

                        <tr mat-header-row *matHeaderRowDef="displayedColumns"></tr>
                        <tr mat-row *matRowDef="let row; columns: displayedColumns;"></tr>
                    </table>
                    <mat-paginator [pageSizeOptions]="[5, 10, 20]" showFirstLastButtons></mat-paginator>

                </div>
            </div>

            <div class="add" *ngIf="isAddMode || isEditMode">
                <mat-card style="margin: 0;border: 0;box-shadow: none;">
                    <mat-card-content class="formContent">
                        <form [formGroup]="dataForm" (ngSubmit)="onSubmit()" class="form">
                            <mat-form-field appearance="outline" class="example-full-width form-field"
                                style="width: 320px;">
                                <mat-label>Name</mat-label>
                                <input matInput formControlName="name">
                                <mat-error *ngIf="checkError('name', 'required')">Group Name is required</mat-error>
                                <mat-error *ngIf="checkError('name', 'maxlength')">Group Name should be less than 20
                                    characters</mat-error>
                            </mat-form-field>
                            <div fxLayout="column" fxLayout.gt-xs="row">
                                <!-- <mat-form-field appearance="fill" class="example-full-width form-field"
                                    style="min-width: auto;">
                                    <mat-label>Group Admin</mat-label>
                                    <mat-chip-grid #chipGrid aria-label="Enter answer">
                                        <mat-chip-row *ngFor="let item of accountAdmin" (removed)="remove(item)"
                                            [editable]="true" (edited)="edit(item, $event)"
                                            [aria-description]="'press enter to edit ' + item.user">
                                            {{ item.user }}
                                            <button matChipRemove [attr.aria-label]="'remove ' + item.user">
                                                <mat-icon>cancel</mat-icon>
                                            </button>
                                        </mat-chip-row>
                                    </mat-chip-grid>
                                    <input placeholder="New Fruit..." #fruitInput [formControl]="accountAdmin"
                                        [matChipInputFor]="chipGrid" [matAutocomplete]="auto"
                                        [matChipInputSeparatorKeyCodes]="separatorKeysCodes"
                                        (matChipInputTokenEnd)="add($event)" />
                                    <mat-autocomplete #dataAuto="matAutocomplete" [displayWith]="displaySelectData">
                                        <mat-option *ngFor="let item of filteredSelectData | async" [value]="item">
                                            {{ item.name }}
                                        </mat-option>
                                    </mat-autocomplete>
                                    <mat-error *ngIf="checkError('groupAdmin', 'required')">Group Admin is
                                        required</mat-error>
                                </mat-form-field> -->

                                <mat-form-field appearance="outline" class="form-field example-full-width"
                                    floatLabel="always">
                                    <mat-label>Group Admin</mat-label>
                                    <mat-chip-grid #chipGrid aria-label="Admin selection">
                                        <mat-chip-row *ngFor="let item of accountAdmin" (removed)="remove(item)">
                                            {{item.user}}
                                            <button matChipRemove [attr.aria-label]="'remove ' + admin">
                                                <mat-icon>cancel</mat-icon>
                                            </button>
                                        </mat-chip-row>
                                    </mat-chip-grid>
                                    <input placeholder="New admin" #adminInput [formControl]="adminCtrl"
                                        [matChipInputFor]="chipGrid" [matAutocomplete]="auto"
                                        [matChipInputSeparatorKeyCodes]="separatorKeysCodes"
                                        (matChipInputTokenEnd)="add($event)" />
                                    <mat-autocomplete #auto="matAutocomplete" (optionSelected)="selected($event)">
                                        <mat-option *ngFor="let item of filteredSelectData | async" [value]="item">
                                            {{item.name}}
                                        </mat-option>
                                    </mat-autocomplete>
                                    <p style="color: red;font-size: 13px;padding-top: 20px;" *ngIf="hasError.value">
                                        {{hasError.message}}
                                    </p>
                                </mat-form-field>

                            </div>

                            <mat-toolbar style="background-color: white;margin-bottom: 20px;">
                                <div class="content" fxLayout="row" fxLayout.xs="column" fxFlexFill>
                                    <div fxFlex="80" fxFlex.xs="55">
                                        <mat-checkbox formControlName="active">Active</mat-checkbox>

                                    </div>
                                    <div fxFlex="20">
                                        <button mat-raised-button type="submit" style="margin-right: 10px;float: right;"
                                            color="primary">Submit</button>
                                    </div>

                                </div>
                            </mat-toolbar>

                        </form>
                    </mat-card-content>
                </mat-card>

            </div>
        </mat-tab>

        <mat-tab label="Audit Trail">
            <app-audit-trail-table [module]="9"></app-audit-trail-table>
        </mat-tab>
    </mat-tab-group>

</mat-card>