import { Component, DoCheck, HostListener } from '@angular/core';
import { Router } from '@angular/router';
import { LoadingService } from './service/loading.service';
import { AuthService } from './service/auth.service';
import { InactivityService } from './lib/inactivity/inactivity.service';
import { AppConstants } from './app.constants';
import { SessionService } from './service/session.service';
import { MatDialog } from '@angular/material/dialog';
import { UserProfileComponent } from './components/user-profile/user-profile.component';
import { AuditTrailService } from './service/audit.trail.service';
import { SubscriptionModalComponent } from './components/subscription-modal/subscription-modal.component';

@Component({
  selector: 'app-root',
  templateUrl: './app.component.html',
  styleUrls: ['./app.component.css']
})

export class AppComponent implements DoCheck {

  constructor(
    private router: Router,
    public loadingService: LoadingService,
    public authService: AuthService,
    private inactivityService: InactivityService,
    private constant: AppConstants,
    private sessionService: SessionService,
    public dialog: MatDialog,
    private auditTrailService: AuditTrailService
  ) { }

  public getScreenWidth: any;
  public getScreenHeight: any;
  public screenSize: any;
  isAuthenticated = false;
  user = null;
  loggedIn?: boolean;
  isAdmin: boolean = false;
  isGroup: boolean = false;
  isAccountAdmin: boolean = false;
  appName = this.constant.appName;
  appNameShort = this.constant.appNameShort;
  isLinkShow: boolean = false;
  
  ngOnInit() {
    this.getScreenWidth = window.innerWidth;
    this.getScreenHeight = window.innerHeight;
    this.screenSize = this.getScreenSize(this.getScreenWidth);
    this.authService.isAuthenticated$.subscribe((isAuthenticated) => {
      this.isAuthenticated = isAuthenticated;
    });
    this.checkSession();
  }

  checkSession() {
    if (this.authService.getToken()) {
      this.sessionService.checkSessionExpiry();
    }
  }

  ngDoCheck(): void {
    this.initialLoad();
  }

  @HostListener('window:resize', ['$event'])
  onWindowResize() {
    this.getScreenWidth = window.innerWidth;
    this.getScreenHeight = window.innerHeight;
    this.screenSize = this.getScreenSize(this.getScreenWidth);
  }

  getScreenSize(width) {
    let size = null;
    if (width <= 640) {
      size = 'sm';
    } else if (width >= 641 && width <= 1007) {
      size = 'md';
    } else if (width >= 1008) {
      size = 'lg'
    }
    return size;
  }

  initialLoad() {
    if (this.authService.getToken()) {
      this.user = this.authService.getUser();
      if (this.user != null) {
        this.isAdmin = this.authService.extractId(this.user.azp) === 1;
        this.isAccountAdmin = this.authService.extractId(this.user.azp) === 2;
        this.isAuthenticated = true;
        this.isGroup = this.user.group !== "Individual - No Group";
        this.isLinkShow = this.isGroup || this.isAdmin
      }
    }
  }

  openProfile() {
    const dialogRef = this.dialog.open(UserProfileComponent, {
      width: '850px',
    });

    // Scroll to top after modal is opened
    dialogRef.afterOpened().subscribe(() => {
      const dialogContent = document.querySelector('mat-dialog-content');
      if (dialogContent) {
        dialogContent.scrollTop = 0;  
      }
    });
  }

  openSubscription() {
    this.dialog.open(SubscriptionModalComponent, {
      width: '450px',
      height: '300px',
    });
  }

  redirect(path, type) {
    if (type) {
      this.router.navigate([path, type]);
    } else {
      this.router.navigate([path]);
    }
  }

  async logout() {
    const confirmation = confirm('Do you want to logout');
    if (confirmation) {
      await (await this.auditTrailService.addAuditTrail("logout", null, this.authService.getUser().email, 6, 1, { logout: 1, message: "Logout" }, null)).subscribe((response) => { });
      this.isAuthenticated = false;
      this.authService.logOut();
      this.router.navigate(['login']);
    }
  }
}
