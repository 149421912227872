import { Component, ElementRef, EventEmitter, Input, Output, ViewChild } from '@angular/core';
import { FormGroup, FormBuilder, Validators } from '@angular/forms';
import { ReCaptcha2Component } from 'ngx-captcha';
import { AppConstants } from 'src/app/app.constants';
import { environment } from 'src/environments/environment';

@Component({
  selector: 'app-recaptcha',
  templateUrl: './recaptcha.component.html',
  styleUrls: ['./recaptcha.component.css']
})
export class RecaptchaComponent {
  constructor(private formBuilder: FormBuilder, private constant: AppConstants) { }

  public aFormGroup: FormGroup;
  @ViewChild('captchaElem') captchaElem: ReCaptcha2Component;
  @ViewChild('langInput') langInput: ElementRef;
  @Input() data: any;
  @Output() dataEvent = new EventEmitter<any>();

  public captchaIsLoaded = false;
  public captchaSuccess = false;
  public captchaIsExpired = false;
  public captchaResponse?: string;

  public theme: 'light' | 'dark' = 'light';
  public size: 'compact' | 'normal' = 'normal';
  public lang = 'en';
  public type: 'image' | 'audio';

  siteKey = environment.recaptchaSiteKey;

  ngOnInit() {
    this.aFormGroup = this.formBuilder.group({
      recaptcha: ['', Validators.required]
    });
  }
  // reset(): void {
  //   this.captchaElem.resetCaptcha();
  // }
  handleSuccess(data) {
    this.dataEvent.emit({ token: data, expired: false });
  }

  handleExpire() {
    this.dataEvent.emit({ token:'', expired: true });
  }
}
