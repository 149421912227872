import { Injectable } from '@angular/core';
import axios, { AxiosInstance, AxiosRequestConfig, AxiosResponse } from 'axios';
import { AuthService } from '../service/auth.service';
import { environment } from 'src/environments/environment';

@Injectable({
  providedIn: 'root',
})
export class HttpAxiosService {
  private httpClient: AxiosInstance;

  constructor(
    private authService: AuthService,

  ) {
    this.httpClient = axios.create({
      // baseURL: 'https://api.virtuascript.net',
       baseURL: environment.apiUrl
    });

    this.setupInterceptors();
  }

  private setupInterceptors() {
    const token = this.authService.getToken();
    const user = this.authService.getUser();

    this.httpClient.interceptors.request.use(
      (config) => {
        config.headers.Authorization = token ? `Bearer ${token}` : '';
        return config;
      },
      (error) => {
        return Promise.reject(error);
      }
    );

  }

  public get<T>(url: string, config?: AxiosRequestConfig) {
    return this.httpClient.get<T>(url, config);
  }

  public post<T>(url: string, data?: any, config?: AxiosRequestConfig) {
    return this.httpClient.post<T>(url, data, config);
  }

  public put<T>(url: string, data?: any, config?: AxiosRequestConfig): Promise<AxiosResponse<T>> {
    return this.httpClient.put<T>(url, data, config);
  }

}
