<div class="main-div">
    <mat-card style="border: 0; box-shadow: none;">
        <mat-card-header>
            <mat-card-title>User Signup</mat-card-title>
        </mat-card-header>
        <mat-card-content class="formContent">
            <form [formGroup]="dataForm" (ngSubmit)="onSubmit()" class="form">
                <div fxLayout="column" fxLayout.gt-xs="row">
                    <mat-form-field appearance="outline" class="form-field">
                        <mat-label>First name</mat-label>
                        <input matInput formControlName="firstname" required>
                        <mat-error *ngIf="checkError('firstname', 'required')">First name is
                            required</mat-error>
                    </mat-form-field>

                    <mat-form-field appearance="outline" class="example-full-width form-field">
                        <mat-label>Last name</mat-label>
                        <input matInput formControlName="lastname" required>
                        <mat-error *ngIf="checkError('lastname', 'required')">Last name is
                            required</mat-error>
                    </mat-form-field>

                </div>

                <mat-form-field appearance="outline" class="example-full-width form-field">
                    <mat-label>Email</mat-label>
                    <input matInput 
                        placeholder="pat@example.com" formControlName="email" required>
                    <!-- <mat-error *ngIf="checkError('email', 'required')">Email is required</mat-error> -->
                    <mat-error *ngIf="checkError('email', 'invalidEmail')">Email is invalid</mat-error>
                </mat-form-field>
                <mat-error class="emailLength" *ngIf="checkError('email', 'emailLengthExceeded')">Email length exceeds
                    100 characters</mat-error>
                <app-recaptcha [data]="recaptcha" (dataEvent)="receiveDataFromCaptcha($event)"></app-recaptcha>

                <mat-toolbar style="background-color: white;margin-bottom: 20px;padding: 0;">
                    <div class="content" fxLayout="row" fxLayout.xs="column" fxFlexFill>
                        <div fxFlex="80" fxFlex.xs="55">
                            <mat-checkbox class="non-clickable-label" [ngModelOptions]="{standalone: true}"
                                class="ng-margin" [(ngModel)]="dataProtection">
                                <p class="data-protection" (click)="openDialog()"> I read and accept the data protection
                                    policy</p>
                            </mat-checkbox>

                        </div>
                        <div fxFlex="20">
                            <button mat-raised-button type="submit" color="primary"
                                style="margin-top: 12px;float: right;"
                                [disabled]="!dataForm.valid || !dataProtection || recaptcha.expired">Submit</button>
                        </div>
                    </div>
                </mat-toolbar>
            </form>
        </mat-card-content>

    </mat-card>

</div>