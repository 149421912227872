import { CommonModule } from '@angular/common';
import { Component, Inject } from '@angular/core';
import { ReactiveFormsModule, FormGroup, FormControl, Validators } from '@angular/forms';
import { MatAutocompleteModule } from '@angular/material/autocomplete';
import { MatButtonModule } from '@angular/material/button';
import { MatCheckboxModule } from '@angular/material/checkbox';
import { MAT_DIALOG_DATA, MatDialogModule } from '@angular/material/dialog';
import { MatFormFieldModule } from '@angular/material/form-field';
import { MatInputModule } from '@angular/material/input';
import { ActivatedRoute, Router } from '@angular/router';
import { Observable, catchError, throwError, startWith, map } from 'rxjs';
import { SnackbarService } from 'src/app/lib/snackbar/snackbar.service';
import { AuditTrailService } from 'src/app/service/audit.trail.service';
import { ExamService } from 'src/app/service/exam.service';
import { UserService } from 'src/app/service/user.service';

@Component({
  selector: 'app-add-user-exam-modal',
  templateUrl: './add-user-exam-modal.component.html',
  styleUrls: ['./add-user-exam-modal.component.css'],
  standalone: true,
  imports: [MatDialogModule, MatInputModule, MatButtonModule, MatCheckboxModule, MatAutocompleteModule, MatFormFieldModule, CommonModule, ReactiveFormsModule]
})
export class AddUserExamModalComponent {
  public dataForm: FormGroup;
  exam: any[];
  userId: any;
  filteredExam: Observable<any[]>;
  userIds: any[];
  constructor(
    private examService: ExamService,
    private userService: UserService,
    private snackbarService: SnackbarService,
    private _Activatedroute: ActivatedRoute,
    private router: Router,
    private auditTrailService: AuditTrailService,
    @Inject(MAT_DIALOG_DATA) public data: any

  ) { }
  ngOnInit() {
    this.userIds = this.data.userIds;
    this.dataForm = new FormGroup({
      examControl: new FormControl('', [Validators.required]),
      isActive: new FormControl(true, [Validators.required]),
    });
    this.fetchData();
  }

  private _filterExam(value: any): any[] {
    if (value != null) {
      if (value == '') {
        return this.exam;
      }
      if (typeof value === 'string') {
        const filterValue = value.toLowerCase();
        return this.exam.filter(item => item.name.toLowerCase().includes(filterValue));
      } else if (typeof value === 'object' && value.name) {
        return this.exam.filter(item => item.name.toLowerCase() === value.name.toLowerCase());
      }
    }
    return [];
  }
  // Function to display the selected exam
  displayExam(exam: any): string {
    return exam ? exam.name : '';
  }

  public checkError = (controlName: string, errorName: string) => {
    return this.dataForm.controls[controlName].hasError(errorName);
  }

  async fetchData() {
    (await this.examService.getAllExam())
      .pipe(
        catchError(() => {
          return Observable.call(throwError(() => "Error"));
        })
      )
      .subscribe((response) => {
        const data = JSON.parse(JSON.stringify(response));
        this.exam = data;
        this.filteredExam = this.dataForm.get('examControl').valueChanges.pipe(
          startWith(''),
          map((value: any) => this._filterExam(value))
        );
      });
  }

  async onSubmit() {
    if (this.dataForm.valid) {
      const payload = {
        ...this.dataForm.value,
        userIds: this.userIds
      }
      await (await this.userService.addUserExam(payload))
        .subscribe((response) => {
        const data = JSON.parse(JSON.stringify(response));
          this.snackbarService.showSnackbar(data.message, 'top', 2000);
          this.auditTrailService.sendNotification(true);
        });
    }
  }

}
