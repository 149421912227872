import { Directive, ElementRef, HostListener } from '@angular/core';
import {formatWithCommas} from '../../common-function'
@Directive({
  selector: '[appCommaDelimiter]'
})
export class CommaDelimiterDirective {

  constructor(private el: ElementRef) { }

  @HostListener('input', ['$event']) onInput(event: any) {
    // Get the input value
    let inputVal = this.el.nativeElement.value;

    // Remove non-numeric characters
    inputVal = inputVal.replace(/[^0-9.]/g, '');

    // Format with comma delimiter
    inputVal = formatWithCommas(inputVal);

    // Update the input value
    this.el.nativeElement.value = inputVal;
  }

}
