import { Injectable } from '@angular/core';
import { environment } from 'src/environments/environment';

@Injectable({
    providedIn: 'root'
})
export class AppConstants {
    public appName = "QuizBrew";
    public appNameShort = "QuizBrew";
    public sessionTimeoutMin = 10;
    public examSubmissionTimerSec = 10;
    public noAccountId = environment.noAccountId;
    public imageAnswerFilePath = environment.imageAnswerFilePath;
    public invidualNoGroup = "Individual - No Group";
    public googleClientId = "747371411974-cno4j5dqksent5d6mi45ud62t4cpraqm.apps.googleusercontent.com";
    // public recaptchaSiteKey = "6Ldmqg0qAAAAAG7vHozbOVqVWzkwcVxHnq57lKVc";
    public recaptchaVerifyURL = "https://www.google.com/recaptcha/api/siteverify";
    // public apiKey = "vD4yizZj739LamE5JhpZ38rEMJux8tVFuHL87005";
    public sessionTimeoutTitle = "Session Timed out";
    public sessionTimeoutMessage = "Your session has timed out due to inactivity. Please log in again to continue.";
    public emailCodeMessage = "Your information has been submitted successfully. Please check your email for the confirmation code.";
    public letters = ['A', 'B', 'C', 'D', 'E', 'F'];
    public examMode = {
        Start: 0,
        Continue: 1,
        Retake: 2,
        Details: 3
    };

    public subscriptionStatus = {
        Pending: 0,
        Approved: 1,
        Disapproved: 2,
        Blocked: 3
    };
    public auditTrailAction = {
        Insert: 1,
        Update: 2,
        Delete: 3,
        Download: 4,
        Login: 5,
        Logout: 6,
        Register: 7,
        confirm: 8,
        "Session Timeout": 9,
        "Start Exam": 10,
        "Submit Exam": 11
    };
    public auditTrailModule = {
        Auth: 1,
        Exam: 2,
        ExamMasterfile: 3,
        QuestionMasterfile: 4,
        CategoryMasterfile: 5,
        UserMasterfile: 6,
        UserExamMasterfile: 7,
        AccountPayModel: 8,
        AccountGroup: 9,
        AccountSubscription: 10

    };

    public moduleList = [
        { id: 1, name: "Auth" },
        { id: 2, name: "Exam" },
        { id: 3, name: "Exam Masterfile" },
        { id: 4, name: "Question Masterfile" },
        { id: 5, name: "Category Masterfile" },
        { id: 6, name: "User Masterfile" },
        { id: 7, name: "User Exam Masterfile" },
        { id: 8, name: "Account Pay Model" },
        { id: 9, name: "Account Group" },
        { id: 10, name: "Account Subscription" },
        { id: 11, name: "Account Member" },
    ];

    public level = [
        { id: 1, text: "1" },
        { id: 2, text: "2" },
        { id: 3, text: "3" },
        { id: 4, text: "4" },
    ];

    public role = [
        { id: 0, text: "Candidate" },
        { id: 1, text: "System Admin" },
        { id: 2, text: "Account Admin" },
    ];
    public ExamType = [
        { id: 1, text: "MCQ" },
        { id: 2, text: "Multiple Choice" },
        { id: 3, text: "Coding" },
        { id: 4, text: "Numeric" },
        { id: 5, text: "Text" },
    ];

    public QuestionType = [
        { id: 1, text: "Multiple Correct Answers" },
        { id: 2, text: "Multiple Choice" },
        { id: 3, text: "Text Answer" },
        { id: 4, text: "Number Answer" },
        { id: 5, text: "Essay" },
    ];
    public modelFrequency = [
        { id: 1, text: "Monthly" },
        { id: 2, text: "Annual" },
        { id: 3, text: "Forever" },

    ];

    public subscriptionType = [
        { id: 1, text: "Individual" },
        { id: 2, text: "Group" },

    ];

    public status = [
        { id: 1, text: "Active" },
        { id: 2, text: "Inactive" },
        { id: 3, text: "Blocked" },

    ];

    public deleteDataMessage = 'Are you sure you want to delete the selected data?';
    public serverError = 'Service Unavailable';
    public errorImportCSV = "Error while importing CSV data.";
    public errorExportCSV = "Error while exporting CSV data.";
    public finishExam = "Are you sure you want to submit the exam?"
    public wordCountMinimum = "It seems like you haven’t completed your current response. Are you sure you want to move on to the another question? If you’d like to continue, simply click “Yes” Otherwise, you can return to finish your current answer.";
    public deleteQuestionAndMessage = 'Deleting selected question will also delete the answer?';
    public updateResultConfirmation = "Please confirm that the values you have entered are final. <br>Once submitted, this action cannot be undone. <br>If you need to make any changes, please do so now before proceeding.";
    public changeQuestionTypeMessage = 'Changing the question type will delete previous answers. Do you want to proceed?';
    public examTimeUsedUp = 'Sorry the time alloted for this exam was already used up. Kindly click Finish Exam button to complete. Failure to complete will result to a NO score. Thank you.';
    public deleteUserConfirmation = 'This action is irreversible and will permanently remove all associated data, including exams.';
    public invalidExam = 'The setup for this exam is not yet complete. Kindly contact the Administrator on this matter. Thanks.';
    public startExamMessage = `Please be informed that you can not go back nor stop once the exam has started. <br>
    Ensure that you are ready and computer, internet connection, as well as power source are stable. <br><br>Press start to continue.`;
    public textAnswerToolTip = "Please enter text using alphabetic characters only (A-Z, a-z). Avoid using numbers, symbols, or special characters unless it is part of the answer. Not case sensitive, you can answer in lower or uppercase as long the spelling is correct.";
    public wholeNumberToolTip = "Please enter a whole number only. Avoid using any non-numeric characters or decimal points. Comma is provided to make the number readable.";
    public decimalNumberToolTip = "Please enter a decimal number only (e.g., 10.5). Avoid using any unnecessary characters or symbols. Comma is provided to make the numbers readable.";
}