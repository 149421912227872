import { Injectable } from '@angular/core';
import { AppConstants } from '../app.constants';
import { Subject } from 'rxjs/internal/Subject';
import { Router } from '@angular/router';
import { AuthService } from './auth.service';
import { DialogService } from './dialog.service';
import { CookieService, CookieOptions } from 'ngx-cookie-service';
import { AuditTrailService } from './audit.trail.service';

@Injectable({
  providedIn: 'root'
})
export class SessionService {
  constructor(
    private dialogService: DialogService,
    private router: Router,
    private constant: AppConstants,
    private authService: AuthService,
    private cookieService: CookieService,
    private auditTrailService: AuditTrailService

  ) { }

  getExpiryTimeStamp() {
    const sessionDurationInMinutes = this.constant.sessionTimeoutMin;
    const expiryTimestamp: number = new Date().getTime() + sessionDurationInMinutes * 60 * 1000;
    return expiryTimestamp;
  }
  setSessionExpiry() {
    const expiryTimestamp = this.getExpiryTimeStamp();

    const cookieOptions: CookieOptions = {
      secure: true,
      expires: new Date(expiryTimestamp),
    };

    this.cookieService.set('sessionExpiry', expiryTimestamp.toString(), cookieOptions);
    const token = this.authService.getToken();
    if (token) {
      this.authService.setTokenCookie(token, expiryTimestamp);
    }
  }

  async checkSessionExpiry() {
    const storedExpiry: string = this.cookieService.get('sessionExpiry');
    if (storedExpiry) {
      const currentTimestamp: number = new Date().getTime();
      if (currentTimestamp > parseInt(storedExpiry, 10)) {
        await (await this.auditTrailService.addAuditTrail("timeout", null, this.authService.getUser().email, 9, 1, { message: "Session Timed out" }, null)).subscribe((response) => { });
        this.authService.logOut();
        this.router.navigate(['login']);
        this.dialogService.openDialog(this.constant.sessionTimeoutTitle, this.constant.sessionTimeoutMessage, 'Okay', false, async (confirmed) => {
        });
      }
    }
  }

}