import { Component, ElementRef, inject, OnInit, ViewChild } from '@angular/core';
import { ExamService } from '../../service/exam.service';
import { Router, RouterModule } from '@angular/router';
import { AsyncPipe, NgFor } from '@angular/common';
import { MatListModule } from '@angular/material/list';
import { MatButtonModule } from '@angular/material/button';
import { MatCardModule } from '@angular/material/card';
import { MatDialog, MatDialogModule } from '@angular/material/dialog';
import { MatGridListModule } from '@angular/material/grid-list';
import { CommonModule } from '@angular/common';
import { MatSidenavModule } from '@angular/material/sidenav';
import { MatMenuModule } from '@angular/material/menu';
import { MatToolbarModule } from '@angular/material/toolbar';
import { MatIconModule } from '@angular/material/icon';
import { MatExpansionModule } from '@angular/material/expansion';
import { MatTooltipModule } from '@angular/material/tooltip';
import { MatTable, MatTableDataSource, MatTableModule } from '@angular/material/table';
import { MatPaginator, MatPaginatorModule } from '@angular/material/paginator';
import { Observable, catchError, from, map, startWith, throwError } from 'rxjs';
import { FormGroup, FormControl, Validators } from '@angular/forms';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { MatInputModule } from '@angular/material/input';
import { MatFormFieldModule } from '@angular/material/form-field';
import { MatSelectModule } from '@angular/material/select';
import { MatCheckboxModule } from '@angular/material/checkbox';
import { SnackbarService } from 'src/app/lib/snackbar/snackbar.service';
import { DialogService } from 'src/app/service/dialog.service';
import { MatAutocompleteModule } from '@angular/material/autocomplete';
import { MatTabsModule } from '@angular/material/tabs';
import { MatProgressBarModule } from '@angular/material/progress-bar';
import { CategoryService } from 'src/app/service/category.service';
import { AWSS3Service } from 'src/app/service/aws.s3.service';
import { CategoryModel } from 'src/app/model/category';
import { AuditTrailTableComponent } from "../../components/audit-trail-table/audit-trail-table.component";
import { AuditTrailService } from 'src/app/service/audit.trail.service';
import { FlexLayoutModule } from "@angular/flex-layout";
import { SelectionModel } from '@angular/cdk/collections';
import { AppConstants } from 'src/app/app.constants';
import { CommaDelimiterModule } from 'src/app/lib/comma-delimiter-module/comma-delimiter.module';
import { formatWithCommas } from '../../common-function'
import { GroupService } from 'src/app/service/group.service';
import { UserService } from 'src/app/service/user.service';
import { AuthService } from 'src/app/service/auth.service';
import { ENTER, COMMA } from '@angular/cdk/keycodes';
import { Admin } from 'src/app/subscribe/subscribe.component';
import { AdminGroupModel } from 'src/app/model/admin-group';
import { MatAutocompleteSelectedEvent } from '@angular/material/autocomplete';
import { MatChipInputEvent, MatChipsModule } from '@angular/material/chips';
import { LiveAnnouncer } from '@angular/cdk/a11y';
@Component({
  selector: 'app-group',
  templateUrl: './group.component.html',
  styleUrls: ['./group.component.css'],
  standalone: true,
  imports: [MatSidenavModule, ReactiveFormsModule, CommaDelimiterModule, AsyncPipe, MatChipsModule, FlexLayoutModule, MatAutocompleteModule, MatTabsModule, FormsModule, MatProgressBarModule, MatCheckboxModule, MatSelectModule, ReactiveFormsModule, MatInputModule, MatFormFieldModule,
    MatPaginatorModule, MatTableModule, MatToolbarModule, MatMenuModule, MatIconModule, MatExpansionModule, MatTooltipModule,
    CommonModule, MatGridListModule, MatListModule, NgFor, MatCardModule, MatButtonModule, RouterModule, MatDialogModule, AuditTrailTableComponent]
})
export class GroupComponent {
  displayedColumns: string[] = ['select', 'position', 'name', 'groupadmin', 'active', 'action'];
  dataSource = new MatTableDataSource<CategoryModel>;
  dataSourceAT = new MatTableDataSource<any>;
  selection = new SelectionModel<any>(true, []);
  filteredLevelCategories: Observable<any[]>;

  public dataForm: FormGroup;
  isAddMode: boolean = false;
  isEditMode: boolean = false;
  hasAction: any;
  categories: any;
  categoriesLevel: any;
  pageSize: number = 10;

  @ViewChild(MatPaginator) paginator: MatPaginator;
  @ViewChild(MatTable) table: MatTable<CategoryModel>;

  // Mass upload
  @ViewChild('fileInput')
  fileInput;
  file: File | null = null;
  hasSubmit: boolean = false;
  isMainVisible: boolean = false;
  frequency = this.constant.modelFrequency;
  selectData: any[];
  filteredSelectData: Observable<any[]>;
  user: any;
  isAdmin: boolean = false;
  userIds = '';
  accountAdmin: AdminGroupModel[] = [];
  separatorKeysCodes: number[] = [ENTER, COMMA];
  hasError = {
    value: false,
    message: "Admin is required."
  }


  adminCtrl = new FormControl('');

  @ViewChild('adminInput') adminInput: ElementRef<HTMLInputElement>;

  announcer = inject(LiveAnnouncer);
  ngAfterViewInit() {
    this.dataSource.paginator = this.paginator;
  }

  constructor(
    private router: Router, public dialog: MatDialog,
    private examService: ExamService,
    private snackbarService: SnackbarService,
    private dialogService: DialogService,
    private s3: AWSS3Service,
    private categoryService: CategoryService,
    private auditTrailService: AuditTrailService,
    private constant: AppConstants,
    private groupService: GroupService,
    private userService: UserService,
    private authService: AuthService

  ) {
  }

  ngOnInit() {
    this.initUser();
    this.fetchData();
    this.dataForm = new FormGroup({
      groupId: new FormControl(''),
      name: new FormControl('', [Validators.required, Validators.maxLength(50)]),
      active: new FormControl(true),
    });

    this.hasAction = null;
  }

  validateError(){
    if (this.accountAdmin.length == 0) {
      this.hasError.value = true;
    }else{
      this.hasError.value = false;
    }
  }


  add(event: MatChipInputEvent): void {
    const value = (event.value || '').trim();

    // Add 
    if (value) {
      const account = this.accountAdmin.find(x => x.user == value.trim());
      if (!account) {
        const user = { user: value.trim(), id: 0, isNew: 1 };
        this.accountAdmin.push(user);
      }
    }

    this.validateError();

    // Clear the input value
    event.chipInput!.clear();

    this.adminCtrl.setValue('');
  }

  remove(admin): void {
    const index = this.accountAdmin.indexOf(admin);

    if (index >= 0) {
      this.accountAdmin.splice(index, 1);

      this.announcer.announce(`Removed ${admin}`);
    }

    this.validateError();

  }

  selected(event: MatAutocompleteSelectedEvent): void {
    const value = event.option.viewValue;
    const account = this.accountAdmin.find(x => x.user == value.trim());
    if (!account) {
      const user = { user: value.trim(), id: 0, isNew: 1 };
      this.accountAdmin.push(user);
    }

    this.validateError();

    this.adminInput.nativeElement.value = '';
    this.adminCtrl.setValue('');
  }

  adminEvent() {
    console.log('test')
  }

  edit(item: AdminGroupModel, event: any): void {
    const input = event.input;
    const value = event.value;

    if (value.trim()) {
      // Edit 
      item.user = value.trim();
    }

    // Reset the input value
    if (input) {
      input.value = item.user;
    }
  }

  async initUser() {
    this.user = this.authService.getUser();
    if (this.user != null) {
      this.isAdmin = this.authService.extractId(this.user.azp) === 1;

    }
  }
  refresh() {
    this.fetchData();
    this.selection.clear();
  }

  isAllSelected() {
    const numSelected = this.selection.selected.length;
    const numRows = this.dataSource.data.length;
    return numSelected === numRows;
  }

  /** Selects all rows if they are not all selected; otherwise clear selection. */
  toggleAllRows() {
    if (this.isAllSelected()) {
      this.selection.clear();
      return;
    }

    this.selection.select(...this.dataSource.data);
  }

  /** The label for the checkbox on the passed row */
  checkboxLabel(row?: any): string {
    if (!row) {
      return `${this.isAllSelected() ? 'deselect' : 'select'} all`;
    }
    return `${this.selection.isSelected(row) ? 'deselect' : 'select'} row ${row.position + 1}`;
  }


  async deleteHandler() {
    const ids = this.selection.selected.map(item => item.id)
    await this.delete(ids);
  }

  public checkError = (controlName: string, errorName: string) => {
    return this.dataForm.controls[controlName].hasError(errorName);
  }

  applyFilter(event: Event) {
    const filterValue = (event.target as HTMLInputElement).value;
    this.dataSource.filter = filterValue.trim().toLowerCase();
  }

  cleanInputValue(value: string): string {
    return value.replace(/,/g, '');
  }

  displaySelectData(item: any): string {
    return item ? item.name : '';
  }

  private _filterData(value: any): any[] {
    if (value != null) {
      if (value == '') {
        return this.selectData;
      }
      if (typeof value === 'string') {
        const filterValue = value.toLowerCase();
        return this.selectData.filter(item => item.name.toLowerCase().includes(filterValue));
      } else if (typeof value === 'object' && value.name) {
        return this.selectData.filter(item => item.name.toLowerCase() === value.name.toLowerCase());
      }
    }
    return [];
  }

  async fetchUser() {
    (await this.userService.fetchUsers())
      .pipe(
        catchError(() => {
          return Observable.call(throwError(() => "Error"));
        })
      )
      .subscribe((response) => {
        const data = JSON.parse(JSON.stringify(response));
        data.forEach((item) => {
          item.name = item.email;
        })

        if (this.isEditMode) {
          const idList = this.userIds.split(',').map(id => parseInt(id.trim(), 10));
          for (const id of idList) {
            const user = data.find(x => x.id == id);
            if (user) {
              this.accountAdmin.push({ id: 0, user: user.email, isNew: 0 })
            }
          }
        }

        this.selectData = data;
        this.filteredSelectData = this.adminCtrl.valueChanges.pipe(
          startWith(''),
          map((value: any) => this._filterData(value))
        );
      });
  }

  async fetchData() {
    (await this.groupService.getGroup())
      .pipe(
        catchError(() => {
          return Observable.call(throwError(() => "Error"));
        })
      )
      .subscribe((response) => {
        const data = JSON.parse(JSON.stringify(response));
        this.dataSource.data = data;
        this.paginator.pageSize = this.pageSize;
        this.dataSource.paginator = this.paginator;
      });
  }

  handler() {
    const frequency = this.dataForm.get('frequency');
    const payment = this.dataForm.get('payment');
    if (frequency.value != 0) {
      payment.setValidators([Validators.required]);
    } else {
      payment.clearValidators();
    }
  }

  async onSubmit() {
    if (this.dataForm.valid && !this.hasError.value) {
      const payload = {
        ...this.dataForm.value,
        accountAdminList: this.accountAdmin
      }
      console.log(payload);
      // return;
      if (this.isAddMode) {
        (await this.groupService.addGroup(payload))
          .subscribe((response) => {
            const data = JSON.parse(JSON.stringify(response));
            this.snackbarService.showSnackbar(data.message, 'top', 2000);
            this.fetchData();
            this.auditTrailService.sendNotification(true);
            this.backAction();
          });
      }

      if (this.isEditMode) {
        (await this.groupService.editGroup(payload))
          .subscribe((response) => {
            const data = JSON.parse(JSON.stringify(response));
            this.snackbarService.showSnackbar(data.message, 'top', 2000);
            this.fetchData();
            this.auditTrailService.sendNotification(true);
            this.backAction();
          });
      }

    }
  }

  public onSelectedIndexChange(tabIndex: number) {
    if (tabIndex === 0) {
      this.fetchData();
    }
  }

  async addAction() {
    await this.fetchUser();
    this.isEditMode = false;
    this.isAddMode = true;
  }

  backAction() {
    this.isAddMode = false;
    this.isEditMode = false;
    this.dataForm.reset();
    this.dataForm.patchValue({
      active: true
    });
    this.hasError.value = false;
    this.accountAdmin = [];
    setTimeout(() => {
      this.paginator.pageSize = this.pageSize;
      this.dataSource.paginator = this.paginator;
    }, 0);
  }

  async editItem(data) {
    this.isEditMode = true;
    this.isAddMode = false;
    this.userIds = data.groupadmin;
    await this.fetchUser();
    try {
      this.dataForm.patchValue({
        groupId: data.id,
        name: data.name,
        groupAdmin: { id: data.id, name: data.email },
        active: data.active
      });

    } catch (error) {
      console.error("An error occurred while editing item:", error);
    }
  }

  async delete(ids) {
    this.dialogService.openDialog('Delete data', 'Are you sure you want to delete the selected data?', 'No', true, async (confirmed) => {
      if (confirmed) {
        (await this.groupService.deleteGroup(ids))
          .subscribe((response) => {
            this.selection.clear();
            this.snackbarService.showSnackbar(response.toString(), 'top', 2000);
            this.fetchData();
            this.auditTrailService.sendNotification(true);
          });
      } else {
        console.log('Close');
      }
    });
  }

  async exportAction() {
    (await this.groupService.export())
      .pipe(
        catchError((err) => {
          return Observable.call(throwError(() => "Error"));
        })
      )
      .subscribe((response) => {
        const blob = new Blob([response], { type: 'text/csv' });
        const url = window.URL.createObjectURL(blob);
        const a = document.createElement('a');
        a.href = url;
        a.download = 'Account_group.csv';
        a.click();
        window.URL.revokeObjectURL(url);
        this.auditTrailService.sendNotification(true);
      });
  }

  async importCategory() {
    this.hasSubmit = true;
    try {
      const key = await this.s3.uploadFile(this.file);
      const response = await this.categoryService.importCategory(key);
      if (response.status === 200) {
        this.fetchData();
        this.auditTrailService.sendNotification(true);
        this.snackbarService.showSnackbar(response.data.message, 'top', 10000);
      }

      this.file = null;
      this.hasSubmit = false;
    } catch (error) {
      this.snackbarService.showSnackbar("An error occured while processing the request", 'top', 10000);
      this.file = null;
      this.hasSubmit = false;
    }
  }

  async exportCategoryTemplate() {
    (await this.categoryService.exportCategoryTemplate())
      .pipe(
        catchError((err) => {
          return Observable.call(throwError(() => "Error"));
        })
      )
      .subscribe((response) => {
        const blob = new Blob([response], { type: 'text/csv' });
        const url = window.URL.createObjectURL(blob);
        const a = document.createElement('a');
        a.href = url;
        a.download = 'Category_template.csv';
        a.click();
        window.URL.revokeObjectURL(url);
        this.auditTrailService.sendNotification(true);
      });
  }

  onClickFileInputButton(): void {
    this.fileInput.nativeElement.click();
  }

  onChangeFileInput(): void {
    const files: { [key: string]: File } = this.fileInput.nativeElement.files;
    this.file = files[0];
  }
}
